
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import {
	ProfileVisibilityGroup,
} from "@/../types/interfaces";

@Component({})
export default class EditRecruitingProfilePrivacy extends Mixins(VuetifyMixin) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;
	requiredRule = [(v: any): boolean | string => !!v || "Required"];

	get PrivacyGroups(): ProfileVisibilityGroup[] {
		// TODO: Automaically generate from type instead
		return ["recruiter"];
	}

	sectionKeys = {};

	changeSetting(
		privacyGroup: ProfileVisibilityGroup,
		name: "name" | "position"
	) {
		let newValue: boolean = false;
		let visibility = this.EditAthleteProfile.profileVisibility[privacyGroup];
		switch (name) {
		case "name":
			newValue = !(visibility.firstName && visibility.lastName);
			this.EditAthleteProfile.profileVisibility[
				privacyGroup
			].firstName = newValue;
			this.EditAthleteProfile.profileVisibility[
				privacyGroup
			].lastName = newValue;
			break;
		case "position":
			newValue = !(
				visibility.primaryPosition && visibility.secondaryPosition
			);
			this.EditAthleteProfile.profileVisibility[
				privacyGroup
			].primaryPosition = newValue;
			this.EditAthleteProfile.profileVisibility[
				privacyGroup
			].secondaryPosition = newValue;
			break;
		}
	}

	percentageValueCheck<T>(values: T[], check: T): number {
		return values.filter(value => value === check).length / values.length;
	}

	basicSection(group: ProfileVisibilityGroup): number {
		let keys = [
			this.EditAthleteProfile.profileVisibility[group].birthDate,
			this.EditAthleteProfile.profileVisibility[group].gender,
			this.EditAthleteProfile.profileVisibility[group].pictureUrl,
			this.EditAthleteProfile.profileVisibility[group].height,
			this.EditAthleteProfile.profileVisibility[group].weight,
			this.EditAthleteProfile.profileVisibility[group].dominantSide,
			this.EditAthleteProfile.profileVisibility[group].email,
			this.EditAthleteProfile.profileVisibility[group].phone,
			this.EditAthleteProfile.profileVisibility[group].primaryPosition,
			this.EditAthleteProfile.profileVisibility[group].secondaryPosition,
			this.EditAthleteProfile.profileVisibility[group].country,
			this.EditAthleteProfile.profileVisibility[group].city,
			this.EditAthleteProfile.profileVisibility[group].teams,
			this.EditAthleteProfile.profileVisibility[group].socialMedia,
		];
		return this.percentageValueCheck(keys, true);
	}
	toggleBasicSection(group: ProfileVisibilityGroup) {
		if (this.basicSection(group) != 1) {
			this.EditAthleteProfile.profileVisibility[group].birthDate = true;
			this.EditAthleteProfile.profileVisibility[group].gender = true;
			this.EditAthleteProfile.profileVisibility[group].pictureUrl = true;
			this.EditAthleteProfile.profileVisibility[group].height = true;
			this.EditAthleteProfile.profileVisibility[group].weight = true;
			this.EditAthleteProfile.profileVisibility[group].dominantSide = true;
			this.EditAthleteProfile.profileVisibility[group].email = true;
			this.EditAthleteProfile.profileVisibility[group].phone = true;
			this.EditAthleteProfile.profileVisibility[group].primaryPosition = true;
			this.EditAthleteProfile.profileVisibility[group].secondaryPosition = true;
			this.EditAthleteProfile.profileVisibility[group].country = true;
			this.EditAthleteProfile.profileVisibility[group].city = true;
			this.EditAthleteProfile.profileVisibility[group].socialMedia = true;
		} else {
			this.EditAthleteProfile.profileVisibility[group].birthDate = false;
			this.EditAthleteProfile.profileVisibility[group].gender = false;
			this.EditAthleteProfile.profileVisibility[group].pictureUrl = false;
			this.EditAthleteProfile.profileVisibility[group].height = false;
			this.EditAthleteProfile.profileVisibility[group].weight = false;
			this.EditAthleteProfile.profileVisibility[group].dominantSide = false;
			this.EditAthleteProfile.profileVisibility[group].email = false;
			this.EditAthleteProfile.profileVisibility[group].phone = false;
			this.EditAthleteProfile.profileVisibility[group].primaryPosition = false;
			this.EditAthleteProfile.profileVisibility[group].secondaryPosition = false;
			this.EditAthleteProfile.profileVisibility[group].country = false;
			this.EditAthleteProfile.profileVisibility[group].city = false;
			this.EditAthleteProfile.profileVisibility[group].socialMedia = false;
		}
	}

	bioSection(group: ProfileVisibilityGroup): number {
		let keys = [
			this.EditAthleteProfile.profileVisibility[group].shortBio,
			this.EditAthleteProfile.profileVisibility[group].schedule,
			this.EditAthleteProfile.profileVisibility[group].accolades,
			this.EditAthleteProfile.profileVisibility[group].academicAwards,
			this.EditAthleteProfile.profileVisibility[group].extraCurriculars,
			this.EditAthleteProfile.profileVisibility[group].previousClubs,
			this.EditAthleteProfile.profileVisibility[group].otherActivities,
			this.EditAthleteProfile.profileVisibility[group].membershipID,
			this.EditAthleteProfile.profileVisibility[group].highlightDetails,
			this.EditAthleteProfile.profileVisibility[group].fullmatchDetails,
			this.EditAthleteProfile.profileVisibility[group].highestLevelPlayed,
			this.EditAthleteProfile.profileVisibility[group].currentLevel,
			this.EditAthleteProfile.profileVisibility[group].languagesSpoken,
			this.EditAthleteProfile.profileVisibility[group].citizenships
		];
		return this.percentageValueCheck(keys, true);
	}
	toggleBioSection(group: ProfileVisibilityGroup) {
		if (this.bioSection(group) != 1) {
			this.EditAthleteProfile.profileVisibility[group].shortBio = true
			this.EditAthleteProfile.profileVisibility[group].schedule = true;
			this.EditAthleteProfile.profileVisibility[group].accolades = true
			this.EditAthleteProfile.profileVisibility[group].academicAwards = true
			this.EditAthleteProfile.profileVisibility[group].extraCurriculars = true
			this.EditAthleteProfile.profileVisibility[group].previousClubs = true
			this.EditAthleteProfile.profileVisibility[group].otherActivities = true
			this.EditAthleteProfile.profileVisibility[group].membershipID = true
			this.EditAthleteProfile.profileVisibility[group].highlightDetails = true
			this.EditAthleteProfile.profileVisibility[group].fullmatchDetails = true
			this.EditAthleteProfile.profileVisibility[group].highestLevelPlayed = true
			this.EditAthleteProfile.profileVisibility[group].currentLevel = true
			this.EditAthleteProfile.profileVisibility[group].languagesSpoken = true
			this.EditAthleteProfile.profileVisibility[group].citizenships = true
		} else {
			this.EditAthleteProfile.profileVisibility[group].shortBio = false
			this.EditAthleteProfile.profileVisibility[group].schedule = false;
			this.EditAthleteProfile.profileVisibility[group].accolades = false
			this.EditAthleteProfile.profileVisibility[group].academicAwards = false
			this.EditAthleteProfile.profileVisibility[group].extraCurriculars = false
			this.EditAthleteProfile.profileVisibility[group].previousClubs = false
			this.EditAthleteProfile.profileVisibility[group].otherActivities = false
			this.EditAthleteProfile.profileVisibility[group].membershipID = false
			this.EditAthleteProfile.profileVisibility[group].highlightDetails = false
			this.EditAthleteProfile.profileVisibility[group].fullmatchDetails = false
			this.EditAthleteProfile.profileVisibility[group].highestLevelPlayed = false
			this.EditAthleteProfile.profileVisibility[group].currentLevel = false
			this.EditAthleteProfile.profileVisibility[group].languagesSpoken = false
			this.EditAthleteProfile.profileVisibility[group].citizenships = false
		}
	}

	educationSection(group: ProfileVisibilityGroup): number {
		let keys = [
			this.EditAthleteProfile.profileVisibility[group].highschoolName,
			this.EditAthleteProfile.profileVisibility[group].highschoolGradYear,
			this.EditAthleteProfile.profileVisibility[group].highschoolGPA,
			this.EditAthleteProfile.profileVisibility[group].schoolCommitment,
			this.EditAthleteProfile.profileVisibility[group].ncaaID,
			this.EditAthleteProfile.profileVisibility[group].SATScore,
			this.EditAthleteProfile.profileVisibility[group].ACTScore,
			this.EditAthleteProfile.profileVisibility[group].anticipatedStudy,
		]
		return this.percentageValueCheck(keys, true);
	}
	toggleEducationSection(group: ProfileVisibilityGroup) {
		if (this.educationSection(group) != 1) {
			this.EditAthleteProfile.profileVisibility[group].highschoolName = true
			this.EditAthleteProfile.profileVisibility[group].highschoolGradYear = true
			this.EditAthleteProfile.profileVisibility[group].highschoolGPA = true
			this.EditAthleteProfile.profileVisibility[group].schoolCommitment = true
			this.EditAthleteProfile.profileVisibility[group].ncaaID = true
			this.EditAthleteProfile.profileVisibility[group].SATScore = true
			this.EditAthleteProfile.profileVisibility[group].ACTScore = true
			this.EditAthleteProfile.profileVisibility[group].anticipatedStudy = true
		} else {
			this.EditAthleteProfile.profileVisibility[group].highschoolName = false
			this.EditAthleteProfile.profileVisibility[group].highschoolGradYear = false
			this.EditAthleteProfile.profileVisibility[group].highschoolGPA = false
			this.EditAthleteProfile.profileVisibility[group].schoolCommitment = false
			this.EditAthleteProfile.profileVisibility[group].ncaaID = false
			this.EditAthleteProfile.profileVisibility[group].SATScore = false
			this.EditAthleteProfile.profileVisibility[group].ACTScore = false
			this.EditAthleteProfile.profileVisibility[group].anticipatedStudy = false
		}
	}
}
