import { FrontEndModel } from '@/models/FrontEndModel';
import { ScoutingObservationModel } from "@/models";

export class GameReportModel extends FrontEndModel {
    dateCreated!: Date;
    eventId?: string;                               // optional calendar event ID
    date!: Date;                                    // event date
    locationId?: string;                            // locationId if location is on BA or event location
    coachId!: string;                               // coachId for coach who created the game report
    teamId?: string;                                // optional teamId for a team game report
    opponent?: string;                              // oponentId if team is on BA or opponet team name
    athletes!: Array<string>                        // athleteId for participating athletes
    observations!: Array<ScoutingObservationModel>  // athlete observations
    title?: string;                                 // event title
    notes?: string;                                 // coach notes

	constructor(obj) {
		super();
        this.dateCreated = new Date();
        this.date = new Date();
		this.load(obj);
	}

	load(obj: Record<string, any>): this {
        super.load(obj);
		if (obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		if (obj['date']) this.date = new Date(obj['date']);
		return this;
	}

}