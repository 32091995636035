import { Component, Vue } from 'vue-property-decorator';
import { ScoutingReportScore, ScoutingReportScoreValues } from "@/../types/enums";
import { SelectorItem } from './selectorItem';

@Component
export class ScoutingReportScoreSelectorMixin extends Vue {
	get AvailableScoutingReportScoreValues(): SelectorItem<ScoutingReportScore>[] {
		return ScoutingReportScoreValues.map((value, i) => ({
			text: i === 4 ? '4+' :`${value}`,
			value: value,
		}));
	}

	ScoreColor(value: string | number): string {
		if( Number(value) > 4.25 ) return 'purple darken-3';
		else if( Number(value) > 3.25 ) return 'green darken-4';
		else if( Number(value) > 2.75 ) return 'light-green darken-3';
		else if( Number(value) > 1.75 ) return 'lime darken-3';
		else if( Number(value) > 0.75 ) return 'deep-orange darken-4';
		else if( Number(value) > 0 ) return 'red darken-4';
		return 'grey'
	}
}