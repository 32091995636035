
import { Component, Vue, Model, Mixins } from 'vue-property-decorator';
import {VuetifyMixin, BAIconsMixin} from '@/mixins'

@Component
export class YearPicker extends Mixins(VuetifyMixin, BAIconsMixin){
	@Model('input', { type: Number , default: 2021 }) value!: number;

	get Years(): number[] {
		const listOfYears = []
		let today = new Date()
		let max = today.getFullYear()
		let min = max - 100
		for (let index = max; index >= min; index--) {
			listOfYears.push(index)
		}
		return listOfYears
	}
}
export default YearPicker;
