export function capitalize(value: string): string {
	if (!value) return "";
	return value.replace(/^\w/, c=> c.toUpperCase());
}

export function lowercase(value: string): string {
	if (!value) return "";
	return value.toLowerCase();
}

export function uppercase(value: string): string {
	if (!value) return "";
	return value.toUpperCase();
}