// CoachAthleteReportsMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { ScoutingReportScore } from "@/../types";
import { StringsMixin, MyCoachMixin } from '@/mixins';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { AthleteReportModel } from "@/models";
import { athleteReportApi } from '@/api/AthleteReportApi';

@Component
export class CoachAthleteReportsMixin extends Mixins(StringsMixin, MyCoachMixin) {
    ScoutingReportScore = ScoutingReportScore;

    mounted() {
        this.loadAthleteReports();
    }

    loadingAthleteReports: boolean = false;
    athleteReports: Array<AthleteReportModel> = [];
	async loadAthleteReports() {
        if( this.IsEmpty(this.AthleteReportsAthleteId) ) return;

        this.loadingAthleteReports = true;
		const query: RepositoryQuery<AthleteReportModel> = {
			$match: {
				athleteId: this.AthleteReportsAthleteId,
				coachId: this.MyCoachId,
			}
		}
		const options: QueryOptions = { sort: { fields: [{ field: 'date', desc: true }] } };
		const response: PaginatedResponse<AthleteReportModel> = await athleteReportApi.queryAll(query, options);
        this.athleteReports = response.docs;
        this.loadingAthleteReports = false;
	}
    get HasAthleteReports(): boolean {
        return this.IsNotEmptyArray(this.athleteReports);
    }
    get AthleteReportsAthleteId(): string {
        return 'this function must be overloaded';
    }
}
