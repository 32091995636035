
import md5 from 'js-md5'
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { UserProfileModel } from '@/models/UserProfileModel';
import { VuetifyMixin } from '@/mixins';

@Component({
	components: { },
})
export default class UserChip extends Mixins(VuetifyMixin) {
	@Prop({ default: 'baColorVibrantBlue' }) color: string;
	@Prop({ default: undefined }) backgroundColor: string | undefined;
	@Prop({ default: 'medium'}) private fontSize: string;
	@Prop({ default: 35 }) avatarSize: number;
	@Prop({ default: undefined }) borderSize: number | undefined;
	@Prop({ default: false, type: Boolean }) hideName: boolean;
	@Prop({ default: 2, type: Number }) avatarMargin: number;
	@Prop({
		required: true,
		default: (): Partial<UserProfileModel> => ({firstName: "", lastName: ""})
	}) private userInfo: Partial<UserProfileModel>;

	@Prop({ type: Boolean, default: true }) padding: boolean;
	@Prop({ type: Boolean, default: false }) private dark: boolean;
	@Prop({ type: Boolean, default: false }) hideAvatar: boolean;
	@Prop({ type: Boolean, default: false }) private useGravatar: boolean;

	@Prop({ type: String, default: null }) profileId: string | null;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean }) athlete;
	@Prop({ type: Boolean }) coach;

	@Prop({ type: Boolean }) nolink;
	@Prop({ type: String, default: undefined }) title: string;

	get InfoLookupDisabled(): boolean{
		return this.disabled || (this.userInfo.pictureUrl && this.userInfo.pictureUrl.length > 0);
	}

	get Dark(): boolean{
		return this.dark;
	}

	get ProfileId(): string | undefined{
		if(this.profileId === null) return this.userInfo.id;
		return this.profileId;
	}
	get ImageUrl() : string | null{
		if(!this.userInfo) return null;
		if(this.userInfo.pictureUrl){
			return this.userInfo.pictureUrl;
		}else if(this.userInfo.email && this.useGravatar){
			return `https://www.gravatar.com/avatar/${md5(this.userInfo.email)}.jpg?size=120`;
		}else{
			return null;
		}
	}
	get Name(): string{
		return `${ this.userInfo.firstName } ${ this.userInfo.lastName }`;
	}

	get NameDecoration(): string {
		return this.nolink ? '' : 'underline';
	}
	get NameColor(): string {
		return this.nolink ? 'black' : 'primary'
	}

	get HasTitleSlot(): boolean{
		return this.$slots.title !== undefined;
	}

	get FontClass(): string{
		if( this.fontSize.toUpperCase().startsWith('S') ) return 'user-name-sm';
		if( this.fontSize.toUpperCase().startsWith('L') ) return 'user-name-lg';
		if( this.fontSize.toUpperCase().startsWith('X') ) return 'user-name-xl';
		return 'user-name-md';
	}
}
