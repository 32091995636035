import store from "../store";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from "@/../types/interfaces";
import { BASchoolModel } from "@/models/baSchool/BASchoolModel";
import { baSchoolApi } from "@/api/BASchoolApi";

const Mutations = {
	QUERY_BASCHOOLS: 'QUERY_BASCHOOLS',
	QUERY_BASCHOOLS_SUCCESS: 'QUERY_BASCHOOLS_SUCCESS',
	QUERY_BASCHOOLS_FAILURE: 'QUERY_BASCHOOLS_FAILURE',
	FINDBYID_BASCHOOLS: 'FINDBYID_BASCHOOLS',
	FINDBYID_BASCHOOLS_SUCCESS: 'FINDBYID_BASCHOOLS_SUCCESS',
	FINDBYID_BASCHOOLS_FAILURE: 'FINDBYID_BASCHOOLS_FAILURE',
	SAVE_BASCHOOL: 'SAVE_BASCHOOL',
	SAVE_BASCHOOL_SUCCESS: 'SAVE_BASCHOOL_SUCCESS',
	SAVE_BASCHOOL_FAILURE: 'SAVE_BASCHOOL_FAILURE',
	DELETE_BASCHOOL: 'DELETE_BASCHOOL',
	DELETE_BASCHOOL_SUCCESS: 'DELETE_BASCHOOL_SUCCESS',
	DELETE_BASCHOOL_FAILURE: 'DELETE_BASCHOOL_FAILURE',
	CLEANUP_BASCHOOLS: 'CLEANUP_BASCHOOLS',
	CLEANUP_BASCHOOLS_SUCCESS: 'CLEANUP_BASCHOOLS_SUCCESS',
	CLEANUP_BASCHOOLS_FAILURE: 'CLEANUP_BASCHOOLS_FAILURE',
}

const name = "BASchoolStore";

if (store.state[name]) {
	store.unregisterModule(name);
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store,
})
export default class BASchoolModule extends VuexModule {
	numBASchools: number = 0;
	activeBASchools: number = 0;
	baSchools: BASchoolModel[] = [];
	currentBASchool: BASchoolModel = undefined;

	loadingBASchools: boolean = false;
	findingBASchool: boolean = false;
	creatingBASchool: boolean = false;
	deletingBASchool: boolean = false;
	cleaningBASchools: boolean = false;
	@Action({ rawError: true })
	async loadBASchools( {query, options} : {query?: RepositoryQuery<BASchoolModel>, options?: QueryOptions } = {}): Promise<BASchoolModel[]> {
		this.context.commit(Mutations.QUERY_BASCHOOLS);
		try {
			const [{count}, data] = await Promise.all([
				baSchoolApi.countAll(),
				baSchoolApi.queryAll( query, options ),
			]);
			this.context.commit(Mutations.QUERY_BASCHOOLS_SUCCESS, { count, data });
			return this.baSchools;
		} catch (e) {
			this.context.commit(Mutations.QUERY_BASCHOOLS_FAILURE, e);
			return this.baSchools;
		}
	}
	@Mutation [Mutations.QUERY_BASCHOOLS](): void {
		this.loadingBASchools = true;
	}
	@Mutation [Mutations.QUERY_BASCHOOLS_SUCCESS]({count, data}: {count: number, data: PaginatedResponse<BASchoolModel>}): void {
		this.loadingBASchools = false;
		this.activeBASchools = count;
		this.numBASchools = data.total;
		this.baSchools = data.docs;
	}
	@Mutation [Mutations.QUERY_BASCHOOLS_FAILURE](error: any): void {
		this.loadingBASchools = false;
	}

	@Action async findBASchool(baSchoolId: string): Promise<BASchoolModel> {
		this.context.commit(Mutations.FINDBYID_BASCHOOLS);
		try {
			const data = await baSchoolApi.findById(baSchoolId);
			this.context.commit(Mutations.FINDBYID_BASCHOOLS_SUCCESS, data);
			return data;
		} catch (e) {
			this.context.commit(Mutations.FINDBYID_BASCHOOLS_FAILURE, e);
		}
	}
	@Mutation [Mutations.FINDBYID_BASCHOOLS](): void {
		this.findingBASchool = true;
	}
	@Mutation [Mutations.FINDBYID_BASCHOOLS_SUCCESS](data: BASchoolModel): void {
		this.currentBASchool = data;
		this.findingBASchool = false;
	}
	@Mutation [Mutations.FINDBYID_BASCHOOLS_FAILURE](error: any): void {
		this.findingBASchool = false;
	}
    
	@Action async saveBASchool(baSchool: BASchoolModel): Promise<BASchoolModel> {
		this.context.commit(Mutations.SAVE_BASCHOOL)
		try{
			const newSchool = await baSchoolApi.save(baSchool);
			this.context.commit(Mutations.SAVE_BASCHOOL_SUCCESS);
			return newSchool;
		}catch(e){
			this.context.commit(Mutations.SAVE_BASCHOOL_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_BASCHOOL](){
		this.creatingBASchool = true;
	}
	@Mutation [Mutations.SAVE_BASCHOOL_SUCCESS](){
		this.creatingBASchool = false;
	}
	@Mutation [Mutations.SAVE_BASCHOOL_FAILURE](){
		this.creatingBASchool = false;
	}

	@Action async deleteBASchool(baSchool: BASchoolModel): Promise<BASchoolModel> {
		this.context.commit(Mutations.DELETE_BASCHOOL)
		try{
			const delBASchool = await baSchoolApi.delete(baSchool);
			this.context.commit(Mutations.DELETE_BASCHOOL_SUCCESS);
			return delBASchool;
		}catch(e){
			this.context.commit(Mutations.DELETE_BASCHOOL_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_BASCHOOL](){
		this.deletingBASchool = true;
	}
	@Mutation [Mutations.DELETE_BASCHOOL_SUCCESS](){
		this.deletingBASchool = false;
	}
	@Mutation [Mutations.DELETE_BASCHOOL_FAILURE](){
		this.deletingBASchool = false;
	}

	@Action async cleanupDatabase() {
		this.context.commit(Mutations.CLEANUP_BASCHOOLS)
		try{
			await baSchoolApi.cleanup();
			this.context.commit(Mutations.CLEANUP_BASCHOOLS_SUCCESS);
		}catch(e){
			this.context.commit(Mutations.CLEANUP_BASCHOOLS_FAILURE, e);
		}
	}
	@Mutation [Mutations.CLEANUP_BASCHOOLS](){
		this.cleaningBASchools = true;
	}
	@Mutation [Mutations.CLEANUP_BASCHOOLS](){
		this.cleaningBASchools = false;
	}
	@Mutation [Mutations.CLEANUP_BASCHOOLS](){
		this.cleaningBASchools = false;
	}

}