import { FrontEndModel } from '../FrontEndModel';
import { RubricModel } from './RubricModel';
import { SourceVideoMuxModel } from '../source-video/SourceVideoMuxModel';
import { MarkupTagClipMuxModel } from '../markup-tag-clip-mux/MarkupTagClipMuxModel';
import { AssignmentStatus } from '@/../types/enums';
import { Evaluation } from '@/../types/interfaces';

export class AssignmentModel extends FrontEndModel {
	name: string = "";
	details: string | null = null;
	identifier: string = "";
	color: string = "";
	backgroundColor: string = "";
	description: string = '';
	rubric: RubricModel | null;
  sourceVideoSubmissions: SourceVideoMuxModel[] = [];
  sourceVideoSubmissionIds: string[] = [];
	sourceVideoClips: MarkupTagClipMuxModel[] = [];
	active: boolean = false;
	status: AssignmentStatus = AssignmentStatus.NotStarted;
	evaluation: Evaluation | null = null;
	submittedAt: Date | null = null;

	useDocumentUpload: boolean = false;
	/**
	 * Id unique for this assignment - but shared between all students' copies of the assignment.
	 * 
	 * Use to identify which file attachments were added by the instructor
	 * 
	 * */
	documentUploadId: string = "";
	useVideoReview: boolean = true;
	calendlyUrl: string | null = null;

	/**
	 * Returns a deep copy of this instance.
	 */
	copy<AssignmentModel>(): AssignmentModel{
		return <AssignmentModel><unknown>(new AssignmentModel().load({
			...this,
			rubric: this.rubric !== null ? this.rubric.copy() : null,
			sourceVideoSubmissions: this.sourceVideoSubmissions.map(videoSubmission => ({
				...videoSubmission,
			})),
			sourceVideoSubmissionIds: this.sourceVideoSubmissionIds.map(videoSubmissionId => ({
				videoSubmissionId,
			})),
			sourceVideoClips: this.sourceVideoClips.map(videoClips => ({
				...videoClips,
				tagKeywords: videoClips.tagKeywords.slice(),
			})),
			evaluation: {
				...this.evaluation
			}
		}));
	}

	/**
	 * Returns a deep copy of this instance with id removed
	 */
	copyAsTemplate(): AssignmentModel{
		const newTemplate = this.copy<AssignmentModel>();
		delete (<any>newTemplate)._id;
		delete newTemplate.id;
		return <AssignmentModel>newTemplate;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['rubric']) this.rubric = new RubricModel().load(obj['rubric']);
		if (obj['submittedAt']) this.submittedAt = new Date(obj['submittedAt']);
		this.sourceVideoSubmissions = this.sourceVideoSubmissions.filter(p => p !== null).map(p => new SourceVideoMuxModel().load(p));
		this.sourceVideoClips = this.sourceVideoClips.filter(p => p !== null).map(p => new MarkupTagClipMuxModel().load(p));
		return this;
	}

	/**
	 * Returns a template assignment for an assignment with no Rubric (ie. document upload only)
	 */
	static getDocumentUploadAssignment(): AssignmentModel{
		const assignment = new AssignmentModel();
		assignment.name = 'Assignment';
		assignment.identifier = '1';
		assignment.description = '';
		assignment.rubric = null;
		assignment.color = 'baColorGray2a';
		assignment.backgroundColor = 'baColorGray4';
		return assignment.copyAsTemplate();
	}
}