// AthleteApiMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin } from '@/mixins';
import { sportStore } from '@/store';

@Component
export class AthleteApiMixin extends Mixins(AppHostnameMixin) {
	created() {
		this.loadSports();
	}

	loadedSports = false;
	async loadSports(): Promise<void>{
		this.loadedSports = false;
		await sportStore.loadSports();
		this.loadedSports = true;
	}
	get IsAthleteApiReady(): boolean {
		return this.loadedSports;
	}
}
