
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mindsetReportApi } from '@/api/MindsetReportApi';
import { MindsetReportModel } from '@/models/mindset/MindsetReportModel';
import { MindsetError } from "@/../types/constants/ryzer"

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class MindsetProvider extends Vue{
	@Prop({ default: 'div' }) private as: string;
	@Prop() private profileId: string;

	hasMindset: boolean = false;
	mindsetReport: MindsetReportModel = undefined;
	archetype: string = '';
	error: string = '';
	noUser: boolean = false;
	noTest: boolean = false;

	get SlotProps(): { hasMindset: boolean, archetype: string, mindsetReport: MindsetReportModel, noUser: boolean, noTest: boolean, error: string } {
		return {
			hasMindset: this.hasMindset,
			archetype: this.archetype,
			mindsetReport: this.mindsetReport,
			noUser: this.noUser,
			noTest: this.noTest,
			error: this.error
		};
	}

	created(): void {
		this.loadMindset();
	}

	// watch the profileId prop. if it changes, load the profile
	@Watch('profileId')
	loadMindset(): void{
		if( !this.profileId ) return;

		this.lookupProfile( this.profileId );
	}

	async lookupProfile(profileId: string): Promise<MindsetReportModel>{
		if( !profileId ) return;

		this.mindsetReport = await mindsetReportApi.findById(profileId);
		if( !!this.mindsetReport ) {
			this.hasMindset = this.mindsetReport.error == '' && this.mindsetReport.archetype != '';
			this.archetype = this.mindsetReport.archetype;
			this.error = this.mindsetReport.error;
			this.noUser = this.mindsetReport.error.indexOf(MindsetError.RyzerUserDoesNotExist) >= 0;
			this.noTest = this.mindsetReport.error.indexOf(MindsetError.RyzerUserNoTAP) >= 0;
		}

		return this.mindsetReport;
	}
}
