// ScoutingObservationModel.ts

import { FrontEndModel } from '@/models/FrontEndModel';
import { ScoutingReportScore } from '@best-athletes/ba-types';

export class ScoutingObservationModel extends FrontEndModel {
	date!: Date;
	coachId!: string;
	athleteId!: string;
	eventId?: string;
	label!: string;
	rating!: ScoutingReportScore;
	notes?: string;

	constructor(obj) {
		super();
		this.date = new Date();
		this.load(obj);
		if( !!obj.date ) this.date = new Date(obj.date);
	}
}