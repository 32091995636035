import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import * as Routes from '@/../types/constants/web_client_user.routes';
import { AdminAppHostnames, ClubAdminAppHostnames } from '@/../types/constants/hostnames';
import { EnvironmentName } from '@/../types/constants/environments';
import { redirectToAuth0Logout, redirectToAuth0Login, redirectToAuth0Signup } from '@/auth/auth0Redirects';

import NotFound from '../views/NotFound.vue'
import Unauthorized from '../views/Unauthorized.vue'

import { actionUrlPreGuard, actionUrlPostHook } from '../router/routeGuards/actionUrlGuards'
import { ProfileManagementRouteConfigs } from './ProfileManagementRouteConfigs';
import { AdminRouteConfigs } from './AdminRouteConfigs';
import { OrgAdminRouteConfigs } from './OrgAdminRouteConfigs';
import { AthleteRouteConfigs } from './AthleteRouteConfigs';
import { CoachRouteConfigs } from './CoachRouteConfigs';
import { OnboardingRouteConfigs } from './OnboardingRouteConfigs';
import { OnboardingLayout, SimplePageLayout } from '../layouts'
import LoadingPage from '@/views/LoadingPage.vue';
import InvitationDecline from '@/views/InvitationDecline.vue';
import { authGuard } from '@/auth/authGuard';

import { config } from '@/config/config'
import { PublicCoachRouteConfigs } from './PublicCoachRouteConfigs';
import { PublicAthleteRouteConfigs } from './PublicAthleteRouteConfigs';

export const ENV_NAME: EnvironmentName = config.BA_ENV;
const DEV_MODE = ENV_NAME !== 'production';

Vue.use(Router);

export interface HinderRouteMetadata{
	hideAppBar?: boolean,
	showOnMobile?: boolean,
	pageTitle?: string,
	useBackButton?: boolean,
	appBarColor?: string,
	onClickTitle?: () => void,
	systemBarColor?: string,
}

function getRoutes(hostname: string): RouteConfig[]{
	if(DEV_MODE){
		console.log("[router.ts DEV_MODE] Generating Routes for Hostname", hostname);
	}
	const routes: RouteConfig[] = [];
	if (AdminAppHostnames.includes(<any>hostname)){
		// Use Internal Admin Routes
		routes.push(
			...AdminRouteConfigs,
		);
	} else if (ClubAdminAppHostnames.includes(<any>hostname)){
		// Use Club Admin Routes
		routes.push(
			...OrgAdminRouteConfigs,
		);
	}else{
		routes.push(
			...AthleteRouteConfigs,
			...ProfileManagementRouteConfigs,
			...OnboardingRouteConfigs,
		);
		routes.push(
			...CoachRouteConfigs,
		);
	}
	routes.push(
		...PublicCoachRouteConfigs,
		...PublicAthleteRouteConfigs,
	)

	routes.push({
		/**
		 * This route is the base route for the different decline invitation routes
		 */
		path: '/invitation/decline',
		component: OnboardingLayout,
		children:[
			{
				path: 'team/:key',
				name: Routes.AthleteTeamInvitationDecline,
				component: InvitationDecline,
				props: {
					text: 'Team Invitiation Declined'
				},
			}
		]
	})
	routes.push({
		/**
		 * This redirect page is to provide a location where the user can be sent with an actionable URL without being kicked out by the roles guard.
		 */
		path: '/redirect',
		component: OnboardingLayout,
		beforeEnter: authGuard,
		children:[
			{
				path: '',
				name: Routes.LoadingPage,
				component: LoadingPage,
				props: {
					redirect: true,
					timeout: 10,
					message: "Please wait. You will be redirected shortly.",
				},
			}
		]
	})
	routes.push({
		path: '/logout',
		redirect: redirectToAuth0Logout,
	});
	routes.push({
		path: '/login',
		redirect: redirectToAuth0Login,
	});
	routes.push({
		path: '/signup',
		redirect: redirectToAuth0Signup,
	});
	routes.push({
		path: '/unauthorized',
		component: OnboardingLayout,
		children:[
			{
				name: Routes.Unauthorized,
				path: '',
				component: Unauthorized,
			}
		]
	});
	routes.push({
		path: '*',
		component: OnboardingLayout,
		children:[
			{
				name: Routes.NotFound,
				path: '',
				component: NotFound,
			}
		]
	});
	return routes;
}

const routes = getRoutes(location.host);

export const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(actionUrlPreGuard);
router.afterEach(actionUrlPostHook);

export default router
