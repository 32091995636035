import { AppMetadata, Auth0Identity, BAUser, UserMetadata } from '@/../types/interfaces';
import { RoleName } from '@/../types/enums';
import { FrontEndModel } from '../FrontEndModel';

export class UserModel extends FrontEndModel implements BAUser {
	roles: RoleName[];
	email: string;
	email_verified: boolean;
	pictureUrl: string;
	firstName: string;
	lastName: string;
	groups: string[];
	athleteId?: string;
	coachId?: string;
	app_metadata?: AppMetadata;
	user_metadata?: UserMetadata;
	identities?: Auth0Identity[];
}
