// ShowcaseEventMixin.vue

import { Component, Mixins } from 'vue-property-decorator';
import { BAEventModel } from '@/models/baEvent';
import { BAShowcaseEvent } from '@/../types/constants/web_client_user.routes'
import { baEventApi } from '@/api/BAEventsApi';
import { StringsMixin } from './StringsMixin';

@Component
export class ShowcaseEventMixin extends Mixins(StringsMixin) {
	mounted() {
        this.loadShowcaseEvents();
	}

	showcaseEvents: BAEventModel[] = [];
	showcaseEventsLoaded = false;
	async loadShowcaseEvents() {
		this.showcaseEventsLoaded = false;
		const result = await baEventApi.queryAll({$match: {showcase: true}}, {});
		this.showcaseEvents = [];
		const now = new Date();
		for( const ev of result.docs ) {
			if( ev.showcaseStart < now && ev.showcaseEnd > now ) this.showcaseEvents.push(ev);
		}
		this.showcaseEventsLoaded = true;
	}
	get HasShowcaseEvents(): boolean {
		if( this.IsEmpty(this.showcaseEvents) ) return false;
		return this.showcaseEvents.length > 0;
	}

	gotoEvent(eventId: string) {
		this.$router.push({
			name: BAShowcaseEvent,
			params: { eventId }
		}); 
	}
}
