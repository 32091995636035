import { CrudApi } from './CrudApi';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { PaginatedResponse, QueryOptions, RepositoryQuery, ScoutingReportEvaluationAverage } from '@/../types/interfaces';
import { AxiosRequestConfig } from 'axios';

class ScoutingReportApi extends CrudApi<ScoutingReportModel>{
	constructor() {
		super('scouting-report', (obj) => new ScoutingReportModel().load(obj));
	}
	/**
	 * Uses `queryByAthleteId` to lookup a single report using Athlete ACLs instead of the report ACLs.
	 * 
	 * Use this when the athlete wants to load their own report
	 */
	async findByAthleteId(athleteId: string, reportId: string, config?: AxiosRequestConfig): Promise<ScoutingReportModel | null> {
		const pagination = await this.queryByAthleteId(athleteId, {
			$match: {
				id: reportId
			}
		},{
			limitPerPage: 1,
		});
		const [report] = pagination.docs;
		if (report === undefined) {
			return null;
		}
		return report;
	}
	async queryByAthleteId(athleteId: string, query: RepositoryQuery<ScoutingReportModel> = {}, options: QueryOptions = {}, config?: AxiosRequestConfig): Promise<PaginatedResponse<ScoutingReportModel>> {
		const response: PaginatedResponse<ScoutingReportModel> = await CrudApi.Api(c => c.post(`${this.resource}/athlete/${athleteId}/queryByAthleteId`, query, this.applyOptionsToRequest(options, config)));
		response.docs = response.docs.map(x => this.create(x));
		return response;
	}
	async queryEvaluationAverageByAthleteId(athleteId: string, query: RepositoryQuery<ScoutingReportModel> = {}, options: QueryOptions = {}, config?: AxiosRequestConfig): Promise<ScoutingReportEvaluationAverage> {
		const response: ScoutingReportEvaluationAverage = await CrudApi.Api(c => c.post(`${this.resource}/athlete/${athleteId}/queryEvaluationAverage`, query, this.applyOptionsToRequest(options, config)));
		return response
	}
}
export const scoutingReportApi = new ScoutingReportApi();