// BAPartnerMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { PageState } from '@/models/PageState';
import { baPartnerApi } from '@/api/BAPartnerApi';
import { BAPartnerModel } from '@/models';
import { StringsMixin } from './StringsMixin';

@Component
export class BAPartnerMixin extends Mixins(StringsMixin) {
    partnerName!: string; // class using BAPartnerMixin must specify a partnerName
    partner: BAPartnerModel;

    pageState = new PageState("Ready");

    loadingPartner: boolean = false;
    ready: boolean = false;

    async created() {
        this.loadingPartner = true;
        this.partner = await baPartnerApi.findByName(this.partnerName);
        this.loadingPartner = false;
        this.ready = this.IsNotEmpty(this.partner);
    }

    get LoadingPage(): boolean {
        return this.loadingPartner;
    }
    get IsReady(): boolean {
        return this.ready;
    }
}