import { Component,  Vue } from 'vue-property-decorator';
import { Stripe } from "@stripe/stripe-js";
import { CustomerApi } from '@/api/CustomerApi';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { AthleteCheckoutPage } from '@/../types/constants/web_client_user.routes';
import { StartCheckoutParams } from '@/../types/interfaces';
import { ProductModel } from '@/models/product/ProductModel';
import { BAEventModel, EventTicketModel } from '@/models/baEvent';
import { BAPartnerProduct, BAPartnerProductPrice } from '@/models';
import { notificationStore } from '@/store';

@Component
export class StripeMixin extends Vue {
	$stripe: Stripe;

	async buyProduct(title: string, product: ProductModel, parentId: string): Promise<void>{
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title,
			parentId: parentId,
			products:[
				{
					productId: product.stripeProductId,
					quantity: 1,
					meta:{
						athleteId: parentId,
					}
				}
			],
			allowCoupons: true,
			automaticTax: true,
		};
		const intent = await CustomerApi.createCheckoutIntent('athlete', parentId, params);
		this.$router.push({
			name: AthleteCheckoutPage,
			params:{
				...this.$route.params,
				checkoutIntentId: intent.id
			}
		});
	}

	async buyPartnerProduct(product: BAPartnerProduct, price: BAPartnerProductPrice, parentId: string) {
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title: product.name,
			parentId,
			lineItems: [ {
				title: price.name,
				description: product.description,
				stripeProductId: product.stripeId,
				stripePriceId: price.stripeId,
				quantity: 1,
				meta: {
					athleteId: parentId,
				}
			} ],
			allowCoupons: true,
			automaticTax: true,
		}

		try {
			const intent = await CustomerApi.createCheckoutIntent('athlete', parentId, params);
			this.$router.push({
				name: AthleteCheckoutPage,
				params:{
					...this.$route.params,
					checkoutIntentId: intent.id
				}
			});
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error preparing checkout for ticket. Contact Best Athletes for support.`});
		}
	}
	
	async buyEventTicket(event: BAEventModel, ticket: EventTicketModel, parentId: string) {
		const params = {
			title: event.name,
			parentId,
			lineItems: [ {
				title: ticket.name,
				description: ticket.description,
				stripeProductId: event.stripeId,
				stripePriceId: ticket.stripeId,
				quantity: 1,
			} ],
			allowCoupons: true,
			automaticTax: true,
		}

		try {
			const intent = await CustomerApi.createCheckoutIntent('athlete', parentId, params);
			this.$router.push({
				name: AthleteCheckoutPage,
				params:{
					...this.$route.params,
					checkoutIntentId: intent.id
				}
			});
		} catch(e) {
			throw( Error(`Error preparing checkout for ticket.\n\t${e}`) );
		}
	}
}