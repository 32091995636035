import { FrontEndModel } from '@/models/FrontEndModel';
import { TargetedSharingRecruitingRecord } from '@/../types/interfaces';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { formatDatePrettyShort } from '@/helpers';

export const TargetedSharingData = {
	'no': {
		icons: {
			outline: 'mdi-thumb-down-outline',
			full: 'mdi-thumb-down',
		},
		colors: {
			base: 'red',
			icon: 'black',
			text: 'black--text',
			light: 'red lighten-4 black--text',
			lightIcon: 'red lighten-2',
			lightText: 'red--text',
			tooltip: 'red lighten-1',
		},
		tooltip: {
			action: 'Dislike',
			actioned: 'Disliked',
			view: {
				pre: 'Review profile for',
				post: ''
			},
			filter: {
				on: 'Viewing unliked athletes. Click to hide.',
				off: 'Click to view unliked athletes'
			},
		},
	},
	'maybe': {
		icons: {
			outline: 'mdi-help-circle-outline',
			full: 'mdi-help-circle',
		},
		colors: {
			base: 'lime black--text',
			icon: 'black',
			text: 'black--text',
			light: 'lime lighten-4 black--text',
			lightIcon: 'lime lighten-2',
			lightText: 'lime--text',
			tooltip: 'lime lighten-1 black--text',
		},
		tooltip: {
			action: 'Flag for review',
			actioned: 'Flagged for review',
			view: {
				pre: 'Examine profile for',
				post: 'again'
			},
			filter: {
				on: 'Viewing flagged athletes. Click to hide.',
				off: 'Click to view flagged athletes'
			},
		},
	},
	'yes': {
		icons: {
			outline: 'mdi-thumb-up-outline',
			full: 'mdi-thumb-up',
		},
		colors: {
			base: 'green',
			icon: 'black',
			text: 'black--text',
			light: 'green lighten-4 black--text',
			lightIcon: 'green lighten-2',
			lightText: 'green--text',
			tooltip: 'green lighten-1',
		},
		tooltip: {
			action: 'Like',
			actioned: 'Liked',
			view: {
				pre: 'View full profile for',
				post: 'again'
			},
			filter: {
				on: 'Viewing Liked Athletes. Click to hide.',
				off: 'Click to view Liked Athletes'
			},
		},
	},
	'seen': {
		icons: {
			outline: 'mdi-eye-settings-outline',
			full: 'mdi-eye-settings',
		},
		colors: {
			base: 'primary',
			icon: 'white',
			text: 'white--text',
			light: 'light-blue lighten-5 black--text',
			lightIcon: 'light-blue lighten-3',
			lightText: 'light-blue--text',
			tooltip: 'light-blue lighten-1',
		},
		tooltip: {
			action: 'View',
			actioned: 'Viewed',
			view: {
				pre: 'Review shared profile for',
				post: ''
			},
			filter: {
				on: 'Viewing undecided shares. Click to hide.',
				off: 'Click to view athletes with no rating'
			},
		},
	},
	'unseen': {
		icons: {
			outline: 'mdi-eye-outline',
			full: 'mdi-eye',
		},
		colors: {
			base: 'primary',
			light: 'light-blue lighten-4 black--text',
			text: 'white--text',
			icon: 'white',
			lightIcon: 'light-blue lighten-3',
			lightText: 'light-blue--text',
			tooltip: 'light-blue lighten-1',
		},
		tooltip: {
			action: 'View',
			actioned: 'View',
			view: {
				pre: '',
				post: 'has shared their profile. Click to view.'
			},
			filter: {				
				on: 'Viewing only new athlete shares. Click to see all.',
				off: 'View only New athlete shares'
			},
		},
	},
	'reshared': {
		icons: {
			outline: 'mdi-eye-refresh-outline',
			full: 'mdi-eye-refresh',
		},
		colors: {
			base: 'primary',
			icon: 'white',
			text: 'white--text',
			light: 'light-blue lighten-4 black--text',
			lightIcon: 'light-blue lighten-3',
			lightText: 'light-blue--text',
			tooltip: 'light-blue lighten-1',
		},
		tooltip: {
			action: 'Open',
			actioned: 'Opened',
			view: {
				pre: '',
				post: 'has re-shared their profile with you'
			},
			filter: {
				on: 'Viewing new profile shares. Click to hide.',
				off: 'Click to view Athletes who shared their profile'
			},
		},
	}
}


export class TargetedSharingModel extends FrontEndModel {
	date: Date;										// share date
	email: string;									// coach email where share was sent
	parentId: string;								// athleteId
	message: string;								// athlete message to coach
	seen: boolean = false;							// coach has seen profile since the last targeted share
	seenOnce: boolean = false;						// coach has seen profile at least once
	found: boolean = false;							// coach found athlete without a targeted shared (e.g. club coach or share link shared outside BA)
	sortValue: number = 0;							// value assigned for visibility in coach dashboard
	recipientData: TargetedSharingRecruitingRecord;	// share recipient information
	archive: boolean = false;						// archive = hide from athlete's view
	archiveCoach: boolean = false;					// archiveCoach = hide from coach's view
	coachNotes: string = "";						// coach notes

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['date']) this.date = new Date(obj['date']);
		if(obj['seen']) this.seenOnce = true;
		if(!obj['archive']) this.archive = false;
		if(!obj['archiveCoach']) this.archiveCoach = false;
		return this;
	}

	SetResponse(response: RecruitingResponse) {
		this.recipientData.response = response;
	}
	get WasFound(): boolean {
		return this.found;
	}
	get HasReshared(): boolean {
		return( this.seenOnce && !this.seen );
	}
	get HasResponded(): boolean {
		return( this.seenOnce && !!this.recipientData && !!this.recipientData.response );
	}
	get Response(): RecruitingResponse { 
		if( !this.HasResponded ) return undefined;
		return this.recipientData.response;
	}
	get HasSelection(): boolean {
		if( !this.HasResponded ) return false;
		return( this.Response != RecruitingResponse.Seen );
	}
	get ShareDate(): string {
		if( this.date ) return formatDatePrettyShort(new Date(this.date));
		return '';
	}
	get LastView(): string {
		if( !this.HasResponded ) return '';

		if( this.recipientData.date ) return formatDatePrettyShort(new Date(this.recipientData.date));

		return this.ShareDate;
	}

	Color(type: string = 'base'): string {
		if( !this.HasResponded ) return TargetedSharingData.unseen.colors[type];
		return TargetedSharingData[this.Response].colors[type];
	}
	
	GetTypeColor(responseType: RecruitingResponse, hover: boolean, lightKey: string = 'light') {
		const primary: string = 'primary lighten-1';
		const dim: string = 'grey lighten-1';
		if( !this.HasSelection ) return hover? TargetedSharingData[responseType].colors.base : primary;
		if( this.Response === responseType ) return hover? TargetedSharingData[responseType].colors[lightKey] : TargetedSharingData[responseType].colors.base;
		return hover? TargetedSharingData[responseType].colors[lightKey] : dim;
	}
	GetTypeIcon(responseType: RecruitingResponse, hover: boolean) {
		if( this.Response === responseType ) return hover? TargetedSharingData[responseType].icons.outline : TargetedSharingData[responseType].icons.full;
		return hover? TargetedSharingData[responseType].icons.full : TargetedSharingData[responseType].icons.outline;
	}
	GetTypeToolTip(responseType: RecruitingResponse, name: string) {
		if( !this.HasSelection ) return `${TargetedSharingData[responseType].tooltip.action} ${name}`;
		if( this.Response === responseType ) return `${name} is ${TargetedSharingData[responseType].tooltip.actioned}. Click to reset.`;
		return `Change ${name} to ${TargetedSharingData[responseType].tooltip.actioned}`;
	}

	get ArchiveCoachIcon(): string {
		return this.archiveCoach? 'mdi-archive-minus-outline':'mdi-archive-plus'
	}
	ArchiveCoachToolTip(name: string): string {
		return this.archiveCoach? `Restore profile share for ${name}` : `Archive profile share for ${name}`;
	}
	get ViewIcon(): string {
		if( this.HasReshared ) return TargetedSharingData.reshared.icons.full;
		if( this.seen ) return TargetedSharingData.seen.icons.outline;
		return TargetedSharingData.unseen.icons.full;
	}
	GetToolTipClear(name: string) {
		if( this.HasSelection ) return `Clear ${TargetedSharingData[this.Response].tooltip.actioned} selection for ${name}`;
	}
	ViewToolTip(name: string): string {
		if( this.HasReshared ) return `${TargetedSharingData.reshared.tooltip.view.pre} ${name} ${TargetedSharingData.reshared.tooltip.view.post} `;
		if( !this.HasResponded ) return `${TargetedSharingData.unseen.tooltip.view.pre} ${name} ${TargetedSharingData.unseen.tooltip.view.post} `;
		return `${TargetedSharingData[this.Response].tooltip.view.pre} ${name} ${TargetedSharingData[this.Response].tooltip.view.post}`;
	}

	ShortMessage(len: number = 50): string {
		if( this.message.length < len ) return this.message;
		return this.message.split('.')[0].substring(0, len) + '...';
	}
}

export class TargetedSharingAthleteModel extends TargetedSharingModel {
	age: string;
	location: string;
	gradYear: string;
	position: string;
}