import { ESSchoolModel, SchoolModel } from "@/models/school/SchoolModel";
import { AxiosRequestConfig } from "axios";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from "@/../types/interfaces";
import { CrudApi } from "./CrudApi";
import { CoachInfo } from '@/../types/interfaces/CoachInfo';

class SchoolApi extends CrudApi<SchoolModel> {
	constructor(){
		super('school', (obj) => new SchoolModel().load(obj));
	}

	/**
	 * Requires Admin permissions.
	 */
	async createIndex(config?: AxiosRequestConfig): Promise<SchoolModel[]> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/createIndex`, config));
	}
	async reindexIndexAll(config?: AxiosRequestConfig): Promise<SchoolModel[]> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/reindex-all`, config));
	}


	async searchSchools({ query, options }: { query: RepositoryQuery<SchoolModel>, options: QueryOptions }, config?: AxiosRequestConfig): Promise<PaginatedResponse<ESSchoolModel>> {
		const response: PaginatedResponse<any> = await CrudApi.Api(c => c.post(`/${this.resource}/search`, query, this.applyOptionsToRequest(options, config)));
		return response;
	}

	async importSchoolFile(key: string, config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/import-json-from-uploads-bucket`, { key }, config))
	}

	async updateCoach(coach: CoachInfo, config?: AxiosRequestConfig): Promise<CoachInfo> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/coach`, { coach }, config));
	}

	async updateSchoolCoaches(coaches: CoachInfo[], config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/coaches`, {coaches}, config));
	}

	async updateAllCoaches(coaches: CoachInfo[], config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/all-coaches`, {coaches}, config));
	}
}

export const schoolApi = new SchoolApi();