import { FeatureMap } from "@/../types/interfaces";

export class SubscriptionHistoryModel {
	feature: Partial<FeatureMap> = {};
	date: Date = new Date();
	subscriptionId: string = "";

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);

		if (obj['date']) {
			this.date = new Date(obj['date'])
		}

		return this
	}
}