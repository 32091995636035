import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { targetedSharingApi } from '@/api/TargetedSharingApi';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';

const Mutations = {
	QUERY_TARGETED_SHARES: 'QUERY_TARGETED_SHARES',
	QUERY_TARGETED_SHARES_SUCCESS: 'QUERY_TARGETED_SHARES_SUCCESS',
	QUERY_TARGETED_SHARES_FAILURE: 'QUERY_TARGETED_SHARES_FAILURE',

	PATCH_TARGETED_SHARES: 'PATCH_TARGETED_SHARES',
	PATCH_TARGETED_SHARES_SUCCESS: 'PATCH_TARGETED_SHARES_SUCCESS',
	PATCH_TARGETED_SHARES_FAILURE: 'PATCH_TARGETED_SHARES_FAILURE',
}

const name = 'TargetedSharingStore';
if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class TargetedSharingModule extends VuexModule {
	queryTargetedSharesInitialized: boolean = false;
	queryTargetedSharesLoading: boolean = false;
	totalTargetedShares: number = 0;
	targetedShares: TargetedSharingModel[] = [];
	@Action({
		rawError: true,
	}) async queryTargetedShares({ query, options }: { query?: RepositoryQuery<TargetedSharingModel>, options?: QueryOptions } = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_TARGETED_SHARES)
		try {
			const data = await targetedSharingApi.queryAll(query, options);		
			this.context.commit(Mutations.QUERY_TARGETED_SHARES_SUCCESS, data );
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_TARGETED_SHARES_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_TARGETED_SHARES](): void {
		this.queryTargetedSharesLoading = true;
	}

	@Mutation [Mutations.QUERY_TARGETED_SHARES_SUCCESS]( data: PaginatedResponse<TargetedSharingModel>): void {
		this.totalTargetedShares = data.total;
		this.targetedShares = data.docs;
		this.queryTargetedSharesLoading = false;
		this.queryTargetedSharesInitialized = true;
	}

	@Mutation [Mutations.QUERY_TARGETED_SHARES_FAILURE](error: any): void {
		this.queryTargetedSharesLoading = false;
	}

	recordPatching: boolean = false;
	@Action({
		rawError: true,
	}) async patchRecord( record: Partial<TargetedSharingModel>): Promise<void> {
		this.context.commit(Mutations.PATCH_TARGETED_SHARES)
		try {
			await targetedSharingApi.patch(record, { params: { supressEvents: true }});
			this.context.commit(Mutations.PATCH_TARGETED_SHARES_SUCCESS);
		} catch(e) {
			this.context.commit(Mutations.PATCH_TARGETED_SHARES_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_TARGETED_SHARES](): void {
		this.recordPatching = true;
	}
	@Mutation [Mutations.PATCH_TARGETED_SHARES_SUCCESS](): void {
		this.recordPatching = false;
	}
	@Mutation [Mutations.PATCH_TARGETED_SHARES_FAILURE](error: any): void {
		this.recordPatching = false;
	}	
}