
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, VuetifyMixin } from '@/mixins';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import MonthPicker from '../forms/MonthPicker.vue';
import { OrganizationTypeSelectorMixin, SportsSelectorMixin, CountriesSelectorMixin } from '@/mixins/SelectorMixins';
import { DatePickerAllowedDatesFunction } from 'vuetify';
import { SportName } from '@/../types/enums';
import { Compact } from 'vue-color';
import JerseyEditColor from './JerseyEditColor.vue'

interface VueColor {
  hex: string;
  rgba: { r: number; g: number; b: number; a: number };
  hsl: { h: number; s: number; l: number; a: number };
  [key: string]: any; // To account for other possible color representations
}

@Component({
	components: { FormAddArea, FileUploadArea, MonthPicker, JerseyEditColor, 'compact-picker': Compact},
})
export default class TeamCreateTeamInfo extends Mixins(VuetifyMixin, OrganizationTypeSelectorMixin, SportsSelectorMixin, CountriesSelectorMixin, BAIconsMixin){
	showLinkOrgDialog: boolean = false;
	thisYear: number = new Date().getFullYear();

	@Prop() value = {
		valid: false,
		name: "",
		country: "",
		city: "",
		sportId: "",
		gender: "",
		seasonFormat: "year",
		seasonYear: this.thisYear,
		seasonStartDate: null,
		seasonEndDate: null,
		organizationTypeId: "",
		organizationName: "",
		division: "",
		logoUrl: null,
		jerseyPrimary: {
			label: 'Primary',
			color: '#FF0000FF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
		jerseyAlternate: {
			label: 'Alternate',
			color: '#0000BBFF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
		jerseyOther: {
			label: 'Goalkeeper',
			color: '#007F0EFF',
			accent: "#FFFFFFFF",
			number: "#FFFFFFFF",
		},
	};
	input(): void{
		this.$emit('input', this.value);
	}

	async mounted() {
		await this.loadSportId();
	}
	loadingInformation: boolean = false;
	
	async loadSportId() {
		this.loadingInformation = true;
		await this.loadSports();
		if( !this.allSports ) {
			this.value.sportId = '';
		}
		else if( !this.value.sportId || this.value.sportId.length === 0 ) {
			const sport = this.allSports.find(s => s.name === SportName.Soccer);	// CCM_TODO_SPORT - change to organization sport
			if( sport ) this.value.sportId = sport.id;
		}

		this.loadingInformation = false;
	}

	requiredRule = [
		(v: any): boolean | string => !!v || 'Required',
	];

	$refs:{
		form: HTMLFormElement;
	}

	organizationDialog = {
		name: "",
		typeId: "",
	}

	get SeasonYears(): number[]{
		return new Array(6).fill(0).map((_, i) => this.thisYear - 2 + i);
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

	get EndAllowedDates(): DatePickerAllowedDatesFunction{
		if(!this.value.seasonStartDate) return () => true;
		return (date: string) => {
			const [ year, month ] = date.split('-');
			return (+year >= this.value.seasonStartDate.getUTCFullYear()) && (+month > this.value.seasonStartDate.getUTCMonth());
		}
	}

	editingColors: boolean = false;
}
