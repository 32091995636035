/* CrudApi.ts */
/* Implements CRUD API: Create, Read, Update, Delete */

import { api } from './api';
import { getInstance } from '../auth/auth0Plug';
import { Auth0Plugin } from '../auth/Auth0';
import { AclPermission } from '@/../types/permissions';
import { QueryOptions, PaginatedResponse, RepositoryQuery } from '@/../types/interfaces';
import { FrontEndModel } from '../models/FrontEndModel';
import { AccessControlledModel } from '../models/AccessControlledModel';
import { grantUserAcls } from '../services/AccessControlledService';
import { AxiosRequestConfig } from 'axios';

export class CrudApi<T extends FrontEndModel | AccessControlledModel>{
	static Api = api;

	// CrudApi construction
	// resource : indicates the resource that this API instance is interacting with - rootUrl/resource/
	// create   : function used to create an object that describes the resource
	constructor(protected resource: string, protected create: (obj: unknown) => T){}

	// obtains the unique ID for the resource
	async uuid(config?: AxiosRequestConfig): Promise<{ uuid: string }>{
		const response = await CrudApi.Api((c) => c.get(`/${this.resource}/uuid`, config));
		return {
			uuid: response.uuid,
		};
	}

	// obtains a count of all items in the resource
	async countAll(config?: AxiosRequestConfig): Promise<{ count: number }>{
		const response: PaginatedResponse<T> = await CrudApi.Api((c) => c.get(`/${this.resource}/count`, config));
		return {
			count: response.count,
		};
	}

	// obtains a set of entries in the specified resource that meet the query options
	async queryAll(query: RepositoryQuery<T>, options: QueryOptions = {}, config?: AxiosRequestConfig): Promise<PaginatedResponse<T>>{
		const response: PaginatedResponse<T> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/query`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => this.create(doc)),
		};
	}

	// obtains, with access priviliges, a set of entries in the specified resource that meet the query options
	async queryAllWithAccess(query: RepositoryQuery<T>, options: QueryOptions, config?: AxiosRequestConfig): Promise<PaginatedResponse<T>>{
		const response: PaginatedResponse<T> = await CrudApi.Api(
			(c) => c.post(`/${this.resource}/queryWithAccess`, query, this.applyOptionsToRequest(options, config))
		);
		return {
			total: response.total,
			count: response.count,
			page: response.page,
			totalPages: response.totalPages,
			docs: response.docs.map((doc: any) => this.create(doc)),
		};
	}

	// obtain the specified entry in the specified resource
	async findById(id: string, options?: QueryOptions, config?: AxiosRequestConfig): Promise<T | null>{
		try{
			const obj = await CrudApi.Api((c) => c.get(`/${this.resource}/${id}`, this.applyOptionsToRequest(options, config)));
			return this.create(obj);
		}catch(e){
			if (e.response.status === 404){
				return null;
			}
			throw e;
		}
	}
	async publicFindById(id: string, options?: QueryOptions, config?: AxiosRequestConfig): Promise<T> {
		try {
			const obj = await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}`, this.applyOptionsToRequest(options, config)));
			return this.create(obj);
		} catch(e) {
			if( e.response.status === 404 ) return null;
			throw e;
		}
	}


	/**
	 * Will append the current user's id with 'owner' access to the object.
	 */
	async insertWithOwnership(obj: T, config?: AxiosRequestConfig): Promise<T>{
		const objWithAcls = (<AccessControlledModel>obj);
		if (!Array.isArray(objWithAcls.users)) throw new Error("Object must extend AccessControlled");

		const auth: Auth0Plugin = getInstance();
		if(!auth) throw new Error("Failed to load auth plugin");
		
		grantUserAcls(objWithAcls, [AclPermission.Owner], { userId: auth.user.id, groups: [] });
		return await this.save(obj, config);
	}

	// Create a new entry to the resource
	async insert(obj: T, config?: AxiosRequestConfig): Promise<T>{
		const newObj = await CrudApi.Api((c) => c.post(`/${this.resource}`, obj, config));
		return this.create(newObj);
	}

	// Update the specified entry in the resource
	async update(obj: T, config?: AxiosRequestConfig): Promise<T>{
		const newObj = await CrudApi.Api((c) => c.put(`/${this.resource}/${obj.id}`, obj, config));
		return this.create(newObj);
	}

	// Patch the specified entry in the resource
	async patch(obj: Partial<T>, config?: AxiosRequestConfig): Promise<T> {
		const newObj = await CrudApi.Api((c) => c.patch(`/${this.resource}/${obj.id}`, obj, config));
		return this.create(newObj);
	}

	// Patch, with Access Control
	async patchWithAcls(obj: Partial<T>, config?: AxiosRequestConfig): Promise<T> {
		const newObj = await CrudApi.Api((c) => c.patch(`/${this.resource}/${obj.id}/withAcls`, obj, config));
		return this.create(newObj);
	}

	// Save will create a new entry or update, if one already exists
	async save(obj: T, config?: AxiosRequestConfig): Promise<T>{
		const newObj = await (!obj.id ? this.insert(obj, config) : this.update(obj, config));
		return this.create(newObj);
	}

	// Delete the specified entry from the resource
	async delete(obj: T, config?: AxiosRequestConfig): Promise<T>{
		const deletedObj = await CrudApi.Api((c) => c.delete(`/${this.resource}/${obj.id}`, config));
		return this.create(deletedObj);
	}

	// Get all the entries in the resource
	async findAllWithAccess(config?: AxiosRequestConfig): Promise<T[]>{
		const objs = await CrudApi.Api((c) => c.get(`/${this.resource}`, config));
		return objs.map((obj: any) => this.create(obj));
	}

	// Get all the entries in the resource that match the specified query options
	async getAll(options?: QueryOptions, config?: AxiosRequestConfig): Promise<T[]>{
		const objs = await CrudApi.Api((c) => c.get(`/${this.resource}/all`, this.applyOptionsToRequest(options, config)));
		return objs.map((obj: any) => this.create(obj));
	}

	// Apply the specified options in the configuration
	protected applyOptionsToRequest(options: QueryOptions = {}, config: AxiosRequestConfig = {}): AxiosRequestConfig{
		if(options === undefined) return config;
		config.params = {
			...config.params,
			...options,
		};
		if(options.populate){
			config.params = {
				...config.params,
				populate: options.populate.join(','),
			}
		}
		if(options.sort){
			config.params = {
				...config.params,
				sort: options.sort.fields.map(sort => `${sort.field}${sort.desc ? ':desc' : ''}`).reduce((a,b) => a + ',' + b,)
			}
		}
		if(options.limitPerPage){
			config.params = {
				...config.params,
				limitPerPage: options.limitPerPage
			}
		}
		return config;
	}
}