export enum BASchoolDivision {
    NCAA_1 = 'NCAA 1',
    NCAA_2 = 'NCAA 2',
    NCAA_3 = 'NCAA 3',
    NAIA = 'NAIA',
    NCCAA = 'NCCAA',
    Ind_2yr = 'Ind 2yr',
    Ind_4yr = 'Ind 4yr',
    CCCAA = 'CCCAA',
    NJCAA = 'NJCAA',
    NJCAA_2 = 'NJCAA 2',
    NJCAA_3 = 'NJCAA 3',
    NWAC = 'NWAC',
    USPORT = 'USPORT',
    CCAA = 'CCAA',
}

export enum BASchoolConference {
    A_10 = 'A-10',
    ACC = 'ACC',
    Allegheny_Mountain = 'Allegheny Mountain',
    America_East = 'America East',
    American = 'American',
    American_Midwest = 'American Midwest',
    American_Rivers_Conference = 'American Rivers Conference',
    American_Southwest = 'American Southwest',
    Appalachian = 'Appalachian',
    Arizona_CC_Athletic_Conf = 'Arizona CC Athletic Conf',
    Assoc__of_DIII_Independents = 'Assoc. of DIII Independents',
    Atlantic_East_Conference = 'Atlantic East Conference',
    Atlantic_Sun = 'Atlantic Sun',
    Bay_Valley = 'Bay Valley',
    Big_12 = 'Big 12',
    Big_8 = 'Big 8',
    Big_East = 'Big East',
    Big_Sky = 'Big Sky',
    Big_South = 'Big South',
    Big_Ten = 'Big Ten',
    Big_West = 'Big West',
    California_Collegiate = 'California Collegiate',
    California_Pacific = 'California Pacific',
    Cascade = 'Cascade',
    CCIW = 'CCIW',
    Centennial = 'Centennial',
    Central_Atlantic = 'Central Atlantic',
    Central_Intercollegiate = 'Central Intercollegiate',
    Central_Valley = 'Central Valley',
    Chicagoland = 'Chicagoland',
    Coast = 'Coast',
    Coast_To_Coast_Athletic_Conference = 'Coast-To-Coast Athletic Conference',
    Colonial = 'Colonial',
    Colonial_States = 'Colonial States',
    Commonwealth_Coast = 'Commonwealth Coast',
    Conference_Carolinas = 'Conference Carolinas',
    Conference_USA = 'Conference USA',
    Continental = 'Continental (formerly Aii)',
    Crossroads_League = 'Crossroads League',
    CUNYAC = 'CUNYAC',
    East_Coast = 'East Coast',
    Empire_8 = 'Empire 8',
    EPAC = 'EPAC',
    FCSAA_Mid_Florida = 'FCSAA Mid-Florida',
    FCSAA_Southern = 'FCSAA Southern',
    FCSAA_Suncoast = 'FCSAA Suncoast',
    Frontier = 'Frontier',
    GAC = 'GAC',
    Garden_State = 'Garden State',
    Georgia_Collegiate_Athletic_Assn_ = 'Georgia Collegiate Athletic Assn.',
    GLIAC = 'GLIAC',
    Golden_State = 'Golden State',
    Golden_Valley = 'Golden Valley',
    Great_Lakes_Valley = 'Great Lakes Valley',
    Great_Midwest_Athletic_Conference = 'Great Midwest Athletic Conference',
    Great_Northeast = 'Great Northeast',
    Great_Northwest = 'Great Northwest',
    Great_Plains = 'Great Plains',
    Great_Rivers = 'Great Rivers',
    Gulf_Coast = 'Gulf Coast',
    Gulf_South = 'Gulf South',
    Heart_of_America = 'Heart of America',
    Heartland_Collegiate = 'Heartland Collegiate',
    Horizon_League = 'Horizon League',
    Hudson_Valley = 'Hudson Valley',
    Illinois_Skyway = 'Illinois Skyway',
    Independent = 'Independent',
    Inland_Empire = 'Inland Empire',
    Iowa_CC_Athletic_Conf = 'Iowa CC Athletic Conf',
    Ivy_League = 'Ivy League',
    Kansas_Collegiate = 'Kansas Collegiate',
    Kansas_Jayhawk_CC_Conf = 'Kansas Jayhawk CC Conf',
    Landmark = 'Landmark',
    Liberty_League = 'Liberty League',
    Little_East = 'Little East',
    Lone_Star = 'Lone Star',
    MAC_Commonwealth = 'MAC Commonwealth',
    MAC_Freedom = 'MAC Freedom',
    MACJC = 'MACJC',
    Maryland_JUCO = 'Maryland JUCO',
    MASCAC = 'MASCAC',
    Massachusetts_CC_Athletic_Conf = 'Massachusetts CC Athletic Conf',
    MCAC = 'MCAC',
    Metro_Atlantic = 'Metro Atlantic (MAAC)',
    Michigan_CC_Athletic_Assn = 'Michigan CC Athletic Assn',
    Michigan_Intercollegiate = 'Michigan Intercollegiate',
    Mid_America_Intercollegiate = 'Mid-America Intercollegiate',
    Mid_American = 'Mid-American (MAC)',
    Mid_Eastern = 'Mid-Eastern (MEAC)',
    Mid_Hudson = 'Mid-Hudson',
    Mid_South = 'Mid-South',
    Mid_State = 'Mid-State',
    Midwest = 'Midwest',
    Midwest_Christian = 'Midwest Christian',
    Minnesota_Intercollegiate = 'Minnesota Intercollegiate',
    Missouri_CC_Athletic_Conf = 'Missouri CC Athletic Conf',
    Missouri_Valley = 'Missouri Valley',
    Mountain_East = 'Mountain East',
    Mountain_Valley = 'Mountain Valley',
    Mountain_West = 'Mountain West',
    MWAC = 'MWAC',
    N4C = 'N4C',
    NCCAA = 'NCCAA',
    Nebraska_CCAC = 'Nebraska CCAC',
    NESCAC = 'NESCAC',
    New_England_Collegiate = 'New England Collegiate',
    NEWMAC = 'NEWMAC',
    NJAC = 'NJAC',
    North_Atlantic = 'North Atlantic',
    North_Coast = 'North Coast',
    North_Star_Athletic_Association = 'North Star Athletic Association',
    Northeast = 'Northeast',
    Northeast_10 = 'Northeast 10',
    Northeastern_Intercollegiate_Athletics_Conference = 'Northeastern Intercollegiate Athletics Conference',
    Northern_Athletics_Collegiate_Conf_ = 'Northern Athletics Collegiate Conf.',
    Northern_Sun = 'Northern Sun',
    Northwest = 'Northwest',
    NWAC = 'NWAC',
    Ohio_Athletic = 'Ohio Athletic',
    Ohio_CC_Athletic_Conf = 'Ohio CC Athletic Conf',
    Ohio_Valley = 'Ohio Valley',
    Oklahoma_Collegiate = 'Oklahoma Collegiate',
    Old_Dominion = 'Old Dominion',
    Orange_Empire = 'Orange Empire',
    Pac_12 = 'Pac-12',
    Pacific_West = 'Pacific West',
    Patriot_League = 'Patriot League',
    PCAC = 'PCAC',
    Peach_Belt = 'Peach Belt',
    Presidents = 'Presidents',
    PSAC = 'PSAC',
    PSUAC = 'PSUAC',
    Red_River = 'Red River',
    Region_10 = 'Region 10',
    Region_13 = 'Region 13',
    Region_14 = 'Region 14',
    Region_15 = 'Region 15',
    Region_16 = 'Region 16',
    Region_2 = 'Region 2',
    Region_20 = 'Region 20',
    Region_21 = 'Region 21',
    Region_23 = 'Region 23',
    Region_3 = 'Region 3',
    Region_4 = 'Region 4',
    Region_5 = 'Region 5',
    Region_8 = 'Region 8',
    Region_9 = 'Region 9',
    River_States = 'River States',
    Rocky_Mountain = 'Rocky Mountain',
    Scenic_West = 'Scenic West',
    SCIAC = 'SCIAC',
    SEC = 'SEC',
    SIAC = 'SIAC',
    Skyline = 'Skyline',
    SLIAC = 'SLIAC',
    Sooner = 'Sooner',
    South_Atlantic = 'South Atlantic',
    South_Coast = 'South Coast',
    Southern = 'Southern',
    Southern_Athletic_Association = 'Southern Athletic Association',
    Southern_Collegiate = 'Southern Collegiate',
    Southern_States = 'Southern States',
    Southland = 'Southland',
    Summit_League = 'Summit League',
    Sun_Belt = 'Sun Belt',
    Sun_Conf_ = 'Sun Conf.',
    Sunshine_State = 'Sunshine State',
    SUNYAC = 'SUNYAC',
    SWAC = 'SWAC',
    TCCAA = 'TCCAA',
    United_East_Conference = 'United East Conference',
    University_Athletic_Association = 'University Athletic Association',
    Upper_Midwest = 'Upper Midwest',
    USA_South = 'USA South',
    USCAA = 'USCAA',
    WAC = 'WAC',
    West_Coast = 'West Coast',
    Western_State = 'Western State',
    WIAC = 'WIAC',
    WJCAC = 'WJCAC',
    Wolverine_Hoosier = 'Wolverine-Hoosier',
    WPCC = 'WPCC',
    Yankee = 'Yankee',
}

export enum BASchoolState {
    AL = "Alabama",
	AK = "Alaska",
	AZ = "Arizona",
	AR = "Arkansas",
	CA = "California",
	CO = "Colorado",
	CT = "Connecticut",
	DE = "Delaware",
	FL = "Florida",
	GA = "Georgia",
	HI = "Hawaii",
	ID = "Idaho",
	IL = "Illinois",
	IN = "Indiana",
	IA = "Iowa",
	KS = "Kansas",
	KY = "Kentucky",
	LA = "Louisiana",
	ME = "Maine",
	MD = "Maryland",
	MA = "Massachusetts",
	MI = "Michigan",
	MN = "Minnesota",
	MS = "Mississippi",
	MO = "Missouri",
	MT = "Montana",
	NE = "Nebraska",
	NV = "Nevada",
	NH = "New Hampshire",
	NJ = "New Jersey",
	NM = "New Mexico",
	NY = "New York",
	NC = "North Carolina",
	ND = "North Dakota",
	OH = "Ohio",
	OK = "Oklahoma",
	OR = "Oregon",
	PA = "Pennsylvania",
	RI = "Rhode Island",
	SC = "South Carolina",
	SD = "South Dakota",
	TN = "Tennessee",
	TX = "Texas",
	UT = "Utah",
	VT = "Vermont",
	VA = "Virginia",
	WA = "Washington",
	WV = "West Virginia",
	WI = "Wisconsin",
	WY = "Wyoming",
	DC = "District of Columbia",
	AS = "American Samoa",
	GU = "Guam",
	MP = "Northern Mariana Islands",
	PR = "Puerto Rico",
	UM = "United States Minor Outlying Islands",
	VI = "U.S.Virgin Islands",
}

export enum BASchoolProvince {
	AB = "Alberta",
	BC = "British Columbia",
	MB = "Manitoba",
	NB = "New Brunswick",
	NL = "Newfoundland and Labrador",
	NS = "Nova Scotia",
	ON = "Ontario",
	PE = "Prince Edward Island",
	QC = "Quebec",
	SK = "Saskatchewan",
	NT = "Northwest Territories",
	NU = "Nunavut",
	YT = "Yukon",
}

const combinedStatesAndProvinces = {
    ...BASchoolState,
    ...BASchoolProvince,
}
export type CombinedStatesAndProvincesType = typeof combinedStatesAndProvinces;

export const BASchoolStateOrProvince: CombinedStatesAndProvincesType = combinedStatesAndProvinces as CombinedStatesAndProvincesType;

export enum BAPopularMajor {
    Arts = "Arts",
    Aerospace = "Aerospace",
    Anthropology = "Anthropology",
    Architecture = "Architecture",
    Astronomy = "Astronomy",
    BiomedicalSciences = "Biomedical Sciences",
    BiologyBiologicalSciences = "Biology/Biological Sciences",
    Biotechnology = "Biotechnology",
    Business = "Business",
    Chemistry = "Chemistry",
    CivilEngineering = "Civil Engineering",
    Communication = "Communication",
    ComparativeLiterature = "Comparative Literature",
    ComputerScience = "Computer Science",
    CriminalJusticeAndCorrections = "Criminal Justice and Corrections",
    Criminology = "Criminology",
    Cultural = "Cultural",
    CyberElectronicOperationsAndWarfare = "Cyber/Electronic Operations and Warfare",
    Dance = "Dance",
    DataScience = "Data Science",
    EarthScience = "Earth Science",
    Economics = "Economics",
    Education = "Education",
    ElectricalAndElectronicsEngineering = "Electrical and Electronics Engineering",
    Engineering = "Engineering",
    EnvironmentalDesignArchitecture = "Environmental Design/Architecture",
    EnvironmentalScience = "Environmental Science",
    EnvironmentalStudies = "Environmental Studies",
    Fashion = "Fashion",
    FilmStudies = "Film Studies",
    Finance = "Finance",
    ForeignLanguagesAndLiteratures = "Foreign Languages and Literatures",
    Gender = "Gender",
    Geography = "Geography",
    GeologicalGeophysicalEngineering = "Geological/Geophysical Engineering",
    GraphicDesign = "Graphic Design",
    Health = "Health",
    History = "History",
    HospitalAndHealthCareFacilitiesAdministrationManagement = "Hospital and Health Care Facilities Administration/Management",
    HumanNutrition = "Human Nutrition",
    IndustrialAndProductDesign = "Industrial and Product Design",
    IndustrialEngineering = "Industrial Engineering",
    InformationTechnology = "Information Technology",
    InternationalAndInterculturalCommunication = "International and Intercultural Communication",
    InternationalPublicHealthInternationalHealth = "International Public Health/International Health",
    LandscapeArchitecture = "Landscape Architecture",
    Linguistics = "Linguistics",
    MarineBiology = "Marine Biology",
    Marketing = "Marketing",
    MaterialsEngineering = "Materials Engineering",
    Mathematics = "Mathematics",
    Microbiology = "Microbiology",
    Music = "Music",
    Neuroscience = "Neuroscience",
    NurseAnesthetist = "Nurse Anesthetist",
    Nutrition = "Nutrition",
    Philosophy = "Philosophy",
    Physics = "Physics",
    Political = "Political",
    PreLawStudies = "Pre-Law Studies",
    Psychology = "Psychology",
    PublicAdministration = "Public Administration",
    PublicHealth = "Public Health",
    PublicRelations = "Public Relations",
    RenewableEnergy = "Renewable Energy",
    ReligiousMusicAndWorship = "Religious Music and Worship",
    Robotics = "Robotics",
    SocialPsychology = "Social Psychology",
    SocialWork = "Social Work",
    Sociology = "Sociology",
    Theatre = "Theatre",
    TheatreLiterature = "Theatre Literature",
    UrbanForestry = "Urban Forestry",
    Veterinary = "Veterinary",    
}
      

export enum BASchoolType {
    Public_4year = "4-year, Public",
    Private_4year = "4-year, Private for-profit",
    Private_nfp_4year = "4-year, Private not-for-profit",
    Public_2year = "2-year, Public",
    Private_2year = "2-year, Private for-profit",
    Private_nfp_2year = "2-year, Private not-for-profit",
    Public_lt_2year = "< 2-year, Public",
    Private_lt_2year = "< 2-year, Private for-profit",
    Private_nfp_lt_2year = "< 2-year, Private not-for-profit",
    Public_4year_ass = "4-year, primarily associate's, Public",
    Private_4year_ass = "4-year, primarily associate's, Private for-profit",
    Private_nfp_4year_ass = "4-year, primarily associate's, Private not-for-profit",
}

export enum BACampusSetting {
    City_Large = "City Large",
    City_Midsize = "City Midsize",
    City_Small = "City Small",
    Suburb_Large = "Suburb Large",
    Suburb_Midsize ="Suburb Midsize",
    Suburb_Small = "Suburb Small",
    Town_Distant = "Town Distant",
    Town_Fringe = "Town Fringe",
    Town_Remote = "Town Remote",
    Rural_Distant = "Rural Distant",
    Rural_Fringe = "Rural Fringe",
    Rural_Remote = "Rural Remote",
}