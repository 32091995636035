
import { Component, Prop, Mixins } from 'vue-property-decorator';
import UserChip from '../../ui/UserChip.vue';
import OrderSelector from '../../ui/OrderSelector.vue';
import PlusDiamondSvg from '@/components/svg/PlusDiamondSvg.vue';
import { PlayerOnTeam } from '../../../models/team';
import { DepthChartPosition } from '../../../models/depthChart';
import { SoccerPosition, BaseSoccerPosition } from '@/../types/enums';
import { LocalForageMixin, SportsSelectorMixin, BAIconsMixin, VuetifyMixin } from '@/mixins';

@Component({
	components: { UserChip, OrderSelector, PlusDiamondSvg },
})
export default class EditPositionOverlay extends Mixins(SportsSelectorMixin, LocalForageMixin, VuetifyMixin, BAIconsMixin){

	get StarterPlayer(): PlayerOnTeam | undefined{
		return this.PlayersList[0];
	}
	showStarterIsInjured: boolean = true;
	get StarterIsInjured(): boolean{
		if(this.StarterPlayer === undefined) return false;
		return this.StarterPlayer.injured && this.showStarterIsInjured;
	}
	showStarterIsIneligible: boolean = true;
	get StarterIsIneligible(): boolean{
		if(this.StarterPlayer === undefined) return false;
		return !this.StarterPlayer.eligibleToPlay && this.showStarterIsIneligible;
	}

	localForagePersistFields = [
		'showRankHint'
	];
	showRankHint: boolean = true;
	clearRankHint(): void{
		this.showRankHint = false;
		this.persistField('showRankHint');
	}
	get ShowRankHint(){
		return this.showRankHint && this.positionFormData.playerIds.length > 1;
	}

	getAvatarColor(player: PlayerOnTeam, index: number): string{
		if(player.injured === true || player.eligibleToPlay === false){
			return 'baColorAlertRed';
		}
		if(index === 0){
			return 'baColorGreen1';
		}
		return 'baColorLightBlue';
	}
	getRowClasses(player: PlayerOnTeam, index: number): string{
		if(player.injured === true || player.eligibleToPlay === false){
			return 'alert-red-lighten';
		}
		if(index === 0){
			return 'green1-lighten';
		}
		return '';
	}

	get PlayersList(): PlayerOnTeam[]{
		const playerList = this.positionFormData.playerIds.map(id => this.players.find(p => p.id === id));
		return playerList.filter(x => x !== undefined)
	}

	get IsGoalie(){
		return this.positionFormData.position === BaseSoccerPosition.Goalkeeper;
	}
	get PositionNumber(): string{
		return this.position.number;
	}
	get PositionNumbersExcludeGoalie(): string[]{
		/** Numbers 2-11 (exclude Goalie) */
		return new Array(10).fill(null).map((_,i) => `${i+2}`);
	}
	get FilteredPositions(){
		if(this.IsGoalie){
			return [ {
				text: SoccerPosition.Goalkeeper,
				value: SoccerPosition.Goalkeeper,
			} ];
		}else{
			return this.AvailablePositions().filter(p => p !== BaseSoccerPosition.Goalkeeper).map(p => ({
				text: p,
				value: p
			}));
		}
	}

	@Prop({ default: 62 }) headerHeight: number;
	@Prop({ default: 'baColorDeepBlue' }) color: string;
	@Prop({ default: 'baColorAppForeground' }) backgroundColor: string;
	@Prop({ default: true, type: Boolean }) tile: boolean;

	@Prop({ required:true }) players: PlayerOnTeam[];
	@Prop({ required:true }) position: DepthChartPosition;
	positionFormData: Partial<DepthChartPosition> = {
		position: "",
		label: "",
		number: "",
		playerIds: [],
	};
	selectedPlayer: PlayerOnTeam | null = null;

	created(){
		// Copy the position so we can edit it freely without modifying the state
		this.positionFormData = {
			...this.position,
			playerIds: this.position.playerIds.slice(),
		};
	}

	cancel(){
		this.$emit('cancel');
	}
	submit(){
		// Until there's a way to set a custom label
		this.positionFormData.label = this.positionFormData.position;
		this.$emit('submit', this.positionFormData);
	}

	get AvailableRoster(){
		return this.players.filter(player => {
			return this.positionFormData.playerIds.find(id => id === player.id) === undefined;
		});
	}
	playerFilter(item: PlayerOnTeam, queryText: string, itemText: string): boolean{
		const query = new RegExp(queryText, 'ig');
		return query.test(item.firstName) ||
		query.test(item.lastName) ||
		query.test(item.number);
	}

	addPlayer(player: PlayerOnTeam){
		this.positionFormData.playerIds.push(player.id);
		this.$nextTick(() => {
			this.selectedPlayer = null;
		});
	}
	removePlayer(index: number){
		this.positionFormData.playerIds.splice(index, 1);
	}
	moveUp(index: number){
		if(index <= 0) return;
		const [ player ] = this.positionFormData.playerIds.splice(index, 1);
		this.positionFormData.playerIds.splice(--index, 0, player);
	}
	moveDown(index: number){
		if(index > this.positionFormData.playerIds.length) return;
		const [ player ] = this.positionFormData.playerIds.splice(index, 1);
		this.positionFormData.playerIds.splice(++index, 0, player);
	}

	get HeaderStyle(){
		return {
			'height': `${this.headerHeight}px !important`,
			'line-height': `${this.headerHeight}px !important`,
			'color': 'white',
			'background-color': this.getColor(this.color),
			'border-bottom': `1px solid #0A1F32`,
		};
	}
	get SearchStyle(){
		return {
			'height': '90px',
			'line-height': '90px',
			'border-top': `1px solid #074E69 !important`,
			'background-color': this.getColor(this.color),
		};
	}
	get OverlayStyle(){
		return {
			'background-color': this.getColor(this.backgroundColor),
		};
	}
}
