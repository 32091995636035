import { Component, Mixins } from 'vue-property-decorator';
import { ScoutingReportScore } from "@/../types";
import { StringsMixin, MyCoachMixin } from '@/mixins';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { ScoutingObservationModel } from "@/models";
import { scoutingObservationApi } from '@/api/ScoutingObservationApi'

@Component
export class CoachObservationsMixin extends Mixins(StringsMixin, MyCoachMixin) {
    ScoutingReportScore = ScoutingReportScore;

    mounted() {
        this.loadObservations();
    }

    loadingObservations: boolean = false;
    observations: Array<ScoutingObservationModel> = [];
	async loadObservations() {
        if( this.IsEmpty(this.ObservationsAthleteId) ) return;

        this.loadingObservations = true;
		const query: RepositoryQuery<ScoutingObservationModel> = {
			$match: {
				athleteId: this.ObservationsAthleteId,
				coachId: this.MyCoachId,
			}
		}
		const options: QueryOptions = { sort: { fields: [{ field: 'date', desc: true }] } };
		const response: PaginatedResponse<ScoutingObservationModel> = await scoutingObservationApi.queryAll(query, options);
        this.observations = response.docs;
        this.loadingObservations = false;
	}
    get HasObservations(): boolean {
        return this.IsNotEmptyArray(this.observations);
    }
    get ObservationsAthleteId(): string {
        return 'this function must be overloaded';
    }
}
