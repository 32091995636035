import { AthleteMetric } from '@/../types/enums';
export function getMetricUnit(value: AthleteMetric, selfAssessment: boolean = false, derived: boolean = false): string {
	switch (value) {
	case AthleteMetric.Acceleration:
		return derived? 'm/s' : 's';
	case AthleteMetric.Speed:
		return derived? 'm/s' : 's';
	case AthleteMetric.Power:
		return derived? 'W' : 'cm';
	case AthleteMetric.Agility:
		return selfAssessment ? 's' : '';
	case AthleteMetric.Recovery:
		return derived? 'km/h' : '';
	default:
		return '';
	}
}
