import { render, staticRenderFns } from "./AccountSettingsPersonalInfo.vue?vue&type=template&id=c10003b6&scoped=true&"
import script from "./AccountSettingsPersonalInfo.vue?vue&type=script&lang=ts&"
export * from "./AccountSettingsPersonalInfo.vue?vue&type=script&lang=ts&"
import style0 from "./AccountSettingsPersonalInfo.vue?vue&type=style&index=0&id=c10003b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c10003b6",
  null
  
)

export default component.exports