
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { formatDatePretty,getTime } from '../../helpers/date';
// @ts-ignore
import viewMapPic from "../../assets/img/view-map-icon.png";
import FinalScoreBox from './FinalScoreBox.vue';
import { TeamModel } from '@/models/team';
import { teamsStore } from '@/store';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { GameResultModel } from '@/models/calendar/GameResultModel';

@Component({
	components: { FinalScoreBox },
})
export default class BoxScore extends Mixins(VuetifyMixin) {
	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: null }) focusDate: Date | null;
	@Prop({ default: null }) event: CalendarEventModel;
	@Prop({ default: null }) gameResult: GameResultModel | null;
	@Prop ({ default: null }) teamId: string;
	team: TeamModel | null = null
	viewMapPic = viewMapPic;

	async created(): Promise<void>{
		this.team = await teamsStore.loadTeamById(this.teamId)
	}

	get FocusDate(): Date{
		return this.focusDate;
	}
	get EventInfo():{date:string,time:string}{
		return {
			date: formatDatePretty(this.FocusDate),
			time: getTime(this.event.start),
		}
	}

	get MapLink(): string{
		if (this.event.address){
			const address = this.event.address
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+address)
		} else if (this.event.venue){
			const venue = this.event.venue
			return encodeURI('https://www.google.com/maps/search/?api=1&query='+venue)
		}
		return 'https://www.google.com/maps'
	}
}
