
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, FormRulesMixin, StatusMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { RemoteFile } from '@/models/file/FileModel';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { UploadResponse } from '@/components/utilities/upload/UploadDialog.vue';

@Component({
    components: {
        FileUploadArea,
    }
})
export default class GetFileDialog extends Mixins(StringsMixin, BAIconsMixin, VuetifyMixin, StatusMixin, FormRulesMixin) {
    @Prop({required: true}) prefix!: string;
    @Prop() value: RemoteFile;
    @Prop() buttonIcon: string;
    @Prop() buttonText: string;
    @Prop() buttonTooltip: string;
    @Prop() title: string;
    @Prop() uploadTooltip: string;
    @Prop({type: Boolean}) multiple;

    $refs:{ form: HTMLFormElement, title: HTMLTextAreaElement }
    waiting: boolean = false;

    showDialog: boolean = false;
    async onActivator() {
        this.showDialog = true;
    }

    async emitCurrentFile(): Promise<boolean> {
        if( !this.$refs.form.validate() ) {
            this.setError( "File information incomplete. Please complete all of the required fields" );
            return false;
        }

        // complete the current entry
        await this.$emit('input', this.value);
        return true;
    }
    async onAddAnother() {
        if( !(await this.emitCurrentFile()) ) return;
        this.value = new RemoteFile(`New ${this.title}`, '');
        this.showDialog = true;
        this.clearStatus();
        this.setFocus(this.$refs.title);
    }
    async onOK() {
        if( !(await this.emitCurrentFile()) ) return;
        this.showDialog = false;
    }
    async onCancel() {
        this.clearStatus();
        this.showDialog = false;
    }

    async readFile() {
        if( this.IsEmpty(this.value?.fileUrl) ) return;

        try {
            this.clearStatus();
            await this.value.read();
        } catch(e) {
            this.setError(e);
        }
    }
	async onFileUploaded(res: UploadResponse): Promise<void> {
        if( this.IsEmpty(res?.url)) return;

        this.value.fileUrl = res.url;
        await this.readFile();
	}
    async onFileChange() {        
        await this.readFile();
    }
}

