import { getEnumValues } from './get-enum-values';
/**
 * Supported Customer Countries. Determines a customer's plans
 */
export enum CustomerCountry {
	US="US",
	CA="CA",
}

export function getCustomerCountry(country: string): CustomerCountry {
	if( [CustomerCountry.CA, 'CANADA', 'CAN', 'CA'].includes(country.toUpperCase()) ) return CustomerCountry.CA;
	if( [CustomerCountry.CA, 'UNITED STATES', 'USA', 'US'].includes(country.toUpperCase()) ) return CustomerCountry.US;

	// default to USA
	return CustomerCountry.US;
}
export const CustomerCountryValues = getEnumValues<CustomerCountry>(CustomerCountry);