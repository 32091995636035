import { CrudApi } from './CrudApi';
import { BAPurchaseModel } from "@/models";
import { QueryOptions, PaginatedResponse, RepositoryQuery } from '@/../types/interfaces';

class BAPurchaseApi extends CrudApi<BAPurchaseModel> {
	constructor() {
		super('baPurchase', (obj) => new BAPurchaseModel().load(obj));
	}

	async findByAthleteId(athleteId: string): Promise<Array<BAPurchaseModel>> {
		const query: RepositoryQuery<BAPurchaseModel> = {
			search: athleteId,
			fields: ['parentId'],
		}
		const options: QueryOptions = {};
		const response: PaginatedResponse<BAPurchaseModel> = await this.queryAll(query, options);
		if( response.count < 1 ) return [];
		return response.docs;
	}
	async findPurchase(athleteId: string, productId: string): Promise<BAPurchaseModel> {
		const query: RepositoryQuery<BAPurchaseModel> = {
			$match: {
				parentId: athleteId,
				productId,
			}
		}
		const options: QueryOptions = {};
		const response: PaginatedResponse<BAPurchaseModel> = await this.queryAll(query, options);
		if( response.count < 1 ) return undefined;
		return response.docs[0];
	}
}

export const baPurchaseApi = new BAPurchaseApi();
