import store from '../store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { StatisticModel } from '../../models/statistic'
import { statisticApi } from '@/api/StatisticApi';
import { AgeGroup, UserMetadata } from '@best-athletes/ba-types';
import { ageToAgeGroup } from '@/../types';
import { AnalyticsAssessmentFilter, Comparison, GenericSpiderChart, RadarChartSeries, GetSpiderChartApiResponse } from '@best-athletes/ba-types';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';
import { AthleteAssessmentDataModel } from '@/models';
import { AxiosError } from 'axios';
import { PaginatedResponse } from '@best-athletes/ba-types';
import { athleteApi } from '@/api/AthleteApi';

export const Mutations = {
	SET_USE_SAMPLE_DATA: 'SET_USE_SAMPLE_DATA',
	SET_AGE_GROUPS: 'SET_AGE_GROUPS',

	LOAD_COMPARATIVE_DATA: 'LOAD_COMPARATIVE_DATA',
	LOAD_COMPARATIVE_DATA_SUCCESS: 'LOAD_COMPARATIVE_DATA_SUCCESS',
	LOAD_COMPARATIVE_DATA_FAILURE: 'LOAD_COMPARATIVE_DATA_FAILURE',

	CLEAR_USER_PERFORMANCE_CATEGORIES: 'CLEAR_USER_PERFORMANCE_CATEGORIES',

	GET_USER_PERFORMANCE_CATEGORIES: 'GET_USER_PERFORMANCE_CATEGORIES',
	GET_USER_PERFORMANCE_CATEGORIES_SUCCESS: 'GET_USER_PERFORMANCE_CATEGORIES_SUCCESS',
	GET_USER_PERFORMANCE_CATEGORIES_FAILURE: 'GET_USER_PERFORMANCE_CATEGORIES_FAILURE',

	SELECT_ASSESSMENT: 'SELECT_ASSESSMENT',

	LOAD_ASSESSMENTS: 'LOAD_ASSESSMENTS',
	LOAD_ASSESSMENTS_SUCCESS: 'LOAD_ASSESSMENTS_SUCCESS',
	LOAD_ASSESSMENTS_FAILURE: 'LOAD_ASSESSMENTS_FAILURE',

	SET_SPIDER_CHART_EMPTY: 'SET_SPIDER_CHART_EMPTY',
	SET_SPIDER_CHART_STATE: 'SET_SPIDER_CHART_STATE',
	LOAD_SPIDER_CHART: 'LOAD_SPIDER_CHART',
	LOAD_SPIDER_CHART_SUCCESS: 'LOAD_SPIDER_CHART_SUCCESS',
	LOAD_SPIDER_CHART_FAILURE: 'LOAD_SPIDER_CHART_FAILURE',

	LOAD_LINECHART_PERCENTILES: 'LOAD_LINECHART_PERCENTILES',
	LOAD_LINECHART_PERCENTILES_SUCCESS: 'LOAD_LINECHART_PERCENTILES_SUCCESS',
	LOAD_LINECHART_PERCENTILES_FAILURE: 'LOAD_LINECHART_PERCENTILES_FAILURE',
}

export type ComparativeDataV2State = {
	spiderChartState: ComparativeDataPageState;
	athleteId: string | null;
	assessment: AthleteAssessmentDataModel | null;
	spiderChartFilters: AnalyticsAssessmentFilter[];
	spiderChartData: RadarChartSeries[];
	spiderChartLowSampleSize: boolean;
	spiderChartAvailable: boolean;
	assessmentPagination: PaginatedResponse<AthleteAssessmentDataModel>;

	lineChartState: ComparativeDataPageState;
	lineChartPercentiles: GenericSpiderChart[];
	comparativeDataState: ComparativeDataPageState;
	compareGroups: AgeGroup[];
	statistics: StatisticModel[];
	comparisonLoading: boolean;
	comparisonInitialized: boolean;
	comparison: Comparison | null;
	// Getters
	AssessmentList: AthleteAssessmentDataModel[];
	ComparativeDataLoading: boolean;
	Over18: boolean;
	MyAgeGroup: AgeGroup;
	ComparativeDataReady: boolean;
	ComparisonReady: boolean;
	Comparison: Comparison | null;
};

const name = 'ComparativeDataV2Store';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class ComparativeDataV2Module extends VuexModule implements ComparativeDataV2State {
	/**
	 * useSampleData is persisted in the user's user_metadata managed by UserStore
	 */
	get useSampleData(): boolean{
		const user_metadata: UserMetadata = this.context.rootGetters['UserStore/UserMetadata'];
		return user_metadata.useSampleData ?? false;
	}
	@Action async setUseSampleData(useSampleData: boolean): Promise<void>{
		const userMetadata: { user_metadata: Partial<UserMetadata> } = { user_metadata: { useSampleData } };
		await this.context.dispatch('UserStore/updateUserMetadata', userMetadata, { root: true });
	}
	spiderChartState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	athleteId: string | null = null;
	assessment: AthleteAssessmentDataModel | null = null;
	spiderChartFilters: AnalyticsAssessmentFilter[] = [];
	spiderChartData: RadarChartSeries[] = [
		{
			values: [0, 0, 0, 0, 0],
			color: 'baColorLightBlue',
			borderColor: 'baColorDeepBlue',
			name: ''
		}
	];
	spiderChartLowSampleSize: boolean = false;
	spiderChartAvailable: boolean = false;
	assessmentPagination: PaginatedResponse<AthleteAssessmentDataModel> = {
		count: 0,
		docs: [],
		total: 0,
		page: 0,
	};
	
	lineChartState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	lineChartPercentiles: GenericSpiderChart[] = [];
	comparativeDataState: ComparativeDataPageState = new ComparativeDataPageState("Initial");
	compareGroups: AgeGroup[] = [];
	statistics: StatisticModel[] = [];
	comparisonLoading: boolean = false;
	comparisonInitialized: boolean = false;
	comparison: Comparison | null = null;

	get AssessmentList(): AthleteAssessmentDataModel[]{
		return this.assessmentPagination.docs;
	}

	@Action({
		rawError: true,
	}) async loadAssessments({ athleteId }: { athleteId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_ASSESSMENTS);
		try {
			const assessmentsQuery = await athleteApi.queryAssessmentsByAthleteId(athleteId, {},{
				limitPerPage: 24,
				sort: {
					fields: [{desc: true,field:'assessmentDate'}]
				}
			});
			this.context.commit(Mutations.LOAD_ASSESSMENTS_SUCCESS, { assessmentsQuery });
			if (this.assessment === null && this.AssessmentList.length > 0) {
				await this.context.dispatch('selectAssessment', { assessmentId: this.AssessmentList[0].id });
			}
		} catch (e) {
			this.context.commit(Mutations.LOAD_ASSESSMENTS_FAILURE, { error: e });
		}
	}
	@Mutation [Mutations.LOAD_ASSESSMENTS](): void {
		this.spiderChartState = new ComparativeDataPageState('Loading');
	}
	@Mutation [Mutations.LOAD_ASSESSMENTS_SUCCESS]({ assessmentsQuery }: { assessmentsQuery: PaginatedResponse<AthleteAssessmentDataModel> }): void {
		this.assessmentPagination = assessmentsQuery;
		if(this.assessmentPagination.docs.length === 0){
			this.spiderChartState = new ComparativeDataPageState('NoAssessment');
		}else{
			this.spiderChartState = new ComparativeDataPageState('Ready');
		}
	}
	@Mutation [Mutations.LOAD_ASSESSMENTS_FAILURE]({ error }: { error: AxiosError }): void {
		this.spiderChartState = ComparativeDataPageState.getComparativeDataPageState(error);
	}

	@Action({
		rawError: true,
	}) async loadSpiderChart({ athleteId, filters = [] }: { athleteId: string, filters?: AnalyticsAssessmentFilter[] }): Promise<void> {
		if(this.spiderChartState.IsInitial){
			await this.context.dispatch('loadAssessments', { athleteId });
		}
		if (!this.assessment) {
			this.context.commit(Mutations.SET_SPIDER_CHART_EMPTY, { filters: this.spiderChartFilters });
			this.context.commit(Mutations.SET_SPIDER_CHART_STATE, {state: new ComparativeDataPageState('NoAssessment')});
			return;
		}
		this.context.commit(Mutations.LOAD_SPIDER_CHART, { filters });
		try {
			const chartResponse = await athleteApi.getAssessmentRadarChart(
				athleteId,
				{
					assessmentId: this.assessment.id,
					filters: this.spiderChartFilters
				}
			);
			const { chart } = chartResponse;
			if (chart === null) {
				this.context.commit(Mutations.SET_SPIDER_CHART_EMPTY, { filters: chartResponse.filters });
				this.context.commit(Mutations.SET_SPIDER_CHART_STATE, {state: new ComparativeDataPageState('NotEnoughData')});
			} else {
				this.context.commit(Mutations.LOAD_SPIDER_CHART_SUCCESS, { chartResponse });
			}
		} catch (e) {
			this.context.commit(Mutations.LOAD_SPIDER_CHART_FAILURE, { error: e });
		}
	}
	@Action({
		rawError: true,
	}) async setSpiderChartLoading(): Promise<void> {
		this.context.commit(Mutations.SET_SPIDER_CHART_STATE, {state: new ComparativeDataPageState("Loading")});
	}
	@Mutation [Mutations.SET_SPIDER_CHART_STATE]({ state }: { state: ComparativeDataPageState } ): void {
		this.spiderChartState = state;
	}
	@Mutation [Mutations.SET_SPIDER_CHART_EMPTY]({ filters = []}: { filters?: AnalyticsAssessmentFilter[]} = {} ): void {
		this.spiderChartData = [{
			values: [0, 0, 0, 0, 0],
			color: 'baColorLightBlue',
			borderColor: 'baColorDeepBlue',
			name: ''
		}];
		this.spiderChartLowSampleSize = true;
		this.spiderChartAvailable = false;
		this.spiderChartFilters = filters;
	}
	@Mutation [Mutations.LOAD_SPIDER_CHART]({filters = []}:{filters?:AnalyticsAssessmentFilter[]} = {}): void {
		this.spiderChartState = new ComparativeDataPageState('Loading');
		this.spiderChartFilters = filters;
	}
	@Mutation [Mutations.LOAD_SPIDER_CHART_SUCCESS]({ chartResponse }: { chartResponse: GetSpiderChartApiResponse }): void {
		const { chart, filters, sample: { sampleIsLow } } = chartResponse;
		this.spiderChartData = [{
			values: [
				chart.power,
				chart.acceleration,
				chart.speed,
				chart.agility,
				chart.recovery
			],
			color: 'baColorLightBlue',
			borderColor: 'baColorDeepBlue',
			name: ''
		}];
		this.spiderChartLowSampleSize = sampleIsLow;
		this.spiderChartAvailable = true;
		this.spiderChartFilters = filters;
		this.spiderChartState = new ComparativeDataPageState('Ready');
	}
	@Mutation [Mutations.LOAD_SPIDER_CHART_FAILURE]({ error }: { error: AxiosError }): void {
		this.spiderChartState = ComparativeDataPageState.getComparativeDataPageState(error);
	}

	@Action({
		rawError: true,
	}) async loadLineChartPercentiles(
		{ athleteId, percentileFilters = [], assessmentFilters }: 
		{ athleteId: string, percentileFilters?: AnalyticsAssessmentFilter[], assessmentFilters:(assessment: AthleteAssessmentDataModel) => boolean  }
	): Promise<void> {
		this.context.commit(Mutations.LOAD_LINECHART_PERCENTILES);
		try {
			if (this.AssessmentList.length === 0) {
				await this.loadAssessments({ athleteId });
			}
			const responses = await Promise.all(
				this.AssessmentList.filter(assessmentFilters).map(async (assessment) => {
					return {
						assessment,
						chart: await athleteApi.getAssessmentRadarChart(this.athleteId, {
							assessmentId: assessment.id,
							filters: [
								...percentileFilters,
								{
									category: 'age_group',
									operator: '=',
									value: ageToAgeGroup(assessment.age, {allowUnder13: true}),
								}
							]
						})
					}
				})
			)
			const responsesWithAvailableCharts = responses.filter(r => r.chart.chart !== null)
			const percentiles = responsesWithAvailableCharts.map(r => r.chart.chart)

			this.context.commit(Mutations.LOAD_LINECHART_PERCENTILES_SUCCESS, { percentiles });
		} catch (e) {
			this.context.commit(Mutations.LOAD_LINECHART_PERCENTILES_FAILURE, { error: e });
		}
	}
	@Mutation [Mutations.LOAD_LINECHART_PERCENTILES](): void {
		this.lineChartState = new ComparativeDataPageState('Loading');
	}
	@Mutation [Mutations.LOAD_LINECHART_PERCENTILES_SUCCESS]({ percentiles }: { percentiles: GenericSpiderChart[] }): void {
		this.lineChartPercentiles = percentiles;
		if (this.lineChartPercentiles.length === 0){
			this.lineChartState = new ComparativeDataPageState('NoAssessment');
		}else{
			this.lineChartState = new ComparativeDataPageState('Ready');
		}
	}
	@Mutation [Mutations.LOAD_LINECHART_PERCENTILES_FAILURE]({ error }: { error: AxiosError }): void {
		this.lineChartState = ComparativeDataPageState.getComparativeDataPageState(error);
	}


	@Action({
		rawError: true,
	}) async selectAssessment({ assessmentId }: { assessmentId: string }): Promise<void> {
		const assessment = this.AssessmentList.find(a => a.id === assessmentId) ?? null;
		if(assessment !== null){
			const athleteId = assessment.athleteId;
			this.context.commit(Mutations.SELECT_ASSESSMENT, { assessment });
			this.context.dispatch('loadComparativeData', { assessmentId: assessment.id, athleteId });
		}
	}
	@Mutation [Mutations.SELECT_ASSESSMENT]({ assessment }: { assessment: AthleteAssessmentDataModel }): void {
		this.assessment = assessment;
		this.athleteId = assessment.athleteId;
	}

	get ComparativeDataLoading(): boolean{
		return this.comparativeDataState.IsLoading;
	}

	get Over18(): boolean{
		return [
			AgeGroup.U18,
			AgeGroup.U19,
			AgeGroup.U20,
			AgeGroup.O20,
		].includes(this.MyAgeGroup);
	}

	get MyAgeGroup(): AgeGroup{
		return this.context.rootGetters['AthleteProfileStore/AthleteAgeGroup'];
	}
	/** Compare groups from V1 comparative data might still be useful in the future */

	@Action({
		rawError: true,
	}) async setComparativeGroups({ ageGroups, assessmentId, athleteId }: { ageGroups: AgeGroup[], assessmentId: string, athleteId: string }): Promise<void> {
		try {
			this.context.commit(Mutations.SET_AGE_GROUPS, { ageGroups, assessmentId });
			this.loadComparativeData({ athleteId, assessmentId });
		} catch (e) {
			console.error("Failed to Set Age Groups", e);
		}
	}
	@Mutation [Mutations.SET_AGE_GROUPS]({ ageGroups }: { ageGroups: AgeGroup[] }): void {
		this.compareGroups = ageGroups;
	}

	get ComparativeDataReady(): boolean{
		return this.comparativeDataState.IsReady;
	}

	@Action({
		rawError: true,
	}) async loadComparativeData({ assessmentId, athleteId }: { assessmentId: string, athleteId: string }): Promise<StatisticModel[]> {
		try {
			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA);
			const statistics = await statisticApi.globalStatistics(athleteId, {
				assessmentId,
				ageGroup: [this.MyAgeGroup, ...this.compareGroups],
			});
			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA_SUCCESS, { statistics });
			return statistics;
		} catch (e) {
			console.error("Failed to Load Comparative Data", e);
			this.context.commit(Mutations.LOAD_COMPARATIVE_DATA_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_COMPARATIVE_DATA](): void {
		this.comparativeDataState = new ComparativeDataPageState('Loading');
	}
	@Mutation [Mutations.LOAD_COMPARATIVE_DATA_SUCCESS]({ statistics }: { statistics: StatisticModel[] }): void {
		this.statistics = statistics;
		this.comparativeDataState = new ComparativeDataPageState('Ready');
	}
	@Mutation [Mutations.LOAD_COMPARATIVE_DATA_FAILURE](error: any): void {
		this.comparativeDataState = ComparativeDataPageState.getComparativeDataPageState(error);
	}

	get ComparisonReady(): boolean {
		return this.comparisonInitialized && this.comparison !== null;
	}
	get Comparison(): Comparison | null {
		return this.comparison;
	}

	@Action({
		rawError: true,
	}) async getUserPerformanceCategories({ assessmentId }: { assessmentId: string }): Promise<Comparison> {
		try {
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES);
			const [ comparison ] = await statisticApi.compare({
				assessmentId,
			});
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES_SUCCESS, { comparison });
			return comparison;
		} catch (e) {
			console.error("Failed to Load Comparative Data", e);
			this.context.commit(Mutations.GET_USER_PERFORMANCE_CATEGORIES_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES](): void {
		this.comparisonLoading = true;
	}
	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES_SUCCESS]({ comparison }: { comparison: Comparison }): void {
		this.comparison = comparison;
		this.comparisonLoading = false;
		this.comparisonInitialized = true;
	}
	@Mutation [Mutations.GET_USER_PERFORMANCE_CATEGORIES_FAILURE](error: any): void {
		this.comparisonLoading = false;
	}

	@Action({
		rawError: true,
	}) async clearUserPerformanceCategories(): Promise<void> {
		this.context.commit(Mutations.CLEAR_USER_PERFORMANCE_CATEGORIES);
	}
	@Mutation [Mutations.CLEAR_USER_PERFORMANCE_CATEGORIES](): void {
		this.comparison = null;
	}
}
