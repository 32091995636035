import moment from 'moment';
import { DateTime, Duration, DurationOptions, DurationUnit, Settings, DateTimeFormatOptions } from 'luxon';
import { title } from '../pipes/title.pipe';
import { EventProgress } from '@/../types/enums';

export function getBrowserTimezone(): string{
	return Settings.defaultZoneName;
}

/**
 * Returns a Date at the beginning of today.
 * 
 * 
 * ie;
 * `2020-11-09T00:00:00.000Z`
 */
export function getTodayUTC(): Date{
	return clearTime(new Date());
}

/**
 *  Returns the beginning of the first day of the current month
 */
export function getBeginningOfMonth(utc: boolean = false): Date {
	const date = new Date();
	if(utc){
		date.setUTCDate(1);
		date.setUTCHours(0, 0, 0, 0);
	}else{
		date.setDate(1);
		date.setHours(0,0,0,0);
	}
	return date;
}
export function getDateInNDays(days: number = 365): Date {
	const date = new Date();
	date.setDate(date.getDate() + days);
	return date
}
export function getNextYear(): Date {
	return getDateInNDays(365)
}
/**
 * Returns a copy of date with the Hours, minutes, seconds, and miliseconds set to 0
 */
export function clearTime(date: Date): Date{
	const d = new Date(date);
	d.setUTCMilliseconds(0);
	d.setUTCSeconds(0);
	d.setUTCMinutes(0);
	d.setUTCHours(0);
	return d;
}

export function getDaysBetween(start: Date, end: Date, options?: DurationOptions): Duration {
	const End = DateTime.fromJSDate(end).toUTC();
	const Start = DateTime.fromJSDate(start).toUTC();
	return End.diff(Start, 'days', options);
}

export function dateIsBetween(date: Date, start: Date, end: Date): boolean{
	return date >= start && date <= end;
}

export function getDifference(start: Date, end: Date, unit: DurationUnit | DurationUnit[] = ['hours'], options?: DurationOptions): Duration{
	const End = DateTime.fromJSDate(end).toUTC();
	const Start = DateTime.fromJSDate(start).toUTC();
	return End.diff(Start, unit, options);
}
export function getDifferenceString(start: Date, end: Date, unit: DurationUnit | DurationUnit[] = ['hours'], options?: DurationOptions): string{
	const dur = getDifference(start, end, unit, options);
	const formatUnit = (u: DurationUnit) => {
		if (dur[u] === 0) return undefined;
		if (dur[u]=== 1) return `1 ${title(u).slice(0, u.lastIndexOf('s'))}`;
		return `${dur[u]} ${title(u)}`;
	};
	if(Array.isArray(unit)){
		return unit.map(formatUnit).filter(x => x !== undefined).join(', ');
	}
	return formatUnit(unit);
}

export function getMonthName(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("MMMM");
	}
	return DateTime.fromJSDate(date).toFormat("MMMM");
}
export function getShortMonthName(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("MMM");
	}
	return DateTime.fromJSDate(date).toFormat("MMM");
}

export function getWeekdayName(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("EEEE");
	}
	return DateTime.fromJSDate(date).toFormat("EEEE");
}
export function getShortWeekdayName(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("EEE");
	}
	return DateTime.fromJSDate(date).toFormat("EEE");
}

export function getDate(date: Date, utc: boolean = false): string{
	if(utc){
		return `${+DateTime.fromJSDate(date).toUTC().toFormat("d")}`;
	}
	return `${+DateTime.fromJSDate(date).toFormat("d")}`;
}

export function getDaySuffix(date: Date, utc: boolean = false): string{
	const day = getDate(date, utc);
	return `${ day }${getNumberSuffix(+day)}`;
}

export function getNumberSuffix(num: number): string{
	let suffix: string;
	const lastDigit = num % 10;
	const lastTwoDigits = num % 100;
	if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13){
		suffix = "th";
	}else{
		switch (lastDigit) {
		case 1:
			suffix = 'st';
			break;
		case 2:
			suffix = 'nd';
			break;
		case 3:
			suffix = 'rd';
			break;
		default:
			suffix = 'th';
		}
	}
	return suffix;
}

/**
 * Ensure a two digit number is always prefixed with 0
 * 3 => 03
 * 12 => 12
 */
export function zeroPrefix(num: number): string{
	return `0${num}`.slice(-2);
}

/**
 * Returns JS date as yyyy-MM-dd or yyyy-MM-dd hh:mm
 */
export function formatDate(date: Date, allDay?: boolean, utc: boolean = false): string{
	const format = allDay === false ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd";
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat(format);
	}
	return DateTime.fromJSDate(date).toFormat(format);
}

/**
 * Returns JS date as dd/MM/yyyy
 */
export function formatDateSlashes(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("dd/MM/yyyy");
	}
	return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy");
}

/**
 * Returns JS date as yyyy-MM-dd
 */
export function formatDateHyphensYYYYMMDD(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("yyyy-MM-dd");
	}
	return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
}

/**
 * Returns JS date as yyyy/MM/dd
 */
export function formatDateSlashesYYYYMMDD(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("yyyy/MM/dd");
	}
	return DateTime.fromJSDate(date).toFormat("yyyy/MM/dd");
}

/**
 * Returns JS date as MMMM dd, yyyy
 */
export function formatDatePretty(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("MMMM dd, yyyy");
	}
	return DateTime.fromJSDate(date).toFormat("MMMM dd, yyyy");
}

/**
 * Returns JS date as MMM dd, yyyy
 */
export function formatDatePrettyShort(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("MMM dd, yyyy");
	}
	return DateTime.fromJSDate(date).toFormat("MMM dd, yyyy");
}

/**
 * Returns JS date as dd MMMM yyyy
 */
export function formatDateEval(date: Date, utc: boolean = false): string{
	if(utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("dd MMMM yyyy");
	}
	return DateTime.fromJSDate(date).toFormat("dd MMMM yyyy");
}

/**
 * Returns JS date as Monday, Saturday, September 12, 2020 [at 9:00 AM EST]
 */
export function formatDatePrettyLong(date: Date, includeTime: boolean = true, utc: boolean = false): string{
	if(utc === false){
		return DateTime.fromJSDate(date).toFormat(`EEEE, MMMM dd, yyyy${includeTime ? ' \'at\' hh:mm a':''}`);
	}
	return DateTime.fromJSDate(date).toUTC().toFormat(`EEEE, MMMM dd, yyyy${includeTime ? ' \'at\' hh:mm a':''}`);
}
/**
 * Returns JS date as January - 2018
 */
export function formatDateMonthYear(date: Date, utc: boolean = false, seperator: string = ' - '): string{
	if(utc === false){
		return DateTime.fromJSDate(date).toFormat(`MMMM${seperator}yyyy`);
	}
	return DateTime.fromJSDate(date).toUTC().toFormat(`MMMM${seperator}yyyy`);
}

/**
 * Returns JS date as Jun 10, 2020 [|] 4:55PM
 */
export function formatDateLastModified(date: Date, utc: boolean = false): string{
	const fmt = `MMM dd, yyyy | h:mm:ss a`;
	if (utc){
		return DateTime.fromJSDate(date).toUTC().toFormat(fmt);
	}
	return DateTime.fromJSDate(date).toFormat(fmt);
}

/**
 * Returns JS date as Jun 10
 */
export function formatDateMonthDay(date: Date, utc: boolean = false): string{
	const fmt = `MMM dd`;
	if (utc){
		return DateTime.fromJSDate(date).toUTC().toFormat(fmt);
	}
	return DateTime.fromJSDate(date).toFormat(fmt);
}

export function formatDateAndTime(date: Date, utc: boolean = false): string {
	const fmt = `MMM dd, yyyy  h:mm a`;
	if (utc){
		return DateTime.fromJSDate(date).toUTC().toFormat(fmt);
	}
	return DateTime.fromJSDate(date).toFormat(fmt);
}


/**
 * Returns JS date as 9:00 AM EST
 */
export function getTime(date: Date, { keepLocalTime = true, twentyFourHour = false }: { keepLocalTime?: boolean, twentyFourHour?: boolean } = {}): string{
	let format: string = "h:mm a";
	if(twentyFourHour){
		format = "HH:mm";
	}
	if(keepLocalTime){
		return DateTime.fromJSDate(date).toFormat(format).toUpperCase();
	}
	return DateTime.fromJSDate(date).toUTC().toFormat(format).toUpperCase();
}

/**
 * Returns age from birthdate in years and months
 */
export function calculateAge(date: Date): { years: number, months: number }{
	const a = moment();
	const b = DateTime.fromJSDate(date);
	const age = moment.duration(a.diff(b));
	const years = age.years();
	const months = age.months();
	return { years, months };
}

/**
 * Returns a formatted date string based on the provided format.
 * @param date
 * @param format
 * @returns {string}
 */
export function formatDateWithFormat(date: Date, format: string): string{
	return DateTime.fromJSDate(date).toUTC().toFormat(format);
}

/**
 * Returns JS date as yyyy-MM-dd or yyyy-MM-dd hh:mm
 */
export function getLongDateString(date: Date): string{
	const monthName = getMonthName(date);
	const weekdayName = getWeekdayName(date);
	const day = getDaySuffix(date);
	return `${weekdayName} ${monthName} ${day}`;
}

/**
 * Returns JS date as MMM D, yyyy
 */
export function getMediumDateString(date: Date, utc: boolean = false): string{
	if (utc){
		return DateTime.fromJSDate(date).toUTC().toFormat("MMM d, yyyy");
	}
	return DateTime.fromJSDate(date).toFormat("MMM d, yyyy");
}

/**
 * Formats a duration in Seconds for use in a timeline like 260.0 -> "4:20" or 10.2 as "10.2 Seconds"
 */
export function formatDurationShort(theDuration: number, includeSuffix?: boolean, suffixValue?: string): string{
	const theSecs = theDuration
	const hh = Math.floor(theSecs / 3600)
	const mm = Math.floor(theSecs / 60) % 60
	const ss = Math.floor(theSecs) % 60
	const sss = Math.floor(theSecs * 10) % 10
	return (
		(hh > 0 ? hh + ':' : '') +
	(hh > 0 && mm < 10 ? '0' + mm + ':' : mm > 0 ? mm + ':' : '') +
	(ss < 10 && mm > 0 ? '0' : '') +
	ss +
	(mm < 1 ? '.' + sss + (includeSuffix ? ' ' + suffixValue : '') : '' )
	)
}

/**
 * Formats minutes as X hours Y minutes
 */
export function formatMinutesPretty(minutes: number): string{
	const hh = Math.floor(minutes / 60)
	const mm = Math.floor(minutes) % 60
	return (
		(hh > 0 ? hh + ' hours ' : '') +
		(mm > 0 ? mm + ' minutes ' : '')
	)
}
/**
 * Formats a duration in Minutes & Seconds for use in a timeline scale 260.0 -> "04:20" or 10.2 as "00:10"
 */
export function formatDurationForTimeline(theDuration: number): string{
	const theSecs = theDuration
	const hh = Math.floor(theSecs / 3600)
	const mm = Math.floor(theSecs / 60) % 60
	const ss = Math.floor(theSecs) % 60
	return (
		(hh > 0 ? hh + ':' : '') +
	(mm < 10 ? '0' + mm + ':' : mm + ':') +
	(ss < 10 ? '0' : '') + ss
	);
}

/**
 * Returns true if string is a valid date
 */
export function isValidDate(date: string | Date): boolean {
	return isNaN(new Date(date).getTime()) === false;
}

/**
 * Returns an enum of if an event is upcoming, inprogress, or completed
 */
export function eventProgress(start: Date, end: Date, focusDate?: Date): EventProgress{
	if(start === null || end === null) return EventProgress.Upcoming;
	const currentTime = new Date();
	const focus = focusDate ? focusDate : new Date();
	const focusStart = new Date(focus.getFullYear(), focus.getMonth(), focus.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
	const focusEnd = new Date(focus.getFullYear(), focus.getMonth(), focus.getDate(), end.getHours(), end.getMinutes(), end.getSeconds());
	if (focusStart > currentTime){
		return EventProgress.Upcoming
	} else if (focusStart <= currentTime && focusEnd >= currentTime){
		return EventProgress.InProgress
	} else{
		return EventProgress.Completed
	}	
}

