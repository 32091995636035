
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AuthMixin, BAIconsMixin, StringsMixin, VuetifyMixin, StatusMixin, StripeMixin, PurchaseMixin, AthleteRoutingMixin, AthleteProfileMixin, StylesMixin } from '@/mixins';
import { BAPartnerProduct, BAPurchaseModel, BAPartnerModel } from '@/models';
import { userStore } from '@/store';
import OnboardingDialog from '@/views/onboarding/OnboardingDialog.vue';
import { User } from '@auth0/auth0-spa-js';

@Component({
    components: {
        OnboardingDialog,
    }
})
export default class PartnerProductPurchaseDialog extends Mixins(BAIconsMixin, StringsMixin, VuetifyMixin, StatusMixin, StylesMixin, AuthMixin, StripeMixin, PurchaseMixin, AthleteProfileMixin, AthleteRoutingMixin) {
    @Prop({required: true}) product: BAPartnerProduct;
    @Prop() partner: BAPartnerModel;

    loginRequest: boolean = false;

    gotoPartnerWebsite() {
        if( this.IsEmpty(this.partner?.website) ) return;

        window.open(this.partner.website, "_blank");
    }

    onOrderRequest() {
        this.athleteProfile = userStore.AthleteProfile;
        if( this.IsNotEmpty(this.athleteProfile) ) {
            this.purchaseRequest = new BAPurchaseModel().load({
                parentId: this.AthleteId,
                productId: this.product.id,
                priceId: this.product.Price?.id,
                date: new Date(),
                requested: true,
                completed: false,
                data: this.PurchaseAthleteLabels(this.product.dataLabels, this.athleteProfile),
            })
        }
        this.onShowPurchaseDialog();
    }

    async onProceedToPayment() {
        try {
        const purchase = await this.savePurchaseRequest();

        if( this.product.IsFree ) {
                await this.completePurchaseRequest(purchase);
                this.reloadWindow();
            } else {
                // go to Stripe to purchase the product
                this.buyPartnerProduct(this.product, this.product.Price, this.AthleteId);
            }
        } catch(e) {
            this.setError(`Unable to process purchase. Contact Best Athletes for support:\n\n${e.message}`);
            this.waitForStripe = false;
        }
    }

	async onLoginRequest() {
        try{
            this.loginRequest = true;
            this.setStatus(`Log in using your Best Athletes account`);
            const success = await this.loginUsingPopup();

            if( success ) {
                this.setStatus(`User logged in. Retrieving user information...`);
                const user: User = await this.updateAfterLogin();
                if( this.IsNotEmpty(user) ) {
                    this.setStatus(`User Logged in successfully. Refreshing page...`);
                    this.reloadWindow();
                }
            } else {
                this.setError("User login cancelled");
                this.loginRequest = false;
            }
        } catch(e) {
            this.loginRequest = false;
            this.setError(e);
            this.gotoLogout();
        }
	}
}

