import store from "../store";
import { Module, VuexModule, Mutation, Action, MutationAction } from "vuex-module-decorators";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from "@/../types/interfaces";
import { ESSchoolModel, SchoolModel } from "@/models/school/SchoolModel";
import { schoolApi } from "@/api/SchoolApi";

const Mutations = {
	LOAD_SCHOOLS: "LOAD_SCHOOLS",
	LOAD_SCHOOLS_SUCCESS: "LOAD_SCHOOLS_SUCCESS",
	LOAD_SCHOOLS_FAILURE: "LOAD_SCHOOLS_FAILURE",
	LOAD_SPECIFIC_SCHOOL: "LOAD_SPECIFIC_SCHOOL",
	LOAD_SPECIFIC_SCHOOL_SUCCESS: "LOAD_SPECIFIC_SCHOOL_SUCCESS",
	LOAD_SPECIFIC_SCHOOL_FAILURE: "LOAD_SPECIFIC_SCHOOL_FAILURE",
};

const name = "SchoolStore";

if (store.state[name]) {
	store.unregisterModule(name);
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store,
})
export default class SchoolModule extends VuexModule {
	
	totalSchools: number = 0;
	schoolList: (SchoolModel | ESSchoolModel)[] = [];
	schoolsLoading: boolean = false;
	schoolsInitialized: boolean = false;
	activeSchools: number = 0;
	parentId: string = "";
	
	@MutationAction
	async setParentId(parentId: string): Promise<{parentId: string}> {
		return { parentId }
	}

	@Action({
		rawError: true,
	}) async loadSchoolList({ query, options }: {
		query?: RepositoryQuery<SchoolModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.LOAD_SCHOOLS);
		try {
			const [{ count }, schools] = await Promise.all([
				schoolApi.countAll(),
				schoolApi.searchSchools({query: query, options: options}, { headers: {'parent-id': this.parentId}}),
				// schoolApi.queryAll({...query}, options,),
			]);

			this.context.commit(Mutations.LOAD_SCHOOLS_SUCCESS, { count, schools });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_SCHOOLS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_SCHOOLS](): void {
		this.schoolsLoading = true;
	}
	@Mutation [Mutations.LOAD_SCHOOLS_SUCCESS]({count,schools}: {count: number, schools: PaginatedResponse<SchoolModel | ESSchoolModel>}): void {
		this.schoolsLoading = false;
		this.schoolsInitialized = true;
		this.activeSchools = count;
		this.totalSchools = schools.total;
		this.schoolList = schools.docs.map(school => new ESSchoolModel().load(school as ESSchoolModel));
		// this.schoolList = schools.docs.map(school => new SchoolModel().load(school as SchoolModel));
	}
	@Mutation [Mutations.LOAD_SCHOOLS_FAILURE](error: any): void {
		this.schoolsLoading = false;
	}

	specificSchool: SchoolModel | null;

	@Action({
		rawError: true,
	})
	async getSpecificSchool(id: string): Promise<void> {
		this.context.commit(Mutations.LOAD_SPECIFIC_SCHOOL);
		try {
			console.log(id);
			
			const response = await schoolApi.findById(id);
			this.context.commit(
				Mutations.LOAD_SPECIFIC_SCHOOL_SUCCESS,
				response
			);
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_SPECIFIC_SCHOOL_FAILURE, e);
		}
	}
	@Mutation [Mutations.LOAD_SPECIFIC_SCHOOL](): void {
		this.schoolsLoading = true;
	}

	@Mutation [Mutations.LOAD_SPECIFIC_SCHOOL_SUCCESS](
		response: SchoolModel
	): void {
		this.specificSchool = response;
		this.schoolsLoading = false;
	}

	@Mutation [Mutations.LOAD_SPECIFIC_SCHOOL_FAILURE](error: any): void {
		this.schoolsLoading = false;
	}
}
