import { CrudApi } from './CrudApi';
import { VideoClipCategoriesModel } from '../models/video-clip-categories/VideoClipCategoriesModel';
import { SportName } from '@/../types/enums/sports';
import { ClipCategoryModel } from '../models/video-clip-categories/ClipCategoryModel';


class VideoClipCategoriesModelApi extends CrudApi<VideoClipCategoriesModel>{
	constructor() {
		super('videoClipCategories', (obj) => new VideoClipCategoriesModel().load(obj));
	}

	async findBySport({ sportName }: { sportName: SportName }): Promise<VideoClipCategoriesModel[]> {
		return await CrudApi.Api(c => c.get(`/videoClipCategories/${sportName}`));
	}
	async findBySportAndCollection({ sportName, collectionName }: { sportName: SportName , collectionName: string}): Promise<VideoClipCategoriesModel[]> {
		return await CrudApi.Api(c => c.get(`/videoClipCategories/${sportName}/${collectionName}`));
	}
	// save collection of categories handed by parent class
	async deleteCollectionForSport({ sportName, collectionName }: { sportName: SportName, collectionName: string }): Promise<VideoClipCategoriesModel[]> {
		return await CrudApi.Api(c => c.delete(`/videoClipCategories/${sportName}/${collectionName}`));
	}


	async findClipCategoryInCollectionByName({ sportName, collectionName, clipCategoryName }: { sportName: SportName, collectionName: string, clipCategoryName: string }): Promise<ClipCategoryModel> {
		return await CrudApi.Api(c => c.get(`/videoClipCategories/${sportName}/${collectionName}/by-name/${clipCategoryName}`));
	}
	async findClipCategoryInCollectionByIndex({ sportName, collectionName, clipCategoryIndex }: { sportName: SportName, collectionName: string, clipCategoryIndex: string }): Promise<ClipCategoryModel> {
		return await CrudApi.Api(c => c.get(`/videoClipCategories/${sportName}/${collectionName}/by-index/${clipCategoryIndex}`));
	}
	async saveClipCategoryToCollection({ sportName, collectionName, clipCategory }: { sportName: SportName, collectionName: string, clipCategory: ClipCategoryModel }): Promise<VideoClipCategoriesModel> {
		return await CrudApi.Api(c => c.post(`/videoClipCategories/${sportName}/${collectionName}/clip-category`, clipCategory));
	}
	async updateClipCategoryDescription({ sportName, collectionName, clipCategory }: { sportName: SportName, collectionName: string, clipCategory: ClipCategoryModel }): Promise<VideoClipCategoriesModel> {
		return await CrudApi.Api(c => c.patch(`/videoClipCategories/${sportName}/${collectionName}/clip-category`, clipCategory));
	}
	async deleteClipCategoryFromCollectionByName({ sportName, collectionName, clipCategoryName }: { sportName: SportName, collectionName: string, clipCategoryName: string }): Promise<ClipCategoryModel> {
		return await CrudApi.Api(c => c.delete(`/videoClipCategories/${sportName}/${collectionName}/by-name/${clipCategoryName}`));
	}
	async deleteClipCategoryFromCollectionByIndex({ sportName, collectionName, clipCategoryIndex }: { sportName: SportName, collectionName: string, clipCategoryIndex: string }): Promise<ClipCategoryModel> {
		return await CrudApi.Api(c => c.delete(`/videoClipCategories/${sportName}/${collectionName}/by-index/${clipCategoryIndex}`));
	}

}
export const videoClipCategoriesModelApi = new VideoClipCategoriesModelApi();