import { CrudApi } from './CrudApi';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { AthleteProfileModel } from '@/models';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { VideoModel } from '@/models/video/VideoModel';

export class RecipientTargetedSharingApi extends CrudApi<TargetedSharingModel>{
	constructor(coachId: string) {
		super(`targetedSharing/recipient/${coachId}`, (obj) => new TargetedSharingModel().load(obj));
	}

	async setResponse(id: string, response: RecruitingResponse): Promise<TargetedSharingModel> {
		const res = await CrudApi.Api(c => c.post(`/${this.resource}/${id}/set-response`, { response }));
		return this.create(res);
	}
	async setCoachNotes(id: string, notes: string): Promise<TargetedSharingModel> {
		const res = await CrudApi.Api(c => c.post(`/${this.resource}/${id}/set-coachNotes`, { notes }));
		return this.create(res);
	}
	async setArchiveCoach(id: string, archive: boolean): Promise<TargetedSharingModel> {
		const res = await CrudApi.Api(c => c.post(`/${this.resource}/${id}/set-archivecoach`, { archive }));
		return this.create(res);
	}
	
	async getRecruitingProfile(id: string): Promise<RecruitingProfileModel> {
		const res = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/get-athlete-profile`));
		return new RecruitingProfileModel().load(res);
	}
	async getAthleteProfile(id: string): Promise<AthleteProfileModel> {
		const res = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/get-athlete-profile`));
		return new AthleteProfileModel().load(res);
	}
	async getAthleteProfileVideos(id: string): Promise<VideoModel[]> {
		const res = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/get-athlete-videos`));
		return res.map((video: any) => new VideoModel().load(video));
	}
	async getAthleteProfileVideoClips(id: string): Promise<VideoClipModel[]> {
		const res = await CrudApi.Api(c => c.get(`/${this.resource}/${id}/get-athlete-videoClips`));
		return res.map((video: any) => new VideoModel().load(video));
	}
}