// StatusMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { StringsMixin } from '@/mixins';

@Component
export class StatusMixin extends Mixins(StringsMixin) {
    statusText: string = "";
    infoText: string = "";
    errorText: string = "";

    // mounted() {
    //     this.$on('onError', this.onError);
    //     this.$on('onInfo', this.onInfo);
    // }

    get HasStatus() {
        return this.IsNotEmpty(this.statusText);
    }
    get HasInfo() {
        return this.IsNotEmpty(this.infoText);
    }
    get HasError() {
        return this.IsNotEmpty(this.errorText);
    }
    clearStatus(status: string = undefined) {
        this.statusText = status;
        this.infoText = undefined;
        this.errorText = undefined;
    }
    setStatus(status: string) {
        this.clearStatus();
        this.statusText = status;
    }
    setError(error: string) {
        console.error(error);

        this.clearStatus(error);
        this.errorText = error;
        this.$emit('onError');
    }
    setInfo(info: string) {
        this.clearStatus(info);
        this.infoText = info;
        this.$emit('onInfo');
    }

    // // override the following functions to handle post-processing of status events
    // onError() {}
    // onInfo() {}

}