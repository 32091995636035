import { isEmpty } from '@/pipes';
import { AccessControlledModel } from '../AccessControlledModel';
import { ScoutingReportPlayerDetails, ScoutingReportGameDetails, ScoutingReportEvaluationMatrix, ContactInfo, ScoutingReportEvaluationMatrixNotes, ScoutingReportScore } from '@best-athletes/ba-types';

export class ScoutingReportModel extends AccessControlledModel{
	version: number = 2;
	createdAt: Date = new Date();
	athleteId?: string | null = null;
	contactInfo: ContactInfo | null = null;
	lastModified: Date | null = null;
	anonymousCoach: boolean = false;

	playerDetails: ScoutingReportPlayerDetails;
	gameDetails: ScoutingReportGameDetails;
	evaluationMatrix: ScoutingReportEvaluationMatrix;
	evaluationMatrixNotes: ScoutingReportEvaluationMatrixNotes;

	submitted: boolean = false;

	constructor() {
		super();
		this.createdAt = new Date;
		this.athleteId = undefined;
		this.contactInfo = {
			firstName: '',
			lastName: '',
			email: '',
			lastContact: new Date,
		};
		this.lastModified = new Date;
		this.anonymousCoach = false;
		this.playerDetails = {
			firstName: '',
			lastName: '',
			position: '',
			jerseyNumber: 0,
		};
		this.gameDetails = {
			coachId: '',
			evaluationDate: new Date,
		}
		this.evaluationMatrix = {
			technical: null,
			tactical: null,
			physical: null,
			mental: null,
			attackingOrganization: null,
			attackingTransition: null,
			attackingSetPlays: null,
			defendingOrganization: null,
			defendingTransition: null,
			defendingSetPlays: null,
			competitive: null,
			resilience: null,
			intelligence: null,
			speed: null,
			presence: null,
			performanceScore: null,
			potentialScore: null,
		};
		this.evaluationMatrixNotes = {
			technical: '',
			tactical: '',
			physical: '',
			mental: '',
			attackingOrganization: '',
			attackingTransition: '',
			attackingSetPlays: '',
			defendingOrganization: '',
			defendingTransition: '',
			defendingSetPlays: '',
			competitive: '',
			resilience: '',
			intelligence: '',
			speed: '',
			presence: '',
			performanceScore: '',
			potentialScore: '',
		};
		this.submitted = false;
	}
	/** True if all steps of the Evaluation Matrix have been given a grade */
	get IsComplete(): boolean{
		return this.submitted;
	}

	get PlayerName(): string | null{
		if(this.playerDetails.firstName && this.playerDetails.lastName){
			return `${this.playerDetails.firstName} ${this.playerDetails.lastName}`;
		}
		return null;
	}

	get HasContactInfo(): boolean{
		if(this.contactInfo === null) return false;
		return this.contactInfo.email !== "";
	}

	EvaluationStr(item: string, fourPlusThreshold: number = 4.99): string {
		if( isEmpty( this.evaluationMatrix[item] ) ) return '';
		return this.evaluationMatrix[item] > fourPlusThreshold? '4+' : this.evaluationMatrix[item].toFixed(1);
	}
	ScoreLevel(score: ScoutingReportScore ): string {
		switch( score ) {
			case ScoutingReportScore.Score1:
				return 'Inferior';
			case ScoutingReportScore.Score2:
				return 'Ordinary';
			case ScoutingReportScore.Score3:
				return 'Average';
			case ScoutingReportScore.Score4:
				return 'Excellent';
			case ScoutingReportScore.Score4Plus:
				return 'Outstanding';
			}
	}
	GetCategoryDescriptor(category: string): string {
		switch( category ) {
			case 'potentialScore': return  `overall athlete at this level`;
			case 'technical': return `technical player`;
			case 'tactical': return `tactical outlook`;
			case 'physical': return `physical presence`;
			case 'mental': return `mindset`;
			case 'attackingOrganization': return `plan while organizing the attack`;
			case 'attackingTransition': return `transition execution`;
			case 'attackingSetPlays': return `preparation during attacking set pieces`;
			case 'defendingOrganization': return `organization while defending`;
			case 'defendingTransition': return `execution transitioning to defence`;
			case 'defendingSetPlays': return `presence while defending set pieces`;
			case 'competitive': return `competitive spirit`;
			case 'resilience': return `resilience`;
			case 'intelligence': return `mindset and outlook`;
			case 'speed': return `speed of execution`;
			case 'presence': return `presence of the field`;
				}
	}
	GetNote(category: string): string {
		if( !this.evaluationMatrixNotes[category] ) return `An ${this.ScoreLevel(this.evaluationMatrix[category])} ${this.GetCategoryDescriptor(category)}`;
		return this.evaluationMatrixNotes[category];
	}
	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['createdAt']) this.createdAt = new Date(obj['createdAt']);
		if (obj['lastModified'] !== null) this.lastModified = new Date(obj['lastModified']);
		if (obj['playerDetails'] && obj['playerDetails']['dateOfBirth']){
			this.playerDetails.dateOfBirth = new Date(obj['playerDetails']['dateOfBirth']);
		}
		if (obj['gameDetails'] && obj['gameDetails']['evaluationDate']){
			this.gameDetails.evaluationDate = new Date(obj['gameDetails']['evaluationDate']);
		}
		if (obj['evaluationMatrixNotes'] === undefined) {
			this.evaluationMatrixNotes = {
				technical: null,
				tactical: null,
				physical: null,
				mental: null,
				attackingOrganization: null,
				attackingTransition: null,
				attackingSetPlays: null,
				defendingOrganization: null,
				defendingTransition: null,
				defendingSetPlays: null,
				competitive: null,
				resilience: null,
				intelligence: null,
				speed: null,
				presence: null,
				performanceScore: null,
				potentialScore: null,
			}
		}
		if (obj['playerDetails']['competitiveLevel'] == undefined) {
			this.playerDetails.competitiveLevel = null;
		}
		return this;
	}

	get FourCornerAvailable(): boolean{
		if( !this.evaluationMatrix ) return false;
		return( !!this.evaluationMatrix.technical && !!this.evaluationMatrix.tactical && !!this.evaluationMatrix.physical && !!this.evaluationMatrix.mental );
	}
	get FourMomentAvailable(): boolean {
		if( !this.evaluationMatrix ) return false;
		if (this.evaluationMatrix.attackingOrganization && this.evaluationMatrix.attackingTransition && this.evaluationMatrix.defendingOrganization && this.evaluationMatrix.defendingTransition){
			return true
		}
		return false
	}
	get SixMomentAvailable(): boolean {
		if( !this.evaluationMatrix ) return false;
		if (this.FourMomentAvailable && this.evaluationMatrix.attackingSetPlays && this.evaluationMatrix.defendingSetPlays){
			return true
		}
		return false
	}
	get CRISPAvailable(): boolean{
		if( !this.evaluationMatrix ) return false;
		if (this.evaluationMatrix.competitive && this.evaluationMatrix.resilience && this.evaluationMatrix.intelligence && this.evaluationMatrix.speed && this.evaluationMatrix.presence){
			return true
		}
		return false
	}
	get OverallChartAvailable(): boolean{
		return this.FourCornerAvailable && this.SixMomentAvailable && this.CRISPAvailable;
	}

	get FourCornersLabels(): string[]{
		return ['Technical', 'Tactical', 'Physical', 'Mental'];
	}
	get SixMomentEvaluationLabels(): string[]{
		return [
			'Att Org', 'Att Trans', 'Att Set Plays',
			'Def Org', 'Def Trans', 'Def Set Plays'
		];
	}
	get CRISPEvaluationLabels(): string[]{
		return ['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence'];
	}
	get OverallChartLabels(): string[]{
		return [
			...this.FourCornersLabels,
			...this.SixMomentEvaluationLabels,
			...this.CRISPEvaluationLabels,
		]
	}

	get FourCornersValues(): number[]{
		if (!this.FourCornerAvailable) return [];
		return [
			this.evaluationMatrix.technical,
			this.evaluationMatrix.tactical,
			this.evaluationMatrix.physical,
			this.evaluationMatrix.mental,
		]
	}
	get SixMomentValues(): number[]{
		if (!this.SixMomentAvailable) return [];
		return  [
			this.evaluationMatrix.attackingOrganization,
			this.evaluationMatrix.attackingTransition,
			this.evaluationMatrix.attackingSetPlays,
			this.evaluationMatrix.defendingOrganization,
			this.evaluationMatrix.defendingTransition,
			this.evaluationMatrix.defendingSetPlays,
		]
	}
	get CRISPValues(): number[]{
		if (!this.CRISPAvailable) return [];
		return [
			this.evaluationMatrix.competitive,
			this.evaluationMatrix.resilience,
			this.evaluationMatrix.intelligence,
			this.evaluationMatrix.speed,
			this.evaluationMatrix.presence
		]
	}
	get OverallChartValues(): number[]{
		return [
			...this.FourCornersValues,
			...this.SixMomentValues,
			...this.CRISPValues,
		]
	}

}