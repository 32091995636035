
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImpersonationLayout extends Vue{


	@Prop({ default: null }) title: string | null;

}
