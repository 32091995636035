
import { Component, Mixins, Prop} from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';

@Component({
	components: {
		ProfilePictureUpload,
	}
})
export default class CoachSettingPersonalInfo extends Mixins(VuetifyMixin){
	@Prop() value: CoachProfileModel;

	showImageUploadDialog = false;
	valid = false;
}
