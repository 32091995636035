import { CrudApi } from './CrudApi';
import { BAPartnerModel } from "@/models";
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';

class BAPartnerApi extends CrudApi<BAPartnerModel> {
	constructor() {
		super('baPartner', (obj) => new BAPartnerModel().load(obj));
	}

	async findByName(partnerName: string): Promise<BAPartnerModel | null> {
		const query: RepositoryQuery<BAPartnerModel> = { 
			search: partnerName.trim(),
			fields: ['name']
		};
		const options: QueryOptions = {};
		const partnersFound = await this.queryAll(query, options);
		if( partnersFound.count > 0 ) {
			for( const partner of partnersFound.docs ) {
				if( partner.name === partnerName ) return partner;
			}
		}
		return null;
	}

}

export const baPartnerApi = new BAPartnerApi();
