
import { MindsetUserModel } from '@/models';
import { CrudApi } from './CrudApi';

class MindsetUserApi extends CrudApi<MindsetUserModel>{
	constructor() {
		super('mindsetUser', (obj) => new MindsetUserModel().load(obj));
	}
}
export const mindsetUserApi = new MindsetUserApi();
