/* MindsetReportMixin.ts */

import { Component, Vue } from 'vue-property-decorator';

@Component
export class MindsetReportMixin extends Vue {
	MindsetReportsInfoHeaders = [
		{
			text: 'Trait',
			value: 'name',
			sortable: false
		},
		{
			text: 'Description',
			value: 'description',
			sortable: false
		}
	]
	MindsetReportsInfoItems = [
		{
			name: "Mental Toughness",
			abbr: "M.Tgh",
			description: "Ability to perform well under adversity"
		},
		{
			name: "Grit",
			abbr: "Grit",
			description: "Ability to persevere over the long haul",
		},
		{
			name: "Motivational Drive",
			abbr: "Driv",
			description: "Intrinsic motivation, aspiration to achieve, desire",
		},
		{
			name: "Composure",
			abbr: "Cpsr",
			description: "Emotional maturity, stability, positive outlook ",
		},
		{
			name: "Confidence",
			abbr: "Cnfd",
			description: "Level of self-confidence and assertiveness",
		},
		{
			name: "Coachability",
			abbr: "Coach",
			description: "Ability and willingness to apply coaching",
		},
		{
			name: "Growth Mindset",
			abbr: "Gr.Mnd",
			description: "Belief that abilites can be improved through effort",
		},
		{
			name: "Attention to Detail",
			abbr: "Detail",
			description: "Natural desire to complete tasks properly",
		},
		{
			name: "Conventionality",
			abbr: "Cnvntl",
			description: "Importance of societal norms, traditions and rules",
		},
		{
			name: "Mental Execution",
			abbr: "M.Exc",
			description: "Ability to make acurate and timely decisions",
		},
		{
			name: "Focus Speed",
			abbr: "F.Spd",
			description: "Ability to quickly and accurately focus",
		},
		{
			name: "Decisiveness",
			abbr: "Dcvns",
			description: "Tendency to make quick decisions",
		},
		{
			name: "Adaptability",
			abbr: "Adapt",
			description: "Adapt to new ideas, discover concepts, develop new skills",
		},
	]
}
