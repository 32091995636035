
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PersonalInfoForm, BAUser } from '@/../types/interfaces';
import { FormRulesMixin } from '../../../mixins';

@Component
export default class AccountSettingsPersonalInfo extends Mixins(FormRulesMixin){
	
	valid: boolean = false;
	@Prop({ default: (): PersonalInfoForm => ({
		firstName: "",
		lastName: "",
		email: "",
		phone: null,
		gender: null,
	})}) value: PersonalInfoForm;

	@Prop() user: BAUser;
	@Prop({ type: Boolean, default: false }) saveLoading: boolean;

	get EditsAllowed(): boolean{
		return false && this.HasSocialIdentity
	}
	get HasSocialIdentity(): boolean{
		return this.user.identities.find(i => i.isSocial === true) !== undefined;
	}
	get UserFullName(): string{
		return `${this.user.firstName} ${this.user.lastName}`;
	}

	onSave(): void{
		this.$emit('save', this.value);
	}
	onCancel(): void{
		this.$emit('cancel');
	}
}
