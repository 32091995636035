/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const AdminSideNavLayout = () => import('@/layouts/AdminSideNavLayout.vue');
export const OrgAdminSideNavLayout = () => import('@/layouts/OrgAdminSideNavLayout.vue');
export const AthleteSideNavLayout = () => import('@/layouts/AthleteSideNavLayout.vue');
export const CoachSideNavLayout = () => import('@/layouts/CoachSideNavLayout.vue');
export const SimplePageLayout = () => import('@/layouts/SimplePageLayout.vue');
export const OnboardingLayout = () => import('@/layouts/OnboardingLayout.vue');
export const PlainTopNavLayout = () => import('@/layouts/PlainTopNavLayout.vue');
export const PublicAthleteSideNavLayout = () => import('@/layouts/PublicAthleteSideNavLayout.vue');
export const PublicCoachSideNavLayout = () => import('@/layouts/PublicCoachSideNavLayout.vue');
