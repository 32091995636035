import { FrontEndModel } from "@/models/FrontEndModel";
import { ScoutingReportScore } from "@/../types";
import { AthleteProfileModel, CoachProfileModel, PlayerOnTeam, TeamModel } from "@/models";
import { isValidUUID, isEmpty, isNotEmpty, isNotEmptyArray } from "@/pipes";
import { athleteApi } from "@/api/AthleteApi";
import { teamApi } from "@/api/TeamApi";
import { coachApi } from "@/api/CoachApi";
import { sportApi } from "@/api/SportApi";

export class AthleteReportCategory {
	name: string;
	rating: ScoutingReportScore;
	notes: string;
}

export class AthleteReportCategoryHeading extends AthleteReportCategory {
	categories: Array<AthleteReportCategory>;
	expanded: boolean;
}

const AthleteReportDefaultHeadings = [
	{
		name:'Technical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Dribbling', rating: undefined, notes: undefined},
			{name:'Passing', rating: undefined, notes: undefined},
			{name:'Shooting', rating: undefined, notes: undefined},
			{name:'Control', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Tactical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Positioning', rating: undefined, notes: undefined},
			{name:'Movement', rating: undefined, notes: undefined},
			{name:'Awareness', rating: undefined, notes: undefined},
			{name:'Decisions', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Physical', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Speed', rating: undefined, notes: undefined},
			{name:'Strength', rating: undefined, notes: undefined},
			{name:'Stamina', rating: undefined, notes: undefined},
			{name:'Agility', rating: undefined, notes: undefined}
		]
	},
	{
		name:'Mental', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Coachability', rating: undefined, notes: undefined},
			{name:'Confidence', rating: undefined, notes: undefined},
			{name:'Focus', rating: undefined, notes: undefined},
			{name:'Leadership', rating: undefined, notes: undefined},
		]
	},
	{
		name:'Social', rating: undefined, notes: undefined, expanded: false,
		categories: [
			{name:'Teamwork', rating: undefined, notes: undefined},
			{name:'Respect', rating: undefined, notes: undefined},
			{name:'Sportsmanship', rating: undefined, notes: undefined},
			{name:'Adaptability', rating: undefined, notes: undefined},
		]
	},
];	

export class AthleteReportModel extends FrontEndModel {
	athleteId: string;
	teamId: string;
	coachId: string;
	date: Date;
	playerType: string;
	number: string;
	position: string;
	rating: ScoutingReportScore;
	notes: string;
	tags: string;
	headings: Array<AthleteReportCategoryHeading>;

    constructor(athleteId: string = undefined, teamId: string = undefined, coachId: string = undefined) {
        super();

        this.athleteId = athleteId;
        this.teamId = teamId;
        this.coachId = coachId;

        this.date = new Date();
    }

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);

		if( obj['date'] ) this.date = new Date(obj['date']);
		if( isEmpty(obj['headings'])) this.loadHeadings();

		return this;
	}

	async loadHeadings(team: TeamModel = undefined, coach: CoachProfileModel = undefined) {
		var sportName: string = undefined;

		if( isEmpty(team) && isNotEmpty(this.teamId) ) team = await teamApi.findById(this.teamId);
		if( isNotEmpty(team) ) sportName = team.Sport;

		if( isEmpty(sportName) ) {
			if( isEmpty(coach) ) coach = await coachApi.findById(this.coachId);
			if( isNotEmpty(coach) ) sportName = coach.SportName;
		}

		if( isNotEmpty(sportName) ) {
			const sport = await sportApi.findByName(sportName);
			if( isNotEmpty(sport) && sport.athleteReportHeadings.length > 0 ) {
				this.headings = [...sport.athleteReportHeadings];
			}
		} else {
			// default headings
			this.headings = AthleteReportDefaultHeadings;
		}
	}
}

export class AthleteReportModelEx extends AthleteReportModel {
	athlete: AthleteProfileModel;
	coach: CoachProfileModel;
	team: TeamModel;
	player: PlayerOnTeam;

    async loadAthlete(athletes: Array<AthleteProfileModel> = [], teams: Array<TeamModel> = []) {
        if( isEmpty(this.athleteId) ) return;

		this.athlete = athletes.find(a => a.id === this.athleteId);
		if( !this.HasAthleteProfile ) this.athlete = await athleteApi.publicFindById(this.athleteId);

		await this.loadTeam(teams);
		if( !this.HasTeam ) return;

		this.player = this.team.players.find(p => p.athleteId === this.athleteId);
		if( this.HasTeamPlayer ) {
			this.number = this.player.number;
			this.position = this.player.position;
		}
    }
	async loadCoach(coaches: Array<CoachProfileModel> = []) {
		if( isEmpty(this.coachId) ) return;

		this.coach = coaches.find(c => c.id === this.coachId);
		if( !this.HasCoachProfile ) this.coach = await coachApi.findById(this.coachId);
		if( this.HasCoachProfile && isEmpty(this.headings) ) this.loadHeadings(this.team, this.coach);
	}
	async loadTeam(teams: Array<TeamModel> = []) {
		if( isEmpty(this.teamId) && this.HasAthleteProfile ) this.teamId = this.athlete.currentTeam;
		if( isEmpty(this.teamId) && this.HasAthleteProfile && isNotEmptyArray(this.athlete.teams) ) this.teamId = this.athlete.teams[this.athlete.teams.length - 1].id;

		if( isNotEmptyArray(teams) ) {
			this.team = teams.find(t => t.id === this.teamId);
		}
		if( !this.HasTeam ) {
			if( isValidUUID(this.teamId) ) {
				this.team = await teamApi.findById(this.teamId);
			} else if( this.HasAthleteProfile ) {
				const teams = await teamApi.findByAthleteId(this.athlete?.id);
				if( isNotEmptyArray(teams) ) this.team = teams[teams.length - 1];
			}
		}
		if( isEmpty(this.headings) ) this.loadHeadings(this.team, this.coach);
	}

	get HasAthleteProfile(): boolean {
		return isNotEmpty(this.athlete);
	}
	get HasCoachProfile(): boolean {
		return isNotEmpty(this.coach);
	}
	get HasTeam(): boolean {
		return isNotEmpty(this.team);
	}
	get HasTeamPlayer(): boolean {
		return isNotEmpty(this.player);
	}

	get FirstName(): string {
		if( isEmpty(this.athlete) ) return '';
		return this.athlete.firstName;
	}
	get LastName(): string {
		if( isEmpty(this.athlete) ) return '';
		return this.athlete.lastName;
	}
	get PlayerName(): string {
		if( isEmpty(this.athlete) ) return '';
		if( isEmpty(this.athlete.lastName) ) return this.athlete.firstName;
		if( isEmpty(this.athlete.firstName) ) return this.athlete.lastName;
		return `${this.athlete.firstName} ${this.athlete.lastName}`;
	}
	get DateOfBirth(): Date {
		if( isEmpty(this.athlete) ) return new Date('March 16 2006');
		return this.athlete.birthDate;
	}
	get TeamName(): string {
		if( isEmpty(this.team) ) return this.teamId;
		return this.team.name;
	}
	get CoachName(): string {
		if( isEmpty(this.coach) ) return '';
		return this.coach.FullName;
	}

    get OverallScore(): AthleteReportCategory {
        const category: AthleteReportCategory = new AthleteReportCategory;
        category.name = this.athlete? this.athlete.FullName : 'athlete';
        category.rating = this.rating;
        return category;
    }
}