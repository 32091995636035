
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CrudApi } from '@/api/CrudApi';
import { PaymentMethod, PaymentMethodResult } from '@stripe/stripe-js';
import { PaymentMethodModel } from '@/models/stripe/PaymentMethodModel';
import { stripe } from '@/plugins/stripe';
import { PaymentMethodFormValue } from '@/../types/interfaces';

/**
 * Provides a list of saved payment methods for a given parentId.
 * 
 * @parentId: The ID of the parent domain object you're querying payment info for.
 */
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class PaymentMethodInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) parentId: string;
	@Prop({ default: 'athlete' }) apiPrefix: string;

	loading: boolean = false;
	initialized: boolean = false;
	paymentMethods: PaymentMethodModel[] = [];

	async loadPaymentMethods(): Promise<void>{
		this.loading = true;
		
		const rawPaymentMethods = await CrudApi.Api((api) => api.get(`${this.apiPrefix}/${this.parentId}/paymentMethod`));

		this.paymentMethods = rawPaymentMethods.map((method: any) => new PaymentMethodModel().load(method));

		this.loading = false;
		this.initialized = true;
	}

	created(): void{
		this.loadPaymentMethods();
	}

	async deleteById(id: string): Promise<void>{
		await CrudApi.Api((api) => api.delete(`${this.apiPrefix}/${this.parentId}/paymentMethod/${id}`));
		const index = this.paymentMethods.findIndex(p => p.id === id); 
		if(index > -1){
			this.paymentMethods.splice(index, 1);
		}
	}
	async deleteByPaymentMethodId(stripePaymentMethodId: string): Promise<void>{
		await CrudApi.Api((api) => api.delete(`${this.apiPrefix}/${this.parentId}/paymentMethod/stripe/${stripePaymentMethodId}`));
		const index = this.paymentMethods.findIndex(p => p.stripePaymentMethodId === stripePaymentMethodId); 
		if(index > -1){
			this.paymentMethods.splice(index, 1);
		}
	}

	async addNewPaymentMethod(stripePaymentMethod: PaymentMethodFormValue<PaymentMethod.Card>) {
		const newPaymentMethod = new PaymentMethodModel().load({
			parentId: this.parentId,
			stripePaymentMethodId: stripePaymentMethod.id,
			stripePaymentMethodDetails: stripePaymentMethod
		})
		this.paymentMethods.push(newPaymentMethod);
	}


	get SlotProps(): Record<string, any>{
		return {
			loading: this.loading,
			initialized: this.initialized,
			paymentMethods: this.paymentMethods,
			deleteById: (id: string) => this.deleteById(id),
			addNewPaymentMethod: this.addNewPaymentMethod,
			deleteByPaymentMethodId: (stripePaymentMethodId: string) => this.deleteByPaymentMethodId(stripePaymentMethodId)
		};
	}
}
