import { FrontEndModel } from '../FrontEndModel';
import { Grade, RubricCriteriaGroup } from '@/../types/interfaces';

export class RubricModel extends FrontEndModel {
	name: string = "";

	categories: string[] = [];

	criteriaGroup: RubricCriteriaGroup[] = [];

	gradeSet: Grade[] = [];
}
