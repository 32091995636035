import { CourseRequirement, SocialMediaField } from "@/../types/interfaces";
import { Country, SportGender, SportName } from "@/../types/enums";
import { Currency } from "@/../types/enums/currency";
import { FrontEndModel } from "@/models/FrontEndModel";
import { MinMax } from "@/../types/interfaces/MinMax";


export class CoachDetailModel {
	name: string = "";
	title: string = "";
	email: string = "";
	phone: string = "";
	fax: string = "";
	ccc_person_id: number = 0;

	load<T = this>(obj: Partial<T>): this {
		Object.assign(this, obj);
		return this;
	}
}

export class SportDetailModel {
	name: SportName = SportName.Soccer;
	gender: SportGender = SportGender.MEN;
	division: string = '';
	conference: string = '';

	facility: string = '';
	coaches: CoachDetailModel[] = [];

	socialMedia: SocialMediaField[] = [];

	load<T = this>(obj: Partial<T>): this {
		Object.assign(this, obj);
		return this;
	}
}

export class LocationModel extends FrontEndModel {
	cccSchoolId: number = 0;
	dliId: string = "";

	name: string = "";
	address: string = "";
	city: string = "";
	state: string = "";
	zipcode: string = "";
	country: Country = Country.US;
	phone: string = "";
	fax: string = "";

	teamLogo: string = "";

	sports: SportDetailModel[] = [];

	nickname: string = "";
	colors: string = "";

	socialMedia: SocialMediaField[] = [];


	load(obj: Partial<LocationModel>): this{
		Object.assign(this, obj);

		if (obj['sports']) {
			this.sports = obj['sports'].flatMap(sport => new SportDetailModel().load(sport));
		}
		return this;
	}

	get Divisions(): string[] {
		return Array.from(new Set(this.sports.filter(sport => sport.division !== null && sport.division.trim() !== '').flatMap(sport => sport.division)))
	}

	get Conferences(): string[] {
		return Array.from(new Set(this.sports.filter(sport => sport.conference !== null && sport.conference.trim() !== '').flatMap(sport => sport.conference)))
	}

	get Conference(): string {
		return this.Conferences.length > 0 ? this.Conferences[0] : '';
	}
}

class BaseSchoolModel extends FrontEndModel {
	name: string = "";

	nicheUuid: string = "";
	ipedsId: number = 0;
	
	hide: boolean = false;
		
	// Urban/ Rural
	citySetting: string = "";
	
	// 4-year, 2 year
	schoolType: string = "";
	
	// Public/Private
	governance: string = "";
	
	// Whether the school is part of NCAA/ NJCAA/ NAIA/ Other
	athleteAssociation: string = "";
	
	testConsideration: string = "";
	acceptanceRate: number = 1;
	freshmanEnrolled: number = 0;
	undergradsEnrolled: number = 0;
	maleToFemaleUndergradRatio: number = 0;
	studentFacultyRatio: number = 0;
	graduationRate: number = 1;
	retentionRate: number = 1;
	
	satRequirement: number = 0;
	satCompositeScore: MinMax = { min: 400, max: 1600 };
	satMath: MinMax = { min: 400, max: 1600 };
	satReading: MinMax = { min: 400, max: 1600 };
	
	actRequirement: number = 0;
	actCompositeScore: MinMax = { min: 1, max: 36 };
	actMath: MinMax = { min: 1, max: 36 };
	actEnglish: MinMax = { min: 1, max: 36 };
	
	gpa: number = 0;
	courseRequirements: CourseRequirement[] = []
	inStateTuition: number = 0;
	outStateTuition: number = 0;
	netPrice: number = 0;
	
	currency: Currency = Currency.USD;
	
	fees: number = 0;
	onCampusHousingAvailable: boolean = false;
	housingCost: number = 0;
	financialAidProcess: string = "";
	financialAidPackagesAvailable: string[] = [];
	
	// Percent of full-time freshman students receiving any financial aid
	percentFreshmanAidGranted: number = 0;
	federalAidGranted: number = 0;
	
	popularMajors: string[] = []
	
	socialMedia: SocialMediaField[] = [];
	schoolLogo: string = "";
}

export class SchoolModel extends BaseSchoolModel {
	locations: LocationModel[] = [];
	
	load(obj: Partial<SchoolModel>): this {
		Object.assign(this, obj);
	
		if (obj['locations']) {
			this.locations = obj['locations'].map(location => new LocationModel().load(location));
		}
	
		return this;
	}
	
	get Name(): string {
		if( this.name ) return this.name;
		if( this.locations.length > 0 ) return this.locations[0].name;
		return "undefined"
	}
	get Country(): string {
		if( this.locations.length > 0 ) return this.locations[0].country;
	}
	get City(): string {
		if( this.locations.length > 0 ) return this.locations[0].city;
	}

	get Sports(): { name: string, gender: SportGender, location: string }[] {
		return this.locations.flatMap(location => location.sports.flatMap(sport => ({ name: sport.name, gender: sport.gender, location: location.id })))
	}

}

/**
 * Elastic Search School Model that is returned from 
 */
export class ESSchoolModel extends BaseSchoolModel {
	locations: LocationModel;

	load(obj: Partial<ESSchoolModel>): this {
		Object.assign(this, obj);
	
		if (obj['locations']) {
			this.locations = new LocationModel().load(obj['locations']);
		}
	
		return this;
	}
	
	get Sports(): { name: string, gender: SportGender, location: string }[] {
		return this.locations.sports.flatMap(sport => ({ name: sport.name, gender: sport.gender, location: this.locations.id }));
	}
}