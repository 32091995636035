import { CrudApi } from './CrudApi';
import { CourseModel, RubricModel, AssignmentModel, StudentModel } from '../models/course';
import { AxiosRequestConfig } from 'axios';
import { QueryOptions } from '@/../types/interfaces';

interface StudentApiParams{
	courseId: string;
}

class StudentApi extends CrudApi<StudentModel>{
	constructor(params: StudentApiParams){
		super(`course/${params.courseId}/student`, (obj) => new StudentModel().load(obj));
	}
}

export class CourseApi extends CrudApi<CourseModel>{
	constructor() {
		super('course', (obj) => new CourseModel().load(obj));
	}
	public static StudentApi = (params: StudentApiParams): StudentApi => new StudentApi(params);

	private async saveStudentRefs(obj: Partial<CourseModel>): Promise<void>{
		if (Array.isArray(obj.students) && obj.students.length > 0){
			const studentApi = CourseApi.StudentApi({ courseId: obj.id });
			const students = await Promise.all(obj.students.map(student  => studentApi.save(student)));
			obj.studentRefs = students;
		}
	}

	async insert(obj: CourseModel, config?: AxiosRequestConfig): Promise<CourseModel> {
		await this.saveStudentRefs(obj);
		return super.insert(obj, config);
	}
	async update(obj: CourseModel, config?: AxiosRequestConfig): Promise<CourseModel> {
		await this.saveStudentRefs(obj);
		return super.update(obj, config);
	}
	async patch(obj: Partial<CourseModel>, config?: AxiosRequestConfig): Promise<CourseModel> {
		await this.saveStudentRefs(obj);
		return super.patch(obj, config);
	}

	async loadTemplates(): Promise<CourseModel[]> {
		const courses = await CrudApi.Api(c => c.get(`/course/templates`));
		return courses.map((c: unknown) => new CourseModel().load(c));
	}
	async findByInstructorId({ coachId }: { coachId: string }, options?: QueryOptions, config?: AxiosRequestConfig): Promise<CourseModel[]> {
		const courses = await CrudApi.Api(c => c.get(`/course/by-instructor/${coachId}`, this.applyOptionsToRequest(options, config)));
		return courses.map((c: unknown) => new CourseModel().load(c));
	}
	async findByStudentId({ coachId }: { coachId: string }, options?: QueryOptions, config?: AxiosRequestConfig): Promise<CourseModel[]> {
		const courses = await CrudApi.Api(c => c.get(`/course/by-student/${coachId}`, this.applyOptionsToRequest(options, config)));
		return courses.map((c: unknown) => new CourseModel().load(c));
	}
	async getStudentJoinUrl({ courseId }: { courseId: string }): Promise<string>{
		const result = await CrudApi.Api(c => c.get(`/course/${courseId}/student/join-url`));
		return result.url;
	}
	async getStaffJoinUrl({ courseId }: { courseId: string }): Promise<string>{
		const result = await CrudApi.Api(c => c.get(`/course/${courseId}/staff/join-url`));
		return result.url;
	}
	async findAllRubrics(): Promise<RubricModel[]>{
		const rubrics = await CrudApi.Api(c => c.get('/course/rubric'));
		return rubrics.map((r: unknown) => new RubricModel().load(r));
	}
	async assignToAll(courseId: string, assignment: AssignmentModel): Promise<CourseModel> {
		const course = await CrudApi.Api(c => c.post(`/course/${courseId}/assignToAll/`, assignment));
		return new CourseModel().load(course);
	}
	async assignToStudents({ courseId, assignment, studentIds }: {
		courseId: string,
		assignment: AssignmentModel,
		studentIds: string[],
	}): Promise<CourseModel> {
		const course = await CrudApi.Api(c => c.post(`/course/${courseId}/assign`, { assignment, studentIds }));
		return new CourseModel().load(course);
	}
	async updateAssignmentForStudent({ courseId, studentId, assignment }: {
		courseId: string,
		studentId: string,
		assignment: AssignmentModel,
	}): Promise<CourseModel> {
		const course = await CrudApi.Api(c => c.patch(`/course/${courseId}/${studentId}/updateAssignment`, { assignment, studentId }));
		return new CourseModel().load(course);
	}

	async sendAssignmentReminder({ courseId, studentId, assignmentId }: {
		courseId: string,
		studentId: string,
		assignmentId: string,
	}): Promise<void> {
		await CrudApi.Api(c => c.post(`/course/${courseId}/student/${studentId}/assignment/${assignmentId}/reminder`));
	}

	async addSourceVideoIdToAssignmentForStudent({ courseId, studentId, assignmentId, sourceVideoId }: {
		courseId: string,
		studentId: string,
		assignmentId: string,
		sourceVideoId: string,
	}): Promise<CourseModel> {
		const course = await CrudApi.Api(c => c.patch(`/course/${courseId}/${studentId}/${assignmentId}/addSourceVideoId`, { sourceVideoId }));
		return new CourseModel().load(course);
	}

	async removeSourceVideoIdFromAssignmentForStudent({ courseId, studentId, assignmentId, sourceVideoId }: {
		courseId: string,
		studentId: string,
		assignmentId: string,
		sourceVideoId: string,
	}): Promise<CourseModel> {
		const course = await CrudApi.Api(c => c.patch(`/course/${courseId}/${studentId}/${assignmentId}/${sourceVideoId}`));
		return new CourseModel().load(course);
	}
}
export const courseApi = new CourseApi();