
import { Component, Prop, Mixins, Watch, Vue } from 'vue-property-decorator';
import { formatDurationForTimeline } from '../../helpers';
import { VuetifyMixin, DebounceMixin, BAIconsMixin } from '../../mixins';
import { toFixed } from '@/pipes';
import { VideoClipModel } from '@/models/video/VideoClipModel';

@Component
export default class BAVideoTimelineSlider extends Mixins(DebounceMixin, VuetifyMixin, BAIconsMixin) {
	
	formatDurationForTimeline = formatDurationForTimeline;

	public timeToScrubberValue(time: number): number{
		return (time / this.totalTime) * 100
	}

	get ReadOnly(): boolean{
		return this.totalTime === 0;
	}

	@Prop({ type: Number, default: 0 }) time: number;
	@Watch('time') timeUpdated(time: number): void{
		this.scrubberValue = this.timeToScrubberValue(time);
	}
	get Time(): number{
		return this.totalTime * (this.scrubberValue / 100);
	}
	@Prop({ type: Number, default: 0 }) leftMarkerTime: number;
	@Watch('leftMarkerTime') leftMarkerTimeUpdated(time: number): void{
		Vue.set(this.rangeValues, 0, this.timeToScrubberValue(time));
	}
	get LeftMarkerTime(): number{
		const [left] = this.rangeValues;
		return this.totalTime * (left/100);
	}
	@Prop({ type: Number, default: 0 }) rightMarkerTime: number;
	@Watch('rightMarkerTime') rightMarkerTimeUpdated(time: number): void{
		Vue.set(this.rangeValues, 1, this.timeToScrubberValue(time));
	}
	get RightMarkerTime(): number{
		const [,right] = this.rangeValues;
		return this.totalTime * (right/100);
	}

	height: number = 65;
	get HeightStyle(): Record<string, string>{
		return {
			height: `${this.height}px`,
		}
	}
	get SVGViewBox(): string{
		return `0 0 18 ${this.height}`;
	}
	get SVGViewBox1Px(): string{
		return `0 0 1 ${this.height}`;
	}
	@Prop({ type: Boolean, default: false }) clipping: boolean;
	@Prop({ type: Boolean, default: false }) showClipInfo: boolean;
	get ShowClipInfo(): boolean{
		if(this.clipping === false) return false;
		return this.showClipInfo || this.rangedAnchored === false;
	}
	@Prop({ default: null }) scrubberColor: string | null;
	get ScrubberColor(): string{
		if(this.scrubberColor !== null){
			return this.getColor(this.scrubberColor);
		}
		if(this.clipping){
			return this.getColor('baColorAccentGold');
		}
		return this.getColor('baColorLightBlue');
	}
	scrubberValue: number = 0;
	scrubberInput(val: number): void{
		this.updateRangesIfAnchored(val);
		this.$emit('update:time', this.Time);
	}
	scrubStart(): void{
		this.$emit('seek-start');
	}
	scrubberChange(): void{
		this.$emit('seek', this.Time);
	}

	rangeInput(val: [number, number]): void{
		this.rangedAnchored = false;
		const [
			oldLeft,
			// oldRight,
		] = this.lastRange;
		const [
			newLeft,
			// newRight,
		] = val;
		if(oldLeft !== newLeft && oldLeft === this.scrubberValue){
			this.scrubberInput(newLeft);
		}
		// else if(oldRight !== newRight && oldRight === this.scrubberValue){
		// 	// This would stick the main scrubber to the right handle
		// 	this.scrubberInput(newRight);
		// }
		this.lastRange = val;
		// this.$emit('update:leftMarkerTime', this.LeftMarkerTime);
		// this.$emit('update:rightMarkerTime', this.RightMarkerTime);
	}
	rangeValues: [number, number] = [0, 0];
	lastRange: [number, number] = this.rangeValues;
	rangedAnchored: boolean = true;
	updateRangesIfAnchored(val: number): void{
		if(!this.rangedAnchored || !this.clipping) return;
		this.rangeValues = [ val, val ];
	}
	public resetRangeSliders(): void{
		this.rangedAnchored = true;
		this.updateRangesIfAnchored(this.scrubberValue);
		this.$emit('reset-range');
	}
	moveScrubberToLeft(): void{
		const [left] = this.rangeValues;
		this.scrubberInput(left);
	}
	moveScrubberToRight(): void{
		const [,right] = this.rangeValues;
		this.scrubberInput(right);
	}

	get ClipLengthStyle(): Record<string,any>{
		const [left, right] = this.rangeValues;
		return {
			width: `${right - left}%`,
			left: `${left}%`,
		};
	}

	get ScrubberStyle(): Record<string, any>{
		return this.ScrubberPositionStyle;
	}
	get ScrubberStyleLeft(): Record<string, any>{
		return {
			...this.ScrubberPositionStyle,
			left: '-18px',
		};
	}
	get ScrubberStyleRight(): Record<string, any>{
		return {
			...this.ScrubberPositionStyle,
			left: 0,
		};
	}
	get ScrubberPositionStyle(): Record<string, any>{
		return {
			position: 'absolute',
			left: '-10px',
			top: 0,
		};
	}

	isLeft(val: number): boolean{
		const [left] = this.rangeValues;
		return left === val;
	}
	get FlipLeft(): boolean{
		const [left] = this.rangeValues;
		return left === 0;
	}
	isRight(val: number): boolean{
		const [,right] = this.rangeValues;
		return right === val;
	}
	get FlipRight(): boolean{
		const [,right] = this.rangeValues;
		return right === 100;
	}

	/** Timeline tick calculations */
	@Prop({ type: Number, default: 0 }) totalTime: number;
	@Prop({ type: Number, default: 0 }) playerCurrentTime: number;
	width: number = 0;
	$refs:{
		timelineContainer: HTMLDivElement;
	}
	mounted(): void{
		this.$nextTick(() => {
			this.onResize(); // Ensure width is calculated after mount
		});
	}
	timelineInitialized: boolean = false;
	initializeTimeline(): void{
		if(!this.timelineInitialized){
			this.onResize();
			this.timelineInitialized = true;
		}
	}
	onResize(): void{
		if(this.$refs.timelineContainer === undefined) return;
		this.width = this.$refs.timelineContainer.clientWidth;
	}
	smallSplitSizeMin = 10;
	textSplitSizeMin = 79;
	textTickSkipFactor = 5;
	pixelsPerSecond = 0.5;
	secondsPerPixel = 0.5;
	smallToLargeDivisions = 4;
	scaleDensityArray: number[] = [0, 1, 2, 4, 10, 20, 30, 60, 120, 240, 600, 1200, 1800, 3600, 7200, 14400, 28800, 43200, 86400];
	scaleDensityCurrent = 2;
	numMainSegments = 10;
	mainSegmentsWidthInPixels = 0;
	mainSegmentsWidthAsPercentage = 0.89;
	leftoverSeconds = 0.5;
	leftoverSecondsWidthInPixels = 42;
	leftoverSecondsWidthAsPercentage = 0.05;

	@Watch('totalTime') updatedTotalTime(time: number): void{
		if(time > 0){
			this.calcTimelineDimensions();
		}
	}
	@Watch('width') calcTimelineDimensions(): void{
		let totalTime = this.totalTime;
		if(totalTime === 0){
			totalTime = 60;
		}

		this.pixelsPerSecond = this.width / totalTime;
		this.secondsPerPixel = totalTime / this.width;
		const secsPerLargeSegment = this.secondsPerPixel * this.smallSplitSizeMin * this.smallToLargeDivisions;
		let notFound = true;
		let aIdx = 0;
		while (notFound && aIdx < this.scaleDensityArray.length) {
			if (this.scaleDensityArray[aIdx] < secsPerLargeSegment && this.scaleDensityArray[aIdx + 1] >= secsPerLargeSegment) {
				this.scaleDensityCurrent = this.scaleDensityArray[aIdx + 1];
				notFound = false;
			}
			aIdx++;
		}

		this.numMainSegments = Math.floor(totalTime / this.scaleDensityCurrent);
		this.mainSegmentsWidthInPixels = Math.floor((this.scaleDensityCurrent * this.numMainSegments) * this.pixelsPerSecond);
		this.mainSegmentsWidthAsPercentage = this.mainSegmentsWidthInPixels / this.width;
		this.leftoverSeconds = totalTime - (this.scaleDensityCurrent * this.numMainSegments);
		this.leftoverSecondsWidthInPixels = Math.floor(this.leftoverSeconds * this.pixelsPerSecond);
		this.leftoverSecondsWidthAsPercentage = this.leftoverSecondsWidthInPixels / this.width;
		this.textTickSkipFactor = Math.ceil(this.textSplitSizeMin / (this.scaleDensityCurrent * this.pixelsPerSecond));
	}

	get numberOfLargeTickSections(): number {
		const tempNum = this.numMainSegments;
		if (Object.is(NaN, tempNum)) {
			return 0;
		} else {
			return tempNum;
		}
	}

	get numberOfSmallTickSections(): number {
		const tempNum = this.smallToLargeDivisions;
		if (Object.is(NaN, tempNum)) {
			return 0;
		} else {
			return tempNum;
		}
	}

	get largeTickSectionWidth(): number {
		return this.mainSegmentsWidthInPixels / this.numMainSegments;
	}

	get smallTickSectionWidth(): number {
		return this.largeTickSectionWidth / this.smallToLargeDivisions;
	}

	timeDisplayAtLargeTick(tickNumber: number): string {
		return formatDurationForTimeline(this.scaleDensityCurrent * tickNumber);
	}

	public getCurrentClipTimelineData(): { start: number, duration: number }{
		return {
			start: this.LeftMarkerTime,
			duration: toFixed(this.RightMarkerTime - this.LeftMarkerTime, 1),
		};
	}

	get NoClipMarkers(): boolean{
		return this.clipMarkers.length === 0;
	}
	@Prop({ default: [] }) clipMarkers: VideoClipModel[];
	@Prop({ default: null }) clipHovered: string | null;
	updateClipHovered(clipHovered: string | null): void{
		this.$emit('update:clip-hovered', clipHovered);
	}
	clipMarkerStyle(clip: VideoClipModel): Record<string, any>{
		return {
			left: `${this.timeToScrubberValue(clip.StartTime)}%`,
			'margin-left': '-15px',
		}
	}
	clipMarkerClicked(clip: VideoClipModel): void{
		this.$emit('click:clip-marker', clip);
	}
}
