
import { getEnumValues } from './get-enum-values';

export enum MindsetMetric{
	MentalToughness='Mental Toughness',
	Composure='Composure',
	Confidence='Confidence',
	Drive='Motivational Drive',
	Grit='Grit',

	MentalExecution='Mental Execution',
	Adaptability='Adaptability',
	Decisiveness='Decisiveness',
	FocusSpeed='Focus Speed',

	Coachability='Coachability',
	AttentionToDetail='Attention To Detail',
	Conventionality='Conventionality',
	GrowthMindset='Growth Mindset',
	InformationProcessing='Information Processing',
}
export const MindsetMetricValues = getEnumValues<MindsetMetric>(MindsetMetric);