// StylesMixin

import { Component, Vue } from 'vue-property-decorator';

@Component
export class StylesMixin extends Vue {

    get styleWrap(): Record<string, any> {
        return {
            whiteSpace: 'pre-wrap',
            overflowWrap: 'normal',
            wordBreak: 'normal'
        };
    }

    styleContained(height: string = '250px'): Record<string, any> {
        return {
            'overflow-y': 'auto',
            'maxHeight': height,
        }
        return {
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: height,
        };
    }
}