import { CrudApi } from './CrudApi';
import { EventLocationModel, EventParticipantModel, EventSponsorModel, BAEventModel } from "@/models/baEvent";
import { BAPurchaseModel } from '@/models';

class EventLocationApi extends CrudApi<EventLocationModel> {
	constructor() {
		super('eventLocation', (obj) => new EventLocationModel().load(obj));
	}
}

class EventSponsorApi extends CrudApi<EventSponsorModel> {
	constructor() {
		super('eventSponsor', (obj) => new EventSponsorModel().load(obj));
	}
}

class BAEventApi extends CrudApi<BAEventModel> {
	constructor() {
		super('baEvent', (obj) => new BAEventModel().load(obj));
	}

	async addParticipant(eventId: string, ticketId: string, athleteId: string) {
		const participant = new EventParticipantModel().load({
			attendeeId: athleteId,
			ticketId,
		});
		const response = await CrudApi.Api((c) => c.put(`/${this.resource}/participant/${eventId}`, participant));
		return new BAPurchaseModel().load(response);
	}

}

export const eventLocationApi = new EventLocationApi();
export const eventSponsorApi = new EventSponsorApi();
export const baEventApi = new BAEventApi();
