export enum MindsetUserStatus {
	Unknown = 'Unknown',
	NoRyzerAccount = 'No Ryzer Account',
	RyzerAccount = "Ryzer Account Created",
	TAPInvite = "TAP Invite",
	MindsetComplete = "Mindset Complete"
}

export interface MindsetUser {
    id: string;                 // Best Athletes user id
    ryzerId: string;            // Ryzer UserID
    email: string;              // Email common in Best Athletes and Ryzer database to identify user
    link: string;               // TAP link for this user
    status: MindsetUserStatus;
}