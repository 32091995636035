import { metricHasValue } from "./metric-has-value.pipe";

/**
 * Formats assessment metric value string as number or empty state if value is missing
 */
export function assessmentMetricValueOrNull(value: number | string | null, precision?: number): string {
	if( metricHasValue(value) ) {
		var val: number = Number(value);
		if( precision === null || precision === undefined ) precision = 2;
		return `${val.toFixed(precision)}`;
	} else {
		return '--';
	}
}
