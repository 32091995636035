/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { QueryOptions, PaginatedResponse, RepositoryQuery, OrganizationCreateOptions } from '@/../types/interfaces';
import { AthleteAssessmentDataModel, SpqDataModel, MindsetReportModel, AthleteProfileModel } from '@/models';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';
import { mindsetReportApi } from '@/api/MindsetReportApi';
import { athleteApi } from '@/api/AthleteApi';
import { notificationStore } from '@/store';
import { ScoutingReportModel } from '@/models';
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { AthleteReportModel } from '@/models';
import { athleteReportApi } from '@/api/AthleteReportApi';

const Mutations = {
	QUERY_ORGANIZATIONS: 'QUERY_ORGANIZATIONS',
	QUERY_ORGANIZATIONS_SUCCESS: 'QUERY_ORGANIZATIONS_SUCCESS',
	QUERY_ORGANIZATIONS_FAILURE: 'QUERY_ORGANIZATIONS_FAILURE',
	CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
	CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
	CREATE_ORGANIZATION_FAILURE: 'CREATE_ORGANIZATION_FAILURE',
	PATCH_ORGANIZATION: 'PATCH_ORGANIZATION',
	PATCH_ORGANIZATION_SUCCESS: 'PATCH_ORGANIZATION_SUCCESS',
	PATCH_ORGANIZATION_FAILURE: 'PATCH_ORGANIZATION_FAILURE',

	QUERY_ATHLETE_REPORTS: 'QUERY_ATHLETE_REPORTS',
	QUERY_ATHLETE_REPORTS_SUCCESS: 'QUERY_ATHLETE_REPORTS_SUCCESS',
	QUERY_ATHLETE_REPORTS_FAILURE: 'QUERY_ATHLETE_REPORTS_FAILURE',
	PATCH_ATHLETE_REPORT: 'PATCH_ATHLETE_REPORT',
	PATCH_ATHLETE_REPORT_SUCCESS: 'PATCH_ATHLETE_REPORT_SUCCESS',
	PATCH_ATHLETE_REPORT_FAILURE: 'PATCH_ATHLETE_REPORT_FAILURE',

	QUERY_SCOUTING_REPORTS: 'QUERY_SCOUTING_REPORTS',
	QUERY_SCOUTING_REPORTS_SUCCESS: 'QUERY_SCOUTING_REPORTS_SUCCESS',
	QUERY_SCOUTING_REPORTS_FAILURE: 'QUERY_SCOUTING_REPORTS_FAILURE',
	PATCH_SCOUTING_REPORT: 'PATCH_SCOUTING_REPORT',
	PATCH_SCOUTING_REPORT_SUCCESS: 'PATCH_SCOUTING_REPORT_SUCCESS',
	PATCH_SCOUTING_REPORT_FAILURE: 'PATCH_SCOUTING_REPORT_FAILURE',

	QUERY_VERIFIED_ASSESSMENTS: 'QUERY_VERIFIED_ASSESSMENTS',
	QUERY_VERIFIED_ASSESSMENTS_SUCCESS: 'QUERY_VERIFIED_ASSESSMENTS_SUCCESS',
	QUERY_VERIFIED_ASSESSMENTS_FAILURE: 'QUERY_VERIFIED_ASSESSMENTS_FAILURE',

	DELETE_VERIFIED_ASSESSMENT: 'DELETE_VERIFIED_ASSESSMENT',
	DELETE_VERIFIED_ASSESSMENT_SUCCESS: 'DELETE_VERIFIED_ASSESSMENT_SUCCESS',
	DELETE_VERIFIED_ASSESSMENT_FAILURE: 'DELETE_VERIFIED_ASSESSMENT_FAILURE',

	QUERY_MINDSET_REPORTS: 'QUERY_MINDSET_REPORTS',
	QUERY_MINDSET_REPORTS_SUCCESS: 'QUERY_MINDSET_REPORTS_SUCCESS',
	QUERY_MINDSET_REPORTS_FAILURE: 'QUERY_MINDSET_REPORTS_FAILURE',

	QUERY_ATHLETES: 'QUERY_ATHLETES',
	QUERY_ATHLETES_SUCCESS: 'QUERY_ATHLETES_SUCCESS',
	QUERY_ATHLETES_FAILURE: 'QUERY_ATHLETES_FAILURE',

	LOAD_SPQ_DATA_LIST: 'LOAD_SPQ_DATA_LIST',
	LOAD_SPQ_DATA_LIST_SUCCESS: 'LOAD_SPQ_DATA_LIST_SUCCESS',
	LOAD_SPQ_DATA_LIST_FAILURE: 'LOAD_SPQ_DATA_LIST_FAILURE',
}

const name = 'AdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class AdminModule extends VuexModule {

	organizations: OrganizationModel[] = [];

	createOrgLoading: boolean = false;
	@Action
	async createOrg({ organization, options }: { organization: OrganizationModel, options: OrganizationCreateOptions }): Promise<OrganizationModel>{
		this.context.commit(Mutations.CREATE_ORGANIZATION)
		try{
			const org = await organizationApi.insert(organization, { params: options });
			this.context.commit(Mutations.CREATE_ORGANIZATION_SUCCESS);
			return org;
		}catch(e){
			this.context.commit(Mutations.CREATE_ORGANIZATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.CREATE_ORGANIZATION](){
		this.createOrgLoading = true;
	}
	@Mutation [Mutations.CREATE_ORGANIZATION_SUCCESS](){
		this.createOrgLoading = false;
	}
	@Mutation [Mutations.CREATE_ORGANIZATION_FAILURE](){
		this.createOrgLoading = false;
	}

	patchOrgLoading: boolean = false;
	@Action
	async patchOrg({ organization, options }: { organization: OrganizationModel, options: OrganizationCreateOptions }): Promise<OrganizationModel>{
		this.context.commit(Mutations.PATCH_ORGANIZATION)
		try{
			const org = await organizationApi.patch(organization, { params: options });
			this.context.commit(Mutations.PATCH_ORGANIZATION_SUCCESS);
			return org;
		}catch(e){
			this.context.commit(Mutations.PATCH_ORGANIZATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_ORGANIZATION](){
		this.patchOrgLoading = true;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_SUCCESS](){
		this.patchOrgLoading = false;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_FAILURE](){
		this.patchOrgLoading = false;
	}

	/* TEAMS */
	numActiveTeams: number = 0;
	
	/* ORGANIZATIONS */
	organizationsLoading: boolean = false;
	organizationsInitialized: boolean = false;
	activeOrganizations: number = 0;
	/** Total Organizations in current query */
	totalOrganizations: number = 0;
	organizationList: OrganizationModel[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizationList({ query, options }: {
		query?: RepositoryQuery<OrganizationModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_ORGANIZATIONS);
		try {
			const [{ count }, orgs] = await Promise.all([
				organizationApi.countAll(),
				organizationApi.queryAll(
					{
						fields: ['name'],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_ORGANIZATIONS_SUCCESS, { count, orgs });
		} catch (e) {
			console.error("Failed to load organizations list", e);
			this.context.commit(Mutations.QUERY_ORGANIZATIONS_FAILURE, e);
			notificationStore.pushSnackbarError({message: "Something went wrong: " + e});
		}
	}

	@Mutation [Mutations.QUERY_ORGANIZATIONS](): void {
		this.organizationsLoading = true;
	}
	@Mutation [Mutations.QUERY_ORGANIZATIONS_SUCCESS]({count,orgs}: {count: number, orgs: PaginatedResponse<OrganizationModel>}): void {
		this.organizationsLoading = false;
		this.organizationsInitialized = true;
		this.activeOrganizations = count;
		this.totalOrganizations = orgs.total;
		this.organizationList = orgs.docs;
	}
	@Mutation [Mutations.QUERY_ORGANIZATIONS_FAILURE](error: any): void {
		this.organizationsLoading = false;
	}


	/** Athlete Reports */
	athleteReportsLoading: boolean = false;
	athleteReportsInitialized: boolean = false;
	athleteReportsCount: number = 0;
	athleteReports: AthleteReportModel[] | null = null;
	@Action({ rawError: true }) async loadAthleteReports({ query, options }: {
		query?: RepositoryQuery<AthleteReportModel>, options?: QueryOptions
		} = {}): Promise<void> {
			this.context.commit(Mutations.QUERY_ATHLETE_REPORTS);

			try {
				const[{count}, data] = await Promise.all([
					athleteReportApi.countAll(),
					athleteReportApi.queryAll({fields: [], ...query}, options)
				]);

				this.context.commit(Mutations.QUERY_ATHLETE_REPORTS_SUCCESS, {count, data});
			} catch(e) {
				console.error("Failed to load athlete reports", e);
				this.context.commit(Mutations.QUERY_ATHLETE_REPORTS_FAILURE, e);
			}		
	}
	@Mutation [Mutations.QUERY_ATHLETE_REPORTS](): void {
		this.athleteReportsLoading = true;
	}
	@Mutation [Mutations.QUERY_ATHLETE_REPORTS_SUCCESS]({data}: {data: PaginatedResponse<AthleteReportModel>}): void {
		this.athleteReportsLoading = false;
		this.athleteReportsInitialized = true;
		this.athleteReportsCount = data.total;
		this.athleteReports = data.docs;
	}
	@Mutation [Mutations.QUERY_ATHLETE_REPORTS_FAILURE](error: any): void {
		this.athleteReportsLoading = false;
	}
	athleteReportPatching: boolean = false;
	@Action
	async patchAthleteReport(athleteReport: AthleteReportModel): Promise<AthleteReportModel>{
		this.context.commit(Mutations.PATCH_ATHLETE_REPORT)
		try{
			const patchedReport = await athleteReportApi.patch(athleteReport);
			this.context.commit(Mutations.PATCH_ATHLETE_REPORT_SUCCESS);
			return patchedReport;
		}catch(e){
			this.context.commit(Mutations.PATCH_ATHLETE_REPORT_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_ATHLETE_REPORT](){
		this.athleteReportPatching = true;
	}
	@Mutation [Mutations.PATCH_ATHLETE_REPORT_SUCCESS](){
		this.athleteReportPatching = false;
	}
	@Mutation [Mutations.PATCH_ATHLETE_REPORT_FAILURE](){
		this.athleteReportPatching = false;
	}


	/** Scouting Reports */
	scoutingReportsLoading: boolean = false;
	scoutingReportsInitialized: boolean = false;
	scoutingReportsCount: number = 0;
	scoutingReports: ScoutingReportModel[] | null = null;
	@Action({ rawError: true }) async loadScoutingReports({ query, options }: {
		query?: RepositoryQuery<ScoutingReportModel>, options?: QueryOptions
		} = {}): Promise<void> {
			this.context.commit(Mutations.QUERY_SCOUTING_REPORTS);

			try {
				const[{count}, data] = await Promise.all([
					scoutingReportApi.countAll(),
					scoutingReportApi.queryAll({fields: [], ...query}, options)
				]);

				this.context.commit(Mutations.QUERY_SCOUTING_REPORTS_SUCCESS, {count, data});
			} catch(e) {
				console.error("Failed to load scouting reports", e);
				this.context.commit(Mutations.QUERY_SCOUTING_REPORTS_FAILURE, e);
			}		
	}
	@Mutation [Mutations.QUERY_SCOUTING_REPORTS](): void {
		this.scoutingReportsLoading = true;
	}
	@Mutation [Mutations.QUERY_SCOUTING_REPORTS_SUCCESS]({data}: {data: PaginatedResponse<ScoutingReportModel>}): void {
		this.scoutingReportsLoading = false;
		this.scoutingReportsInitialized = true;
		this.scoutingReportsCount = data.total;
		this.scoutingReports = data.docs;
	}
	@Mutation [Mutations.QUERY_SCOUTING_REPORTS_FAILURE](error: any): void {
		this.scoutingReportsLoading = false;
	}
	scoutingReportPatching: boolean = false;
	@Action
	async patchScoutingReport(scoutingReport: ScoutingReportModel): Promise<ScoutingReportModel>{
		this.context.commit(Mutations.PATCH_SCOUTING_REPORT)
		try{
			const patchedReport = await scoutingReportApi.patch(scoutingReport);
			this.context.commit(Mutations.PATCH_SCOUTING_REPORT_SUCCESS);
			return patchedReport;
		}catch(e){
			this.context.commit(Mutations.PATCH_SCOUTING_REPORT_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_SCOUTING_REPORT](){
		this.scoutingReportPatching = true;
	}
	@Mutation [Mutations.PATCH_SCOUTING_REPORT_SUCCESS](){
		this.scoutingReportPatching = false;
	}
	@Mutation [Mutations.PATCH_SCOUTING_REPORT_FAILURE](){
		this.scoutingReportPatching = false;
	}


	/** Verified Physical Assessments */
	verifiedAssessmentsLoading: boolean = false;
	verifiedAssessmentsInitialized: boolean = false;
	totalVerifiedAssessments: number = 0;
	verifiedAssessmentList: AthleteAssessmentDataModel[] = [];
	@Action({ rawError: true }) 
	async loadVerifiedAssessmentList({ query, options }: { query?: RepositoryQuery<AthleteAssessmentDataModel>, options?: QueryOptions } = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS);
		try {
			const [{ count }, data] = await Promise.all([
				athleteAssessmentDataApi.countAll(),
				athleteAssessmentDataApi.queryAll(
					{
						fields: [],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS_SUCCESS, { count, data });
		} catch (e) {
			console.error("Failed to load verified assessments", e);
			this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS](): void {
		this.verifiedAssessmentsLoading = true;
	}
	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS_SUCCESS]({data}: {data: PaginatedResponse<AthleteAssessmentDataModel>}): void {
		this.verifiedAssessmentsLoading = false;
		this.verifiedAssessmentsInitialized = true;
		this.totalVerifiedAssessments = data.total;
		this.verifiedAssessmentList = data.docs;
	}
	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS_FAILURE](error: any): void {
		this.verifiedAssessmentsLoading = false;
	}

	deletingVerifiedAssessment: boolean = false;
	@Action({ rawError: true })
	async deleteVerifiedAssessment(assessment: AthleteAssessmentDataModel) {
		this.context.commit(Mutations.DELETE_VERIFIED_ASSESSMENT)
		try{
			const delLocation = await athleteAssessmentDataApi.delete(assessment);
			this.context.commit(Mutations.DELETE_VERIFIED_ASSESSMENT_SUCCESS);
			return delLocation;
		}catch(e){
			this.context.commit(Mutations.DELETE_VERIFIED_ASSESSMENT_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_VERIFIED_ASSESSMENT](){
		this.deletingVerifiedAssessment = true;
	}
	@Mutation [Mutations.DELETE_VERIFIED_ASSESSMENT_SUCCESS](){
		this.deletingVerifiedAssessment = false;
	}
	@Mutation [Mutations.DELETE_VERIFIED_ASSESSMENT_FAILURE](){
		this.deletingVerifiedAssessment = false;
	}


	/** Mindset Reports */
	mindsetReportsLoading: boolean = false;
	mindsetReportsInitialized: boolean = false;
	mindsetReportsCount: number = 0;
	mindsetReports: MindsetReportModel[] = [];
	@Action({
		rawError: true,
	}) async loadMindsetReports({ query, options }: { query?: RepositoryQuery<MindsetReportModel>, options?: QueryOptions } = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_MINDSET_REPORTS);
		try {
			const [{ count }, data] = await Promise.all([
				mindsetReportApi.countAll(),
				mindsetReportApi.queryAll(
					{
						fields: [],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_MINDSET_REPORTS_SUCCESS, { count, data });
		} catch (e) {
			console.error("Failed to load mindset reports", e);
			this.context.commit(Mutations.QUERY_MINDSET_REPORTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_MINDSET_REPORTS](): void {
		this.mindsetReportsLoading = true;
	}
	@Mutation [Mutations.QUERY_MINDSET_REPORTS_SUCCESS]({data}: {data: PaginatedResponse<MindsetReportModel>}): void {
		this.mindsetReportsLoading = false;
		this.mindsetReportsInitialized = true;
		this.mindsetReportsCount = data.total;
		this.mindsetReports = data.docs;
	}
	@Mutation [Mutations.QUERY_MINDSET_REPORTS_FAILURE](error: any): void {
		this.mindsetReportsLoading = false;
	}

	/** Athletes */
	athletesLoading: boolean = true;
	athletesInitialized: boolean = false;
	athletesCount: number = 0;
	athletes: AthleteProfileModel[] = [];
	@Action({ rawError: true }) 
	async loadAthletes({ query, options }: { query?: RepositoryQuery<AthleteProfileModel>, options?: QueryOptions } = {}): Promise<AthleteProfileModel[]> {
		this.context.commit(Mutations.QUERY_ATHLETES);
		try {
			const [{ count }, data] = await Promise.all([
				athleteApi.countAll(),
				athleteApi.queryAll(query, options)
			]);
			this.context.commit(Mutations.QUERY_ATHLETES_SUCCESS, { count, data });
			return this.athletes;
		} catch (e) {
			console.error("Failed to load athletes", e);
			this.context.commit(Mutations.QUERY_ATHLETES_FAILURE, e);
			return this.athletes;
		}
	}
	@Action({
		rawError: true,
	}) async queryAthletes({ query, options }: { query?: RepositoryQuery<AthleteProfileModel>, options?: QueryOptions } = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_ATHLETES);
		try {
			const data = await athleteApi.queryAll(query, options);
			const count = await athleteApi.countAll();
			this.context.commit(Mutations.QUERY_ATHLETES_SUCCESS, { count, data });
		} catch (e) {
			console.error("Failed to load athletes", e);
			this.context.commit(Mutations.QUERY_ATHLETES_FAILURE, e);
		}
	}
	get IsAthletesLoading(): boolean {
		return this.athletesLoading;
	}
	@Mutation [Mutations.QUERY_ATHLETES](): void {
		this.athletesLoading = true;
	}
	@Mutation [Mutations.QUERY_ATHLETES_SUCCESS]({data}: {data: PaginatedResponse<AthleteProfileModel>}): void {
		this.athletesLoading = false;
		this.athletesInitialized = true;
		this.athletesCount = data.total;
		this.athletes = data.docs;
	}
	@Mutation [Mutations.QUERY_ATHLETES_FAILURE](error: any): void {
		this.athletesLoading = false;
	}
}
