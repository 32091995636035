
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin } from '@/mixins'

@Component({
	components: {
    },
})
export default class TooltipListItem extends Mixins(StringsMixin) {
    @Prop({default: ""}) margin: string;
    @Prop({default: "top"}) pos: string;
    @Prop({default: "primary"}) color: string;
    @Prop() iconColor: string;
    @Prop() textColor: string;
    @Prop() icon: string;
    @Prop({default: "tooltip not specified"}) tooltip: string;
    @Prop() text: string;
    @Prop({type: Boolean}) disabled;
    @Prop({type: Boolean}) large;

    get IconColor(): string {
      if( this.IsNotEmpty(this.iconColor) ) return this.iconColor;
      if( this.IsNotEmpty(this.text) ) return "white";
      return this.color;
    }
    get HasIcon() {
      return this.IsNotEmpty(this.icon);
    }
    get HasText() {
      return this.IsNotEmpty(this.text);
    }
    get TextColor() {
      if( this.IsNotEmpty(this.textColor) ) return this.textColor;
      return '';
    }
}
