import { AnalyticsGender } from '../../../../libs/ba-types/src';
import { Gender, SportGender } from '@/../types/enums'

export function genderType(value: Gender | AnalyticsGender | SportGender | string ): Gender | undefined {
	if( !value ) return undefined;

	switch( value ) {
		case Gender.Female:
		case SportGender.WOMEN:
			return Gender.Female;

		case Gender.Male:
		case SportGender.MEN:
			return Gender.Male;

		case Gender.Coed:
		case SportGender.COED:
			return Gender.Coed;		
	}

	const gender: string = value.toUpperCase();
	switch( gender ) {
		case 'FEMALE':
		case 'WOMEN':
		case 'F':
		case 'W':
			return Gender.Female;

		case 'MALE':
		case 'MEN':
		case 'M':
			return Gender.Male;
	}

	return Gender.Coed;
}

export function isGenderMatch(g1: Gender | AnalyticsGender | SportGender | string, g2: Gender | AnalyticsGender | SportGender | string): boolean {
	return genderType(g1) === genderType(g2)
}

export function genderText(value: Gender | AnalyticsGender): string {
	switch( genderType(value) ) {
		case Gender.Female:
			return "Female";

		case Gender.Male:
			return "Male";

		default:
			return "x";
	}
	return undefined;
}

type GenderTextPluralOpts = {
	/** Use Mens/Womens instead of Boys/Girls */
	adult?: boolean,
}
export function genderTextPlural(value: Gender | AnalyticsGender | SportGender, opts: GenderTextPluralOpts = {}): string{
	const { adult = false } = opts;

	switch( genderType(value) ) {
		case Gender.Female:
			return adult? "Women" : "Girls";

		case Gender.Male:
			return adult? "Men" : "Boys";

		case Gender.Coed:
			return "Co-Ed";
	}

	return undefined;
}

export function genderShort(value: Gender | AnalyticsGender): string{
	switch( genderType(value) ) {
		case Gender.Female:
			return 'F';

		case Gender.Male:
			return 'M';

		default:
			return 'X';
	}
	return undefined;
}