/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TeamModel } from '../../models/team';
import { teamApi } from '../../api/TeamApi';
import { TeamCreateOptions } from '@/../types/interfaces';
import { COUNTRIES, ALL_COUNTRIES } from '@/../types/constants/countries';
import { Country, OrganizationTypeValues } from '@/../types/enums';
import { logGAEvent } from '@/plugins/firebase'

const Mutations = {
	CREATE_TEAM: 'CREATE_TEAM',
	CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
	CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',

	PATCH_TEAM: 'PATCH_TEAM',
	PATCH_TEAM_SUCCESS: 'PATCH_TEAM_SUCCESS',
	PATCH_TEAM_FAILURE: 'PATCH_TEAM_FAILURE',

	LEAVE_TEAM: 'LEAVE_TEAM',
	LEAVE_TEAM_SUCCESS: 'LEAVE_TEAM_SUCCESS',
	LEAVE_TEAM_FAILURE: 'LEAVE_TEAM_FAILURE',
}

@Module({
	namespaced: true,
	dynamic: true,
	name: 'TeamCreateStore',
	store: store
})
export default class TeamCreateModule extends VuexModule {

	initSelectorDataLoading: boolean = false
	get locations(){
		return COUNTRIES.concat(ALL_COUNTRIES);
	}
	supportedSports = [
		"Soccer"
	];
	AvailablePositions = {
		"Soccer": [
			"Goalkeeper",
			"Right Fullback",
			"Left Fullback",
			"Center Back",
			"Defending Midfielder",
			"Right Midfielder",
			"Central Midfielder",
			"Striker",
			"Attacking Midfielder",
			"Left Midfielder",
		]
	};
	organizationTypes = OrganizationTypeValues;
	coachRoles = [
		{ text: 'Administrator', value: 'admin' },
		{ text: 'Assistant Coach', value: 'assistant-coach' },
	];

	createTeamLoading: boolean = false;

	@Action({
		rawError: true,
		commit: Mutations.CREATE_TEAM_SUCCESS
	}) async createTeam({ team, opts = {} }: { team: TeamModel, opts: TeamCreateOptions }): Promise<TeamModel>{
		console.log("CreateTeam.store", { opts });
		this.context.commit(Mutations.CREATE_TEAM, team);
		try{
			const newTeam = await teamApi.insertWithOwnership(team, { params: opts });
			logGAEvent("team_created");
			return newTeam;
		}catch(e){
			console.error("Failed to Create Team", e);
			this.context.commit(Mutations.CREATE_TEAM_FAILURE, e);
		}
	}

	@Mutation [Mutations.CREATE_TEAM](){
		this.createTeamLoading = true;
	}
	@Mutation [Mutations.CREATE_TEAM_SUCCESS](team: TeamModel){
		this.createTeamLoading = false;
	}
	@Mutation [Mutations.CREATE_TEAM_FAILURE](error: any){
		this.createTeamLoading = false;
	}

	@Action({
		rawError: true,
		commit: Mutations.PATCH_TEAM_SUCCESS
	}) async patchTeam({ team, opts = {} }: { team: TeamModel, opts: TeamCreateOptions }): Promise<TeamModel>{
		this.context.commit(Mutations.PATCH_TEAM, team);
		try{
			return await teamApi.patch(team, { params: opts });
		}catch(e){
			console.error("Failed to Create Team", e);
			this.context.commit(Mutations.PATCH_TEAM_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_TEAM](){
		this.createTeamLoading = true;
	}
	@Mutation [Mutations.PATCH_TEAM_SUCCESS](team: TeamModel){
		this.createTeamLoading = false;
	}
	@Mutation [Mutations.PATCH_TEAM_FAILURE](error: any){
		this.createTeamLoading = false;
	}

	leaveTeamLoading: boolean = false;
	@Action({
		rawError: true
	})
	async leaveTeam({ teamId }: { teamId: string }): Promise<void> {
		this.context.commit(Mutations.LEAVE_TEAM);
		try {
			await teamApi.leaveTeam({ teamId  });
			this.context.commit(Mutations.LEAVE_TEAM_SUCCESS);
		} catch (e) {
			console.error("Failed to Load Team", e);
			this.context.commit(Mutations.LEAVE_TEAM_FAILURE, e);
		}
	}

	@Mutation [Mutations.LEAVE_TEAM](): void {
		this.leaveTeamLoading = true;
	}
	@Mutation [Mutations.LEAVE_TEAM_SUCCESS](): void {
		this.leaveTeamLoading = false;
	}
	@Mutation [Mutations.LEAVE_TEAM_FAILURE](error: any): void {
		this.leaveTeamLoading = false;
	}
}
