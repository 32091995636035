
import { Component, Prop, Vue } from 'vue-property-decorator';
import { userStore } from '@/store';
import { UserProfileModel } from '@/models/UserProfileModel';
/**
 * Provides a list of all Athlete & Coach Profiles the user has access to.
 */
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class MyProfilesProvider extends Vue{
	@Prop({ default: 'div' }) as: string;

	get SlotProps(): Record<string, any>{
		return {
			ProfilesReady: this.ProfilesReady,
			AthleteProfiles: this.AthleteProfiles,
			CoachProfiles: this.CoachProfiles,
			CurrentProfile: this.CurrentProfile,
			CurrentProfileType: this.CurrentProfileType,
		};
	}

	async created(): Promise<void>{
		if( userStore.IsReady && !this.ProfilesReady && !userStore.loadUserProfilesLoading ) {
			await userStore.loadUserProfiles();
		}
	}

	get ProfilesReady(): boolean{
		return userStore.userProfilesInitialized && !userStore.loadUserProfilesLoading;
	}
	get AthleteProfiles(): UserProfileModel[]{
		return userStore.athleteProfiles;
	}
	get CoachProfiles(): UserProfileModel[]{
		return userStore.coachProfiles;
	}
	get CurrentProfile(): UserProfileModel | null{
		const profile = userStore.currentProfileData;
		if(profile === null) return null;
		if(profile.type === 'coach'){
			return userStore.coachProfiles.find(c => c.id === profile.profileId) ?? null;
		}
		return userStore.athleteProfiles.find(a => a.id === profile.profileId) ?? null;
	}
	get CurrentProfileType(): 'athlete' | 'coach' | null{
		const profile = userStore.currentProfileData;
		if(profile === null) return null;
		return profile.type;
	}

}
