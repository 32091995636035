
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonToggle extends Vue {
	@Prop() icon1: string = "";
	@Prop() leftText: string = "";
	@Prop() leftIcon: string = "";
	@Prop() rightText: string = "";
	@Prop() rightIcon: string = "";
	@Prop() clickedList: boolean = true;
	
	emit(emitted: string, info?: string): void {
		this.$emit(emitted, info);
	}
	showFavourites(): void {
		this.emit("click:fav");
	}
	showList(): void {
		this.emit("click:list");
	}
}
