import { getEnumValues } from './get-enum-values';
export enum Gender {
	Male="male",
	Female="female",
	Other="other",
}
export enum TeamGender{
	Male="male",
	Female="female",
	Coed="coed",
}
export enum SportGender {
	MEN = "men",
	WOMEN = "women",
	COED = "coed",
}
export const TeamGenderValues = getEnumValues<TeamGender>(TeamGender)
export const GenderValues = getEnumValues<Gender>(Gender)

export function isGender(gender: string, g: SportGender): boolean {
	if( gender == g ) return true;

	switch( g ) {
		case SportGender.MEN:
			if( gender.toUpperCase().startsWith('M') ) return true;
			break;

		case SportGender.WOMEN:
			if( gender.toUpperCase().startsWith('W') ) return true;
			if( gender.toUpperCase().startsWith('F') ) return true;
			break;
	}
	return false;
}
