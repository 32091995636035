import { EBPrice } from '@/../types/interfaces/EventbriteTypes';
import { AccessControlledModel } from '../AccessControlledModel';

export class BAEventTicketClassModel extends AccessControlledModel {
	private eventbriteId: string;
	get EventbriteId(): string {
		return this.eventbriteId;
	}
	name: string;
	description: string | null;
	capacity: number;
	quantityTotal: number;
	quantitySold: number;
	free: boolean;
	cost!: EBPrice | null;
	actualCost!: EBPrice | null;
	actualFee!: EBPrice | null;
	tax!: EBPrice | null;
	category!: string;

	get IsAdmission(): boolean{
		return this.category === 'admission'
	}
	get IsAddon(): boolean{
		return this.category === 'add_on'
	}

	/**
	 * Returns the tickets cost or 0 if the ticket is Free
	 */
	get TicketCost(): number{
		if(this.free) return 0;
		if(this.cost === null) return 0;
		return this.cost.value;
	}
	/**
	 * Returns the tickets Cost 'display' value ($CA5.00) or "Free"
	 */
	get TicketCostDisplay(): string{
		if(this.cost === null) return '$0';
		return this.cost.display;
	}
}