import { TeamEventTypeData } from '@/../types/interfaces';
import { TeamEventType } from '@/../types/enums';

const DEFAULT_EVENT_TYPES = [
	{ name: TeamEventType.Game, label: 'Game', color: 'purple' },
	{ name: TeamEventType.Practice, label: 'Practice', color: 'pink', disabled: false },
	{ name: TeamEventType.Social, label: 'Meeting', color: 'teal', disabled: false },
];

export const getDefaultEventTypes = (): TeamEventTypeData[] => (DEFAULT_EVENT_TYPES);

export const getEventColor = (eventType: TeamEventType): string => {
	const type = DEFAULT_EVENT_TYPES.find(type => type.name === eventType);
	if(type === undefined) return 'baColorVibrantBlue';
	return type.color;
};