import { Component, Vue, Watch } from 'vue-property-decorator';
import { teamsStore, athleteProfile } from '@/store';
import { TeamModel } from '@/models/team';

@Component
export class MyTeamsMixin extends Vue {
	reloadMyTeams: boolean = false;
	$auth: {
		athleteId: string,
		coachId: string,
	};
	get MyTeamsInitialized(): boolean{
		return teamsStore.myTeamsInitialized;
	}
	get MyTeamsLoading(): boolean{
		return teamsStore.myTeamsLoading;
	}
	get MyTeams(): TeamModel[]{
		return teamsStore.myTeams;
	}

	get NumTeams(): number {
		return this.MyTeams.length;
	}
	get HasTeams(): boolean {
		return this.NumTeams > 0;
	}

	get HasAthleteTeams(): boolean {
		return this.MyAthleteTeams.length > 0;
	}
	get MyAthleteTeams(): TeamModel[]{
		return athleteProfile.athleteProfileTeams;
	}
	get MyAthleteCurrentTeam(): TeamModel {
		if( !this.HasAthleteTeams ) return undefined;
		return this.MyAthleteTeams[this.MyAthleteTeams.length - 1];
	}
	get MyAthleteId(): string {
		return athleteProfile.athleteProfile?.id;
	}

	created() {
		this.initMyTeams();
	}

	@Watch('$auth.athleteId')
	async reloadTeams() {
		this.reloadMyTeams = true;
		await this.initMyTeams();
	}

	async initMyTeams() {
		if (this.reloadMyTeams || !teamsStore.myTeamsInitialized && this.$auth.coachId) {
			await teamsStore.loadMyTeams(this.$auth.coachId);
		}
		if (this.reloadMyTeams || !athleteProfile.athleteProfileInitialized && this.$auth.athleteId) {
			await athleteProfile.getAthleteProfile(this.$auth.athleteId);
		}
	}

}
