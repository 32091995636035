
import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import{ BAIconsMixin, VuetifyMixin} from '@/mixins';

@Component
export default class BannerNotifications extends Mixins(VuetifyMixin, BAIconsMixin){

	/** Who's notifications are we displaying? (Athlete/Coach) */
	@Prop() recipientId: string;
	/** Show this notification first */
	@Prop() notificationId: string;

	/** data */
	actionInProgress = false;
	private buttonLoading = {};

	/** computed */
	get showBanner(): boolean{
		return this.nextBannerNotification !== undefined
	}
	get Notifications(): NotificationModel[]{
		return notificationStore.notifications;
	}
	get nextBannerNotification(): NotificationModel{
		let notification: NotificationModel;
		/** If NotificationId is passed, show that notification until if it exists whether it's read or not */
		if(this.notificationId){
			notification = notificationStore.notifications.find(n => n.id === this.notificationId && n.uiType === "Banner");
		}

		/** Fallback to the next Unread Banner notification */
		if(notification === undefined){
			return notificationStore.notifications.find(n => n.uiType === "Banner" && n.status === "UNREAD");
		}
		return notification;
	}

	@Watch('recipientId', { immediate: true }) reloadNotifications(): void{
		this.loadNotifications();
	}

	/** methods */
	async loadNotifications(): Promise<void>{
		if( !this.recipientId ) return;
		notificationStore.fetchNotifications(this.recipientId);
	}
	async notificationAction(notificationActionIndex: number): Promise<void>{
		if(this.actionInProgress) return;
		this.setLoading(notificationActionIndex, true);
		try{
			await this.nextBannerNotification.actions[notificationActionIndex].action.exec();
			await this.nextBannerNotification.actions[notificationActionIndex].action.runSuccessActions();
		}catch(e){
			console.error("Error running button callback", e);

			await this.nextBannerNotification.actions[notificationActionIndex].action.runFailureActions();
		}finally{
			await this.markAsReadAndReload(this.nextBannerNotification.id);
			this.setLoading(notificationActionIndex, false);
		}
	}
	setLoading(index: number, loading: boolean): void{
		this.actionInProgress = loading;
		Vue.set(this.buttonLoading, index, loading);
	}
	getLoading(index: number): void{
		return this.buttonLoading[index];
	}

	async markAsReadAndReload(notificationId: string): Promise<void>{
		await notificationStore.markNotificationRead(notificationId);
		this.loadNotifications().catch(console.error);
	}
}
