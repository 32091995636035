// QRCodeMixin.vue

import { Component, Vue } from 'vue-property-decorator';
import * as QRCode from 'qrcode';

@Component
export class QRCodeMixin extends Vue {

	defaultTeamQR: string = 'https://s3.ca-central-1.amazonaws.com/cdn.bestathletes.co/static-assets/BA-qr-code.png'

	async generateQRCode(text: string): Promise<string> {
		try {
			const options = {color: {
				dark:"#005AA8FF",
				light:"#FFFFFF00"
			  } }
			const qrCodeUrl = await QRCode.toDataURL(text, options);
			return qrCodeUrl;
		} catch(e) {
			console.error(`Error generating QR Code (${text})`, e);
			return this.defaultTeamQR;
		}
	}
}