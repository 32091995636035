import { getEnumValues } from './get-enum-values';
export enum OrganizationType{
	College='College',
	University='University',
	Club='Club',
	Academy='Academy',
	Professional='Professional',
	School='School',
	Event='Event',
}
export const OrganizationTypeValues = getEnumValues<OrganizationType>(OrganizationType);
