import { RouteConfig } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';
import { PublicAthleteSideNavLayout } from '../layouts';
import { userStore } from '@/store';

const AthletePublicDashboard = () => import(/* webpackChunkName: "PublicModule" */ '@/views/PublicDashboard.vue');
const BAEventDetailPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/BAEventDetailPage.vue');
const AthletePublicPartner = () => import(/* webpackChunkName: "PublicModule" */ '@/views/partners/Partner.vue');
const AthletePublicPartnerProduct = () => import(/* webpackChunkName: "PublicModule" */ '@/views/partners/PartnerProduct.vue');
const AthletePublicBestEducation = () => import(/* webpackChunkName: "PublicModule" */ '@/views/partners/BestEducation.vue');

export const PublicAthleteRouteConfigs: RouteConfig[] = [
    {
        path: '/public-a',
        props: true,
		component: PublicAthleteSideNavLayout,
		children: [
			{
				path: '',
				name: Routes.AthletePublicDashboard,
				component: AthletePublicDashboard,
				meta:<HinderRouteMetadata>{
					pageTitle: 'Best Athletes Dashboard'
				}
			},
			{
				name: Routes.AthletePublicEventDetails,
				path: 'baevent/:eventId',
				component: BAEventDetailPage,				
				props: true,
				meta: {
					pageTitle: 'Powered by Best Athletes'
				}
			},
			{
				name: Routes.AthletePublicBestEducation,
				path: 'partners/best-education',
				component: AthletePublicBestEducation,
				beforeEnter: (to, from, next) => {
					if( userStore.IsReady ) next( 'partners/best-education' ) ;
					else next();
				},
				props: true,
				meta: {
					public: true,
					requiresAuth: false,
					pageTitle: 'Best Education',
				}
			},
			{
				name: Routes.AthletePublicPartner,
				path: 'partners/:partnerName',
				component: AthletePublicPartner,
				props: true,
				meta: {
					pageTitle: `Best Athletes Partner`,
				}
			},
			{
				name: Routes.AthletePublicPartnerProduct,
				path: 'partners/:partnerName/:productId',
				component: AthletePublicPartnerProduct,
				props: true,
				meta: {
					pageTitle: `Best Athletes Partner`,
				}
			},
        ]
    }
]