import { Component, Vue } from 'vue-property-decorator';
import { Auth0Plugin } from '@/auth/Auth0';
import { RoleName } from '@/../types/enums';
import { userStore } from '@/store';
import { UserPreferenceModel } from '@/models/user/UserPreferenceModel';
import { PopupLoginOptions, User } from '@auth0/auth0-spa-js';

/**
 * Provides information about the currently logged in user.
 */
@Component
export class AuthMixin extends Vue {
	$auth: Auth0Plugin;

	get AuthIsReady(): boolean{
		return this.$auth.IsReady;
	}

	async loginUsingPopup(login_hint?: string): Promise<boolean> {
		try {
			const options: PopupLoginOptions = {
				prompt: 'login',
				screen_hint: 'login',
				connection: 'Username-Password-Authentication',
				login_hint,
			};
			const loggedIn = await userStore.loginWithPopup(options);
			if( !loggedIn ) return false;

			// update user information
			await userStore.refreshToken(true);
			return true;
		} catch(e) {
			console.error(e);
			throw(e);
		}
	}
	async updateAfterLogin(): Promise<User> {
		return await userStore.updateAfterLogin();
	}

	get UserId(): string{
		return userStore.user.id;
	}
	get CurrentProfileType(): "athlete" | "coach" | null {
		return userStore.currentProfileData? userStore.currentProfileData.type : null;
	}
	get CurrentUserRoles(): RoleName[] {
		// DEBUG PROD DB (search DEBUG_PROD)
		// return [RoleName.Admin, RoleName.ClubAdmin, RoleName.UserAdmin, RoleName.FreeUser, RoleName.Coach];
		
		return this.$auth.user.roles;
	}
	get CurrentUserProfileId(): string | null {
		return userStore.currentProfileData ? userStore.currentProfileData.profileId : null;
	}
	get CurrentUserProfilePreference(): UserPreferenceModel | null {
		return userStore.userPreference;
	}
	get CurrentProfileEmail(): string | null {
		return userStore.currentProfileData ? userStore.currentProfileData.email : null;
	}
	get IsExclusivelySocialLogin(): boolean {
		return userStore.user.identities?.filter(identity => identity.isSocial === false).length === 0
	}
	get LoginProvider(): string {
		if (userStore.user.id === undefined) {
			return "Auth0"
		}

		const provider = userStore.user.id.split("|")[0]

		switch (provider) {
		case "auth0":
			return "Auth0"
		case "google-oauth2":
			return "Google"
		default:
			return "Auth0";
		}
	}
	get CompletedProfile(): boolean{
		return this.$auth.completedProfile;
	}

	get AthleteId(): string{
		if (!this.$auth.user) return undefined;
		return this.$auth.user.athleteId;
	}
	get CoachId(): string{
		if (!this.$auth.user) return undefined;
		return this.$auth.user.coachId;
	}

	get IsAdmin(): boolean{
		if(this.$auth.user === null) return false;
		return this.CurrentUserRoles.findIndex(r => r === RoleName.Admin) !== -1;
	}

	get IsInstructorAdmin(): boolean{
		if(this.$auth.user === null) return false;
		return this.CurrentUserRoles.findIndex(r => r === RoleName.InstructorAdmin) !== -1;
	}

	get IsCoach(): boolean{
		return this.CurrentProfileType && this.CurrentProfileType == 'coach';
	}
	get IsAthlete(): boolean{
		return this.CurrentProfileType && this.CurrentProfileType == 'athlete';
	}
	get IsParent(): boolean{
		if(this.$auth.user === null) return false;
		return this.CurrentUserRoles.findIndex(r => r === RoleName.Parent) !== -1;
	}
	
	get HasAthleteProfile(): boolean{
		return userStore.HasAthleteProfile;
	}
	get HasOwnAthleteProfile(): boolean{
		return userStore.HasOwnAthleteProfile;
	}
	get IsImpersonatingAthlete(): boolean{
		return userStore.IsImpersonatingAthlete;
	}
	get HasAdditionalAthleteProfiles(): boolean{
		return userStore.HasAdditionalAthleteProfiles;
	}

	get HasCoachProfile(): boolean{
		return userStore.HasCoachProfile;
	}
	
	get CurrentUserEmail(): string{
		return userStore.user.email;
	}
}
