
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { AuthMixin, AppHostnameMixin, MyCoachMixin, BAIconsMixin } from "@/mixins";
import { AccordionSettings, AccordionAccountSettingHeaderDetail } from "@/components/ui/AccordionSettings.vue";
import CoachSettingPersonalInfo from "@/components/profile/settings/CoachSettingPersonalInfo.vue";
import CoachSettingCoachingRecord from "@/components/profile/settings/CoachSettingCoachingRecord.vue";
import CoachSettingCoachingBio from "@/components/profile/settings/CoachSettingCoachingBio.vue";
import CoachSettingCoachingExperience from "@/components/profile/settings/CoachSettingCoachingExperience.vue";
import CoachSettingCoachingAccolades from "@/components/profile/settings/CoachSettingCoachingAccolades.vue";
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { coachProfile, notificationStore } from '@/store';
import { NotificationToPreferenceSettingMapping, NotificationPreferenceSetting } from '@/components/profile/settings/NotificationPreferenceSetting.vue';
import AccountSettingsChangePassword from "@/components/profile/settings/AccountSettingsChangePassword.vue";

@Component({
	components: {
		Page,
		AccordionSettings,
		CoachSettingPersonalInfo,
		CoachSettingCoachingBio,
		CoachSettingCoachingRecord,
		CoachSettingCoachingExperience,
		CoachSettingCoachingAccolades,
		NotificationPreferenceSetting,
		AccountSettingsChangePassword
	}
})
export class CoachAccordionSettings extends Mixins(
		AuthMixin,
		AppHostnameMixin,
		MyCoachMixin,
		BAIconsMixin
	) {
	accordionHeadersOpen: number = -1;

	savedCoachProfile: CoachProfileModel = new CoachProfileModel();
	unsavedCoachProfile: CoachProfileModel = new CoachProfileModel();

	loading: boolean = false;

	get UnsavedProfile(): CoachProfileModel {
		return this.unsavedCoachProfile;
	}
	set UnsavedProfile(profile: CoachProfileModel) {		
		this.unsavedCoachProfile = new CoachProfileModel().load(profile);
	}
	profileLoaded: boolean = false;
	profileLoadingId: number;

	notificationSettings: NotificationToPreferenceSettingMapping[] = [
		{
			label: "Athlete Profile Shares",
			value: true,
			mapping: ["coachAthleteProfileShare"]
		},
		{
			label: "Team Invites",
			value: true,
			mapping: ["coachStaffInvitiation"]
		},
		{
			label: "Team Calendar Events",
			value: true,
			mapping: ["coachTeamCalendarEvents"]
		},
		{
			label: "Course Invitations",
			value: true,
			mapping: ["coachCourseInvitations"]
		},
		{
			label: "Course Updates",
			value: true,
			mapping: ["coachCourseUpdates"]
		},
		{
			label: "Marketing Emails",
			value: true,
			mapping: ["newsAndUpdates"]
		}
	];
	
	async created(): Promise<void>{
		await this.initProfile()	
	}

	async initProfile(): Promise<void> {
		if (this.CurrentUserProfileId) {
			await coachProfile.getCoachProfile(this.CurrentUserProfileId);
			this.unsavedCoachProfile = new CoachProfileModel().load(this.MyCoachProfile);
			this.savedCoachProfile = new CoachProfileModel().load(this.MyCoachProfile);
			this.profileLoaded = true;
		}
	}

	set Loading(newLoading: boolean) {
		if (newLoading === false)
			setTimeout(() => this.loading = false, 1000);
		else
			this.loading = true;
	}

	get Loading(): boolean {
		return this.loading;
	}

	async saveProfile(validate: () => Promise<{ isValid, errors, flags, fields }>): Promise<void> {
		const { isValid } = await validate();
		if (!isValid) {
			notificationStore.snackbarShowError("Some required information is missing");
			return;
		}

		this.Loading = true;
		await coachProfile.patchCoachProfile(this.unsavedCoachProfile);
		this.savedCoachProfile = new CoachProfileModel().load(this.unsavedCoachProfile);
		this.Loading = false;
		notificationStore.snackbarChangesSavedSuccess();
	}

	get AccordionHeaders(): AccordionAccountSettingHeaderDetail[] {
		return [
			{
				title: "Personal Information",
				description: "Manage your name and contact info",
				icon: this.BAIcons.accountEdit,
				enabled: true,
			},
			{
				title: "Coaching Record",
				description: "Manage your career record.",
				icon: this.BAIcons.record,
				enabled: true,
			},
			{
				title: "Bio & Philosophy",
				description: "Tell your profile viewers a little about yourself and your coaching philosophy.",
				icon: this.BAIcons.bio,
				enabled: true,
			},
			{
				title: "Experience",
				description: "Manage information about your educational career.",
				icon: this.BAIcons.experience,
				enabled: true,
			},
			{
				title: "Accolades",
				description:"Manage information about your educational career.",
				icon: this.BAIcons.accolades,
				enabled: true
			},
			{
				title: "Password & Security",
				description: "Change your password and manage 2FA settings.",
				icon: this.BAIcons.security,
				enabled: true,
			},
			{
				title: "Notification Preferences",
				description: "Choose which types of email notifications you will receive.",
				icon: this.BAIcons.communication,
				enabled: true,
			},
		];
	}
}
export default CoachAccordionSettings;
