// AthleteReportApi.ts

import { CrudApi } from './CrudApi';
import { AthleteReportModel } from '@/models/athleteReport/AthleteReportModel';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { AxiosRequestConfig } from 'axios';

class AthleteReportApi extends CrudApi<AthleteReportModel>{
	constructor() {
		super('athlete-report', (obj) => new AthleteReportModel().load(obj));
	}

	async findByQuery(query: RepositoryQuery<AthleteReportModel>, options: QueryOptions = {}, config?: AxiosRequestConfig): Promise<Array<AthleteReportModel>> {
		const response: PaginatedResponse<AthleteReportModel> = await this.queryAll(query, options, config);
		if( response.count < 1 ) return [];
		return response.docs.map(doc => this.create(doc));
	}
	async findByAthleteId(athleteId: string): Promise<Array<AthleteReportModel>> {
		return this.findByQuery({ 
			search: athleteId,
			fields: ['athleteId']
		});
	}
	async findByCoachId(coachId: string): Promise<Array<AthleteReportModel>> {
		return this.findByQuery({ 
			search: coachId,
			fields: ['coachId']
		});
	}
}
export const athleteReportApi = new AthleteReportApi();