
import { Component, Prop, Mixins, Watch, PropSync } from 'vue-property-decorator';
import { VuetifyMixin, SportsSelectorMixin, FormRulesMixin, BAIconsMixin } from '../../mixins';
import { InviteStaffFormValue } from '@/../types/interfaces';
import { TeamModel } from '../../models/team';
import { teamDashboardStore } from '../../store';
import { StaffMember } from '@/models/team/StaffMember';

@Component({})
export default class InviteStaffDialog extends Mixins(FormRulesMixin, SportsSelectorMixin, VuetifyMixin, BAIconsMixin){
	$refs:{
		form: HTMLFormElement
	}

	@PropSync('visible', { type: Boolean, default: false }) Visible: boolean;

	@Watch('Visible')
	resetForm(newValue) {
		// Reset validation state when showing the form
		if(this.Visible && this.$refs.form && this.formValue.email === ""){
			this.$refs.form.reset();
		}
	}
	
	@Prop({ required: true }) team: TeamModel;
	@Prop() staffId: string;
	@Prop({ default: (): Partial<StaffMember> => ({
		firstName: "",
		lastName: "",
		email: "",
		role: undefined,
	}) }) formValue: InviteStaffFormValue;
	@Prop() isResend: boolean;
	updateForm(){
		this.$emit('update:formValue', this.formValue);
	}

	get manualInviteLoading(){
		return teamDashboardStore.manualInviteLoading;
	}

	formValid = false;

	submit($event: Event){
		$event.preventDefault();
		this.invite();
	}

	async invite(){
		const staff: InviteStaffFormValue = {
			firstName: this.formValue.firstName,
			lastName: this.formValue.lastName,
			email: this.formValue.email,
			role: this.formValue.role
		};
				
		if(this.staffId) staff.id = this.staffId;
		// invite and add if email exists, otherwise only add staff to team
		this.formValue.email ? 
			await teamDashboardStore.inviteStaffByEmail({
				teamId: this.team.id,
				email: this.formValue.email,
				staff,
			}) : 
			await teamDashboardStore.addStaffToTeam({
				teamId: this.team.id,
				staff
			})
		this.close();
	}
	cancel(){
		this.close();
	}

	close(){
		this.Visible = false;
	}
}
