
import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import { DatePickerAllowedDatesFunction, InputValidationRules } from 'vuetify';
import VDatePicker from 'vuetify/lib/components/VDatePicker';
import VMenu from 'vuetify/lib/components/VMenu';
import { formatDate, getMediumDateString } from '../../helpers/date';
import {BAIconsMixin, VuetifyMixin} from '@/mixins';

@Component
export default class DatePicker extends Mixins(BAIconsMixin, VuetifyMixin){
	$refs:{
		menu: VMenu;
		picker: VDatePicker;
	}

	menu: boolean = false;
	@Watch('menu')
	showMenuWatch (val: boolean): void {
		if(this.month < 0){
			if(this.year < 0){
				this.date = '2006-01-01';
			}
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
		}
	}

	@Prop({ type: Boolean, default: false }) dense: boolean;
	@Prop({ type: Boolean, default: false }) private birthdate: boolean;
	@Prop({ type: Number, default: -1 }) private month: number;
	@Prop({ type: Number, default: -1 }) private year: number;
	@Prop({ type: Boolean, default: true }) offsetY: boolean;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean, default: false }) readonly: boolean;
	@Prop({ type: Boolean, default: false }) hideDetails: boolean;
	@Prop({ default: () => true, type: Function }) allowedDates: DatePickerAllowedDatesFunction;
	@Prop() rules: InputValidationRules;
	@Prop() label: string;

	@Prop() private value!: Date;
	input(value: Date){
		this.$emit('input', value);
	}

	@Watch('value')
	setDate (value: Date): void {
		this.value = value;
		this.date = formatDate(value, true, true);
		this.month = this.value.getMonth();
		this.year = this.value.getFullYear();
	}

	mounted(): void{
		if(this.value){
			this.setDate(this.value);
		}
	}

	date: string = "";
	dateInput(value: string){
		this.show = false;
		this.value = new Date(value);
		this.input(this.value);
		this.$refs.menu.save(value);
	}

	private show: boolean = false;

	get PrettyDate(): string{
		if(this.value){
			return getMediumDateString(this.value, true);
		}
		return '';
	}

	get DatePickerProps(){
		if(this.birthdate){
			return {
				max: new Date().toISOString().substring(0, 10),
				min: "1920-01-01",
			}
		}
		return {
			'no-title': true,
		};
	}

}
