
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import { Mixins, Component, Prop } from "vue-property-decorator";
import Dialog from "@/components/ui/Dialog.vue";
import ExcelDataTable from "@/components/ui/ExcelDataTable.vue";
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";

@Component({
	components: { ExcelDataTable, Dialog }
})
export default class CSVUploadPreview extends Mixins(
	VuetifyMixin,
	CSVUploadMixin,
	BAIconsMixin
) {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: null }) headers: any;
	@Prop({ default: [] }) items: any[];

	get Headers() {
		return this.headers ?? this.CSVDataTableHeaders;
	}
	
	get dialogVisbility() {
		return this.visible;
	}
	set dialogVisbility(value: boolean) {
		this.$emit("update:visible", value);
	}

	get tableData() {
		return this.items.filter(row => row.firstName && row.lastName);
	}
	get previewData() {
		if (this.tableData.length > 5) {
			// Get the first 4 items
			let data: Array<Record<string, unknown>> = this.tableData.slice(0, 4);
			// Get the keys of the objects
			let datakeys = Object.keys(data[0]);

			// The last row will be filled with ... to indicate more data
			let lastrow = {};
			datakeys.forEach(key => {
				lastrow[key] = "...";
			});
			data.push(lastrow);

			return data;
		} else {
			return this.tableData.slice(0, 5);
		}
	}
	get playersWithEmails() {
		// Trim all the blank emails of white space and check if they are blank
		return this.tableData.filter(
			row => row.email && row.email.trim().length != 0
		).length;
	}

	confirmPreview() {
		this.$emit("confirm", this.tableData);
		this.$emit("update:visible", false);
	}
}
