import { CrudApi } from './CrudApi';
import { OrganizationModel } from '../models/organization/OrganizationModel';
import { StaffMember } from '@/models/team/StaffMember';
import { TeamModel } from '@/models/team';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { AxiosRequestConfig } from 'axios';

export class OrganizationApi extends CrudApi<OrganizationModel>{
	constructor(){
		super('organization', (obj) => new OrganizationModel().load(obj));
	}

	/**
	 * Requires Admin permissions. This will be a long running job to re-index all of an organization's Athlete's
	 */
	async reIndexAthletes({ organizationId }: { organizationId: string }, config?: AxiosRequestConfig): Promise<TeamModel[]> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/${organizationId}/reIndexAthletes`, config));
	}

	async getOrganizationTeams({ organizationId }: { organizationId: string }, config?: AxiosRequestConfig): Promise<TeamModel[]> {
		const orgTeams = await CrudApi.Api(c => c.get(`/${this.resource}/${organizationId}/team`, config));
		return orgTeams.map(t => new TeamModel().load(t));
	}

	async getOrganizationStaff({ organizationId }: { organizationId: string }, config?: AxiosRequestConfig): Promise<StaffMember[]> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/${organizationId}/staff`, config));
	}

	async removeStaff({ organizationId, staffId }: { organizationId: string, staffId: string }, config?: AxiosRequestConfig): Promise<OrganizationModel> {
		const organization = await CrudApi.Api(c => c.delete(`/${this.resource}/${organizationId}/staff/${staffId}`, config));
		return new OrganizationModel().load(organization);
	}

	async searchOrganizationPlayers({ organizationId, query, options }: { organizationId: string, query: RepositoryQuery<AthleteProfileModel>, options: QueryOptions }, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteProfileModel>> {
		const response: PaginatedResponse<any> = await CrudApi.Api(c => c.post(`/${this.resource}/${organizationId}/players/search`, query, this.applyOptionsToRequest(options, config)));
		return response;
	}
	/**
	 * Alias for searchOrganizationPlayers
	 */
	async queryOrganizationPlayers(params: { organizationId: string, query: RepositoryQuery<AthleteProfileModel>, options: QueryOptions }, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteProfileModel>> {
		const response: PaginatedResponse<any> = await CrudApi.Api(c => c.post(`/${this.resource}/${params.organizationId}/players/query`, params.query, this.applyOptionsToRequest(params.options, config)));
		return response;
	}

	async queryOrganizationPlayersEx(params: { organizationId: string, query: RepositoryQuery<AthleteProfileModel>, options: QueryOptions }, config?: AxiosRequestConfig): Promise<PaginatedResponse<AthleteProfileModel>> {
		// return await this.queryOrganizationPlayers(params);		// query does not use ElasticSearch
		return await this.searchOrganizationPlayers(params);
	}

	/*
	 * Requires Admin permissions.
	 */
	async createIndexLeaderboard({ organizationId }: { organizationId: string }, config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/${organizationId}/players/createIndex`, config));
	}

}

export const organizationApi = new OrganizationApi();