import store from "../store";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { PaginatedResponse, QueryOptions, RepositoryQuery } from "@/../types/interfaces";
import { EventLocationModel, EventSponsorModel, BAEventModel } from "@/models/baEvent";
import { eventLocationApi, eventSponsorApi, baEventApi } from "@/api/BAEventsApi";
import { isNotEmpty, isNotEmptyArray } from "@/pipes";

const Mutations = {
	QUERY_EVENTLOCATIONS: 'QUERY_EVENTLOCATIONS',
	QUERY_EVENTLOCATIONS_SUCCESS: 'QUERY_EVENTLOCATIONS_SUCCESS',
	QUERY_EVENTLOCATIONS_FAILURE: 'QUERY_EVENTLOCATIONS',
	FINDBYID_EVENTLOCATIONS: 'FINDBYID_EVENTLOCATIONS',
	FINDBYID_EVENTLOCATIONS_SUCCESS: 'FINDBYID_EVENTLOCATIONS_SUCCESS',
	FINDBYID_EVENTLOCATIONS_FAILURE: 'FINDBYID_EVENTLOCATIONS',
	SAVE_EVENTLOCATION: 'SAVE_EVENTLOCATION',
	SAVE_EVENTLOCATION_SUCCESS: 'SAVE_EVENTLOCATION_SUCCESS',
	SAVE_EVENTLOCATION_FAILURE: 'SAVE_EVENTLOCATION_FAILURE',
	DELETE_EVENTLOCATION: 'DELETE_EVENTLOCATION',
	DELETE_EVENTLOCATION_SUCCESS: 'DELETE_EVENTLOCATION_SUCCESS',
	DELETE_EVENTLOCATION_FAILURE: 'DELETE_EVENTLOCATION_FAILURE',

	QUERY_EVENTSPONSORS: 'QUERY_EVENTSPONSORS',
	QUERY_EVENTSPONSORS_SUCCESS: 'QUERY_EVENTSPONSORS_SUCCESS',
	QUERY_EVENTSPONSORS_FAILURE: 'QUERY_EVENTSPONSORS',
	SAVE_EVENTSPONSOR: 'SAVE_EVENTSPONSOR',
	SAVE_EVENTSPONSOR_SUCCESS: 'SAVE_EVENTSPONSOR_SUCCESS',
	SAVE_EVENTSPONSOR_FAILURE: 'SAVE_EVENTSPONSOR_FAILURE',
	DELETE_EVENTSPONSOR: 'DELETE_EVENTSPONSOR',
	DELETE_EVENTSPONSOR_SUCCESS: 'DELETE_EVENTSPONSOR_SUCCESS',
	DELETE_EVENTSPONSOR_FAILURE: 'DELETE_EVENTSPONSOR_FAILURE',

	QUERY_BAEVENTS: 'QUERY_BAEVENTS',
	QUERY_BAEVENTS_SUCCESS: 'QUERY_BAEVENTS_SUCCESS',
	QUERY_BAEVENTS_FAILURE: 'QUERY_BAEVENTS',
	FINDBYID_BAEVENTS: 'FINDBYID_BAEVENTS',
	FINDBYID_BAEVENTS_SUCCESS: 'FINDBYID_BAEVENTS_SUCCESS',
	FINDBYID_BAEVENTS_FAILURE: 'FINDBYID_BAEVENTS',
	SAVE_BAEVENT: 'SAVE_BAEVENT',
	SAVE_BAEVENT_SUCCESS: 'SAVE_BAEVENT_SUCCESS',
	SAVE_BAEVENT_FAILURE: 'SAVE_BAEVENT_FAILURE',
	DELETE_BAEVENT: 'DELETE_BAEVENT',
	DELETE_BAEVENT_SUCCESS: 'DELETE_BAEVENT_SUCCESS',
	DELETE_BAEVENT_FAILURE: 'DELETE_BAEVENT_FAILURE',

}

const name = "BAEventStore";

if (store.state[name]) {
	store.unregisterModule(name);
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store,
})
export default class BAEventsModule extends VuexModule {
	/***********************
	 * EVENT LOCATIONS
	 ***********************/
	numEventLocations: number = 0;
	eventLocations: EventLocationModel[] = [];
	currentLocation: EventLocationModel = undefined;

	loadingEventLocations: boolean = false;
	findingEventLocation: boolean = false;
	creatingEventLocation: boolean = false;
	deletingEventLocation: boolean = false;
	@Action async loadEventLocations(): Promise<EventLocationModel[]> {
		this.context.commit(Mutations.QUERY_EVENTLOCATIONS);
		try {
			const options: QueryOptions = {page: 1, limitPerPage: 1000}
			const [{count},data] = await Promise.all([
				eventLocationApi.countAll(),
				eventLocationApi.queryAll( { fields: [] }, options ),
			]);
			this.context.commit(Mutations.QUERY_EVENTLOCATIONS_SUCCESS, { count, data });
			return data.docs;
		} catch (e) {
			this.context.commit(Mutations.QUERY_EVENTLOCATIONS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_EVENTLOCATIONS](): void {
		this.loadingEventLocations = true;
	}
	@Mutation [Mutations.QUERY_EVENTLOCATIONS_SUCCESS]({data}: {data: PaginatedResponse<EventLocationModel>}): void {
		this.loadingEventLocations = false;
		this.numEventLocations = data.total;
		this.eventLocations = data.docs;
	}
	@Mutation [Mutations.QUERY_EVENTLOCATIONS_FAILURE](error: any): void {
		this.loadingEventLocations = false;
	}

	@Action async findEventLocation(locationId: string): Promise<EventLocationModel> {
		this.context.commit(Mutations.FINDBYID_EVENTLOCATIONS);
		try {
			// check the cache
			if( isNotEmptyArray(this.eventLocations)) {
				const location = this.eventLocations.find(l => l.id == locationId);
				if( isNotEmpty(location) ) return location;
			}

			// load location from server
			const data = await eventLocationApi.findById(locationId);
			this.context.commit(Mutations.FINDBYID_EVENTLOCATIONS_SUCCESS, data);
			this.eventLocations.push(data);
			return data;
		} catch (e) {
			this.context.commit(Mutations.FINDBYID_EVENTLOCATIONS_FAILURE, e);
		}
	}
	@Mutation [Mutations.FINDBYID_EVENTLOCATIONS](): void {
		this.findingEventLocation = true;
	}
	@Mutation [Mutations.FINDBYID_EVENTLOCATIONS_SUCCESS](data: EventLocationModel): void {
		this.currentLocation = data;
		this.findingEventLocation = false;
	}
	@Mutation [Mutations.FINDBYID_EVENTLOCATIONS_FAILURE](error: any): void {
		this.findingEventLocation = false;
	}
    
	@Action async saveEventLocation(eventLocation: EventLocationModel): Promise<EventLocationModel> {
		this.context.commit(Mutations.SAVE_EVENTLOCATION)
		try{
			const newLocation = await eventLocationApi.save(eventLocation);
			this.context.commit(Mutations.SAVE_EVENTLOCATION_SUCCESS);
			return newLocation;
		}catch(e){
			this.context.commit(Mutations.SAVE_EVENTLOCATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_EVENTLOCATION](){
		this.creatingEventLocation = true;
	}
	@Mutation [Mutations.SAVE_EVENTLOCATION_SUCCESS](){
		this.creatingEventLocation = false;
	}
	@Mutation [Mutations.SAVE_EVENTLOCATION_FAILURE](){
		this.creatingEventLocation = false;
	}

	@Action async deleteEventLocation(eventLocation: EventLocationModel): Promise<EventLocationModel> {
		this.context.commit(Mutations.DELETE_EVENTLOCATION)
		try{
			const delLocation = await eventLocationApi.delete(eventLocation);
			this.context.commit(Mutations.DELETE_EVENTLOCATION_SUCCESS);
			return delLocation;
		}catch(e){
			this.context.commit(Mutations.DELETE_EVENTLOCATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_EVENTLOCATION](){
		this.deletingEventLocation = true;
	}
	@Mutation [Mutations.DELETE_EVENTLOCATION_SUCCESS](){
		this.deletingEventLocation = false;
	}
	@Mutation [Mutations.DELETE_EVENTLOCATION_FAILURE](){
		this.deletingEventLocation = false;
	}

	/***********************
	 * EVENT SPONSORS 
	 ***********************/
	numEventSponsors: number = 0;
	eventSponsors: EventSponsorModel[] = [];

	loadingEventSponsors: boolean = false;
	creatingEventSponsor: boolean = false;
	deletingEventSponsor: boolean = false;
	@Action async loadEventSponsors(): Promise<EventSponsorModel[]> {
		this.context.commit(Mutations.QUERY_EVENTSPONSORS);
		try {
			const options: QueryOptions = {page: 1, limitPerPage: 1000}
			const [{count},data] = await Promise.all([
				eventSponsorApi.countAll(),
				eventSponsorApi.queryAll( { fields: [] }, options ),
			]);
			this.context.commit(Mutations.QUERY_EVENTSPONSORS_SUCCESS, { count, data });
			return data.docs;
		} catch (e) {
			this.context.commit(Mutations.QUERY_EVENTSPONSORS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_EVENTSPONSORS](): void {
		this.loadingEventSponsors = true;
	}
	@Mutation [Mutations.QUERY_EVENTSPONSORS_SUCCESS]({data}: {data: PaginatedResponse<EventSponsorModel>}): void {
		this.loadingEventSponsors = false;
		this.numEventSponsors = data.total;
		this.eventSponsors = data.docs;
	}
	@Mutation [Mutations.QUERY_EVENTSPONSORS_FAILURE](error: any): void {
		this.loadingEventSponsors = false;
	}
    
	@Action async saveEventSponsor(eventSponsor: EventSponsorModel): Promise<EventSponsorModel> {
		this.context.commit(Mutations.SAVE_EVENTSPONSOR)
		try{
			const newSponsor = await eventSponsorApi.save(eventSponsor);
			this.context.commit(Mutations.SAVE_EVENTSPONSOR_SUCCESS);
			return newSponsor;
		}catch(e){
			this.context.commit(Mutations.SAVE_EVENTSPONSOR_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_EVENTSPONSOR](){
		this.creatingEventSponsor = true;
	}
	@Mutation [Mutations.SAVE_EVENTSPONSOR_SUCCESS](){
		this.creatingEventSponsor = false;
	}
	@Mutation [Mutations.SAVE_EVENTSPONSOR_FAILURE](){
		this.creatingEventSponsor = false;
	}

	@Action async deleteEventSponsor(eventSponsor: EventSponsorModel): Promise<EventSponsorModel> {
		this.context.commit(Mutations.DELETE_EVENTSPONSOR)
		try{
			const delSponsor = await eventSponsorApi.delete(eventSponsor);
			this.context.commit(Mutations.DELETE_EVENTSPONSOR_SUCCESS);
			return delSponsor;
		}catch(e){
			this.context.commit(Mutations.DELETE_EVENTSPONSOR_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_EVENTSPONSOR](){
		this.deletingEventSponsor = true;
	}
	@Mutation [Mutations.DELETE_EVENTSPONSOR_SUCCESS](){
		this.deletingEventSponsor = false;
	}
	@Mutation [Mutations.DELETE_EVENTSPONSOR_FAILURE](){
		this.deletingEventSponsor = false;
	}

	/***********************
	 * BA EVENTS
	 ***********************/
	numBAEvents: number = 0;
	baEvents: BAEventModel[] = [];
	currentEvent: BAEventModel = undefined;

	loadingBAEvents: boolean = false;
	findingEvent: boolean = false;
	creatingBAEvent: boolean = false;
	deletingBAEvent: boolean = false;
	@Action async loadBAEvents( query:RepositoryQuery<BAEventModel> = { fields:[] } ): Promise<BAEventModel[]> {
		this.context.commit(Mutations.QUERY_BAEVENTS);
		try {
			const options: QueryOptions = {page: 1, limitPerPage: 1000}
			const [{count},data] = await Promise.all([
				baEventApi.countAll(),
				baEventApi.queryAll( query, options ),
			]);
			this.context.commit(Mutations.QUERY_BAEVENTS_SUCCESS, { count, data });
			return data.docs;
		} catch (e) {
			this.context.commit(Mutations.QUERY_BAEVENTS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_BAEVENTS](): void {
		this.loadingBAEvents = true;
	}
	@Mutation [Mutations.QUERY_BAEVENTS_SUCCESS]({data}: {data: PaginatedResponse<BAEventModel>}): void {
		this.loadingBAEvents = false;
		this.numBAEvents = data.total;
		this.baEvents = data.docs;
	}
	@Mutation [Mutations.QUERY_BAEVENTS_FAILURE](error: any): void {
		this.loadingBAEvents = false;
	}
    
	@Action async findEvent(eventId: string): Promise<BAEventModel> {
		this.context.commit(Mutations.FINDBYID_BAEVENTS);
		try {
			// check the cache
			if( isNotEmptyArray(this.baEvents) ) {
				const event = this.baEvents.find(e => e.id === eventId);
				if( isNotEmpty(event) ) return event;
			}

			// load event from server
			const data = await baEventApi.findById(eventId);
			this.context.commit(Mutations.FINDBYID_BAEVENTS_SUCCESS, data);
			this.baEvents.push(data);
			return data;
		} catch (e) {
			this.context.commit(Mutations.FINDBYID_BAEVENTS_FAILURE, e);
		}
	}
	@Mutation [Mutations.FINDBYID_BAEVENTS](): void {
		this.findingEvent = true;
	}
	@Mutation [Mutations.FINDBYID_BAEVENTS_SUCCESS](data: BAEventModel): void {
		this.currentEvent = data;
		this.findingEvent = false;
	}
	@Mutation [Mutations.FINDBYID_BAEVENTS_FAILURE](error: any): void {
		this.currentEvent = undefined;
		this.findingEvent = false;
	}
    
	@Action async saveBAEvent(baEvent: BAEventModel): Promise<BAEventModel> {
		this.context.commit(Mutations.SAVE_BAEVENT)
		try{
			const newBAEvent = await baEventApi.save(baEvent);
			this.context.commit(Mutations.SAVE_BAEVENT_SUCCESS);
			return newBAEvent;
		}catch(e){
			this.context.commit(Mutations.SAVE_BAEVENT_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_BAEVENT](){
		this.creatingBAEvent = true;
	}
	@Mutation [Mutations.SAVE_BAEVENT_SUCCESS](){
		this.creatingBAEvent = false;
	}
	@Mutation [Mutations.SAVE_BAEVENT_FAILURE](){
		this.creatingBAEvent = false;
	}

	@Action async deleteBAEvent(baEvent: BAEventModel): Promise<BAEventModel> {
		this.context.commit(Mutations.DELETE_BAEVENT)
		try{
			const delBAEvent = await baEventApi.delete(baEvent);
			this.context.commit(Mutations.DELETE_BAEVENT_SUCCESS);
			return delBAEvent;
		}catch(e){
			this.context.commit(Mutations.DELETE_BAEVENT_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_BAEVENT](){
		this.deletingBAEvent = true;
	}
	@Mutation [Mutations.DELETE_BAEVENT_SUCCESS](){
		this.deletingBAEvent = false;
	}
	@Mutation [Mutations.DELETE_BAEVENT_FAILURE](){
		this.deletingBAEvent = false;
	}

}
