import { FeatureMap } from '../interfaces/FeatureMap'

export const FREE_PLAN: FeatureMap = {
	RECRUITMENT_PROFILE: true,
	TEAM_FEATURES: true,
	SELF_ASSESSMENT: true,
	YOUTUBE_VIDEO_LIMIT: 1,
	ATHLETE_VIDEO_MINUTES: 0,
	VIDEO_CLIP_LIMIT: 3,
	MINDSET_TEST: true,
	MINDSET_REPORT: false,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	SCOUTING_REPORTS: true,
	YEARLY_VERIFIED_SCOUTING_REPORTS: 0,
	SCHOOL_SEARCH: false,
	SCHOOL_SHARES: false,
	ATHLETE_COMPARITIVE_DATA: false,
	PROFILE_SHARE_LIMIT: 3,
	YEARLY_SPQ_ASSESSMENTS: 0,
};

export const BASIC_PLAN: FeatureMap = {
	RECRUITMENT_PROFILE: true,
	TEAM_FEATURES: true,
	SELF_ASSESSMENT: true,
	YOUTUBE_VIDEO_LIMIT: 1,
	ATHLETE_VIDEO_MINUTES: 0,
	VIDEO_CLIP_LIMIT: 3,
	MINDSET_TEST: true,
	MINDSET_REPORT: false,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	SCOUTING_REPORTS: true,
	YEARLY_VERIFIED_SCOUTING_REPORTS: 0,
	SCHOOL_SEARCH: false,
	SCHOOL_SHARES: false,
	ATHLETE_COMPARITIVE_DATA: false,
	PROFILE_SHARE_LIMIT: 3,
	YEARLY_SPQ_ASSESSMENTS: 0,
};

/** Showcase Plan */
export const COMPLETE_PLAN: FeatureMap = {
	RECRUITMENT_PROFILE: true,
	TEAM_FEATURES: true,
	SELF_ASSESSMENT: true,
	YOUTUBE_VIDEO_LIMIT: 3,
	ATHLETE_VIDEO_MINUTES: 0,
	VIDEO_CLIP_LIMIT: null,
	MINDSET_TEST: true,
	MINDSET_REPORT: false,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	SCOUTING_REPORTS: true,
	YEARLY_VERIFIED_SCOUTING_REPORTS: 0,
	SCHOOL_SEARCH: true,
	SCHOOL_SHARES: true,
	ATHLETE_COMPARITIVE_DATA: false,
	PROFILE_SHARE_LIMIT: 100,
	YEARLY_SPQ_ASSESSMENTS: 0,
};
/** Showcase Plan United States */
export const COMPLETE_PLAN_US: FeatureMap = {
	...COMPLETE_PLAN,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
};

/** Elite Plan */
export const PREMIUM_PLAN: FeatureMap = {
	RECRUITMENT_PROFILE: true,
	TEAM_FEATURES: true,
	SELF_ASSESSMENT: true,
	YOUTUBE_VIDEO_LIMIT: 50,
	ATHLETE_VIDEO_MINUTES: 60 * 7, // 7 Hours
	VIDEO_CLIP_LIMIT: null,
	MINDSET_TEST: true,
	MINDSET_REPORT: true,
	YEARLY_VERIFIED_ASSESSMENTS: 0,
	SCOUTING_REPORTS: true,
	YEARLY_VERIFIED_SCOUTING_REPORTS: 0,
	SCHOOL_SEARCH: true,
	SCHOOL_SHARES: true,
	ATHLETE_COMPARITIVE_DATA: true,
	PROFILE_SHARE_LIMIT: null,
	YEARLY_SPQ_ASSESSMENTS: 0,
};
/** Elite Plan United States */
export const PREMIUM_PLAN_US: FeatureMap = {
	...PREMIUM_PLAN,
};