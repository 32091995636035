import { FrontEndModel } from '../FrontEndModel';
import { Action } from '@/../types/interfaces';
import { ActionType } from '@/../types/enums';
import { api } from '@/api/api';
import paramResolver from './ParamResolver';
import { notificationStore } from '@/store';
import rootStore from '@/store/store';
import { NotificationModel } from '../notification/NotificationModel';
import router from '@/router/router';

export class ActionableModel extends FrontEndModel {
	action: Action<NotificationModel>;
	expiryDate: Date | null;
	used: boolean;
	onetime: boolean;

	get HttpMethod(): string{
		if(this.action.method){
			return this.action.method.toLowerCase();
		}
		return null;
	}

	get SuccessMessage(): NotificationModel | null{
		if(!this.action.messages){
			return null;
		}
		return this.action.messages.success;
	}

	async exec(): Promise<{success: boolean}>{
		try{
			switch (this.action.type) {
			case ActionType.Http:
				if(this.action.action !== null){
					const action = paramResolver.replaceAllInString(this.action.action, { 'actionId': () => this.id });
					const payload = paramResolver.replaceAllInObject(this.action.payload ?? {}, { 'actionId': () => this.id });
					await api(a => a[this.HttpMethod](action, payload));
				}
				this.pushSuccessNotification();
				await this.runSuccessActions();
				return { success: true };
			case ActionType.VueRouter:
				router.push({
					name: this.action.action,
					params: this.action.payload
				})
			}
		}catch(e){
			console.error("Failed to Exec HTTP Action", e);
			this.pushFailureNotification();
			await this.runFailureActions();
		}
		return { success: false };
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (this.action && this.action.messages){
			if(this.action.messages.success !== null){
				this.action.messages.success = new NotificationModel().load(this.action.messages.success);
			}
			if(this.action.messages.failure !== null){
				this.action.messages.failure = new NotificationModel().load(this.action.messages.failure);
			}
		}
		if(this.expiryDate) this.expiryDate = new Date(this.expiryDate);
		return this;
	}

	pushSuccessNotification(): void{
		if(!this.action.messages) return;
		if(this.action.messages.success === null) return;
		return this.pushNotification(this.action.messages.success);
	}
	pushFailureNotification(): void{
		if(!this.action.messages) return;
		if(this.action.messages.failure === null) return;
		return this.pushNotification(this.action.messages.failure);
	}

	pushNotification(notification: NotificationModel): void{
		notificationStore.pushLocalNotification(notification);
	}

	async runSuccessActions(): Promise<void>{
		if(!this.action.dispatch) return;
		if(this.action.dispatch.success === null) return;
		return await this.runActions(this.action.dispatch.success);
	}
	async runFailureActions(): Promise<void>{
		if(!this.action.dispatch) return;
		if(this.action.dispatch.failure === null) return;
		return await this.runActions(this.action.dispatch.failure);
	}

	async runActions(actions: {key: string, payload: any}[]): Promise<void>{
		for (const action of actions){
			let payload = null;
			if (typeof action.payload === 'string') {
				payload = paramResolver.replaceAllInString(action.payload);
			} else if (typeof action.payload === 'object') {
				payload = paramResolver.replaceAllInObject(action.payload);
			} else if (typeof action.payload === 'number') {
				payload = action.payload;
			}
			await rootStore.dispatch(action.key, payload);
		}
	}
}