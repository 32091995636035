
import { Component, Mixins, Watch, PropSync, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { TeamModel } from '@/models/team/TeamModel';
import { teamApi } from '@/api/TeamApi';
import { DebounceMixin } from '@/mixins';

@Component
export default class AdminTeamAutocomplete extends Mixins(DebounceMixin, VuetifyMixin){
	search: string = '';

	@PropSync('value') Value: TeamModel | null;
	@Prop({ default: "Teams" }) label: string;
	@Prop({ default: "Start typing to search" }) placeholder: string;
	@Prop({ default: 8 }) limit: number;
	@Prop({ type: Boolean }) dense;
	teamItems: TeamModel[] = [];

	searchLoading: boolean = false;
	@Watch('search') debounceSearchTeams(): void {
		this.searchLoading = true;
		this.debounceCallback('searchTeams', this.searchTeams.bind(this), 150);
	}

	async searchTeams(): Promise<void> {
		this.searchLoading = true;
		try {
			const teamSearch = await teamApi.queryAll({
				search: this.search,
				fields: [
					'name',
				]
			},{
				limitPerPage: this.limit,
			});
			this.teamItems = teamSearch.docs;
		} catch(e) {
			console.error('Failed to query teams', e);
		}finally{
			this.searchLoading = false;
		}
	}
}
