import { AccessControlledModel } from '../AccessControlledModel';
import { StatisticType, Gender, DevelopmentalAge, PerformanceCategory, AthleteMetric } from '@/../types/enums';
import { AgeGroup } from '@best-athletes/ba-types';
import { GlobalStats } from '@/../types/interfaces';

export class StatisticModel extends AccessControlledModel implements GlobalStats {
	gender: Gender = Gender.Male;
	ageGroup: AgeGroup = AgeGroup.O20;
	developmentalAge: DevelopmentalAge = DevelopmentalAge.Average;
	performanceCategory: PerformanceCategory = PerformanceCategory.Recreational;
	acceleration: number = -1;
	speed: number = -1;
	power: number = -1;
	agility: number = -1;
	recovery: number = -1;
	public type: StatisticType = StatisticType.Standard;

	getMetricValue(metric: AthleteMetric): number{
		return this[metric];
	}
}