import { AthleteAssessmentDataModel } from '@/models';

class Sprint {
	trial: number;
	tenMeters: number;
	twentyMeters: number;
	thirtyFiveMeters: number;

	constructor(trial) {
		this.trial = trial;
	}
}

class Jump {
	trial: number;
	cmjHeight: number;
	djContactTime: number;
	djHeight: number;

	constructor(trial) {
		this.trial = trial;
	}
}

export class EventAssessmentDataModel extends AthleteAssessmentDataModel {
	checkedIn: boolean = false;
	idcolor: string;
	idnumber: string;
	shortId: string;
	firstName: string;
	lastName: string;
	email: string;
	eventId: string;
	status: string;
	eventbriteTicketClassName: string;
	notes: string;
	sprints: Array<Sprint> = [new Sprint(1), new Sprint(2)];
	jumps: Array<Jump> = [new Jump(1)];

	get FullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}
	addSprint() {
		this.sprints.push(new Sprint(this.sprints.length + 1));
	}
	addJump() {
		this.jumps.push(new Jump(this.jumps.length + 1));
	}
	isBestSprint(sprint: Sprint): boolean {
		const fastest = this.BestSprint;
		if( !fastest || !sprint ) return false;

		return( sprint.thirtyFiveMeters <= fastest.thirtyFiveMeters );
	}
	get BestSprint(): Sprint {
		var fastest = undefined;
		for( const s of this.sprints ) {
			if( !s.thirtyFiveMeters ) continue;
			if( s.thirtyFiveMeters <= 0 ) continue;

			if( !fastest || ( s.thirtyFiveMeters < fastest.thirtyFiveMeters ) ) {
				fastest = s;
			}
		}
		return fastest;
	}
	get BestSpeed(): string {
		const sprint = this.BestSprint;
		if( !sprint ) return undefined;

		this.speed = ( 15.0 / ( sprint.thirtyFiveMeters - sprint.twentyMeters ) );
		return this.speed.toFixed(2);
	}
	get BestAcceleration(): string {
		const sprint = this.BestSprint;
		if( !sprint ) return undefined;

		this.acceleration = ( 10.0 / sprint.tenMeters );
		return this.acceleration.toFixed(2);
	}
	get Fastest35m(): string {
		const sprint = this.BestSprint;
		if( !sprint ) return undefined;
		if( !sprint.thirtyFiveMeters ) return undefined;

		return sprint.thirtyFiveMeters.toFixed(2);
	}

	isBestCMJ(jump: Jump): boolean {
		const highest = this.BestCounterMovementJump;
		if( !highest || !jump ) return false;

		return( jump.cmjHeight >= highest.cmjHeight );
	}
	get BestCounterMovementJump(): Jump {
		var highest = undefined;
		for( const j of this.jumps ) {
			if( !j.cmjHeight ) continue;
			if( j.cmjHeight <= 0 ) continue;

			if( !highest || ( j.cmjHeight > highest.cmjHeight ) ) {
				highest = j;
			}
		}
		return highest;
	}
	get BestPower() {
		const cmj = this.BestCounterMovementJump;
		if( !cmj ) return undefined;

		this.power = (60.7 * this.mass + 45.3 * cmj.cmjHeight - 2055)
		return this.power.toFixed(2);
	}
}

