// TeamAthletesMixin.ts

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { StringsMixin, AppHostnameMixin } from "@/mixins";
import { TeamAthleteProgress, TeamModel } from '@/models/team';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { athleteApi } from '@/api/AthleteApi';
import { QueryOptions } from '@/../types/interfaces';

/**
 * Provides computed props to display information on a team
 */
@Component
export class TeamAthletesMixin extends Mixins(StringsMixin, AppHostnameMixin) {
	@Prop({ required: true }) team: TeamModel;

	teamAthletes: TeamAthleteProgress[] = [];
	get TeamAthletes(): Array<TeamAthleteProgress> {
		return this.teamAthletes;
	};
	FilteredTeamAthletes(options: QueryOptions = {page: 1, limitPerPage: 10}) {
		// const sortedArray = options.sort
		// 	? this.teamAthletes.slice().sort((a, b) => {
		// 		// Implement custom sorting logic based on `QuerySortOptions` here
		// 		// This example assumes `sort` has a `field` and `direction` property
		// 		return options.sort.direction === 'asc'
		// 		? (a[options.sort.field] as any) - (b[options.sort.field] as any)
		// 		: (b[options.sort.field] as any) - (a[options.sort.field] as any);
		// 	})
		// 	: array; // Return original array if no sorting
		
		// sort athletes
		const sortedAthletes = this.teamAthletes;

		// apply pagination
		const startIndex = options.page? (options.page - 1) * options.limitPerPage! : 0;
		const endIndex = startIndex + options.limitPerPage!;
		const filteredAthletes = sortedAthletes.slice(startIndex, endIndex);
		
		return filteredAthletes;
	}

	loadingAthletes = false;
	async loadTeamAthletes() {
		if( this.IsEmpty(this.team) ) return;

		this.loadingAthletes = true;
		this.teamAthletes = [];
		for( const player of this.team.players ) {
			let athleteProgress: TeamAthleteProgress = {...player, 
				shortId: undefined,
				hasPhoto: false,
				hasEmail: false,
				hasPhone: false,
				hasInsta: false,
				hasTwitter: false,
				hasBio: false,
				hasDOB: false,
				hasHeight: false,
				hasWeight: false,
				hasDominant: false,
				hasLocation: false,
				hasHighlights: false,
				hasGradYear: false,
				hasGPA: false,
				hasSAT: false,
				hasVerifiedAssessment: false,
				mindsetArchetype: undefined,
				hasScoutingReport: false,
				commitment: undefined,
			};
			if( this.IsNotEmpty(player) && this.IsNotEmpty(player.athleteId) ) {
				const recruitingProfile: RecruitingProfileModel = await athleteApi.getAthleteRecruitingProfile(player.athleteId, {as : this.getAppType} );
				const athleteProfile: AthleteProfileModel = recruitingProfile?.athleteProfile;
				athleteProgress = athleteProfile.InitProgress(recruitingProfile);
			}
			this.teamAthletes.push(athleteProgress);
		}
		this.loadingAthletes = false;
	}
}
