import { FrontEndModel } from '@/models/FrontEndModel';
import { isNotEmpty } from '@/pipes';

export class BALocationModel extends FrontEndModel {
    name!: string;
    description!: string;
    address!: string;
    city!: string;
    state!: string;
    country!: string;
    postal_code!: string;
    url?: string;
    latitude?: number;
    longitude?: number;

    get province(): string { return this.state }
    set province(value: string ) { this.state = value }
    get zip(): string { return this.postal_code }
    set zip(value: string) { this.postal_code = value }

    GoogleMap(search: string = 'location'): string {
        var mapUrl = `https://maps.google.com/?q=`;
        if( isNotEmpty(search) ) mapUrl += search;
        if( isNotEmpty(this.address) ) mapUrl += '+' + this.address;
        if( isNotEmpty(this.city) ) mapUrl += '+' + this.City;
        if( isNotEmpty(this.state) ) mapUrl += '+' + this.State;
        if( isNotEmpty(this.country) ) mapUrl += '+' + this.Country;
        if( isNotEmpty(this.postal_code) ) mapUrl += '+' + this.PostalCode;
        return mapUrl;
    }

    get City(): string {
        if( !this.city ) return ''
        return this.city;
    }
    get State(): string {
        if( !this.state ) return ''
        return this.state;
    }
    get Province(): string {
        if( !this.province ) return ''
        return this.province;
    }
    get Country(): string {
        if( !this.country ) return ''
        return this.country;
    }
    get PostalCode(): string {
        if( !this.postal_code ) return ''
        return this.postal_code;
    }
    get Location(): string {
        if( this.city ) {
            if( this.state ) {
                if( this.country ) return `${this.city}, ${this.state}, ${this.country}`;
                return `${this.city}, ${this.state}`;
            } else if( this.country ) {
                return `${this.city}, ${this.country}`;
            }
            return `${this.city}`;
        }
        else {
            if( this.state ) {
                if( this.country ) return `${this.state}, ${this.country}`;
                return `${this.state}`;
            } else if( this.country ) {
                return `${this.country}`;
            }
        }
        return ``;
    }
}