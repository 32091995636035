
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { HelpInfoMixin, BAIconsMixin } from '@/mixins';

@Component
export default class HelpInfo extends Mixins(HelpInfoMixin, BAIconsMixin){
    @Prop({ type: Boolean }) noicon: boolean;
    @Prop() preText: string;
    @Prop() postText: string;
    @Prop() linkText: string;

    onHelpHide() {
        this.value = !this.value;
        this.onToggleHelp();
    }
    
}
