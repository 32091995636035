import {AccessControlledModel} from '../AccessControlledModel';
import {SpqDataAthlete} from '@/../types/interfaces';
import {Gender} from '@/../types/enums';

export class SpqDataModel extends AccessControlledModel implements SpqDataAthlete {
	athleteId?: string;
	dateCreated: Date = new Date();

	// The month/year the SPQ20 was completed:
	// Day of month is set to first of month
	date: Date; //  16 Jul,'20
	// The age of the athlete completing the SPQ20
	age: number = -1;
	// The gender of the athlete
	gender: Gender;
	// The continent the athlete is registered from
	name: string = '';
	email: string = '';
	ref: string = '';

	// calculated scores based on SPQ scale data
	// scales 1, 5, 7, 9, 11, 19, and 20
	scoreAchievementCompetitiveness: number = -1;
	// scales 3, 4, 10, 14, 15, 16, 17
	scoreConfidenceResilience: number = -1;
	// scales 8, 12, and 13.
	scoreInteractionSportsmanship: number = -1;
	// scales 2 and 6
	scorePowerAggressiveness: number = -1;

	setAthleteId(athleteId: string){
		// @TODO: How to handle if athleteId already exists? Allow override?
		this.athleteId = athleteId;
	}



	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		if (obj['date']) this.date = new Date(obj['date']);
		return this;
	}

	get Gender(): Gender {
		if (this.gender.toUpperCase() === 'F' || this.gender === Gender.Female) {
			return Gender.Female;
		} else if (this.gender.toUpperCase() === 'M' || this.gender === Gender.Male) {
			return Gender.Male;
		}
		console.warn("Failed to determine Gender from assessment data", { 'assessmentData.gender': this.gender });
		return Gender.Male;
	}
}