
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from "@/mixins";
import { TeamModel } from '@/models/team';

@Component({})
export default class TeamRecord extends Mixins(VuetifyMixin) {
	@Prop({ required: true }) team: TeamModel;
	@Prop({ default: false }) loading: boolean;
}
