
import { Component, Prop, Vue,Mixins } from 'vue-property-decorator';
import { FileRecordModel } from '../../models/file/FileRecordModel';
import { FileRecordApi } from '../../api/FileRecordApi';
import {BAIconsMixin} from '@/mixins';
@Component
export default class AttachmentUploadItem extends Mixins(BAIconsMixin){
	@Prop() apiPrefix: string;
	@Prop() parentId: string;
	@Prop({ type: Boolean, default: false }) disabled: string;
	@Prop({ required: true }) file: FileRecordModel;
	updateFile(file: FileRecordModel): void{
		this.file = file;
		this.$emit('update:file', file);
	}

	get HasLocalFile(): boolean{
		return this.file.file !== null;
	}

	get Api(): FileRecordApi{
		return new FileRecordApi(this.apiPrefix);
	}

	public async uploadFile(parentId?: string): Promise<FileRecordModel>{
		if(!this.file.IsUploaded && this.file.file !== null){
			if(parentId !== undefined){
				this.file.parentId = parentId;
			}
			const newFile = await this.Api.upload(this.file.file, (event) => {
				this.uploadProgress = ~~((event.loaded / event.total) * 100);
			});
			this.uploadProgress = 100;
			this.$emit('upload-complete');
			return newFile;
		}
	}

	private uploadProgress: number = 0;

	get FileName(): string{
		return this.file.name;
	}
	get UploadProgress(): number{
		if(this.file.IsUploaded){
			return 100;
		}else{
			return this.uploadProgress;
		}
	}
	get UploadComplete(): boolean{
		return this.uploadProgress === 100;
	}

	private async downloadFile(): Promise<void> {
		if(this.file.id){
			console.log("downloadFile");
			const blob = await this.Api.downloadFileById(this.file.id);
			let dl = document.createElement('a');
			dl.href = window.URL.createObjectURL(blob);
			dl.download = this.file.name;
			dl.click();
			document.removeChild(dl);
		}
	}

	deleteLoading: boolean = false;
	private async deleteFile(): Promise<void> {
		this.deleteLoading = true;
		if(this.file.id !== undefined){
			try{
				await this.Api.delete(this.file);
			}catch(e){
				console.error("Failed to Delete File", e);
			}finally{
				this.deleteLoading = false;
			}
		}
		this.$emit('delete');
	}
}
