
import { Component, Prop, Mixins } from 'vue-property-decorator';
import ImageUploadDialog from '../profile/ImageUploadDialog.vue';
import { ImageMimeTypesValues } from '@/../types/enums';
import { BAIconsMixin } from '@/mixins';

@Component({
	components: { ImageUploadDialog },
})
export default class ProfilePictureUpload extends Mixins(BAIconsMixin) {
	showImageUploadDialog: boolean = false;

	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Number, default: 125 }) size: number;
	@Prop({ type: Boolean, default: false }) hideDetails: boolean;
	@Prop() pictureUrl: string;
	updatePicture(url: string): void{
		this.pictureUrl = url;
		this.$emit('update:pictureUrl', url);
	}

	get AvatarColor(): string{
		if(this.pictureUrl){
			return 'baColorVibrantBlue';
		}
		return 'baColorGray8';
	}

	get AllowedMimeTypesSentence(): string{
		const len = ImageMimeTypesValues.length;
		return ImageMimeTypesValues
			.map(this.prettifyMimeType)
			.slice(0, len - 1)
			.join(', ') + ' and ' + this.prettifyMimeType(ImageMimeTypesValues[len-1]);
	}

	prettifyMimeType(t: string){
		return t.slice(t.indexOf('/')+1).toUpperCase();
	}
}
