/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Vue } from 'vue-property-decorator';

@Component
export class FormRulesMixin extends Vue {
	urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

	rulesOptionalEmail = [
		(v: any): string | boolean => /(.+@.+\.[a-z,A-Z]|^$)/.test(v) || 'E-mail must be valid',
	];
	rulesEmail = [
		(v: any): string | boolean => !!v || 'E-mail is required',
		(v: any): string | boolean => /.+@.+\.[a-z,A-Z]/.test(v) || 'E-mail must be valid',
	];
    rulesPassword = [
		(v: any): string | boolean => !!v || 'Password is required',
		(v: any): string | boolean => (v && v.length >= 8) || 'Password must be at least 8 characters long'
	];
	rulesPasswordConfirm(password: string) {
		return [
			(v: any): string | boolean => !!v || 'Password confirmation is required',
			(v: any): string | boolean => (v && v === password) || 'Passwords do not match',
		]
	}
	rulesURL = [
		(v: any): string | boolean => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(v) || 'URL must be valid',
	];
	rulesRequired = [
		(v: any): string | boolean => !!v || 'Required',
	];
	rulesOptionalGender = [
		(v: any): string | boolean => !!v || 'Required',
	];
	rulesRequiredNumberGT0 = [
		(v: number): boolean | string => (+v > 0) || 'Number required > 0',
	];
	rulesRequiredNumberGTEQ0 = [
		(v: number): boolean | string => (+v >= 0) || 'Number required',
	];
	rulesRequiredNumber1to100 = [
		(v: any): string | boolean => !!v || 'Required',
		(v: number): boolean | string => (+v > 0) || 'Number cannot be lower than 1',
		(v: number): boolean | string => (+v <= 100) || 'Number cannot be higher than 100',
	];

	isValidUrl(url: string) {
		if( !url ) return false;
		if( !url.includes('http') && !url.includes('www')) return false;
		return !!url.match(this.urlRegex);
	}
}
