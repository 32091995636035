import { render, staticRenderFns } from "./AppSwitcher.vue?vue&type=template&id=fd21dda8&scoped=true&"
import script from "./AppSwitcher.vue?vue&type=script&lang=ts&"
export * from "./AppSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd21dda8",
  null
  
)

export default component.exports