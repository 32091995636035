
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAPartnerModel } from '@/models';
import { AuthMixin, BAIconsMixin, MyAthleteMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export default class PartnerCard extends Mixins(BAIconsMixin, StringsMixin, VuetifyMixin, MyAthleteMixin, AuthMixin) {
    @Prop({required: true}) partner: BAPartnerModel;
    @Prop({type: Boolean}) mini;

    get IsLoading(): boolean {
        if( this.IsAthlete && this.IsAthleteLoading ) return true;
        return !this.IsReady;
    }
    get IsReady(): boolean {
        if( this.IsAthlete && !this.AthletesReady ) return false;
        return this.IsNotEmpty(this.partner);
    }

    get ProductCardColWidth(): string {
        if( this.mini ) return '12';
        if( this.partner.NumProducts == 1 ) return '12';
        if( (this.partner.NumProducts % 2) == 0 ) return '6';
        return '4';
    }

    HasProductPurchase(productId: string) {
        return this.TheAthleteProfile.HasProductPurchase(productId);
    }

    gotoPartnerWebsite() {
        if( this.IsEmpty(this.partner.website) ) return;

        window.open(this.partner.website, "_blank");
    }
}
