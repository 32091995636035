// BAPartnerModel.ts

import { FrontEndModel } from '@/models/FrontEndModel';
import { isEmpty, isNotEmpty, isZero } from '@/pipes';

export class BAPartnerProductPrice extends FrontEndModel {
    name!: string;
    price?: number;
    stripeId?: string;
    deleted: boolean = false;

    deepcopy(): BAPartnerProductPrice {
        return new BAPartnerProductPrice().load(this);
    }

    get isSale(): boolean {
        return this.name.toLowerCase().includes('sale');
    }
}

export class BAPartnerProduct extends FrontEndModel {
    name!: string;
    imageUrl?: string;
    description?: string;
    buttonAction?: string;
    prices: Array<BAPartnerProductPrice> = [];
    stripeId?: string;
    dataLabels: Array<string> = ['First Name', 'Last Name', 'Email', 'Comments'];
    deleted: boolean = false;

    get HasPrices(): boolean {
        return isNotEmpty(this.prices);
    }
    get PricesStr(): string {
        if( isEmpty(this.prices) ) return 'n/a';

        return this.prices.filter(p => !p.deleted).map(p => `${p.name}:${p.price}`).join(', ');
    }
    get Price(): BAPartnerProductPrice {
        if( !this.HasPrices ) return undefined;

        var price: BAPartnerProductPrice = undefined;
        for( const p of this.prices ) {
            if( !p.deleted ) {
                if( price === undefined ) {
                    price = p;
                } else {
                    if( p.isSale ) price = p;
                }
            }
        }
        return price;
    }
    get IsFree(): boolean {
        const price = this.Price;
        if( !price ) return false;
        return isZero(price.price );
    }

    load(obj: Partial<BAPartnerProduct>): this {
        Object.assign(this, obj);

        if( obj['prices'] ) {
            this.prices = obj.prices.map(p => new BAPartnerProductPrice().load(p));
        }
        return this;
    }

    deepcopy(): BAPartnerProduct {
        return new BAPartnerProduct().load(this);
    }
}

export class BAPartnerModel extends FrontEndModel {
    name!: string;
    icon?: string;
    title?: string;
    subtitle?: string;
    logoUrl?: string;
    imagesUrl?: Array<string> = [];
    tagline?: string;
    description?: string;
    website?: string;
    products: Array<BAPartnerProduct> = [];

    get HasProducts(): boolean {
        return isNotEmpty(this.products);
    }
    get NumProducts(): number {
        if( isEmpty(this.products) ) return 0;
        return this.products.length;
    }

    load(obj: Partial<BAPartnerModel>): this {
        Object.assign(this, obj);
        if( obj['products'] ) {
            this.products = obj.products.map(p => new BAPartnerProduct().load(p));
        }
        return this;    
    }
    deepcopy(): BAPartnerModel {
        return new BAPartnerModel().load(this);
    }
}
