// SocialMediaMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { FormRulesMixin } from "@/mixins";
import { SocialMedia } from "@/../types/enums";
import { SocialMediaField } from '@/../types/interfaces';
import { mdiFacebook, mdiLinkedin, mdiTwitter, mdiInstagram, mdiWeb } from "@mdi/js";

@Component
export class SocialMediaMixin extends Mixins(FormRulesMixin) {
	get SocialMediaColorAndIcon(): Partial<Record<SocialMedia, {icon: string, color: string, size:string, style: string}>> {
		return {
			"Facebook": { icon: mdiFacebook, color: "#4267b2" , size: 'large', style: ""},
			"Instagram": { icon: mdiInstagram, color: "#e1306c", size: 'large', style: ""},
			"LinkedIn": { icon: mdiLinkedin, color: "#0075b5", size: 'large', style: ""},
			"Twitter": { icon: mdiTwitter, color: "#1DA1F2", size: 'large', style: ""},
			"TikTok": { icon: '$vuetify.icons.tiktok' as any, color: "#010101", size: 'large', style: "width:30px" },
			"Website": { icon: mdiWeb, color: "#005AA8", size: 'large', style:"" },
		}
	}

	socialIcon(platform: SocialMedia) {
		const ic = this.SocialMediaColorAndIcon[platform];
		if( !ic ) return 'mdi-web';
		return ic.icon;
	}

	socialLink(social: SocialMediaField) {
		if( this.isValidUrl(social.link) ) return social.link;

		switch(social.platform) {
			case SocialMedia.Facebook:
				return `https://www.facebook.com/${social.link}`;
			case SocialMedia.Instagram:
				return `https://www.instagram.com/${social.link}`;
			case SocialMedia.LinkedIn:
				return `https://www.linkedin.com/in/${social.link}`;
			case SocialMedia.Twitter:
				return `https://twitter.com/${social.link}`;
			case SocialMedia.TikTok:
				return `https://www.tiktok.com/${social.link}`;
			case SocialMedia.Website:
				return social.link;
		}

		return social.link;
	}

}
