// AthleteEditMixin.ts

import { Component, Mixins, Vue } from 'vue-property-decorator';
import { PatchPlayerParams } from '@/store/teams/TeamDashboard.store';
import { teamDashboardStore } from '@/store';
import { PlayerOnTeam } from '@/models/team';
import { SportsSelectorMixin } from './SelectorMixins';

@Component
export class AthleteEditMixin extends Mixins(SportsSelectorMixin) {
	editingPlayer: boolean = false;
	editPlayer: PlayerOnTeam;
	backupNumber: string;
	onEditPlayer(editPlayer: PlayerOnTeam) {
		this.editingPlayer = true;
		this.editPlayer = editPlayer;
		this.backupNumber = this.editPlayer.number;
	}
	onCancelEditPlayer() {
		this.editPlayer.number = this.backupNumber;
		this.editingPlayer = false;
	}
	async onAcceptEditPlayer(teamId: string) {
		await this.patchPlayer(this.editPlayer.id, teamId, this.editPlayer);
		this.editingPlayer = false;
		this.editPlayer = undefined;
	}

    async patchPlayer(playerId: string, teamId: string, playerPatch: Partial<PlayerOnTeam>) {
        const params: PatchPlayerParams = {
            teamId,
            playerId,
            playerPatch,
        };
        await teamDashboardStore.patchPlayer(params);
	}
}