import { isEmpty } from '@/pipes';
import { AccessControlledModel } from './AccessControlledModel';

export class UserProfileModel extends AccessControlledModel {
	shortId: string;
	authIds: string[] = [];
	firstName: string = "";
	lastName: string = "";
	email: string = "";
	phoneNumber: string = "";
	pictureUrl: string = "";
	completedProfile: boolean = false;
	athleteId?: string | null;
	/**
	 * Self reported Club/Org name from onboarding
	 */
	fromOrganization: string = "";

	/**
   * Combines firstName and lastName
   */
	autocompleteDiscoverable: boolean = true;
	get FullName(): string {
		if( isEmpty(this.lastName) ) {
			return this.firstName;
		}
		return `${this.firstName} ${this.lastName}`;
	}
}
