import { CrudApi } from './CrudApi';
import { AxiosRequestConfig } from "axios";
import { BASchoolModel } from '../models/baSchool/BASchoolModel';
import { CoachInfo } from '@/../types/interfaces/CoachInfo';

class BASchoolApi extends CrudApi<BASchoolModel>{
	constructor() {
		super('baSchool', (obj) => new BASchoolModel().load(obj));
	}
	
	async updateBASchools(baSchools: BASchoolModel[], config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/all-baSchools`, {baSchools}, config));
	}
	async updateBASchoolsLogo(baSchoolsLogo: BASchoolModel[], config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/logos`, {baSchoolsLogo}, config));
	}
	async updateAllCoaches(coaches: CoachInfo[], config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/all-coaches`, {coaches}, config));
	}
	async cleanup(config?: AxiosRequestConfig): Promise<void> {
		return await CrudApi.Api(c => c.post(`/${this.resource}/cleanup`, {}, config));
	}
}
export const baSchoolApi = new BASchoolApi();