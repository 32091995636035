
import { Component, Prop, Mixins } from 'vue-property-decorator';
import NewDepthChartForm from '../teams/DepthChart/NewDepthChartForm.vue';
import NewLineupForm from '../teams/NewLineupForm.vue';
import EditPositionOverlay from './DepthChart/EditPositionOverlay.vue';
import DepthChart from '../teams/DepthChart/DepthChart.vue';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import TeamRoster from './TeamRoster.vue';
import { TeamModel } from '../../models/team';
import { teamDashboardStore } from '../../store';
import { PatchPlayerParams } from '../../store/teams/TeamDashboard.store';
import { DepthChartPosition } from '../../models/depthChart';
import { CreateDepthChartFormData } from '@/../types/interfaces';
import Lineup from '../teams/Lineup.vue';
import TeamLineupSummary from '../teams/TeamLineupSummary.vue';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { LineupModel } from '@/models/lineup';
import { TeamEventType } from '@/../types/enums';


@Component({
	components: {
		DepthChart,
		EditPositionOverlay,
		NewDepthChartForm,
		TeamRoster,
		NewLineupForm,
		TeamLineupSummary,
		Lineup,
	},
})
export default class PlayerManagement extends Mixins(VuetifyMixin){

	get HasDepthChart(): boolean{
		return this.team.depthCharts.length > 0;
	}

	@Prop({ required: true }) team: TeamModel;
	/** Team Event for Lineup Creation */
	@Prop({ default: null }) teamEvent: CalendarEventModel | null;
	@Prop({ default: null }) newLineup: LineupModel | null;
	updateNewLineup(lineup: LineupModel): void{
		this.$emit('update:new-lineup', lineup);
	}
	@Prop({ default: [] }) teamEvents: CalendarEventModel[];
	@Prop({ type: Boolean, default: false }) readOnly: boolean;
	@Prop({ type: Boolean, default: false }) viewLineupMode: boolean;
	@Prop({ default: 'baColorDeepBlue' }) color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) sliderColor: string;
	@Prop({ default: [
		'depthCharts',
		'roster',
		'lineups',
	] }) private tabs: string[];

	get ShowDepthCharts(): boolean{
		return this.tabs.includes('depthCharts');
	}
	get ShowRoster(): boolean{
		return this.tabs.includes('roster');
	}
	get ShowLineups(): boolean{
		return this.tabs.includes('lineups');
	}

	get UseEmptyStyle(): boolean{
		return this.selectedTab === 1 && this.NoDepthCharts;
	}
	get SheetStyle(){
		if(this.UseEmptyStyle){
			return {
				'border': `1px dashed ${this.getColor('baColorGray4')}`,
				'background-color': this.getColor('baColorOffWhite'),
			};
		}else{
			return {};
		}
	}
	get SheetElevation(){
		return this.UseEmptyStyle ? 0 : 1;
	}
	get NoDepthCharts(){
		return this.team.depthCharts.length === 0;
	}
	get AddDepthChart(){
		return this.addDepthChart;
	}
	
	selectedTab: number = 0;
	addDepthChart: boolean = false;
	get AddDepthChartLoading(){
		return teamDashboardStore.createDepthChartLoading;
	}
	async createDepthChart(depthChartForm: CreateDepthChartFormData){
		await teamDashboardStore.createDepthChart({
			teamId: this.team.id,
			depthChartForm,
		}).then(() => {
			this.addDepthChart = false;
		});
	}

	get Lineups(): CalendarEventModel[]{
		return this.teamEvents.filter(x => x.eventType === TeamEventType.Game || x.eventType === TeamEventType.Practice)
	}

	get SelectedTeamPlayers(){
		return this.team.players;
	}
	get DepthChartEditPositionData(){
		return teamDashboardStore.editPositionData;
	}

	async savePosition(positionData: DepthChartPosition){
		await teamDashboardStore.saveDepthChartPosition({
			depthChartId: teamDashboardStore.editDepthChartId,
			position: positionData
		});
	}

	async cancelEdit(){
		await teamDashboardStore.cancelDepthChartPosition();
	}

	get DepthCharts(){
		return teamDashboardStore.team.depthCharts;
	}

	get AddLineup(){
		return this.showAddLineup;
	}
	@Prop({ type: Boolean, default: false }) showAddLineup: boolean = false;
	updateAddLineup(value: boolean): void{
		this.showAddLineup = value;
		this.$emit('update:show-add-lineup', value);
	}
}
