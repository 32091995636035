import { getEnumValues } from './get-enum-values';

export enum DocMimeTypes{
	TXT='text/plain',
	PDF='application/pdf',
}

export enum ImageMimeTypes{
	PNG='image/png',
	JPEG='image/jpeg',
	GIF='image/gif',
	SVG='image/svg+xml',
}

export const DocMimeTypesValues = getEnumValues<DocMimeTypes>(DocMimeTypes);
export const ImageMimeTypesValues = getEnumValues<ImageMimeTypes>(ImageMimeTypes);
