
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CrudApi } from '../../api/CrudApi';
import { UserInfo } from '@/../types/interfaces';
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class UserAclInfoProvider extends Vue{
	@Prop({ default: 'div' }) private as: string;

	@Prop({ required: true }) private resource: string;
	@Prop({ required: true }) private documentId: string;
	@Prop({ required: true }) private userId: string;

	loading: boolean = true;
	foundUser: boolean = false;
	userInfo: UserInfo;

	get SlotProps(): Record<string, any>{
		return {
			loading: this.loading,
			foundUser: this.foundUser,
			userInfo: this.userInfo,
		};
	}

	created(): void{
		if(this.resource === undefined || this.documentId === undefined || this.userId === undefined){
			console.error("Missing Required props for UserInfo Lookup!");
			return;
		}
		this.lookupUserInfo({
			resource: this.resource,
			documentId: this.documentId,
			userId: this.userId,
		}).catch(e => {
			console.error("Failed to look up profile picture", e);
		});
	}

	async lookupUserInfo({resource,documentId,userId}: {
		resource: string, documentId: string, userId: string,
	}): Promise<void>{
		if(userId.startsWith('tmp_')){
			this.foundUser = false;
			this.loading = false;
			return;
		}
		this.loading = true;
		try{
			const userInfo: UserInfo = await CrudApi.Api(a => a.get(`/${resource}/${documentId}/userLookup/${userId}`));
			const { firstName, lastName } = userInfo;
			var fullName = userInfo.name;
			if( firstName || lastName ) {
				fullName = `${firstName} ${lastName}`;
			}
			this.userInfo = {
				...userInfo,
				firstName: firstName ?? "--",
				lastName: lastName ?? "--",
				name: fullName,
			};
			this.foundUser = true;
		}catch(e){
			console.log("Failed user info lookup", { resource, documentId, userId }, e);
			this.foundUser = false;
		}finally{
			this.loading = false;
		}
	}

}
