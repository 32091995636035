
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { CalendarEventModel } from '../../models/calendar/CalendarEventModel';
import { getDefaultEventTypes } from '../../helpers/default-event-types';
import { TeamEventTypeData } from '@/../types/interfaces';
import { getTime } from '../../helpers';

@Component
export default class EventItem extends Mixins(VuetifyMixin){

	@Prop({ type: Array, default: getDefaultEventTypes() }) eventTypes!: TeamEventTypeData[];
	@Prop({ default: false, type: Boolean }) private outlined: boolean;
	@Prop({ required: true }) event: CalendarEventModel;
	@Prop({ default: 'baColorVibrantBlue' }) private color: string;

	get EventName(): string{
		return this.event.EventName;
	}
	get EventColor(): string{
		const eventType = this.eventTypes.find(event => event.name === this.event.eventType);
		if(eventType === undefined) return this.getColor(this.color);
		return this.getColor(eventType.color);
	}
	get EventTimeRange(): string{
		if(this.event.start && !this.event.end){
			return `${getTime(this.event.start)}`;
		}else if(this.event.start && this.event.end){
			return `${getTime(this.event.start)}-${getTime(this.event.end)}`;
		}
		return '';
	}
}
