// BAPurchaseModel.ts

import { FrontEndModel } from '@/models/FrontEndModel';

export class BAPurchaseModel extends FrontEndModel {
    parentId!: string;
    productId!: string;
    priceId!: string;
    date!: Date;
    data?: Record<string, any>;
    requested?: boolean;
    completed?: boolean;

    load(obj: Partial<BAPurchaseModel>): this {
        Object.assign(this, obj);
        if( obj['date'] ) {
            this.date = new Date(obj['date']);
        }
        return this;
    }
}