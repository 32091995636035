
import { Mixins, Component, Prop } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import Dialog from "@/components/ui/Dialog.vue";
import ExcelDataTable from "@/components/ui/ExcelDataTable.vue";
import RoundLoader from '@/components/ui/RoundLoader.vue';
import RoundedButton from '@/components/forms/RoundedButton.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";
import { CSVPlayerRoster } from "@/../types/CSVRosterFile";
import { BaseSoccerPosition, BaseSoccerPositionValues, SoccerPositionToBasePositionMapping } from '@/../types/enums/sports';
import { stringToAlphaLower } from '@/pipes/stringToAlpha.pipe';

@Component({
	components: { ExcelDataTable, Dialog, FileUploadArea, RoundLoader, RoundedButton }
})
export default class CSVUploadDialog extends Mixins(VuetifyMixin, CSVUploadMixin, BAIconsMixin) {
	@Prop({ default: false }) visible: boolean;
	@Prop({ default: false, type: Boolean }) hideSampleDownload: boolean;

	get dialogVisbility() {
		return this.visible
	}
	set dialogVisbility(value: boolean) {
		this.$emit('update:visible', value)
	}

	uploadCSVLoading = false;
	unconfirmedPlayers:CSVPlayerRoster[] = [];

	@Prop({ default: () => (csvString: string): CSVPlayerRoster[] => {
		// the default csv parser is used to generate a list of players
		const csvRoster = csvString.split('\n').map(line => line.split(',') );
		/** Tries to match user uploaded position name to one of our base positions.  */
		const findBasePosition = (val: string): BaseSoccerPosition | undefined => {
			const pos = BaseSoccerPositionValues.find(position => stringToAlphaLower(val).indexOf(stringToAlphaLower(position)) > -1);
			if(pos === undefined && SoccerPositionToBasePositionMapping[val] !== undefined){
				return SoccerPositionToBasePositionMapping[val];
			}
			return pos;
		}
		return csvRoster.slice(1).map(([number, firstName, lastName, email, position, dob, gradYear, schoolCommitment], index) => {
			return { 
				row:index + 1,
				number: parseInt(number),
				firstName,
				lastName,
				email,
				position: findBasePosition(position),
				dateOfBirth: dob,
				gradYear: parseInt(gradYear),
				schoolCommitment,
				eligibleToPlay: true,
				injured: false };
		});
	}}) csvParseFn: (csvData: string) => any;

	/**
	 * This uploader will break if you upload a CSV with CRLF line endings. (Looking at you, windows users)
	 */
	uploadCSV(csv: File){
		this.uploadCSVLoading = true;
		const fileReader = new FileReader()
		fileReader.onload = ($event: ProgressEvent<FileReader>) => {
			// Chance of being an invalid file and an arraybuffer instead
			const csvString = $event.target.result as string;
			this.unconfirmedPlayers = this.csvParseFn(csvString);
			this.uploadCSVLoading = false;

			// send input to parents with players list
			this.$emit('input', this.unconfirmedPlayers);

			// close the dialog
			this.$emit('update:visible', false);
		}
		this.unconfirmedPlayers = [];
		fileReader.readAsText(csv);
	}
}
