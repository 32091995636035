
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

/**
 * Displays a loading screen. Optionally redirects after a fixed period of time
 */
@Component
export default class LoadingPage extends Mixins(VuetifyMixin){

	@Prop({ default: false, type: Boolean }) private redirect: boolean;
	@Prop({ default: '/' }) private redirectTo: string;
	@Prop({ default: 3 }) private timeout: number;
	@Prop({ default: null }) message: string | null;

	redirectTimer: any = null;
	mounted(): void{
		if(this.redirect){
			this.redirectTimer = setTimeout(() => {
				this.$router.push(this.redirectTo);
			}, this.TimeoutMs);
		}
	}
	get TimeoutMs(): number{
		return this.timeout * 1000;
	}

	beforeDestroy(): void{
		if(this.redirectTimer) clearTimeout(this.redirectTimer);
	}
}
