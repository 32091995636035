/* RyzerMixin.ts */
/* RyzerMixin contains functionality used by Ryzer Mindset related components */

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { RyzerType, GetMindsetArchetype, RyzerEagle, RyzerEngineer, RyzerIce, RyzerKnight, RyzerMaverick, RyzerMusketeer, RyzerRocket, RyzerTrailblazer } from "@/../types/constants/ryzer"
import { AuthMixin } from '@/mixins';

/**
 * Provides access to Ryzer icons and data types
 */
@Component
export class RyzerMixin extends Mixins(AuthMixin) {
	@Prop({ default : undefined }) archetype: RyzerType;

	get AthleteTypes() : RyzerType[] {
		return [ 
			RyzerEagle,
			RyzerEngineer,
			RyzerIce,
			RyzerKnight,
			RyzerMaverick,
			RyzerMusketeer,
			RyzerRocket,
			RyzerTrailblazer,
		];
	}

	SetMindsetArchetype(archetype: string) {
		this.archetype = GetMindsetArchetype(archetype);
	}

	get MindsetArchetype() : RyzerType {
		if( !this.archetype ) this.archetype = RyzerEngineer; // default
		return this.archetype;
	}

	get MindsetArchetypeTextStyle(): Record<string, any>{
		return {
			color: this.MindsetArchetype.color
		}
	}

	get MindsetArchetypeTitle(): string {
		if( this.IsAthlete ) return 'Athlete Type';
		if( this.IsCoach ) return 'Coach Type';
		if( this.IsParent ) return 'Athlete Type';
		return 'Mindset Type';
	}
	get RyzerTitleTextColor(): Record<string, any>{
		return {
			color: "#1D50B6"
		}
	}
}
