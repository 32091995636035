
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import NotificationMenuItem from '@/components/ui/NotificationMenuItem.vue';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationStatus } from '@/../types/enums';
import { notificationStore } from '@/store';

@Component({
	components: { NotificationMenuItem },
})
export default class NotificationMenu extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: () => ([]) }) notifications = [];
	@Prop({ default: "baColorVibrantBlue" }) iconColor: string;
	get UnreadCount(): number{
		return this.notifications.filter((n: NotificationModel) => n.status === NotificationStatus.UNREAD).length;
	}
	get Count(): number{
		return this.notifications.length;
	}
	get Notifications(): NotificationModel[]{
		return this.notifications;
	}
	get NoNotifications(): boolean{
		return this.Count === 0;
	}

	get Width(): string | number{
		if(this.IsMobile) return '100%';
		return 458;
	}
	get MinHeight(): number{
		return 458;
	}
	get HeaderHeight(): number{
		return 56;
	}
	get ContentHeight(): string{
		return `${this.MinHeight - this.HeaderHeight}px`;
	}
	get Elevation(): number{
		if(this.IsMobile) return 0;
		return 16;
	}

	async toggleRead(notification: NotificationModel): Promise<void>{
		if(notification.IsRead){
			await notificationStore.markNotificationUnread(notification.id);
		}else{
			await notificationStore.markNotificationRead(notification.id);
		}
	}
	async deleteNotification(notification: NotificationModel): Promise<void>{
		await notificationStore.removeNotification(notification.id);
	}
	async markAllAsRead(): Promise<void>{
		for(const notification of this.Notifications){
			await notificationStore.markNotificationRead(notification.id);
			// Delay to give a cascade effect
			await new Promise((res) => setTimeout(res, 50));
		}
	}
}
