
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component({
    components: {
    }
})
export default class GetStringDialog extends Mixins(StringsMixin, BAIconsMixin, VuetifyMixin) {
    @Prop() buttonIcon: string;
    @Prop() buttonText: string;
    @Prop() buttonTooltip: string;
    @Prop() title: string;
    @Prop({type: Boolean}) multiple;

    value: string = "";
    waiting: boolean = false;

    showGetStringDialog: boolean = false;
    onGetString() {
        this.showGetStringDialog = true;
    }
    async onAdd() {
        if( this.IsEmpty(this.value) ) return;

        await this.$emit('input', this.value);
        this.showGetStringDialog = false;
        this.value = "";
        this.showGetStringDialog = true;
        this.setFocus(this.$refs.value);
    }
    async onOK() {
        await this.onAdd();
        this.showGetStringDialog = false;
    }
    onCancel() {
        this.showGetStringDialog = false;
    }
}

