import { CrudApi } from './CrudApi';
import { SportModel } from '../models/sport/SportModel';
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';

class SportApi extends CrudApi<SportModel>{
	constructor() {
		super('sport', (obj) => new SportModel().load(obj));
	}

	async findByName(sportName: string): Promise<SportModel | null> {
		const query: RepositoryQuery<SportModel> = { 
			search: sportName.trim(),
			fields: ['name']
		};
		const options: QueryOptions = {};
		const sportsFound = await this.queryAll(query, options);
		if( sportsFound.count > 0 ) {
			for( const sport of sportsFound.docs ) {
				if( sport.name === sportName ) return sport;
			}
		}
		return null;
	}

}
export const sportApi = new SportApi();