/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import { requireRolesGuard } from './routeGuards/requireRolesGuard';
import { AdminSideNavLayout } from '../layouts';
import ImpersonationLayout from '../layouts/ImpersonationLayout.vue';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { AdminAppRoles } from '@/../types/constants/role-lists';
import { HinderRouteMetadata } from './router';
import { EventAdminRouteConfigs } from './EventAdminRouteConfigs';
import { OrgAdminRouteConfigs } from './OrgAdminRouteConfigs';


const AdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/AdminDashboard.vue');
const OrganizationAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/OrganizationAdminDashboard.vue');
const TeamAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/TeamAdminDashboard.vue');
const TeamCreateNew = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/TeamAdminCreate.vue');
const VerifiedAssessmentAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/VerifiedAssessmentAdminDashboard.vue');
const CoachSharesAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/CoachSharesAdminDashboard.vue');
const AthleteReportsAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/AthleteReportsAdminDashboard.vue');
const ScoutingReportsAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/ScoutingReportsAdminDashboard.vue');
const MindsetReportsAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/MindsetReportsAdminDashboard.vue');
const OrganizationCreate = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/OrganizationCreate.vue');
const UserAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/UserAdminDashboard.vue');
const AthleteAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/AthleteAdminDashboard.vue');
const SportAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/SportAdminDashboard.vue');
const PartnerAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/PartnerAdminDashboard.vue');
const BASchoolAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/BASchoolAdminDashboard.vue');
const SchoolSearchAdminDashboard = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/schoolSearch/SchoolSearchAdminDashboard.vue');
const SchoolCreate = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/schoolSearch/SchoolCreate.vue');
const Assessment = () => import(/* webpackChunkName: "AdminModule" */ '@/views/dataEntry/Assessment.vue');
const BackendJobs = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/BackendJobs.vue');
const SchemaUpgrade = () => import(/* webpackChunkName: "AdminModule" */ '@/views/admin/SchemaUpgrade.vue');

export const AdminRouteConfigs: RouteConfig[] = [
	{
		path: '/',
		component: AdminSideNavLayout,
		beforeEnter: requireRolesGuard(AdminAppRoles, 'any'),
		children: [
			{
				path: '/',
				name: AdminRoutes.AdminDashboard,
				component: AdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'BA Admin Dashboard',
				},
			},
			{
				path: '/organizations',
				name: AdminRoutes.OrganizationAdminDashboard,
				component: OrganizationAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Organization Dashboard',
				},
			},
			{
				path: '/athletes',
				name: AdminRoutes.AthleteAdminDashboard,
				component: AthleteAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Athletes',
				},
			},
			{
				path: '/schools',
				name: AdminRoutes.SchoolSearchAdminDashboard,
				component: SchoolSearchAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Schools',
				}
			},
			{
				path: '/baSchools',
				name: AdminRoutes.BASchoolAdminDashboard,
				component: BASchoolAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Schools',
				}
			},
			{
				path: '/users',
				name: AdminRoutes.UserAdminDashboard,
				component: UserAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Users',
				},
			},
			{
				path: '/sports',
				name: AdminRoutes.SportAdminDashboard,
				component: SportAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Sports',
				}
			},
			{
				path: '/partners',
				name: AdminRoutes.PartnerAdminDashboard,
				component: PartnerAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata> {
					pageTitle: 'Partners',
				}
			},
			{
				path: '/coach-shares',
				name: AdminRoutes.CoachSharesAdminDashboard,
				component: CoachSharesAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Targeted Shares',
				},
			},
			{
				path: '/verified-assessments',
				name: AdminRoutes.VerifiedAssessmentAdminDashboard,
				component: VerifiedAssessmentAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Verified Assessments Dashboard',
				},
			},
			{
				path: '/athlete-reports',
				name: AdminRoutes.AthleteReportsAdminDashboard,
				component: AthleteReportsAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Athlete Reports Dashboard'
				},
			},
			{
				path: '/scouting-reports',
				name: AdminRoutes.ScoutingReportsAdminDashboard,
				component: ScoutingReportsAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Scouting Reports Dashboard'
				},
			},
			{
				path: '/mindset-reports',
				name: AdminRoutes.MindsetReportsAdminDashboard,
				component: MindsetReportsAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Mindset Reports Dashboard'
				},
			},
			{
				path: '/teams',
				name: AdminRoutes.TeamAdminDashboard,
				component: TeamAdminDashboard,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Dashboard',
				},
			},
			{
				path: '/teams/create/:currentStep?',
				name: AdminRoutes.TeamCreateNew,
				component: TeamCreateNew,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Team Setup',
				},
			},
			{
				path: '/teams/:editTeamId/edit/:currentStep?',
				name: AdminRoutes.TeamEdit,
				component: TeamCreateNew,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Team',
				},
			},
			{
				path: '/organization/create/:currentStep?',
				name: AdminRoutes.OrgCreate,
				component: OrganizationCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Organization Setup',
				},
			},
			{
				path: '/organization/:editOrganizationId/edit/:currentStep?',
				name: AdminRoutes.OrgEdit,
				component: OrganizationCreate,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Edit Organization',
				},
			},
			{
				path: '/assessmentAdd',
				name: AdminRoutes.AssessmentAdd,
				component: Assessment,
				props: true,
			},
			{
				path: '/jobs',
				name: AdminRoutes.Jobs,
				component: BackendJobs,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Backend Jobs'
				}
			},
			{
				path: '/data-update',
				name: AdminRoutes.SchemaUpgrade,
				component: SchemaUpgrade,
				props: true,
				meta: <HinderRouteMetadata>{
					pageTitle: 'Data Update'
				}
			},
			...EventAdminRouteConfigs,
		]
	},
	{
		path: '/',
		component: ImpersonationLayout,
		props:{
			title: "You are viewing this Organization as a System Admin"
		},
		meta: <HinderRouteMetadata>{
			systemBarColor: 'baColorAdminPrimary'
		},
		beforeEnter: requireRolesGuard(AdminAppRoles, 'any'),
		children: OrgAdminRouteConfigs.map(r => ({
			...r,
			path: `orgAdmin/${r.path}`,
		})),
	}
];