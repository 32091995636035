
import { Component, Prop, Mixins } from 'vue-property-decorator';
import AccessControlForm from '@/components/profile/settings/AccessControlForm.vue';
import PermissionsSelector from '@/components/forms/PermissionsSelector.vue';
import { BAIconsMixin, FormRulesMixin, AuthMixin, VuetifyMixin } from '@/mixins';
import { title } from '@/pipes';
import { UserAcl, AthleteProfileRole, AthleteUserRelationship, UserInfo, AthleteRelationship, ContextMenuItem } from '@/../types/interfaces';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import LinkText from "@/components/ui/LinkText.vue";

@Component({
	components: { AccessControlForm, PermissionsSelector, LinkText},
})
export default class AthleteAccessControlUserRow extends Mixins(BAIconsMixin, AuthMixin, FormRulesMixin, VuetifyMixin){
	title = title;

	@Prop({ required: true }) value: AthleteProfileModel;
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop() userAcl: UserAcl;
	@Prop() foundUser: boolean;
	@Prop() userInfo: UserInfo;
	@Prop({ default: true, type: Boolean }) cancelVisible: boolean;
	@Prop({ default: false, type: Boolean }) cancelDisabled: boolean;

	updateEmail(email: string): void{
		this.$emit('update:user-info', {
			...this.userInfo,
			email: email,
		});
	}

	get IsMe(): boolean{
		return this.UserId === this.$auth.user.id;
	}

	input(value: AthleteProfileModel): void{
		this.$emit('input', value);
	}

	get UserId(): string{
		return this.userAcl.id
	}
	get User(): AthleteUserRelationship | undefined{
		if(this.value === undefined) return undefined;
		return this.value.getUser(this.userAcl.id);
	}
	get UserPending(): boolean{
		return this.userInfo === undefined || !this.userInfo;
	}
	get UserInfo(): Partial<UserInfo>{
		if(this.userInfo){
			return this.userInfo;
		}
		return {
			firstName: 'Pending',
			lastName: ''
		};
	}
	get UserFullName(): string{
		if(this.userInfo){
			return this.userInfo.name;
		}
		return this.UserInviteName;
	}
	get UserInviteName(): string | undefined{
		if(this.User === undefined) return undefined;
		return this.User.inviteName;
	}
	get UserEmail(): string{
		if(this.User && this.User.inviteEmail){
			return this.User.inviteEmail;
		}
		if(this.userInfo === undefined){
			return '';
		}
		return this.userInfo.email;
	}
	get RoleValue(): AthleteProfileRole | undefined{
		if(this.User === undefined) return undefined;
		return this.User.role;
	}
	inputRole(role: AthleteProfileRole): void{
		// console.log("inputRole", { role });
		this.value.setUser(this.UserId, {
			userId: this.UserId,
			role: role,
			relationship: this.RelationshipValue,
		});
	}
	get RelationshipValue(): AthleteRelationship | undefined{
		if(this.User === undefined) return undefined;
		return this.User.relationship;
	}
	get RelationshipValueName(): string | undefined{
		if(this.RelationshipValue === undefined) return undefined;
		return title(this.RelationshipValue.name);
	}
	inputRelationship(relationship: AthleteRelationship): void{
		// console.log("inputRelationship", { relationship });
		this.value.setUser(this.UserId, {
			userId: this.UserId,
			role: this.RoleValue,
			relationship: relationship,
		});
	}

	get Roles(): AthleteProfileRole[]{
		return AthleteProfileModel.Roles;
	}
	get Relationships(): AthleteRelationship[]{
		return AthleteProfileModel.Relationships;
	}

	get ColsProps(): Record<string,any>{
		return {
			cols: "12",
			md: "12",
			lg: "3",
		}
	}

	resendInvitation() {
		// TO-DO
	}

	deleteUser(): void{
		this.value.removeUser(this.userAcl.id);
		this.input(this.value);
		this.onRemove();
	}

	showRemoveUser: boolean = false;
	onRemove(): void{
		this.$emit('remove', this.userAcl);
		this.showRemoveUser = false;
	}
	get RemoveText(): string{
		return `Remove ${this.UserFullName}'s access?`;
	}
}
