export const AdminDashboard = 'AdminDashboard';
export const OrganizationAdminDashboard = 'OrganizationAdminDashboard';
export const TeamAdminDashboard = 'TeamAdminDashboard';
export const VerifiedAssessmentAdminDashboard = 'VerifiedAssessmentAdminDashboard';
export const CoachSharesAdminDashboard = 'CoachSharesAdminDashboard';
export const ScoutingReportsAdminDashboard = 'ScoutingReportsAdminDashboard';
export const AthleteReportsAdminDashboard = 'AthleteReportsAdminDashboard';
export const MindsetReportsAdminDashboard = 'MindsetReportsAdminDashboard';
export const VerifiedAssessmentImport = 'VerifiedAssessmentImport';
export const TeamCreateNew = 'TeamCreateNew';
export const TeamEdit = 'TeamEdit';
export const OrgCreate = 'OrgCreate';
export const OrgEdit = 'OrgEdit';
export const SchoolSearchAdminDashboard = 'SchoolSearchAdminDashboard';
export const UserAdminDashboard = 'UserAdminDashboard';
export const AthleteAdminDashboard = 'AthleteAdminDashboard';
export const SportAdminDashboard = 'SportAdminDashboard';
export const PartnerAdminDashboard = 'PartnerAdminDashboard';
export const BASchoolAdminDashboard = 'BASchoolAdminDashboard'
export const SchoolCreate = 'SchoolCreate';
export const SchoolEdit = 'SchoolEdit';
export const AssessmentAdd = 'AssessmentAdd';
export const Jobs = 'AdminBackendJobs';
export const SchemaUpgrade = 'SchemaUpgrade';

export const EventAdmin = 'EventAdmin';
export const EventPhysical = 'EventPhysical';
export const EventDashboard = 'EventDashboard';
export const EventOrderAdmin = 'EventOrderAdmin';

export const BAEventAdmin = 'BAEventAdmin';
export const BAEventDetail = 'BAEventDetail';
export const BAEventAdminDetail = 'BAEventAdminDetail';
export const BAEventAdminRedeem = 'BAEventAdminRedeem';
export const BAEventLocationsAdmin = 'BAEventLocationsAdmin';
export const BAEventSponsorsAdmin = 'BAEventSponsorsAdmin';