
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AclPermissionValues } from '@/../types/permissions';
import { title } from '../../pipes';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';

@Component
export default class PermissionsSelector extends Mixins(VuetifyMixin, BAIconsMixin){
	title = title;
	@Prop({ default: () => [] }) addPermissions: string[];
	@Prop({ default: () => [] }) value: string[];
	input(value: string[]): void{
		this.$emit('input', value);
	}

	get Permissions(): string[]{
		return [...AclPermissionValues, ...this.addPermissions];
	}
}
