import { RouteConfig } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { HinderRouteMetadata } from './router';
import { PublicCoachSideNavLayout } from '../layouts';

const PublicDashboard = () => import(/* webpackChunkName: "PublicModule" */ '@/views/PublicDashboard.vue');
const AthletePublicRecruitingProfileView = () => import(/* webpackChunkName: "PublicModule" */ '@/views/AthletePublicRecruitingProfileView.vue')
const TeamPublicRecruitingProfileView = () => import(/* webpackChunkName: "PublicModule" */ '@/views/TeamPublicRecruitingProfileView.vue')
const PublicPartner = () => import(/* webpackChunkName: "PublicModule" */ '@/views/partners/Partner.vue');
const PublicBestEducation = () => import(/* webpackChunkName: "PublicModule" */ '@/views/partners/BestEducation.vue');

export const PublicCoachRouteConfigs: RouteConfig[] = [
    {
        path: '/public',
        props: true,
		component: PublicCoachSideNavLayout,
		children: [
			{
				path: '',
				name: Routes.PublicDashboard,
				component: PublicDashboard,
				meta:<HinderRouteMetadata>{
					pageTitle: 'Best Athletes Dashboard'
				}
			},
			{
				name: Routes.PublicViewAthlete,
				path: 'athlete/:athleteId/:sharingUrlId?',
				component: AthletePublicRecruitingProfileView,
				props: true,
				meta: {
					public: true,
                    requiresAuth: false,
					pageTitle: "Athlete Recruiting Profile"
				}
			},
			{
				name: Routes.PublicViewTeam,
				path: 'team/:teamId/:sharingUrlId?',
				component: TeamPublicRecruitingProfileView,
				props: true,
				meta: {
					public: true,
					requiresAuth: false,
					pageTitle: "Team Recruiting Profile"
				}
			},
			{
				name: Routes.PublicBestEducation,
				path: 'partners/best-education',
				component: PublicBestEducation,
				props: true,
				meta: {
					public: true,
					requiresAuth: false,
					pageTitle: 'Best Education',
				}
			},
			{
				name: Routes.PublicPartner,
				path: 'partners/:partnerName',
				component: PublicPartner,
				props: true,
				meta: {
					pageTitle: `Best Athletes Partner`,
				}
			},
        ]
    }
]