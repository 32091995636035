
import { Component, Prop, Mixins } from 'vue-property-decorator';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { VuetifyMixin, BAIconsMixin, StringsMixin, SportsSelectorMixin } from '@/mixins';
import {AthleteProfileModel} from '@/models/athlete/AthleteProfileModel';
import {uniqBy} from 'lodash';
import { TeamCreateFormValue } from '@/../types/interfaces';
import Dialog from "@/components/ui/Dialog.vue";
import CSVUploadDialog from "@/components/teams/CSVUploadDialog.vue";
import CSVHelpDialog from "@/components/teams/CSVHelpDialog.vue";
import CSVUploadPreview from "@/components/teams/CSVUploadPreview.vue";
import { DataTableHeader } from 'vuetify';
import { SportName } from '@/../types/enums';

@Component({
	components: { 
		FileUploadArea,
		Dialog,
		CSVUploadDialog,
		CSVHelpDialog,
		CSVUploadPreview,
	}
})
export default class TeamCreateTeamRoster extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, SportsSelectorMixin){
	@Prop({ default: () => ({
		valid: true,
		players: [],
		invitePlayers: [],
		sendPlayerInvitations: false,
	}) }) value: TeamCreateFormValue['step3'];
	@Prop({ default: false, type: Boolean}) isEdit:boolean;
	@Prop() team: any;
	@Prop() sport: SportName;
	
	input(): void{
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
		addPlayerForm: HTMLFormElement;
	};

	expandedAthletes: [];

	rosterHeaders: DataTableHeader<any>[] = [
		{
			text: 'Num',
			value: 'number',
			sortable: false,
		},
		{
			text: 'Player',
			value: 'player',
			sortable: false,
		},
		{
			text: 'Pos',
			value: 'position',
			sortable: false,
		},
		{
			text: 'Email Address',
			value: 'email',
			sortable: false
		},
		{
			text: 'DoB',
			value: 'dateOfBirth',
			sortable: false,
		},
		{
			text: 'Commitment',
			value: 'schoolCommitment',
			sortable: false,
		},
		{
			text: '',
			value: 'remove',
		},
	];

	async addPlayer(athlete: AthleteProfileModel) {
		this.value.players.push(athlete);
		this.value.players = uniqBy(this.value.players, 'id');
	}

	get HasPlayers(): boolean {
		return this.value.players.length > 0;
	}

	// handle player invitations
	get HasInvitePlayers(): boolean {
		return this.value.invitePlayers.length > 0;
	}
	addInvitePlayer(){
		this.value.invitePlayers.push(this.defaultPlayer());
	}
	removeInvitePlayer(index: number){
		this.value.invitePlayers.splice(index,1);
	}
	defaultPlayer(){
		return {
			firstName: "",
			lastName: "",
			position: "",
			email: "",
			dateOfBirth: "",
			gradYear: "",
			schoolCommitment: "",
			injured: false,
			eligibleToPlay: true,
		}
	}

	// handle player removal with confirmation dialog
	needRemoveConfirmation = false;
	showPlayerRemoveConfirmation = false;
	playerToRemove = null;
	removePlayerRequest(item) {
		this.playerToRemove = item;
		if( this.needRemoveConfirmation )
		{
			this.showPlayerRemoveConfirmation = true;
		} else {
			this.removePlayer(item)
		}
	}
	closeRemovePlayerRequest() {
		this.playerToRemove = null;
		this.showPlayerRemoveConfirmation = false;
	}
	removePlayer(item){
		this.value.players.splice(this.value.players.indexOf(item), 1);
		this.closeRemovePlayerRequest();
	}

	showCSVHelpDialog = false;
	showCSVUploadDialog = false;
	showCSVPreviewDialog = false;
	showNationalPreviewDialog = false;
	hasNationalPlayers = false;

	unconfirmedPlayers:Array<Record<string, unknown>> = [];
	previewPlayerData(data: Array<Record<string, unknown>>) {
		this.unconfirmedPlayers = data;
		this.showCSVPreviewDialog = true
	}
	confirmPlayerData(data:  Array<Record<string, unknown>>) {
		this.value.invitePlayers = data;
		this.hasNationalPlayers = false;
	}

	nationalPlayers:Array<Record<string, unknown>> = [];
	previewNationalPlayers(data: Array<Record<string, unknown>>) {
		this.nationalPlayers = data;
		this.showNationalPreviewDialog = true
	}
	confirmNationalPlayers(data:  Array<Record<string, unknown>>) {
		this.value.invitePlayers = data;
		this.hasNationalPlayers = true;
	}

	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

	nationalHeaders = [
			{ text: "", sortable: false, value: "row", align: "end", width: "60px" },
			{ text: "Number", sortable: false, value: "number", align: "center" },
			{ text: "First Name", sortable: true, value: "firstName" },
			{ text: "Last Name", sortable: true, value: "lastName" },
			{ text: "Position", sortable: true, value: "position" },
			{ text: "Club", sortable: true, value: "club" },
			{ text: "Caps", sortable: true, value: "caps" },
			{ text: "Goals", sortable: true, value: "goals" },
		];
}
