import { FrontEndModel } from '../FrontEndModel';
import { PlanFeature } from '@best-athletes/ba-types'
import { FeatureMap, Price } from '@/../types/interfaces';
import { Currency } from '@/../types/enums/currency';
import { formatPrice } from '@/pipes';

export class SubscriptionPlanModel extends FrontEndModel {
	public stripeProductId: string | null = null;
	public free: boolean = false;
	public key: string;
	public title: string;
	public description: string;
	public subtitle: string | null;
	public titleAppend: string | null;
	public featureList: PlanFeature[];
	public featureMap: FeatureMap;
	public planOrder: number = 0;
	public complete: boolean = false;
	public disabled: boolean = false;
	public prices: Price[] = [];
	/** Price to show crossed off to show the current price is discounted. Will be formatted in the user's target curreny */
	public slashPrice?: {
		[Currency.CAD]: number,
		[Currency.USD]: number,
	};

	get VideoMinutes(): number{
		return this.featureMap.ATHLETE_VIDEO_MINUTES || 0;
	}

	getPrice(currency: Currency): Price | null {
		const price = this.prices.find(p => p.currency === currency);
		if( !price ) return null;
		return price;
	}
	getPriceFormatted(currency: Currency): string {
		if( !currency ) currency = Currency.CAD;
		const price = this.getPrice(currency);
		if( !price ) return "$--??";
		return `${formatPrice(price.amount, price.currency)}`;
	}
	getSlashPrice(currency: Currency): Price | null {
		if (this.slashPrice === undefined || this.slashPrice[currency] === undefined) return null;
		return {
			amount: this.slashPrice[currency],
			currency,
		};
	}
	getSlashPriceFormatted(currency: Currency): string | null{
		const price = this.getSlashPrice(currency);
		if(price === null) return null;
		return `${formatPrice(price.amount, price.currency)}`;
	}
	/**
	 * Divides yearly price by 12, drops to the nearest dollar and adds $0.95
	 * 299 / 12 = $24.916666666666667  
	 * ~~(24.916666666666667) = $24  
	 * $24 + $0.95 = $24.95/month  
	 */
	getMonthlyPriceFormatted(currency: Currency): string{
		const price = this.getPrice(currency);
		if(price === null) return "$--??";
		const monthlyAmount = (~~(price.amount/12 /100) * 100) + 95;
		return `${formatPrice(monthlyAmount, price.currency)}`;
	}

	/** True if this plan has a feature in it's featureList */
	hasListedFeature(id: number): boolean{
		return this.getListedFeature(id) !== null;
	}
	/** Returns the given feature from featureList */
	getListedFeature(id: number): PlanFeature | null{
		return this.featureList.find(feat => feat.id === id) ?? null;
	}

	getColor(type: string = 'plan'): string {
		if( !this.title ) return 
		if( this.title.includes('Standard') ) {
			if( type.includes('plan') ) return 'orange darken-3';
			if( type.includes('button') ) return 'orange lighten-4';
			if( type.includes('text') ) return 'orange--text';
			return 'orange';
		}
		if( this.title.includes('Showcase') ) {
			if( type.includes('plan') ) return 'green darken-4';
			if( type.includes('button') ) return 'green lighten-4';
			if( type.includes('text') ) return 'green--text';
			return 'green';
		}
		if( this.title.includes('Elite') ) {
			if( type.includes('plan') ) return 'purple darken-3';
			if( type.includes('button') ) return 'purple lighten-4';
			if( type.includes('text') ) return 'purple--text';
			return 'purple';
		}
		if( type.includes('plan') ) 'red darken-4';
		if( type.includes('button') ) return 'red lighten-4';
		return 'red';
	}
}