import { isEmpty } from '@/pipes';
import { AccessControlledModel } from '../../models/AccessControlledModel';

export class FileModel extends AccessControlledModel {
	id: string = 'new' + (new Date().valueOf());
	public url: string | null; // CloudFront URL or permanent URL
	public objectStorageUrl: string | null; // S3 URL
	public key: string; // s3 key
}

export class RemoteFile {
    title!: string;
    fileUrl!: string;

	loaded: boolean = false;
	loading: boolean = false;
	text: string = '';
	contentType: string = 'unknown';

    constructor(title: string = '', fileUrl: string = '') {
		this.loaded = false;
		this.load({title, fileUrl});
    }

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		this.read();

        return this;
    }

	get isText(): boolean {
		if( !this.contentType || (this.contentType === 'unknown') ) return false;

		return( this.contentType.includes('text') );
	}
	async read() {
		if( isEmpty( this.fileUrl ) ) {
			this.loaded = false;
			return;
		}

        try {
			// the remote fileUrl must be valid
			if( typeof this.fileUrl !== 'string' ) return;
			if( !this.fileUrl.includes('http') && !this.fileUrl.includes('www')) return;

			this.loading = true;
            this.loaded = false;

            const response = await fetch(this.fileUrl, {mode: 'cors'});
            if( !response.ok ) {
                throw( Error(`Error opening file: ${response.status} (${this.fileUrl})`));
            }
			this.contentType = response.headers.get('Content-Type');
            this.text = await response.text();

            this.loaded = true;
			this.loading = false;
        } catch(e) {
			console.error(e);
			this.loading = false;
            throw(e);
        }
	}
}

export class AgreementFile extends RemoteFile {
	signed: boolean;
}