
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { BAIconsMixin, VuetifyMixin, FormRulesMixin, AthleteRoutingMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import { BaseSoccerPosition, BodyPart, DominantSide } from "@/../types/enums";
import * as Routes from '@/../types/constants/web_client_user.routes';

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {
	}
})
export default class EditRecruitingProfileSports extends Mixins(BAIconsMixin, VuetifyMixin, FormRulesMixin, AthleteRoutingMixin) {
	@PropSync('editAthleteProfile', { required: true }) athleteProfile: AthleteProfileModel;
	BodyPart = BodyPart;

	// Returns an array of enum keys as text and enum values as values for vuetify items
	get SoccerPositions(): Item<BaseSoccerPosition>[] {
		let items: Item<BaseSoccerPosition>[] = [];

		Object.keys(BaseSoccerPosition)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(position => {
				items.push({
					text: position as keyof typeof BaseSoccerPosition,
					value: BaseSoccerPosition[position]
				});
			});
		return items;
	}

	get DominantSides(): Item<DominantSide>[] {
		let items: Item<DominantSide>[] = [];

		Object.keys(DominantSide).forEach(item => {
			items.push({
				text: item as keyof typeof DominantSide,
				value: DominantSide[item]
			})
		})
		return items;
	}

	gotoScheduleView(): void {
		this.$router.push({
			name: Routes.AthleteScheduleView,
			params:{
				athleteId: this.athleteProfile.id,
			}
		});
	}
}
