
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import ClipPlayerStudio from '@/components/videoClipPlayer/ClipPlayerStudio.vue';
import VideoClipPreview from '@/components/ui/videos/VideoClipPreview.vue';
import { formatDatePretty } from '@/helpers';
import { VideoModel } from '@/models/video/VideoModel';

@Component({
	components: { ClipPlayerStudio, VideoClipPreview },
})
export default class VideoClipLibrary extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: [] }) sourceVideos: VideoModel[];
	@Prop({ default: [] }) videoClips: VideoClipModel[];
	@Prop({ default: VideoClipModel.ATHLETE_CLIP_CATEGORIES }) categories: string[];

	@Prop({ default: null }) customFilter: (clip: VideoClipModel) => boolean | null;
	@Prop({ default: [] }) selectedCategories: string[];
	@Prop({ default: null }) dateFilter: {
		from: Date,
		to: Date,
	} | null = null;

	@Watch('FilteredClips')
	updateFilteredClips(): void{
		this.$emit('update:filtered-clips', this.FilteredClips);
	}

	get SlotProps(): Record<string,any>{
		return {
			sourceVideos: this.sourceVideos,
			FilteredClips: this.FilteredClips,
			selectedVideoClip: this.selectedVideoClip,
			clipPlayerVisible: this.clipPlayerVisible,
			setPlayerVisible: (val: boolean) => this.clipPlayerVisible = val,
		};
	}

	mounted(): void{
		this.updateFilteredClips();
	}

	get FilteredClips(): VideoClipModel[]{
		return this.videoClips.filter((clip: VideoClipModel) => {
			if(this.selectedCategories.length === 0) return true;
			return clip.category.reduce((a,b) => a || this.selectedCategories.includes(b), false)
		}).filter((clip: VideoClipModel) => this.isWithinDateFiler(clip))
			.filter((clip: VideoClipModel) => this.customFilter === null ? true : this.customFilter(clip));
	}

	isWithinDateFiler(clip: VideoClipModel): boolean{
		if(this.dateFilter === null || this.dateFilter === undefined) return true;
		const isAfterStart = this.dateFilter.from <= clip.create_date;
		const isBeforeEnd = this.dateFilter.to >= clip.create_date;
		return isAfterStart && isBeforeEnd;
	}

	get Empty(): boolean{
		return this.videoClips.length === 0;
	}
	get NoClipsInFilter(): boolean{
		return this.FilteredClips.length === 0;
	}

	get Today(): Date{
		return new Date();
	}

	get DateRangeItems(): Array<{
		text: string,
		value: {
			from: Date,
			to: Date,
		}
	}>{
		const thisYear: number = this.Today.getFullYear();
		const thisMonth: number = this.Today.getMonth();
		return new Array(18).fill(null).map((_, i) => {
			const itemMonthOffset = thisMonth - i;
			const from = new Date(thisYear, itemMonthOffset, 1);
			const to = new Date(thisYear, itemMonthOffset + 1, 1); // Last day of the month
			return {
				text: `${formatDatePretty(from)} - ${formatDatePretty(to)}`,
				value:{
					from,
					to,
				}
			};
		});
	}
	$refs: {
		videoClipPlayer: ClipPlayerStudio,
	};
	clipPlayerVisible: boolean = false;
	selectedVideoClip: VideoClipModel | null = null;
	playClip(videoClip: VideoClipModel): void{
		this.clipPlayerVisible = true;
		this.$nextTick(() => {
			this.selectedVideoClip = videoClip;
			this.$refs.videoClipPlayer.playClip(videoClip);
		});
	}
	videoClipClicked(videoClip: VideoClipModel): void{
		/** This ref won't exist if it's overidden by a slot. Emit an event in that case */
		if(this.$refs.videoClipPlayer){
			this.playClip(videoClip);
		}else{
			this.$emit('click:video', videoClip);
		}
	}

}
