import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export class ScoutingScoreColorMixin extends Vue {
	@Prop({ default: 'blue' }) color: string;

	ScoutingScoreColor(score: Number) : string {	
		if( Number(score) > 4.25 ) return 'purple darken-3';
		else if( Number(score) > 3.25 ) return 'green darken-4';
		else if( Number(score) > 2.75 ) return 'light-green darken-3';
		else if( Number(score) > 1.75 ) return 'lime darken-3';
		else if( Number(score) > 0.75 ) return 'deep-orange darken-4';
		else if( Number(score) > 0 ) return 'red darken-4';
		return  this.color;
	}

	ScoutingScoreTextColor(score: Number) : string {
		if( Number(score) > 4.25 ) return 'white';
		else if( Number(score) > 3.25 ) return 'white';
		else if( Number(score) > 2.75 ) return 'white';
		else if( Number(score) > 1.75 ) return 'white';
		else if( Number(score) > 0.75 ) return 'white';
		else if( Number(score) > 0 ) return 'white';
		return  this.color;
	}
}