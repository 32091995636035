
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Prop, Mixins } from "vue-property-decorator";
// @ts-ignore
import VerifiedPhysicalAssessmentPic from "@/assets/img/subscriptions/verified-physical-assessment.png";
// @ts-ignore
import SPQPic from "@/assets/img/subscriptions/spq.png";
// @ts-ignore
import ComparativeDataPic from "@/assets/img/subscriptions/comparative-data.png";
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import * as ProductKey from "@/../types/constants/product-keys";

@Component
export default class ConsumableCards extends Mixins(VuetifyMixin, BAIconsMixin) {
	
	@Prop({ required: true }) private cardType: string;
	@Prop({ default: 0, type: Number }) private count: number;
	@Prop({ default: false, type: Boolean }) private loading: boolean;
	@Prop({ default: "Buy it now" }) private buyText: string;
	@Prop({ default: "Book it now" }) private useText: string;
	@Prop({ default: false, type: Boolean }) private showCheck: boolean;

	// eslint-disable-next-line getter-return
	get CardName(): {
		cardType: string,
		img: string,
		} {
		if (this.cardType == ProductKey.VERIFIED_ASSESSMENT) {
			return {
				cardType: "Verified Physical \n Assessment",
				img: VerifiedPhysicalAssessmentPic
			};
		} else if (this.cardType == ProductKey.SPORTS_PERSONALITY_QUESTIONAIRE) {
			return {
				cardType: "SPQ20 Sports \n Personality Test",
				img: SPQPic
			};
		} else if(this.cardType == ProductKey.COMPARATIVE_DATA_ADDON) {
			return {
				cardType: "1 Year \n Comparative Data",
				img: ComparativeDataPic
			};
		} else if(this.cardType == ProductKey.VIDEO_HOURS_5_ADDON) {
			return {
				cardType: "5 Hours of Video",
				img: VerifiedPhysicalAssessmentPic,
			};
		}
	}

	get CardProperty(): Record<string, any> {
		if (this.count > 0 || this.showCheck) {
			return {
				sheetColor: this.getColor('baColorDeepBlue'),
				badgeColor: this.getColor('baColorGreen1'),
				imgStyle: {
					opacity: "0.3"
				},
				showCheck: this.showCheck,
				hasNumber: true,
				number: this.count,
				buttonText: this.useText,
				emitEvent: 'book'
			};
		} else {
			return {
				sheetColor: this.getColor('baColorGraySecondaryText'),
				badgeColor: this.getColor('baColorPrimaryText'),
				imgStyle: {
					filter: "grayscale(1)",
					opacity: "0.4"
				},
				showCheck: false,
				hasNumber: false,
				buttonText: this.buyText,
				emitEvent: 'buy'
			};
		}
	}
}
