
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin, MyAthleteMixin, AthleteRoutingMixin } from "@/mixins";
import { TeamModel } from '@/models/team';

@Component({
	components: { },
})
export default class TeamHeadingCard extends Mixins(VuetifyMixin, BAIconsMixin, MyAthleteMixin, AthleteRoutingMixin) {
	@Prop({ required: true }) team: TeamModel;
	@Prop({ type: Boolean }) loading; 
	@Prop({ type: Boolean }) useChevron; 
	@Prop({ default: "80" }) avatarSize: string;
	@Prop({ default: "L" }) textSize: string;
	@Prop({ type: Boolean }) active;
	@Prop({ type: Boolean }) dense;
	@Prop({ type: Boolean }) showJersey;
	@Prop({ default: "15" }) jerseySize: string;

	get TeamTextStyle(): string {
		if( this.textSize.toLowerCase().startsWith('s') ) return "team-details-name-sm"
		return "team-details-name-lg"
	}

	get MyNumber(): string {
		if( this.AthleteAppActive ) {
			const player = this.team.players.find(p => p.athleteId === this.CurrentAthleteId );
			if( !!player ) return player.number;
		}

		return "10";
	}
}
