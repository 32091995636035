import { OrganizationType } from "@/../types/enums";
import { AccessControlledModel } from '../AccessControlledModel';
import { StaffMember } from '../team/StaffMember';
import { TeamModel } from '../team/TeamModel';

export class OrganizationModel extends AccessControlledModel {
	name: string = "";
	type: OrganizationType = OrganizationType.Club;
	sport: string = "";
	country: string = "";
	city: string = "";
	league: string = "";
	logoUrl: string = "";
	orgUrl: string = "";
	bio: string = "";
	staffMembers: StaffMember[] = [];
	teams: Array<TeamModel | string> = [];
	get PopulatedTeams(): TeamModel[] {
		const populated = this.teams.filter(doc => (typeof doc !== 'string'));
		return <TeamModel[]>populated;
	}

	load(obj: Partial<OrganizationModel>): this {
		Object.assign(this, obj);
		return this;
	}

	get TeamCount(): number{
		return this.teams.length;
	}
	get AccountStatus(): string{
		return 'Active';
	}

	get Location(): string{
		if(this.city && this.country) return `${this.city}, ${this.country}`;
		return this.city;
	}

	get FullBio(): string{
		if( this.bio !== "" ) return this.bio;
		return `${this.name} - ${this.Location}`
	}
}