import {AccessControlledModel} from '../AccessControlledModel';
import {MindsetReport} from '@/../types/interfaces';
import { isNotEmpty, isZero } from '@/pipes';

export class MindsetReportModel extends AccessControlledModel implements MindsetReport {
	error: string = '';					// database error information

	athleteId: string;					// user Best Athletes ID
	ryzerId: string;					// user Ryzer ID

	dateCreated: Date = new Date();

	firstName: string = '';				// First Name
	lastName: string = '';				// Last Name
	tapId: string = '';					// TAP Unique ID
	date: Date;							// Date Taken - mindset assessment date
	email: string = '';					// Email
	dateOfBirth: Date;					// Date of Birth/Age
	position: string = '';				// Self Reported Position
	school: string = '';				// School
	schoolType: string = '';			// School Type
	schoolLocation: string = '';		// School Location
	
	archetype: string = '';				// Archetype
	trafficLight: string = '';			// Traffic Light

	overall: number = 0;				// Overall TAP Score (1-100)
	mindsetFit: number = 0;				// Mindset Fit Score (0-100)
	
	mentalToughness: number = 0;		// Mental Toughness (1-100)
	composure: number = 0;				// Composure (1-100)
	confidence: number = 0;				// Confidence (1-100)
	drive: number = 0;					// Drive (1-100)
	grit: number = 0;					// Grit (1-100)

	mentalExecution: number = 0;		// Mental Execution (1-100)
	adaptability: number = 0;			// Adaptability (1-100)
	decisiveness: number = 0;			// Decisiveness (1-100)
	focusSpeed: number = 0;				// Focus Speed (1-100)

	coachability: number = 0;			// Coachability (1-100)
	attentionToDetail: number = 0;		// Attention to Detail (1-100)
	conventionality: number = 0;		// Conventionality (1-100)
	growthMindset: number = 0;			// Growth Mindset (1-100)
	informationProcessing: number = 0;	// Information Processing (1-100)

	hittingMindset: number = 0;			// Hitting Mindset (1-100)
	leadershipRating: number = 0;		// Leadership Rating (1-100)
	pressurePerformer: number = 0;		// Pressure Performer (1-100)

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['dateCreated']) this.dateCreated = new Date(obj['dateCreated']);
		if (obj['date']) this.date = new Date(obj['date']);
		if (obj['dateOfBirth']) this.dateOfBirth = new Date(obj['dateOfBirth']);
		return this;
	}

	get FullName(): string {
		if (!this.firstName && !this.lastName) { return 'unspecified' }		
		return this.firstName + ' ' + this.lastName;
	}

	get HasError(): boolean {
		return isNotEmpty( this.error );
	}

	EvaluationStr(item: string, noviceThreshold: number = 35) {
		if( isZero(this[item]) ) return '';
		if( this[item] < noviceThreshold ) return "N";
		return this[item].toString();
	}
}