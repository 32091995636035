
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAPartnerProduct, BAPartnerModel } from '@/models';
import { BAIconsMixin, StylesMixin } from '@/mixins';
import PartnerProductPurchaseDialog from './PartnerProductPurchaseDialog.vue'

@Component({
    components: { 
        PartnerProductPurchaseDialog
    },
})
export default class PartnerProductCard extends Mixins(BAIconsMixin, StylesMixin) {
    @Prop({required: true}) product: BAPartnerProduct;
    @Prop() partner: BAPartnerModel;
}
