
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { VuetifyMixin, VideoUploadMixin, BAIconsMixin } from '@/mixins';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { Tag } from '@/models/tag/Tag';

export interface SubmitEvent{ 
	category: string[],
	title: string,
	description: string,
	addToHighlight: boolean,
	tags: Tag[],
}

@Component({
	components: {},
})
export default class ClipDetailForm extends Mixins(VuetifyMixin, VideoUploadMixin, BAIconsMixin) {
	@Prop({ default: null }) editClip: VideoClipModel | null;
	@Prop({ type: Boolean, default: false}) visible: boolean = false;

	category: string[];
	title = '';
	description = '';
	addToHighlight = false;
	tags: Tag[] = [];

	inputTags(tags: Tag[]): void{
		this.tags = tags;
	}

	mounted(): void{
		if(this.editClip !== null){
			this.category = this.editClip.category;
			this.title = this.editClip.name;
			this.description = this.editClip.text;
			this.addToHighlight = this.editClip.inHighlightReel;
			this.tags = this.editClip.tags;
		}
	}

	submit(): void {
		const submitEvent: SubmitEvent = {
			category: this.category,
			title: this.title,
			description: this.description,
			addToHighlight: this.addToHighlight,
			tags: this.tags,
		}
		this.$emit('submit', submitEvent);
	}

	get clipItems(): string[] {
		const items = VideoClipModel.ATHLETE_CLIP_CATEGORIES;
		return items
	}
}
