/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfig } from 'vue-router';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { eventsAdminStore } from '@/store';

const EventAdminPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/EventAdminPage.vue');
const PhysicalEventsDataEntryPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/PhysicalEventsDataEntryPage.vue');
const BestAthletesEventDashboard = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventDashboard.vue');
const BestAthletesEventAttendeeList = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventAttendeeList.vue');
const BestAthletesEventOrderAdmin = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/components/events/BestAthletesEventOrderAdmin.vue');

const BAEventsAdminDashboard = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/admin/BAEventsAdminDashboard.vue');
const BAEventDetailPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/BAEventDetailPage.vue');
const BAEventAdminDetailPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/admin/BAEventAdminDetailPage.vue');
const BAEventAdminRedeemPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/admin/BAEventAdminRedeemPage.vue');
const BAEventLocationAdminPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/admin/BAEventLocationAdminPage.vue');
const BAEventSponsorAdminPage = () => import(/* webpackChunkName: "EventsAdminModule" */ '@/views/admin/BAEventSponsorAdminPage.vue');

export const EventAdminRouteConfigs: RouteConfig[] = [
	{
		name: AdminRoutes.EventPhysical,
		path: '/event-physical',
		meta:{
			pageTitle: 'Physical Assessment Data Entry',
		},
		component: PhysicalEventsDataEntryPage
	},
	{
		name: AdminRoutes.BAEventAdmin,
		path: '/baevent-admin',
		meta: {
			pageTitle: 'Best Athletes Events Administration'
		},
		component: BAEventsAdminDashboard
	},
	{
		name: AdminRoutes.BAEventDetail,
		path: '/baevent/:eventId',
		meta: {
			pageTitle: 'Best Athletes Event'
		},
		component: BAEventDetailPage
	},
	{
		name: AdminRoutes.BAEventAdminDetail,
		path: '/baevent-admin/:eventId',
		meta: {
			pageTitle: 'Best Athletes Event Administration'
		},
		component: BAEventAdminDetailPage
	},
	{
		name: AdminRoutes.BAEventAdminRedeem,
		path: '/baevent-redeem',
		meta: {
			pageTitle: 'Best Athletes Event Check In'
		},
		component: BAEventAdminRedeemPage
	},
	{
		name: AdminRoutes.BAEventLocationsAdmin,
		path: '/baeventlocations',
		meta: {
			pageTitle: 'Best Athletes Event Locations Administration'
		},
		component: BAEventLocationAdminPage
	},
	{
		name: AdminRoutes.BAEventSponsorsAdmin,
		path: '/baeventsponsors',
		meta: {
			pageTitle: 'Best Athletes Event Sponsors Administration'
		},
		component: BAEventSponsorAdminPage
	},
	{
		name: AdminRoutes.EventAdmin,
		path: '/event-admin',
		meta:{
			pageTitle: 'All Events',
		},
		component: EventAdminPage
	},
	{
		path: '/event-admin/:eventId',
		props: true,
		async beforeEnter(to, _, next){
			await eventsAdminStore.loadCurrentEventById(to.params.eventId);
			return next();
		},
		component: BestAthletesEventDashboard,
		children:[
			{
				path: '',
				name: AdminRoutes.EventDashboard,
				props: true,
				component: BestAthletesEventAttendeeList,
				meta: {
					pageTitle: 'Back to events',
					useBackButton: true,
				},
			},
			{
				path: 'order/:eventbriteOrderId',
				name: AdminRoutes.EventOrderAdmin,
				props: true,
				component: BestAthletesEventOrderAdmin,
				meta:{
					pageTitle: 'Back to events',
					useBackButton: true,
				}
			}
		]
	}
];