import { getEnumValues } from '../enums';
export enum CrudPermission{
	Create = 'create',
	Read = 'read',
	Update='update',
	Delete='delete',

	Public='public',
	ListAll='list-all',
}
export const CrudPermissions = getEnumValues<CrudPermission>(CrudPermission);