
import { Component, Mixins, PropSync } from "vue-property-decorator";
import { BAIconsMixin, VuetifyMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import { LanguageCode, Language, CountryCode, Country } from "@/../types/enums";

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {}
})
export default class EditRecruitingProfileBio extends Mixins(BAIconsMixin, VuetifyMixin) {
	@PropSync('editAthleteProfile', { required: true }) EditAthleteProfile: AthleteProfileModel;	

	async created(): Promise<void> {
		// Point the array reference to EditProfile's array
		this.eagerLanguagesSpoken = this.EditAthleteProfile.languagesSpoken;
		this.eagerCitizenships = this.EditAthleteProfile.citizenships;
	}

	// Lanaguages
	eagerLanguagesSpoken: LanguageCode[] = [];
	get LanguagesSpoken(): LanguageCode[] {
		return this.eagerLanguagesSpoken;
	}
	get languageItems(): Item<LanguageCode>[] {
		// Get all the languages from the Language type and turn it items array for autocomplete
		let items: Item<LanguageCode>[] = [];
		Object.keys(Language).forEach(lang_code => {
			items.push({
				text: Language[lang_code],
				value: lang_code as LanguageCode
			});
		});

		return items;
	}
	get isLanguagesFull(): boolean {
		return this.isArrayValuesFull(this.EditAthleteProfile.languagesSpoken);
	}

	// Citizenship
	eagerCitizenships: CountryCode[] = []
	get Citizenships(): CountryCode[] {
		return this.eagerCitizenships;
	}
	get citizenshipItems(): Item<CountryCode>[] {
		let items: Item<CountryCode>[] = [];

		Object.keys(Country).forEach(country_code => {
			items.push({
				text: Country[country_code],
				value: country_code as CountryCode
			});
		});

		return items;
	}
	get isCitizenshipsFull(): boolean {
		return this.isArrayValuesFull(this.EditAthleteProfile.citizenships);
	}

}
