
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../../mixins';

@Component
export default class VideoThumbnail extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ required: true }) thumbnailUrl: string;
	@Prop({ default: 'baColorPrimaryBlue' }) color: string;
	@Prop({ default: 100 }) iconSize: number;
}
