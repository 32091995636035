/* CoachRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as Routes from '../../types/constants/web_client_user.routes';
import { AppHostnameMixin } from '@/mixins';
import { GameReportModel, ScoutingObservationModel } from '@/models'
import { gameReportApi } from '@/api/GameReportApi';
import { isEmpty } from 'lodash';

/**
 * Provides routing routines
 */
@Component
export class CoachRoutingMixin extends Mixins(AppHostnameMixin) {
	RouteToCoachAthleteReportsSummary(): Partial<Route> {
		return {
			name: Routes.CoachAthleteReportsSummary,
		}
	}
	RouteToCoachAthleteReport(reportId): Partial<Route> {
		return {
			name: Routes.CoachAthleteReport,
			params:{
				reportId,
			}
		};
	}
	RouteToCreateAthleteReport(athleteId: string, teamId: string, coachId: string): Partial<Route> {
		return {
			name: Routes.CoachAthleteReport,
			params:{
				athleteId,
				teamId,
				coachId,
			}
		};
	}
	RouteToCoachGameReportsSummary(): Partial<Route> {
		return {
			name: Routes.CoachGameReportsSummary,
			params:{
				...this.$route.params,
			}
		};
	}
	RouteToCoachGameReport(teamId: string, reportId: string): Partial<Route> {
		return {
			name: Routes.CoachGameReport,
			params:{
				reportId,
				teamId,
			}
		};
	}
	RouteToCoachScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.CoachScoutingReportForm,
			params:{
				reportId,
			}
		};
	}
	RouteToCoachViewScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.ViewScoutingReport,
			params:{
				reportId,
			},
		};
	}

	RouteToCoachScoutingReportCreate(athleteId: string, teamId: string = undefined): Partial<Route> {
		return {
			name: Routes.CoachScoutingReportFormAthlete,
			params: { 
				athleteId,
				teamId,
			}
		}
	}

	RouteToTeamProgressDashboard(teamId): Partial<Route> {
		return {
			name: Routes.CoachTeamProgressDashboard,
			params: { 
				teamId,
			}
		}
	}
	RouteToCoachViewAthlete(athleteId): Partial<Route> {
		return {
			name: Routes.CoachViewAthlete,
			params: {
				athleteId,
			}
		}	
	}
	RouteToCoachViewTeam(teamId): Partial<Route> {
		return {
			name: Routes.TeamDashboard,
			params:{
				teamId,
			}			
		}
	}

	gotoCoachRoute(route: Partial<Route>) {
		if( this.AthleteAppActive ) return;
		this.$router.push(route);
	}

	gotoTeamProgressDashboard(teamId: string) {
		this.gotoCoachRoute(this.RouteToTeamProgressDashboard(teamId));
	}

	gotoCoachViewPlayerProfile(athleteId: string): void {
		this.gotoCoachRoute(this.RouteToCoachViewAthlete(athleteId))
	}

	gotoCoachViewTeam(teamId: string) {
		this.gotoCoachRoute(this.RouteToCoachViewTeam(teamId));
	}

	gotoCoachAthleteReportsSummary() {
		this.gotoCoachRoute(this.RouteToCoachAthleteReportsSummary());
	}
	gotoCoachAthleteReport(reportId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCoachAthleteReport(reportId));
	}
	gotoCreateAthleteReport(athleteId: string, teamId: string = undefined, coachId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCreateAthleteReport(athleteId, teamId, coachId))
	}
	gotoGameReportsSummary() {
		this.gotoCoachRoute(this.RouteToCoachGameReportsSummary());
	}
	gotoCoachGameReport(teamId: string = undefined, reportId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCoachGameReport(teamId, reportId));
	}
	async gotoCoachGameReportFromObservation(observationId: string) {
		this.gotoGameReportsSummary();

		// TODO: Use gameReportApi to find the observation when obs ids are added to game report
		const gameReport: GameReportModel = await gameReportApi.findByObservation(observationId);
		if( isEmpty(gameReport) ) return;
		this.gotoCoachGameReport(gameReport.teamId, gameReport.id);
	}

	gotoCoachScoutingReport(reportId: string = null) {
		this.gotoCoachRoute(this.RouteToCoachScoutingReport(reportId));
	}
	gotoCreateScoutingReport(athleteId: string, teamId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCoachScoutingReportCreate(athleteId, teamId))
	}
	gotoCoachViewScoutingReport(reportId: string) {
		this.gotoCoachRoute(this.RouteToCoachViewScoutingReport(reportId));
	}
}
