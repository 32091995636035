import { getEnumValues } from './get-enum-values';
export enum PlayerType {
	Anchor='Anchor',
	Spreader='Spreader',
	Aggressor='Aggressor',
	Safety='Safety',
	Progressor='Progressor',
	Overlapper='Overlapper',
	Builder='Builder',
	Distributor='Distributor',
	BoxToBox='Box to Box',
	Orchestrator='Orchestrator',
	Creator='Creator',
	BoxCrasher='Box Crasher',
	Outlet='Outlet',
	Unlocker='Unlocker',
	WideThreat='Wide Threat',
	Roamer='Roamer',
	Target='Target',
	Finisher='Finisher',
}
export const PlayerTypeValues = getEnumValues<PlayerType>(PlayerType);
