
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import PaymentMethodIcon from '@/components/payments/PaymentMethodIcon.vue';
import { PaymentMethodModel } from '@/models/stripe/PaymentMethodModel';

@Component({
	components: {
		PaymentMethodIcon,
	}
})
export default class PaymentMethod extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: null }) paymentMethod: PaymentMethodModel | null;
	@Prop({ type: Boolean, default: false }) hideControls: boolean;
	get BorderColor(): string{
		if(this.IsDefaultPaymentMethod){
			return this.getColor('baColorVibrantBlue');
		}
		return this.getColor('baColorGray8');
	}
	get Style(): Record<string, any>{
		return {
			'max-width': '500px',
			border: `1px solid ${this.BorderColor}`,
		}
	}
	get HideControls(): boolean{
		return this.hideControls;
	}
	get CardHasId(): boolean{
		return this.paymentMethod && !!this.paymentMethod.id;
	}
	get IsVisa(): boolean{
		if(this.paymentMethod === null) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails) return false;
		return this.paymentMethod.stripePaymentMethodDetails.card.brand === 'visa';
	}
	get IsMastercard(): boolean{
		if(this.paymentMethod === null) return false;
		if(!this.paymentMethod.stripePaymentMethodDetails) return false;
		return this.paymentMethod.stripePaymentMethodDetails.card.brand === 'mastercard';
	}
	// TODO Add this to PaymentMethodModel
	get IsDefaultPaymentMethod(): boolean{
		return false;
	}
	get NameOnCard(): string{
		if(this.paymentMethod === null) return '';
		if(!this.paymentMethod.stripePaymentMethodDetails) return '';
		if(!this.paymentMethod.stripePaymentMethodDetails.billing_details) return '';
		return this.paymentMethod.stripePaymentMethodDetails.billing_details.name;
	}
	get Last4(): string{
		if(this.paymentMethod === null) return 'XXXX';
		if(!this.paymentMethod.stripePaymentMethodDetails) return 'XXXX';
		return this.paymentMethod.stripePaymentMethodDetails.card.last4;
	}
	get ExpiryString(): string{
		if(this.paymentMethod === null) return 'XX/XX';
		if(!this.paymentMethod.stripePaymentMethodDetails) return 'XX/XX';
		const MM = `0${this.paymentMethod.stripePaymentMethodDetails.card.exp_month}`.slice(-2);
		const YY = `${this.paymentMethod.stripePaymentMethodDetails.card.exp_year}`.slice(-2);
		return `${MM}/${YY}`;
	}
}
