/**
 * Takes a YouTube URL and returns the ID or false
 * Source: https://regex101.com/r/vHEc61/1 from https://stackoverflow.com/questions/19377262/regex-for-youtube-url
 * @param url Youtube URL
 * @returns Youtube ID or False
 */
function youtubeParser(url: string): string {
	const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
	const match = url.match(regExp);
	if( !match ) return undefined;

	return(match.length > 7 && match[6].length == 11) ? match[6] : undefined;
}

function vimeoParser(url: string): string {
	const regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
	const match = url.match(regExp);
	if( !match ) return undefined;

	return (match.length > 5)? match[4] : undefined;
}

export function videoUrlParser(url: string): string {
	const youtubeUrl = youtubeParser(url);
	if( !!youtubeUrl ) return youtubeUrl;

	const vimeoUrl = vimeoParser(url);
	if( !!vimeoUrl ) return vimeoUrl;

	return undefined;
}

export function youtubeUrl(youtubeId: string, embed: boolean = false, start: string = ''): string {
	if( !youtubeId ) return undefined;

	var options = '';
	if( start ) {
		options += `?start=${start}`;
	} else if( embed ) {
		options += '?modestbranding=1?controls=0'
	}
	return `https://www.youtube.com/${embed? 'embed/':'watch?v='}${youtubeId}${options}`;
}

/**
 * https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api	
 */
export function youtubeThumbnailUrl(youtubeId: string): string {
	if( !youtubeId ) return undefined;

	return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
}
