
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../../mixins';
import { FormRulesMixin } from '../../../mixins/FormRulesMixin';
import { DepthChartTemplatesMixin } from '../../../mixins/SelectorMixins';
import { DepthChartModel } from '../../../models/depthChart';
import { TeamModel } from '@/models/team';

@Component
export default class NewDepthChartForm extends Mixins(FormRulesMixin, DepthChartTemplatesMixin, VuetifyMixin, BAIconsMixin){

	// v-model form 'valid' boolean
	@Prop({ default: false }) value: boolean ;
	input(value){ this.$emit('input', value); }

	@Prop({ default: false, type: Boolean }) loading: boolean;
	@Prop({ default: null }) template: DepthChartModel | null;
	@Prop() team: TeamModel;

	formValid = false;
	formValue = {
		name: "",
		template: null,
		autoFill: true,
	}

	get Templates(): DepthChartModel[]{
		if(this.template){
			return [
				this.template,
				...this.AvailableDepthChartTemplates
			];
		}
		return this.AvailableDepthChartTemplates;
	}

	generateName: boolean = true;
	useDefaultName(value: DepthChartModel){
		if(this.generateName){
			this.formValue.name = `${this.team.name} ${new Date().getFullYear()} | ${value.name}`;
		}
	}

	submit($event: Event){
		$event.preventDefault();
		this.$emit('submit', this.formValue);
	}

	cancel(){
		this.$emit('cancel');
	}
}
