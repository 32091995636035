
import { Component, Vue, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import TeamProfileCard from "@/components/profile/recruiting/TeamProfileCard.vue";

@Component({
	components: {
		Page,
		TeamProfileCard,
	},
})
export default class TeamShare extends Vue {
	@Prop({ default: '' }) owner: string;
	@Prop({ required: true }) teamId: string;
	@Prop({ default: '' }) sharingUrlId: string;
	@Prop({ type: Boolean }) printPreview;

}
