

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AuthMixin, VuetifyMixin, AppHostnameMixin, BAIconsMixin } from '@/mixins';
import { userStore } from '@/store'

@Component
export default class Unauthorized extends Mixins(VuetifyMixin, AuthMixin, AppHostnameMixin, BAIconsMixin) {

  gotoHome(){
    userStore.logout();
    this.logoutAthleteApp();
  }
}
