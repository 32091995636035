import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { SelfAssessmentModel } from '@/models/athlete/SelfAssessmentModel';
import { MetricData } from '@/../types/interfaces';
import { AthleteMetric } from '@/../types/enums';
import { assessmentMetricValueOrNull } from '@/pipes/assessment-metric-value-or-null.pipe';
import { metricHasValue } from './metric-has-value.pipe';

export function assessmentToMetricExpanded(assessmentData: AthleteAssessmentDataModel | SelfAssessmentModel, metric: AthleteMetric): MetricData {
	if (!assessmentData || assessmentData.__type === 'SelfAssessmentModel') {
		return {
			label: '--', value: '--', unit: '', descriptionText: '', prescriptionText: '', missing: true,
		}
	}
	switch (metric) {
	case AthleteMetric.Acceleration:
		return {
			label: 'Your Acceleration',
			value: `${assessmentData.acceleration ? parseFloat(assessmentData.acceleration.toFixed(2)) : '--'}`,
			unit: 'm/s',
			descriptionText: "The 10-metre sprint measures acceleration ability. A strong correlation has been observed in 10-metre sprint speeds over different age groups and both genders, as well as playing levels, with older and more elite players covering the distances in a faster time.",
			prescriptionText: "To improve acceleration requires improving explosive strength. Use uphill sprinting to do this, with the following protocol: 2 x 10 x (5-6 seconds uphill sprints, with 60 seconds rest between sprints, and 3 minutes rest between sets)",
			missing: !metricHasValue(assessmentData.acceleration),
		};
	case AthleteMetric.Speed:
		return {
			label: 'Velocity',
			value: `${assessmentData.speed ? parseFloat(assessmentData.speed.toFixed(2)) : '--'}`,
			unit: 'm/s',
			descriptionText: "The 20-35 metre sprint test measures maximal running speed. A strong correlation has been observed in 20-35-metre sprint speeds over different age groups and both genders, as well as playing levels, with older and more elite players covering the distances in a faster time.",
			prescriptionText: "To improve speed requires performing sprint training. Run on a track or field, with  the following protocol: 3 x 5 x (30-metre sprints,with 1.5 minutes rest between sprints, and 4 minutes rest between sets)",
			missing: !metricHasValue(assessmentData.speed),
		};
	case AthleteMetric.Power:
		return {
			label: 'Power Output',
			value: `${assessmentData.power ? parseFloat(assessmentData.power.toFixed(2)) : '--'}`,
			unit: 'Watts',
			descriptionText: "The Counter Movement Jump assesses athletes’ leg muscle power and elasticity. A strong correlation has been observed in jump heights over different age groups and both genders, as well as playing levels, with older and more elite players reaching higher jump heights.",
			prescriptionText: "To improve power requires leg strength. Use squat and lunge exercises, with the following protocol: 3 x (10 barbell squats, with 2 minutes rest between sets) and 3 x (10 dumbbell lunges, with 2 minutes rest between sets)",
			missing: !metricHasValue(assessmentData.power),
		};
	case AthleteMetric.Agility:
		return {
			label: '',
			value: '',
			unit: '',
			descriptionText: "The Drop Jump assesses athletes’ leg muscle reactive strength index or “RSI”, which is a measure of agility. A strong correlation has been observed in RSI over different age groups and both genders, as well as playing levels, with older and more elite players reaching higher RSI scores.",
			prescriptionText: "To improve reactive strength requires jumping exercises. Use plyometric exercises with the following protocol: 6 x (10 maximal squat jumps, with 60 seconds rest between sets). Use a weight that you can lift comfortably 10 times.",
			missing: !metricHasValue(assessmentData.reactiveStrengthIndex),
		};
	case AthleteMetric.Recovery:
		return {
			label: 'Distance',
			value: assessmentMetricValueOrNull(assessmentData.yoyoIntermittentRecoveryTestDistance),
			unit: 'm',
			descriptionText: "This test is a sport-specific measure of recovery for soccer players. The distance covered during this test correlates highly with the amount of high intensity running covered in a game as assessed with time motion analysis. Therefore, a player who covers a greater distance in this test, can perform more high intensity running during a game.",
			prescriptionText: "To improve recovery requires aerobic interval training. Run on a track or field, using the following protocol: 4 x (4 minutes running at 80% intensity, with 4 minutes running at 60% intensity as active rest). For the 80% interval, try to run at your Maximal Aerobic Velocity.",
			missing: !metricHasValue(assessmentData.yoyoIntermittentRecoveryTestStage),
		};
	default:
		return { label: '', value: '', unit: '', descriptionText: '', prescriptionText: '', missing: true };
	}
}


