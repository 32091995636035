
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { PublicProfileInfo } from '@/../types/interfaces';
import { athleteApi } from '@/api/AthleteApi';
import { coachApi } from '@/api/CoachApi';
import { AthleteProfileModel } from '@/models';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel'
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { UserProfileModel } from '@/models/UserProfileModel';
import { TeamAthleteProgress } from '@/models/team'
import { AppHostnameMixin, StringsMixin } from '@/mixins';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class ProfileProvider extends Mixins(StringsMixin, AppHostnameMixin){
	@Prop({ default: 'div' }) private as: string;

	@Prop() private profileId: string;
	@Prop({ type: Boolean }) private coach;
	@Prop({ type: Boolean }) private athlete;
	@Prop({ type: Boolean }) private full;
	@Prop({ type: Boolean }) private progress;
	@Prop({ type: Boolean }) private public;

	hasPicture: boolean = false;
	pictureUrl: string = "";
	fullName: string = "default";
	profileFound: boolean = false;
	profile: PublicProfileInfo<UserProfileModel> = undefined;
	fullAthleteProfile: AthleteProfileModel = undefined;
	athleteProgress: TeamAthleteProgress = undefined;
	athleteProfile: PublicProfileInfo<AthleteProfileModel> = undefined;
	coachProfile: CoachProfileModel = undefined;

	// SlotProps returns the properties provided by ProfileProvider
	get SlotProps(): {
			loadingProfile: boolean,
			profileFound: boolean,
			fullName: string, 
			profile: PublicProfileInfo<UserProfileModel>, 
			athleteProfile: PublicProfileInfo<AthleteProfileModel>, 
			fullAthleteProfile: AthleteProfileModel,
			athleteProgress: TeamAthleteProgress,
			coachProfile: CoachProfileModel,
		} {
		return {
			loadingProfile: this.loadingProfile,
			profileFound: this.profileFound,
			profile: this.profile,
			athleteProfile: this.athleteProfile,
			fullAthleteProfile: this.fullAthleteProfile,
			athleteProgress: this.athleteProgress,
			coachProfile: this.coachProfile,
			fullName: this.fullName,
		};
	}

	// when the object is created, load the profile
	created(): void {
		this.loadProfile();
	}

	loadingProfile = true;
	// watch the profileId prop. if it changes, load the profile
	@Watch('profileId')
	loadProfile(): void{
		if (!this.profileId) return;
		this.loadingProfile = true;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.lookupProfile(this.profileId).catch(() => {});
		this.loadingProfile = false;
	}

	// lookup the profile from the store
	async lookupProfile(profileId: string): Promise<void> {
		if ( this.IsEmpty(profileId)) return;

		// TODO: Add caching
		if (this.athlete) {
			if( this.full ) {
				if( this.progress ) {
					const recruitingProfile: RecruitingProfileModel = await athleteApi.getAthleteRecruitingProfile(profileId, {as : this.getAppType} );
					this.fullAthleteProfile = recruitingProfile.athleteProfile;
					this.athleteProgress = this.fullAthleteProfile.InitProgress(recruitingProfile);
				} else {
					this.fullAthleteProfile = await athleteApi.getFullProfile(profileId);
				}
			}
			else this.profile = await this.lookupAthlete(profileId);
		}
		else if (this.coach) {
			this.profile = await this.lookupCoach(profileId);
		}
		else {
			// coach or athlete not specified
			// try to find an athlete with this profileId
			this.profile = await this.lookupAthlete(profileId);
			if (!this.profile) {
				// no athlete found with this profileId, look for a coach
				this.profile = await this.lookupCoach(profileId);
			}
		}

		// update profile information
		this.profileFound = !!this.profile;
		this.fullName = this.profile?.FullName;
	}

	async lookupAthlete(profileId: string): Promise<PublicProfileInfo<AthleteProfileModel> | undefined> {
		try{
			if( this.public ) {
				this.athleteProfile = await athleteApi.getPublicProfile(profileId);
			} else {
				this.athleteProfile = await athleteApi.getBasicProfile(profileId);
			}
		} catch(e) {
			console.error(`ERROR looking up Athlete: ${e}`);
		}

		return this.athleteProfile;
	}

	async lookupCoach(profileId: string): Promise<CoachProfileModel>{
		this.coachProfile = await coachApi.findById(profileId);
		return this.coachProfile;
	}
}
