
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import {VuetifyMixin, BAIconsMixin} from '@/mixins'

@Component
export default class OrderSelector extends Mixins(VuetifyMixin, BAIconsMixin){

	@Prop({ required: true }) private order: number;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) private borderColor: string;
	
}
