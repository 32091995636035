export type AdminAppHostname = 'admin.local.bestathletes.co:8080'
														| 'admin.dev.bestathletes.co'
														| 'admin.testing.bestathletes.co'
														| 'admin.stage.bestathletes.co'
														| 'admin.app.bestathletes.co';
export const AdminAppHostnames: AdminAppHostname[] = [
	'admin.local.bestathletes.co:8080',
	'admin.dev.bestathletes.co',
	'admin.testing.bestathletes.co',
	'admin.stage.bestathletes.co',
	'admin.app.bestathletes.co',
];

export type ClubAdminAppHostname = 'club.local.bestathletes.co:8080'
														| 'club.dev.bestathletes.co'
														| 'club.testing.bestathletes.co'
														| 'club.stage.bestathletes.co'
														| 'club.app.bestathletes.co';
export const ClubAdminAppHostnames: ClubAdminAppHostname[] = [
	'club.local.bestathletes.co:8080',
	'club.dev.bestathletes.co',
	'club.testing.bestathletes.co',
	'club.stage.bestathletes.co',
	'club.app.bestathletes.co',
];

export type CoachingAppHostname = 'app.local.bestathletes.co:8080/c'
														| 'dev.bestathletes.co/c'
														| 'testing.bestathletes.co/c'
														| 'stage.bestathletes.co/c'
														| 'app.bestathletes.co/c';
export const CoachingAppHostnames: CoachingAppHostname[] = [
	'app.local.bestathletes.co:8080/c',
	'dev.bestathletes.co/c',
	'testing.bestathletes.co/c',
	'stage.bestathletes.co/c',
	'app.bestathletes.co/c',
];

export type PublicAppHostname = 'app.local.bestathletes.co:8080/public'
														| 'dev.bestathletes.co/public'
														| 'testing.bestathletes.co/public'
														| 'stage.bestathletes.co/public'
														| 'app.bestathletes.co/public';
export const PublicAppHostnames: PublicAppHostname[] = [
	'app.local.bestathletes.co:8080/public',
	'dev.bestathletes.co/public',
	'testing.bestathletes.co/public',
	'stage.bestathletes.co/public',
	'app.bestathletes.co/public',
];

export type AthleteAppHostname = 'app.local.bestathletes.co:8080'
														| 'dev.bestathletes.co'
														| 'testing.bestathletes.co'
														| 'stage.bestathletes.co'
														| 'app.bestathletes.co';
export const AthleteAppHostnames: AthleteAppHostname[] = [
	'app.local.bestathletes.co:8080',
	'dev.bestathletes.co',
	'testing.bestathletes.co',
	'stage.bestathletes.co',
	'app.bestathletes.co',
];