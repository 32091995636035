
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';

@Component
export default class HelpButton extends Mixins(VuetifyMixin){
    @Prop({ required: true }) value: boolean;
    @Prop({ type: Boolean }) large;

    onToggleHelp() {
        this.value = !this.value;
        this.$emit('input', this.value);
        this.$emit('toggle');
    }

    get Icon(): string {
        return this.value? 'mdi-help-circle' : 'mdi-help-circle-outline';
    }
    get TooltipText(): string {
        return this.value? `Showing help information. Click to hide help` : `Click to show help on how to improve your profile`;
    }
    get TooltipColor(): string {
        return this.value? 'primary' : 'success';
    }
}
