import { getEnumValues } from './get-enum-values';

export enum SportName{
	Soccer='Soccer',
	Baseball='Baseball',
	Golf='Golf',
	Tennis='Tennis',
	Softball='Softball',
	Basketball='Basketball',
	Volleyball='Volleyball',
	Cheerleading='Cheerleading',
	Football='Football',
	SwimmingDiving='Swimming & Diving',
	Track='Track',
	CrossCountry='Cross Country',
	Bowling='Bowling',
	WaterPolo='Water Polo',
	Lacrosse='Lacrosse',
	Wrestling='Wrestling',
	SandVolleyball='Sand Volleyball',
	TrackField_Outdoor='Track & Field (Outdoor)',
	Archery='Archery',
	BeachVolleyball='Beach Volleyball',
	Rodeo='Rodeo',
	Dance='Dance',
	Equestrian='Equestrian',
	IceHockey='Ice Hockey',
	Skiing='Skiing',
	Badminton='Badminton',
	Rifle='Rifle',
	Rugby='Rugby',
	CyclingBiking='Cycling/Biking',
	Crew='Crew',
	CrewRowing='Crew/Rowing',
	Gymnastics='Gymnastics',
	FieldHockey='Field Hockey',
	Triathlon='Triathlon',
	AcrobaticsTumbling='Acrobatics & Tumbling',
	Fencing='Fencing',
	Sailing='Sailing',
	Squash='Squash',
	Polo='Polo',
	SprintFootball='Sprint Football',
	SynchronizedSwimming='Synchronized Swimming',
	Pistol='Pistol',
	Cyclin='Cycling'
}
export const SportNameValues = getEnumValues<SportName>(SportName);

export enum SoccerPosition{
	Goalkeeper='Goalkeeper',
	RightFullback="Right Fullback",
	LeftFullback="Left Fullback",
	CenterBack="Center Back",
	DefendingMidfielder="Defending Midfielder",
	RightMidfielder="Right Midfielder",
	CentralMidfielder="Central Midfielder",
	AttackingMidfielder="Attacking Midfielder",
	LeftMidfielder="Left Midfielder",
	Striker="Striker",
}

export enum BaseSoccerPosition {
	Goalkeeper = "Goalkeeper",
	Defender = "Defender",
	Midfielder = "Midfielder",
	Forward = "Forward",
}

export const BaseSoccerPositionValues = getEnumValues<BaseSoccerPosition>(BaseSoccerPosition);

export const SoccerPositionToBasePositionMapping: Record<SoccerPosition, BaseSoccerPosition> = {
	'Goalkeeper': BaseSoccerPosition.Goalkeeper,
	"Right Fullback": BaseSoccerPosition.Defender,
	"Left Fullback": BaseSoccerPosition.Defender,
	"Center Back": BaseSoccerPosition.Defender,
	"Defending Midfielder": BaseSoccerPosition.Midfielder,
	"Right Midfielder": BaseSoccerPosition.Midfielder,
	"Central Midfielder": BaseSoccerPosition.Midfielder,
	"Attacking Midfielder": BaseSoccerPosition.Midfielder,
	"Left Midfielder": BaseSoccerPosition.Midfielder,
	"Striker": BaseSoccerPosition.Forward,
}

export const SoccerPositionValues = getEnumValues<SoccerPosition>(SoccerPosition);

export enum HockeyPosition{
	Goaltender='Goaltender',
	LeftWinger='Left Winger',
	LeftDefenseman='Left Defenseman',
	RightWinger='Right Winger',
	RightDefenseman='Right Defenseman',
	Center ='Center',
}
export const HockeyPositionValues = getEnumValues<HockeyPosition>(HockeyPosition);

export type SportPosition = SoccerPosition | HockeyPosition;
export const SportPositionValues = [...SoccerPositionValues, ...HockeyPositionValues];

