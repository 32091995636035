
import { Component, Prop, Mixins } from 'vue-property-decorator'
import Page from '@/views/Page.vue'
import Alert from '@/components/ui/Alert.vue'
import { filesize } from '@/pipes'
import { getMediumDateString, formatDurationShort } from '@/helpers/date'

import BAVideoPlayer from '@/components/ba-video/BAVideoPlayer.vue'

import { StringsMixin, VideoUploadMixin, VuetifyMixin, BAIconsMixin } from '@/mixins'
import { VideoModel } from '@/models/video/VideoModel'
import { VideoClipModel } from '@/models/video/VideoClipModel'
import { MuxVideoDetail, YoutubeVideoDetail } from '@/../types/interfaces'
import { VideoSource, VideoStatus, LoopMode } from '@/../types/enums'
import { videoApi } from '@/api/VideoApi'
import ClipDetailForm, {SubmitEvent as ClipDetailSubmitEvent} from "@/components/videoStudio/ClipDetailForm.vue";
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import { formatMinutesPretty } from "@/helpers/date";
import { videoUrlParser, youtubeThumbnailUrl } from '@/helpers/youtube'
import { BehaviorSubject } from 'rxjs'
import { VideoUploadDialog, VideoUploadDialogVariant } from "@/components/videoStudio/VideoUploadDialog.vue";
import * as UpChunk from '@mux/upchunk';

@Component({
	components: {
		Alert,
		Page,
		BAVideoPlayer,
		ClipDetailForm,
		SubscriptionInfoProvider,
		VideoUploadDialog
	}
})
export default class VideoStudio extends Mixins(VideoUploadMixin, VuetifyMixin, StringsMixin, BAIconsMixin) {
	formatMinutesPretty = formatMinutesPretty;
	$refs:{
		fileInput: HTMLInputElement;
		baVideoPlayer: BAVideoPlayer;
	}
	@Prop({ default: false, type: Boolean }) value: boolean
	updateValue(value: boolean): void{
		this.$emit('input', value);
	}
	/** Parent Id Used for subscription info */
	@Prop({ default: null }) parentId: string;
	@Prop() resource: string;
	@Prop({ default: []}) videos: VideoModel[];
	@Prop({ default: []}) videoClips: VideoClipModel[];
	get ClipMarkers(): VideoClipModel[]{
		if(this.currentVideo === null) return [];
		return this.CurrentVideoClips;
	}
	clipHovered: string | null = null;
	updateHover(clip: VideoClipModel, val: boolean): void{
		if(val === false) this.clipHovered = null;
		this.clipHovered = clip.id;
	}
	updateVideoClips(videoClips: VideoClipModel[]): void{
		this.$emit('update:video-clips', videoClips);
	}
	@Prop({ required: true }) videoParentId: string = '';

	@Prop({ default: 0, type: Number }) videoHoursUsed: number;
	@Prop({ default: 5, type: Number }) maxVideoHours: number;
	@Prop({ default: 0, type: Number }) youtubeUsed: number;
	@Prop({ default: 3, type: Number }) maxYoutubeVideos: number;
	@Prop({ default: VideoUploadDialogVariant.SHOWCASE }) uploadDialogVariant: VideoUploadDialogVariant;
	@Prop({ default: true, type: Boolean }) clippingAvailable: boolean;

	VideoSource = VideoSource;

	currentVideo: VideoModel | null = null;
	currentClip: VideoClipModel | null = null;

	uploadDialogVisible: boolean = false;

	@Prop({ default: () => new BehaviorSubject<VideoClipModel | null>(null)}) currentClipObservable: BehaviorSubject<VideoClipModel | null>;

	async created(): Promise<void> {
		this.currentClipObservable.subscribe(clip => this.currentClip = clip)
	}

	get CurrentClipVideoSrc(): string | null{
		if(this.CurrentClipSourceVideo === null) return null;
		return this.CurrentClipSourceVideo.SourceUrl;
	}
	get CurrentClipSourceVideo(): VideoModel | null{
		if(this.currentClip === null) return this.currentVideo;
		const video = this.videos.find(v => v.id === this.currentClip.video);
		if(!video){
			return null;
		}
		return video;
	}

	// for uploading files
	selectedVideoFile: File | null = null
	selectedVideoFileMetadata: any | null
	isUploadingSV = false
	showUpload = false
	savingSVUploadPercentage = 0
	theMuxUploadId: string = ""
	editorVisible = true;

	loopMode: LoopMode = LoopMode.LOOP_PLAYLIST;

	clipDetailFormVisible = false;

	deleteClipDialogVisible = false;
	deleteVideoDialogVisible = false;

	// Youtube
	addingYoutubeVideo: boolean = false;
	youtubeUrl: string = "";

	get sourceVideosLoaded(): VideoModel[] {
		return this.videos;
	}

	get CurrentVideoClips(): VideoClipModel[] {
		if(this.currentVideo === null) return [];
		return this.videoClips.filter(clip => clip.video === this.currentVideo.id);
	}

	filesize(value: number): string {
		return filesize(value, 1)
	}

	datestring(theDate: Date): string {
		return getMediumDateString(new Date(theDate))
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's')
	}

	formatStartTime(theStartTime: number): string {
		return 'at: ' + formatDurationShort(theStartTime, true, 's')
	}

	selectedAVideo(video: VideoModel): void{
		if(!this.currentVideo || video.id !== this.currentVideo.id){
			this.playClip(null);
			this.$refs.baVideoPlayer.resetClipMarkers();
			this.currentVideo = video;
		}
	}

	playClip(clip: VideoClipModel): void{
		this.currentClipObservable.next(clip)
	}

	videoToDelete: VideoModel | null = null;
	get VideoToDeleteClips(): VideoClipModel[]{
		if(this.videoToDelete === null) return [];
		return this.videoClips.filter(clip => clip.video === this.videoToDelete.id);
	}
	confirmDeleteVideo(video: VideoModel): void {
		this.videoToDelete = video;
		this.deleteVideoDialogVisible = true;
	}
	async deleteVideo(): Promise<void> {
		if(!this.videoToDelete){
			this.deleteVideoDialogVisible = false;
			return;
		}
		this.$emit('delete:video', this.videoToDelete);
		this.videoToDelete = null;
	}

	clipToEdit: VideoClipModel | null = null;
	editClip(clip: VideoClipModel): void{
		this.$refs.baVideoPlayer.setMarkersFromClip(clip);
		this.$refs.baVideoPlayer.seekPlayerTime(clip.start);
		this.clipToEdit = clip;
		this.clipDetailFormVisible = true;
	}

	closeClipDetailForm(): void{
		this.$refs.baVideoPlayer.resetClipMarkers();
		this.$refs.baVideoPlayer.$refs.baVideoTimelineSlider.resetRangeSliders();
		this.clipDetailFormVisible = false;
	}
	clipDetailFormVisibleChanged(val: boolean): void{
		this.clipDetailFormVisible = val;
		if(val === false) this.closeClipDetailForm();
	}

	clipToDelete: VideoClipModel | null = null;
	confirmDeleteClip(clip: VideoClipModel): void {
		this.clipToDelete = clip;
		this.deleteClipDialogVisible = true;
	}
	async deleteClip(): Promise<void>{
		if(!this.clipToDelete){
			this.deleteClipDialogVisible = false;
			return;
		}
		this.$emit('delete:video-clip', this.clipToDelete);
		this.deleteClipDialogVisible = false;
		this.clipToDelete = null;
		this.closeClipDetailForm();
	}

	addClip(): void{
		this.currentClip = null;
		this.clipToEdit = null;
		this.clipDetailFormVisible = true;
	}

	createClipAtCurrentTime(title: string, description: string, category: string[]): VideoClipModel {
		if(!this.CurrentClipSourceVideo) return;
		// Durations are already set in the temp clip
		const clip: VideoClipModel = this.$refs.baVideoPlayer.getCurrentClipTimelineData();
		const video: VideoModel = this.CurrentClipSourceVideo;

		clip.name = title
		clip.text = description
		clip.category = category
		clip.video = video.id

		// TODO: Get source different for each source type
		if (video.source == VideoSource.Mux)
			clip.thumbnailUrl =
				'https://image.mux.com/' +
				(video.detail as MuxVideoDetail).playback_ids[0].id +
				'/thumbnail.png?time=' +
				clip.start.toFixed(1).toString()
		else
			clip.thumbnailUrl = ""

		this.closeClipDetailForm();
		return clip;
	}

	async saveClip(eventData: ClipDetailSubmitEvent): Promise<void> {
		const clip = this.createClipAtCurrentTime(eventData.title, eventData.description, eventData.category);
		clip.inHighlightReel = eventData.addToHighlight;
		clip.tags = eventData.tags;
		if(this.clipToEdit){
			clip.id = this.clipToEdit.id;
		}

		// For youtube videos set the thumbnail to the same as the video
		if (this.currentVideo.source === VideoSource.Youtube) {
			clip.thumbnailUrl = this.currentVideo.thumbnailUrl;
		}

		this.$emit('save:video-clip', clip);
	}

	
	// Uploading the Source Video File
	detectVideoFiles($event: Event): void {
		// console.log('In the Detect Video Files and the event is: ', $event)
		this.selectedVideoFile = ($event.target as HTMLInputElement).files[0]
		// console.log('The actual file selected is: ', this.selectedVideoFile)
	}

	async toggleAddingYoutube(): Promise<void> {
		this.youtubeUrl = '';
		this.addingYoutubeVideo = !this.addingYoutubeVideo;
	}

	async addYoutubeVideo(url: string): Promise<void> {
		const video = new VideoModel();
		const youtubeEmbeddedId = videoUrlParser(url);
		
		if( this.IsNotEmpty(youtubeEmbeddedId) ) {
			video.detail = {
				embedded_id: youtubeEmbeddedId,
			}
			const exists = this.videos.findIndex(v => (v.detail as YoutubeVideoDetail)?.embedded_id === youtubeEmbeddedId)
			if (exists >= 0) return;

			video.thumbnailUrl = youtubeThumbnailUrl(video.detail?.embedded_id);
			video.source = VideoSource.Youtube;
			this.$emit('save:video', video);
			this.uploadDialogVisible = false;
		}
	}

	upload: UpChunk.UpChunk | null = null;

	async uploadVideoFile(): Promise<void> {
		const file = this.selectedVideoFile

		// Check if there is a file
		if (file) {
			this.isUploadingSV = true
			this.savingSVUploadPercentage = 0

			// Get the direct upload URL
			let { video, url: uploadurl} = (await this.createMuxUploadURL(this.videoParentId))
			video.name = file.name;

			this.theMuxUploadId = (video.detail as MuxVideoDetail).upload_id

			console.log('Direct Upload URL: ', uploadurl)

			const upload = await this.uploadVideo(file, uploadurl)
			this.upload = upload;

			upload.on('error', err => {
				console.log('Error uploading video')
				console.log(err)
				this.upload = null;
			})

			upload.on('progress', (progress: any) => {
				// progress.detail contains progress number
				console.log(`Upload progress: ${progress.detail}% `)
				this.savingSVUploadPercentage = progress.detail
			})

			upload.on('success', async() => {
				console.log('Video successfull uploaded')
				console.log(`Getting updated video: `, video.id)
				do {
					// Sleep for a second before updating
					await new Promise(r => setTimeout(r, 1618))
					console.log('Waiting for video to be ready');
					// Update the video details
					video = await videoApi.findById(video.id);

					// Check if the video is ready
				} while (video.status != VideoStatus.READY)
				this.showUpload = false
				this.savingSVUploadPercentage = 0
				this.theMuxUploadId = ''
				this.selectedVideoFile = null

				// Patch the file name
				console.log(`Setting the name to: `, file.name)
				video.name = file.name
				await videoApi.patch(video);

				this.isUploadingSV = false;
				this.uploadDialogVisible = false;
				this.$emit('save:video', video);
				this.upload = null;
			})
		}
	}

	async cancelUpload(): Promise<void> {
		// console.log("The cancel finished and the result is: ", cancelReply);
		if (this.upload) {
			this.upload.abort();
		} 
		this.selectedVideoFile = null
		this.isUploadingSV = false
		this.showUpload = false
		this.savingSVUploadPercentage = 0
		this.theMuxUploadId = ''
	}
}
