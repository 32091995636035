import { PerformanceCategory, GradeName } from '@/../types/enums';

export function performanceCategoryToLabel(value: PerformanceCategory | null): string{
	if(value === PerformanceCategory.College) return 'College';
	if(value === PerformanceCategory.Competitive) return 'Competitive';
	if(value === PerformanceCategory.HighPerformance) return 'High Performance';
	if(value === PerformanceCategory.International) return 'International';
	if(value === PerformanceCategory.ProAcademy) return 'Pro Academy';
	if(value === PerformanceCategory.Professional) return 'Professional';
	if(value === PerformanceCategory.Recreational) return 'Pre Competitive';
	return 'Non Competitive';
}

export function performanceCategoryToColor(value: PerformanceCategory | null, options: { background: boolean } = { background: false }): string{
	const { background = false } = options;
	if (value === PerformanceCategory.Recreational) {
		return background === false ? 'baColorRecreational' : 'baColorRecreationalBG';
	}
	if (value === PerformanceCategory.Competitive) {
		return background === false ? 'baColorCompetitive' : 'baColorCompetitiveBG';
	}
	if (value === PerformanceCategory.HighPerformance || value === PerformanceCategory.College) {
		return background === false ? 'baColorHighPerformance' : 'baColorHighPerformanceBG';
	}
	if (value === PerformanceCategory.ProAcademy || value === PerformanceCategory.Professional) {
		return background === false ? 'baColorProfessional' : 'baColorProfessionalBG';
	}
	if (value === PerformanceCategory.International) {
		return background === false ? 'baColorInternational' : 'baColorInternationalBG';
	}
	return background === false ? 'baColorUnranked' : 'baColorUnrankedBG';
}


export function gradeToColor(value: GradeName, options: { background: boolean } = { background: false }): string{
	const { background = false } = options;
	if (value === GradeName.Approaching) {
		return background === false ? 'baColorAccentGold' : 'baColorAccentGoldLighten';
	}
	if (value === GradeName.Meets || (value as string) === "Meeting") {
		return background === false ? 'baColorRecreational' : 'baColorRecreationalBG';
	}
	if (value === GradeName.Exceeds) {
		return background === false ? 'baColorProfessional' : 'baColorProfessionalBG';
	}
	return background === false ? 'baColorUnranked' : 'baColorUnrankedBG';
}

