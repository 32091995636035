import { AthleteMetric } from '@/../types/enums';
import { FrontEndModel } from '../FrontEndModel';

export class SelfAssessmentTestAttemptModel extends FrontEndModel{
	created: Date = new Date();
	result?: number;
	label?: string;
}

export class SelfAssessmentTestModel extends FrontEndModel {
	constructor(metric?: AthleteMetric) {
		super();
		this.metric = metric;
	}
	dateCompleted: Date = new Date();
	metric: AthleteMetric;
	finalResult: number | null = null;
	finalResultLabel: string | null = null;
	attempts: SelfAssessmentTestAttemptModel[] = [];
	
	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['dateCompleted']) this.dateCompleted = new Date(obj['dateCompleted']);
		if (Array.isArray(obj['attempts'])) this.attempts = obj['attempts'].map((a: Record<string,any>) => new SelfAssessmentTestAttemptModel().load(a));
		return this;
	}
}