import { PlanFeature } from '../interfaces/PlanFeature';

export const PLAN_FEATURES: PlanFeature[] = [
	{
		id: 1,
		html: "Recruiting Profile",
		description: "Player profile",
	},
	{
		id: 2,
		html: "Team Features",
		description: "Connect with Team",
	},
	{
		id: 3,
		html: "Self Assessment",
		description: "Self reported physical assessments",
	},
	{
		id: 4,
		html: "Video Studio",
		description: "Video suite storage space",
	},
	{
		id: 5,
		html: "Highlight Reel",
		description: "Video highlight clips",
	},
	{
		id: 6,
		html: "Mindset Assessment",
		description: "Athletic mindset analysis",
	},
	{
		id: 7,
		html: "Mindset Reports",
		description: "Complete report for Athlete, Parent, Coach (Age 13+)",
	},
	{
		id: 8,
		html: "Verified Physical Assessment",
		description: "Physical testing from sports science team",
	},
	{
		id: 9,
		html: "Scouting Reports",
		description: "Access to scouting from coaches",
	},
	{
		id: 10,
		html: "Scouting Report from BA",
		description: "Verified scouting report from Best Athletes"
	},
	{
		id: 11,
		html: "University & College Search",
		description: "Comprehensive school database",
	},
	{
		id: 12,
		html: "University & College Share",
		description: "Share scouting profile directly with staff"
	},
	{
		id: 13,
		html: "Comparative data analysis",
		description: "Comparison to other athletes",
	},
	{
		id: 14,
		html: "Share Recruitment profile",
		description: "Send profile to coaches, scouts and recruiters",
	},
	{
		id: 15,
		html: "Reverse Search by Coaches",
	},
];