
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { CertificationItem } from '@/models/coach/CoachResumeModel';

@Component({
	components: { 
	}
})
export default class CoachPlayersCertificationRow extends Mixins(VuetifyMixin, BAIconsMixin){
	@PropSync('certificate', { required: true })
	Certificate: CertificationItem;
}
