import { CrudApi } from './CrudApi';
import { ScoutingObservationModel } from "@/models";

class ScoutingObservationApi extends CrudApi<ScoutingObservationModel> {
	constructor() {
		super('scouting-observation', (obj) => new ScoutingObservationModel(obj));
	}
}

export const scoutingObservationApi = new ScoutingObservationApi();
