
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { AuthMixin } from "@/mixins";
import BAChip from "@/components/ui/BAChip.vue";

export interface AccordionAccountSettingHeaderDetail {
	title: string;
	description: string;
	icon: string;
	enabled: boolean;
}

interface Panels extends AccordionAccountSettingHeaderDetail {
	headerSlotName: string;
	contentSlotName: string;
}

@Component({
	components: {
		BAChip
	}
})
export class AccordionSettings extends Mixins(VuetifyMixin, AuthMixin) {
	@PropSync('openheaders', { type: Number }) OpenHeaders;

	@Prop({ default: () => Array<AccordionAccountSettingHeaderDetail>() })
	headers: AccordionAccountSettingHeaderDetail[];

	/**
	 * Used for scrolling into view, can be random string.
	 * Should be unique if there are multiple AccordionSettings
	 * being rendered
	 */
	@Prop({ type: String, default: ''})
	uniqueName: string;

	get PanelsCount(): number {
		return this.headers ? this.headers.length : 0;
	}
	get Panels(): Panels[] {
		if (!this.headers) return [];
		else {
			const panels: Panels[] = [];
			for (let i = 0; i < this.headers.length; i++) {
				panels.push({
					...this.headers[i],
					headerSlotName: `header_${i}`,
					contentSlotName: `content_${i}`
				});
			}
			return panels;
		}
	}

	async headerClicked(id: string): Promise<void> {
		setTimeout(() => this.$vuetify.goTo(`#${id}`), 382);
	}
}

export default AccordionSettings;
