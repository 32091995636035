
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import TeamCreateTeamInfo from '../components/teams/TeamCreateTeamInfo.vue';
import TeamCreateDetailInfo from '../components/teams/TeamCreateDetailInfo.vue';
import TeamCreateStaffInfo from '../components/teams/TeamCreateStaffInfo.vue';
import TeamCreateTeamRoster from '../components/teams/TeamCreateTeamRoster.vue';
import { TeamModel } from '../models/team';
import { TeamDashboard, CoachDashboard } from '@/../types/constants/web_client_user.routes';

import { teamCreateStore, teamsStore } from '../store';
import { StaffMember } from '../models/team/StaffMember';
import { AuthMixin, MyCoachMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { TeamCreateFormValue, TeamCreateOptions } from '@/../types/interfaces';
import { SportName } from '@/../types/enums';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		TeamCreateTeamInfo,
		TeamCreateDetailInfo,
		TeamCreateStaffInfo,
		TeamCreateTeamRoster,
	},
})
export default class TeamCreateForm extends Mixins(MultiStepFormMixin, AuthMixin, MyCoachMixin, StringsMixin, BAIconsMixin){
	get IsEdit(): boolean{
		return this.editTeamId !== undefined;
	}
	@Prop({ default: undefined }) editTeamId?: string;
	@Prop({ default: false }) editAll: boolean;
	@Prop({ default: false }) selfRoleOptional: boolean;
	@Watch('editTeamId') async checkEditTeam(): Promise<void>{
		if(this.IsEdit){
			await this.setupEditTeam(this.editTeamId);
		}else{
			this.resetForm();
		}
	}

	errorMessage: string | null = null;
	async mounted(): Promise<void>{
		this.checkEditTeam();
	}
	editTeam: TeamModel | null = null;
	get EditTeamName(): string{
		if(this.editTeam === null) return this.formValue.step1.name;
		return this.editTeam.name
	}
	async setupEditTeam(teamId: string): Promise<void>{
		const editTeam = await teamsStore.loadTeamById(teamId);
		if( this.IsEmpty(editTeam) ) return;

		this.editTeam = editTeam;
		this.goBackWithPrevious = false;
		this.formValue.step1.name = editTeam.name;
		this.formValue.step1.country = editTeam.country;
		this.formValue.step1.city = editTeam.city;
		this.formValue.step1.sportId = editTeam.sportId;
		this.formValue.step1.gender = editTeam.gender;
		this.formValue.step1.seasonFormat = editTeam.seasonFormat;
		this.formValue.step1.logoUrl = editTeam.logoUrl;
		this.formValue.step1.division = editTeam.division;
		this.formValue.step1.sportId = editTeam.sportId;
		this.formValue.step1.jerseyPrimary = editTeam.jerseyPrimary;
		this.formValue.step1.jerseyAlternate = editTeam.jerseyAlternate;
		this.formValue.step1.jerseyOther = editTeam.jerseyOther;

		this.formValue.teamDetails.bio = editTeam.bio;
		this.formValue.teamDetails.teamUrl = editTeam.teamUrl;
		this.formValue.teamDetails.teamCalendarURL= editTeam.teamCalendarURL;
		this.formValue.teamDetails.honors = editTeam.honors;
		this.formValue.teamDetails.notable = editTeam.notable;

		this.formValue.teamStaff.staffMembers = editTeam.staffMembers;

		this.formValue.step3.players = editTeam.players;
	}
	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	newTeamId: string = "";
	formComplete: boolean = false;
	get Loading(): boolean{
		return teamCreateStore.createTeamLoading || teamsStore.loadTeamByIdLoading;
	}
	steps = 4;
	currentStep = 1;

	get CurrentStepValid(): boolean {
		const step = this.formValue[this.CurrentStepKey];
		return step ? step.valid : true;
	}

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 10;
		case 2: return 40;
		case 3: return 60;
		case 4: return 80;
		default: return 100;
		}
	}

	defaultForm = (): TeamCreateFormValue => ({
		step1: {
			valid: true,
			name: "",
			country: "",
			city: "",
			sportId: SportName.Soccer,
			gender: "",
			seasonFormat: "",
			seasonYear: "",
			seasonStartDate: "",
			seasonEndDate: "",
			organizationTypeId: "",
			organizationName: "",
			logoUrl: null,
			division: "",
			jerseyPrimary: {
				label: 'Primary',
				color: '#FF0000FF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			},
			jerseyAlternate: {
				label: 'Alternate',
				color: '#0000BBFF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			},
			jerseyOther: {
				label: 'Goalkeeper',
				color: '#007F0EFF',
				accent: "#FFFFFFFF",
				number: "#FFFFFFFF",
			},
		},
		teamDetails: {
			valid: true,
			teamUrl: null,
			teamCalendarURL: null,
			colors: "",
			bio: "",
			honors: "",
			notable: "",
		},
		teamStaff: {
			valid: true,
			myRole: "",
			makeEmailPublic: false,
			staffMembers: [],
			sendStaffInvitations: false,
		},
		step3: {
			valid: true,
			players: [],
			invitePlayers: [],
			sendPlayerInvitations: false,
		}
	});
	formValue = this.defaultForm();

	getErrors(error: Record<string, any>): string[][]{
		return Object.values(error).filter(x => x.length !== 0)
	}

	async finish(validate: () => Promise<boolean>): Promise<void>{
		// Validate the form
		if (!(await validate())) {
			this.errorMessage = 'The follow field(s) must be completed:'
			window.scrollTo(0, 0)
			return;
		}

		const isEdit = this.editTeamId !== undefined;
		let team: TeamModel;
		if(isEdit){
			team = teamsStore.myTeams.find(t => t.id === this.editTeamId);
		}
		if(team === undefined){
			team = new TeamModel();
		}

		const {
			name,
			country,
			city,
			sportId,
			gender,
			seasonFormat,
			organizationTypeId,
			organizationName,
			logoUrl,
			division,
		} = this.formValue.step1;
		team.name = name;
		team.country = country;
		team.city = city;
		team.sportId = sportId;
		team.gender = gender;
		team.seasonFormat = seasonFormat;
    	team.organizationId = this.$route.params.organizationId;
		team.organizationTypeId = organizationTypeId;
		team.organizationName = organizationName;
		team.logoUrl = logoUrl;
		team.division = division;

		team.bio = this.formValue.teamDetails.bio;
		team.teamUrl = this.formValue.teamDetails.teamUrl;
		team.teamCalendarURL = this.formValue.teamDetails.teamCalendarURL;
		team.honors = this.formValue.teamDetails.honors;
		team.notable = this.formValue.teamDetails.notable;

		team.jerseyPrimary = this.formValue.step1.jerseyPrimary;
		team.jerseyAlternate = this.formValue.step1.jerseyAlternate;
		team.jerseyOther = this.formValue.step1.jerseyOther;

		const staffMembers: StaffMember[] = this.formValue.teamStaff.staffMembers
		const sendStaffInvitations = this.formValue.teamStaff.sendStaffInvitations;

		team.staffMembers = [...staffMembers];

		const { players, invitePlayers, sendPlayerInvitations } = this.formValue.step3;

		team.players = [
			...players,
			...invitePlayers,
		];

		const opts: TeamCreateOptions = {
			invitePlayers: sendPlayerInvitations,
			inviteStaff: sendStaffInvitations,
			sourceCoachId: this.MyCoachId,
		};

		if(!isEdit){
			const newTeam = await teamCreateStore.createTeam({team, opts});
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.spliceTeam({ team: newTeam });
		}else{
			team.id = this.editTeamId;
			const newTeam = await teamCreateStore.patchTeam({team, opts});
			this.formComplete = true;
			this.newTeamId = newTeam.id;
			teamsStore.spliceTeam({ team: newTeam });
		}
		this.errorMessage = null;
		this.viewTeamProfile();
	}
	cancel(): void{
		this.errorMessage = null;
		this.goToDashboard();
	}

	@Prop({ default: null }) teamDashboardRoute: string | null;
	get TeamDashboard(): string{
		if(this.teamDashboardRoute !== null) return this.teamDashboardRoute;
		return TeamDashboard;
	}
	@Prop({ default: null }) dashboardRoute: string | null;
	get Dashboard(): string{
		if(this.dashboardRoute !== null) return this.dashboardRoute;
		return CoachDashboard;
	}

	viewTeamProfile(): void{
		this.$router.push({
			name: this.TeamDashboard,
			params: { teamId: this.newTeamId },
		});
	}
	goToDashboard(): void{
		this.$router.push({ name: this.Dashboard });
	}
}
