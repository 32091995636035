import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';
import { SelfAssessmentModel } from '@/models/athlete/SelfAssessmentModel';
import { MetricData } from '@/../types/interfaces';
import { AthleteMetric } from '@/../types/enums';
import { getMetricUnitLabel } from './metric-unit-label.pipe';
import { getMetricUnit } from './metric-unit.pipe';
import { assessmentMetricValueOrNull } from '@/pipes/assessment-metric-value-or-null.pipe';
import { metricHasValue } from './metric-has-value.pipe';

export function assessmentToMetric(assessmentData: AthleteAssessmentDataModel | SelfAssessmentModel, metric: AthleteMetric, derived: boolean = false): MetricData {
	if (!assessmentData) {
		return {
			label: '--', value: '--', unit: '', missing: true,
		}
	}
	let data: string | number;
	let precision: number = 2;
	switch (metric) {
	case AthleteMetric.Acceleration:
		data = derived? assessmentData.acceleration : assessmentData.tenMeterSprint;
		break;
	case AthleteMetric.Speed:
		data = derived? assessmentData.speed : assessmentData.twentyToThirtyFiveMeterSplit;
		break;
	case AthleteMetric.Power:
		data = derived? assessmentData.power : assessmentData.counterMovementJumpHeight;
		precision = derived? 0 : 1;
		break;
	case AthleteMetric.Agility:
		data = derived? assessmentData.reactiveStrengthIndex : assessmentData.reactiveStrengthIndex;
		break;
	case AthleteMetric.Recovery:
		data = derived? assessmentData.maximalAerobicVelocity : assessmentData.yoyoIntermittentRecoveryTestStage;
		precision = 1;
		break;
	default:
		data = null;
	}
	return {
		label: getMetricUnitLabel(metric, false, derived),
		unit: getMetricUnit(metric, false, derived),
		value: assessmentMetricValueOrNull(data, precision),
		missing: !metricHasValue(data),
	};
}
