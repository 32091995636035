import { CrudApi } from './CrudApi';
import { BestAthletesEventModel } from '../models/bestAthletesEvent/BestAthletesEventModel';
import { AxiosRequestConfig } from 'axios';
import { BestAthletesEventDetails, EventHandleSignedWaiversPayload, EventRegistrationPayload, BestAthletesEventWaiverDetails } from '@/../types/interfaces';
import { BAEventAttendeeModel } from '@/models/bestAthletesEvent';
import { BAEventOrderModel } from '@/models/bestAthletesEvent/BAEventOrderModel';
import { SWTemplate } from '@/../types/interfaces/SmartwaiverTypes';
import { BAEventWaiverModel } from '@/models/bestAthletesEvent/BAEventWaiverModel';

class BestAthletesAttendeeApi extends CrudApi<BAEventAttendeeModel>{
	constructor() {
		super('bestAthletesEvent/attendee', (obj) => new BAEventAttendeeModel().load(obj));
	}
}

class BestAthletesEventApi extends CrudApi<BestAthletesEventModel>{
	constructor() {
		super('bestAthletesEvent', (obj) => new BestAthletesEventModel().load(obj));
	}
	public AttendeeApi = new BestAthletesAttendeeApi();

	/**
	 * Force a sync with Eventbrite's API. Admins only.
	 */
	async eventbriteSync({ eventIds = [], synchronous }: { eventIds?: string[], synchronous?: boolean } = {}, options: AxiosRequestConfig = {}): Promise<void>{
		if(synchronous === true){
			options.params = {
				...options.params,
				sync: true,
			}
		}
		await CrudApi.Api((c) => c.post(`/${this.resource}/syncEvents`, { eventIds }, options));
	}

	/**
	 * Instantiates the classes in a `BestAthletesEventDetails` response
	 */
	private createEventDetails(response: BestAthletesEventDetails): BestAthletesEventDetails<BestAthletesEventModel, BAEventAttendeeModel, BAEventOrderModel>{
		return {
			event: this.create(response.event),
			order: response.order ? new BAEventOrderModel().load(response.order) : null,
			attendees: response.attendees.map((a: BAEventAttendeeModel) => new BAEventAttendeeModel().load(a)),
		}
	}

	async getEventbriteEventDetails(eventbriteEventId: string, config?: AxiosRequestConfig): Promise<BestAthletesEventModel>{
		const response = await CrudApi.Api((c) => c.get(`/${this.resource}/public/eb/${eventbriteEventId}`, config));
		return new BestAthletesEventModel().load(response);
	}
	async getEventDetails(id: string, config?: AxiosRequestConfig): Promise<BestAthletesEventDetails<BestAthletesEventModel, BAEventAttendeeModel, BAEventOrderModel>>{
		const response = await CrudApi.Api((c) => c.get(`/${this.resource}/public/${id}/details`, config));
		return this.createEventDetails(response);
	}
	async getEventWaiverDetails(id: string, config?: AxiosRequestConfig): Promise<BestAthletesEventWaiverDetails<BAEventWaiverModel>>{
		const response: BestAthletesEventWaiverDetails = await CrudApi.Api((c) => c.get(`/${this.resource}/public/${id}/waiver-details`, config));
		response.signedWaivers = response.signedWaivers.map(o => new BAEventWaiverModel().load(o));
		return response;
	}
	async getEventWaiverPdf({eventbriteEventId: eventId,waiverId}:{eventbriteEventId: string, waiverId: string}): Promise<Blob>{
		const response: Blob = await CrudApi.Api((c) => c.request({
			method: 'GET',
			url: `/${this.resource}/waivers/${eventId}/${waiverId}/download`,
			responseType: 'blob',
		}));
		return response;
	}

	async handleEventRegistration(payload: EventRegistrationPayload, config?: AxiosRequestConfig): Promise<BestAthletesEventDetails<BestAthletesEventModel,BAEventAttendeeModel,BAEventOrderModel>>{
		const response = await CrudApi.Api((c) => c.post(`/${this.resource}/handleRegistration`, payload, config));
		return this.createEventDetails(response);
	}

	async handleWaiversSigned(payload: EventHandleSignedWaiversPayload, config?: AxiosRequestConfig): Promise<BestAthletesEventDetails<BestAthletesEventModel,BAEventAttendeeModel,BAEventOrderModel>>{
		const response = await CrudApi.Api((c) => c.post(`/${this.resource}/handleWaiversSigned`, payload, config));
		return this.createEventDetails(response);
	}
	async getSmartwaiverTemplates(config?: AxiosRequestConfig): Promise<SWTemplate[]>{
		const response = await CrudApi.Api((c) => c.get(`/${this.resource}/waivers/templates`, config));
		return response;
	}

	async getUserEvents(config?: AxiosRequestConfig): Promise<BestAthletesEventModel[]>{
		const response = await CrudApi.Api((c) => c.get(`/${this.resource}/public/my-events`, config));
		return response.map((o: any) => new BestAthletesEventModel().load(o));
	}
}
export const bestAthletesEventApi = new BestAthletesEventApi();