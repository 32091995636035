
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StringsMixin } from '@/mixins';

@Component({
	components: { },
})
export default class UserChip extends Mixins(StringsMixin) {
    @Prop() item: any;
    @Prop() image: string;
    @Prop() text: string;
    @Prop( { default: 20 } ) imageSize: number;
    @Prop( { default: "mr-1" } ) imageClass: string;
    @Prop( { default: "text-caption primary--text cursor-pointer" } ) textClass: string;
}
