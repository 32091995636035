import { getEnumValues } from './get-enum-values';
export enum RecruitingResponse{
	Yes='yes',
	No='no',
	Maybe='maybe',
	Seen='seen',
	unseen='unseen',
	reshared='reshared',
}
export const RecruitingResponseValues = getEnumValues<RecruitingResponse>(RecruitingResponse);
