
import { VuetifyMixin } from "@/mixins";
import { Mixins, Component, Prop } from "vue-property-decorator";
import Dialog from "@/components/ui/Dialog.vue";
import ExcelDataTable from "@/components/ui/ExcelDataTable.vue";
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";


@Component({
	components: { ExcelDataTable, Dialog }
})
export default class CSVHelpDialog extends Mixins(VuetifyMixin, CSVUploadMixin) {
	@Prop({default: false}) visible: boolean;

	sampleCSVData = [
		{
			row: 1,
			number: 1,
			firstName: "Sophie",
			lastName: "Smith",
			email: "soph101@bestathletes.co",
			position: "Goalkeeper",
			gradYear: "2023"
		},
		{
			row: 2,
			number: 3,
			firstName: "Aurora",
			lastName: "Rossi",
			email: "rossia101@bestathletes.co",
			position: "Defender",
			gradYear: "2024"
		},
		{
			row: 3,
			number: 9,
			firstName: "Nala",
			lastName: "Mensah",
			email: "nala101@bestathletes.co",
			position: "Forward",
			gradYear: "2023"
		},
		{
			row: 4,
			number: 8,
			firstName: "Danica",
			lastName: "Garcia",
			email: "dani101@bestathletes.co",
			position: "Midfielder",
			gradYear: "2023"
		}
	];

	get dialogVisbility() {
		return this.visible
	}
	set dialogVisbility(value: boolean) {
		this.$emit('update:visible', value)
	}
}
