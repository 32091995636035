
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

@Component
export default class BASnackbar extends Mixins(VuetifyMixin){
	@Prop({ default: mdiCheckboxMarkedCircleOutline }) icon: string;
	@Prop() iconSize: number | string;
	@Prop({ default: 'baColorGreen2' }) private color: string;
	@Prop({ default: 'baColorDarkGreenInfo' }) private borderColor: string;
	@Prop({ default: false, type: Boolean }) hideIcon: boolean;
	@Prop({ default: false, type: Boolean }) private dense: boolean;
	@Prop({ default: false, type: Boolean }) app: boolean;
	@Prop({ default: false, type: Boolean }) top: boolean;
	@Prop({ default: false, type: Boolean }) absolute: boolean;
	@Prop({ default: false, type: Boolean }) success: boolean;
	/** Use Red Error Colors */
	@Prop({ default: false, type: Boolean }) error: boolean;
	@Prop({ default: false, type: Boolean }) warning: boolean;

	get Color(): string{
		if(this.error) return 'baColorAlertRedBG';
		if(this.warning) return 'baColorLightWarning';
		return this.color;
	}
	get BorderColor(): string{
		if(this.error) return 'baColorAlertRed';
		if(this.warning) return 'baColorAccentGold';
		return this.borderColor;
	}

	get Style(): Record<string, string>{
		return {
			'color': `${this.getColor(this.borderColor)}`,
		};
	}
}
