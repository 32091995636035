
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';

@Component({
	components: { },
})
export default class TeamCreateDetailInfo extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop() value = {
		valid: false,
		teamUrl: null,
		colors: "",
		bio: "",
		honors: "",
		notable: "",
		teamCalendarURL: null,
	};

	input(): void{
		this.$emit('input', this.value);
	}

	requiredRule = [
		(v: any): boolean | string => !!v || 'Required',
	];

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
