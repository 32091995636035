import store from '../store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { bestAthletesEventApi } from '../../api/BestAthletesEventApi';
import { BestAthletesEventModel } from '../../models/bestAthletesEvent/BestAthletesEventModel';
import { BestAthletesEventDetails, EventHandleSignedWaiversPayload, EventRegistrationPayload, BestAthletesEventWaiverDetails } from '@/../types/interfaces';
import { BAEventAttendeeModel } from '@/models/bestAthletesEvent';
import { BAEventOrderModel } from '@/models/bestAthletesEvent/BAEventOrderModel';
import { BAEventWaiverModel } from '@/models/bestAthletesEvent/BAEventWaiverModel';

const Mutations = {
	LOAD_EVENT_DETAILS: 'LOAD_EVENT_DETAILS',
	LOAD_EVENT_DETAILS_SUCCESS: 'LOAD_EVENT_DETAILS_SUCCESS',
	LOAD_EVENT_DETAILS_FAILURE: 'LOAD_EVENT_DETAILS_FAILURE',

	HANDLE_REGISTRATION: 'HANDLE_REGISTRATION',
	HANDLE_REGISTRATION_SUCCESS: 'HANDLE_REGISTRATION_SUCCESS',
	HANDLE_REGISTRATION_FAILURE: 'HANDLE_REGISTRATION_FAILURE',

	HANDLE_SIGN_WAIVERS: 'HANDLE_SIGN_WAIVERS',
	HANDLE_SIGN_WAIVERS_SUCCESS: 'HANDLE_SIGN_WAIVERS_SUCCESS',
	HANDLE_SIGN_WAIVERS_FAILURE: 'HANDLE_SIGN_WAIVERS_FAILURE',

	LOAD_MY_EVENTS: 'LOAD_MY_EVENTS',
	LOAD_MY_EVENTS_SUCCESS: 'LOAD_MY_EVENTS_SUCCESS',
	LOAD_MY_EVENTS_FAILURE: 'LOAD_MY_EVENTS_FAILURE',
}

const name = 'EventDetailsStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class EventDetailsModule extends VuexModule {

	loading: boolean = false;
	initialized: boolean = false;

	get EventReady(): boolean {
		return this.initialized && !this.loading;
	}

	eventDetails: BestAthletesEventDetails<BestAthletesEventModel | null, BAEventAttendeeModel, BAEventOrderModel> = {
		event: null,
		order: null,
		attendees: [],
	};

	@Action({
		rawError: true,
	}) async loadEvent({ eventId } : { eventId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_EVENT_DETAILS);
		try {
			const eventDetails = await bestAthletesEventApi.getEventDetails(eventId);
			const waiverDetails = await bestAthletesEventApi.getEventWaiverDetails(eventId).catch(() => undefined);
			this.context.commit(Mutations.LOAD_EVENT_DETAILS_SUCCESS, {eventDetails, waiverDetails});
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_EVENT_DETAILS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_EVENT_DETAILS](): void {
		this.loading = true;
	}
	@Mutation [Mutations.LOAD_EVENT_DETAILS_SUCCESS]({eventDetails,waiverDetails}: {eventDetails: BestAthletesEventDetails, waiverDetails?: BestAthletesEventWaiverDetails<BAEventWaiverModel>}): void {
		this.loading = false;
		this.initialized = true;
		this.eventDetails = eventDetails;
		const eventIndex = this.myEvents.findIndex(e => e.id === eventDetails.event.id);
		if (eventIndex > -1){
			this.myEvents[eventIndex] = eventDetails.event;
		}
		if (waiverDetails !== undefined){
			this.myEventWaiverDetails[this.eventDetails.event.id] = waiverDetails;
		}
	}
	@Mutation [Mutations.LOAD_EVENT_DETAILS_FAILURE](error: any): void {
		this.loading = false;
	}

	registrationLoading: boolean = false;
	@Action({ rawError: true })
	async handleEventRegistration(payload: EventRegistrationPayload): Promise<void>{
		try {
			const details = await bestAthletesEventApi.handleEventRegistration(payload);
			this.context.commit(Mutations.HANDLE_REGISTRATION_SUCCESS, details);
			await this.loadMyEvents();
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.HANDLE_REGISTRATION_FAILURE, e);
		}
	}

	@Mutation [Mutations.HANDLE_REGISTRATION](): void {
		this.registrationLoading = true;
	}
	@Mutation [Mutations.HANDLE_REGISTRATION_SUCCESS](eventDetails: BestAthletesEventDetails): void {
		this.registrationLoading = false;
		this.eventDetails = eventDetails;
	}
	@Mutation [Mutations.HANDLE_REGISTRATION_FAILURE](error: any): void {
		this.registrationLoading = false;
	}

	signWaiversLoading: boolean = false;
	@Action({ rawError: true })
	async handleWaiversSigned(payload: EventHandleSignedWaiversPayload): Promise<void>{
		this.context.commit(Mutations.HANDLE_SIGN_WAIVERS);
		try {
			const details = await bestAthletesEventApi.handleWaiversSigned(payload);
			this.context.commit(Mutations.HANDLE_SIGN_WAIVERS_SUCCESS, details);
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.HANDLE_SIGN_WAIVERS_FAILURE, e);
		}
	}

	@Mutation [Mutations.HANDLE_SIGN_WAIVERS](): void {
		this.signWaiversLoading = true;
	}
	@Mutation [Mutations.HANDLE_SIGN_WAIVERS_SUCCESS](eventDetails: BestAthletesEventDetails): void {
		this.signWaiversLoading = false;
		this.eventDetails = eventDetails;
	}
	@Mutation [Mutations.HANDLE_SIGN_WAIVERS_FAILURE](error: any): void {
		this.signWaiversLoading = false;
	}


	myEvents: BestAthletesEventModel[] = [];
	myEventWaiverDetails: {[eventId: string]: BestAthletesEventWaiverDetails<BAEventWaiverModel>} = {};
	myEventsLoading: boolean = false;
	myEventsInitialized: boolean = false;
	@Action({ rawError: true })
	async loadMyEvents(): Promise<void> {
		this.context.commit(Mutations.LOAD_MY_EVENTS);
		try {
			const events = await bestAthletesEventApi.getUserEvents();
			const waiverDetails = (await Promise.all(events.map(e => bestAthletesEventApi.getEventWaiverDetails(e.id).catch(() => null)))).filter(e => e !== null);
			this.context.commit(Mutations.LOAD_MY_EVENTS_SUCCESS, { events, waiverDetails });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.LOAD_MY_EVENTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_MY_EVENTS](): void {
		this.myEventsLoading = true;
	}
	@Mutation [Mutations.LOAD_MY_EVENTS_SUCCESS]({events,waiverDetails}: { events: BestAthletesEventModel[], waiverDetails: BestAthletesEventWaiverDetails<BAEventWaiverModel>[]}): void {
		this.myEventsLoading = false;
		this.myEventsInitialized = true;
		this.myEvents = events;
		this.myEventWaiverDetails = waiverDetails.reduce((a,b, index) => {
			a[events[index].id] = b;
			return a;
		}, {});
	}
	@Mutation [Mutations.LOAD_MY_EVENTS_FAILURE](error: any): void {
		this.myEventsLoading = false;
	}
}
