
import { Component, Prop, Mixins } from 'vue-property-decorator';
import UserAclInfoProvider from '../../../components/hoc/UserAclInfoProvider.vue';
import PermissionsSelector from '../../../components/forms/PermissionsSelector.vue';
import { FormRulesMixin, BAIconsMixin } from '../../../mixins';
import { UserAcl, GroupAcl } from '@/../types/interfaces';
import { AccessControlledModel } from '../../../models/AccessControlledModel';

@Component({
	components: { PermissionsSelector, UserAclInfoProvider },
})
export default class AccessControlForm extends Mixins(FormRulesMixin, BAIconsMixin){

	valid: boolean = false;
	@Prop({ required: true }) value: AccessControlledModel;
	input(value: AccessControlledModel): void{
		this.$emit('input', value);
	}
	@Prop({ required: true }) resource: string;
	@Prop({ type: Boolean, default: false }) saveLoading: boolean;
	@Prop({ type: Boolean, default: false }) hideUsers: boolean;
	@Prop({ type: Boolean, default: false }) hideGroups: boolean;

	@Prop({ type: Boolean, default: false }) hideTitle: boolean;
	@Prop({ type: Boolean, default: false }) hideSubtitle: boolean;


	get ColsProps(): Record<string,string>{
		return {
			cols: "12",
			lg: "4",
		}
	}

	get Users(): UserAcl[]{
		if(!this.value) return [];
		return this.value.users;
	}
	get Groups(): GroupAcl[]{
		if(!this.value) return [];
		return this.value.groups;
	}

	deleteUser(userAcl: UserAcl): void{
		console.log("deleteUser", userAcl);
	}

	removePermission(item: string): void{
		console.log("removePermission", item);
	}

	onSave(): void{
		this.$emit('save', this.value);
	}
	onCancel(): void{
		this.$emit('cancel');
	}
}
