import store from '../store';
import { localforage } from '@/plugins/localforage';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { actionApi } from '@/api/ActionApi';
import { Route } from 'vue-router';
import { LoadingPage } from '@/../types/constants/web_client_user.routes';
import router from '@/router/router';

const Mutations = {
	INIT_ACTIONS: 'INIT_ACTIONS',
	REDIRECT_QUEUED: 'REDIRECT_QUEUED',
	QUEUE_ACTION: 'QUEUE_ACTION',
	REMOVE_ACTION: 'REMOVE_ACTION',
	EXEC_NEXT_ACTION: 'EXEC_NEXT_ACTION',

	EXEC_ACTION: 'EXEC_ACTION',
	EXEC_ACTION_SUCCESS: 'EXEC_ACTION_SUCCESS',
	EXEC_ACTION_FAILURE: 'EXEC_ACTION_FAILURE',
}

const name = 'ActionUrlStore';
const queuedActionIdsKey = `${name}/queuedActionIds`;

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class ActionUrlModule extends VuexModule {

	redirectQueuedWith: string | null = null;
	actionsInitialized: boolean = false;
	queuedActionIds: string[] = [];

	get ActionsInitialized(): boolean{
		return this.actionsInitialized;
	}

	get QueuedActions(): string[]{
		return this.queuedActionIds;
	}

	get HasActions(): boolean{
		return this.QueuedActions.length > 0;
	}

	get HasRedirectAction(): boolean{
		return this.redirectQueuedWith !== null;
	}

	@Action({ rawError: true }) async queueAction({newActionId, to}: {newActionId: string, to: Route}): Promise<void> {
		this.context.commit(Mutations.QUEUE_ACTION, newActionId);
		if (to.name === LoadingPage){
			this.context.commit(Mutations.REDIRECT_QUEUED, newActionId);
		}
	}

	@Action({ rawError: true }) async execActions(): Promise<void>{
		for(const actionId of this.queuedActionIds){
			await this.execAction(actionId);
		}
	}

	@Action({ rawError: true }) async execRedirectAction(): Promise<void>{
		if(!this.HasRedirectAction){
			console.warn("No redirect action is available");
			return;
		}
		await this.execAction(this.redirectQueuedWith);
		this.context.commit(Mutations.REDIRECT_QUEUED, null);
	}
	@Action({ rawError: true }) async execAction(actionId: string): Promise<void>{
		const action = await actionApi.findById(actionId);
		if(action.used === true && action.onetime === true){
			console.error("Skipping action that has been used already");
			return;
		}
		const { success } = await action.exec();
		if (success === true){
			action.used = true;
			await actionApi.patch(action);
		}
		this.context.commit(Mutations.REMOVE_ACTION, action.id);
	}

	@Mutation [Mutations.QUEUE_ACTION](actionId: string): void {
		if (this.queuedActionIds.indexOf(actionId) === -1) {
			this.queuedActionIds.push(actionId);
		}
	}

	public async saveActionQueue(actions: string[]): Promise<void>{
		await localforage.setItem(queuedActionIdsKey, actions);
	}

	@Action async initActionQueue(): Promise<string[]>{
		const queuedActionIds = await localforage.getItem<string[]>(queuedActionIdsKey);
		if (!Array.isArray(queuedActionIds)){
			this.context.commit(Mutations.INIT_ACTIONS, []);
			return [];
		}
		this.context.commit(Mutations.INIT_ACTIONS, queuedActionIds);
		return queuedActionIds;
	}

	@Mutation [Mutations.INIT_ACTIONS](queue: string[]): void{
		this.queuedActionIds = queue;
		this.actionsInitialized = true;
	}

	@Mutation [Mutations.REMOVE_ACTION](actionId: string): void{
		const actionIndex = this.queuedActionIds.findIndex(a => a === actionId);
		if (actionIndex > -1){
			this.queuedActionIds.splice(actionIndex,1);
		}
	}
	@Mutation [Mutations.EXEC_ACTION_SUCCESS](actionId: string): void{
		
	}
	@Mutation [Mutations.EXEC_ACTION_FAILURE](actionId: string): void{
		
	}

	@Mutation [Mutations.REDIRECT_QUEUED](actionId: string): void{
		this.redirectQueuedWith = actionId;
	}
}
