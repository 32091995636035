import { Component, Mixins, Prop } from 'vue-property-decorator';
import { userStore } from '@/store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { AthleteProfileMixin, AthleteApiMixin } from '@/mixins';

/**
 * Provides computed properties: 
 * - TheAthleteId
 * - MyAthleteProfile
 */
@Component({
	async beforeRouteEnter(_, __, next): Promise<void>{
		if (!userStore.athleteProfilesInitialized && !userStore.loadAthleteProfilesLoading){
			await this.loadMyAthleteProfiles();
		}
		next();
	},
})
export class MyAthleteMixin extends Mixins(AthleteProfileMixin, AthleteApiMixin) {
	get IsAthleteLoading(): boolean {
		return this.IsLoadingAthleteProfile;
	}

	get CurrentAthleteId(): string | undefined {
		return userStore.athleteId ?? this.athleteId;
	}
	get TheAthleteId(): string{
		if(this.AthleteAppActive){
			return this.CurrentAthleteId;
		}
		return this.athleteId;
	}
	get TheAthleteProfile(): AthleteProfileModel | null {
		return this.athleteProfile;
	}
	
	async loadMyAthleteProfiles(): Promise<void> {
		await userStore.loadAthleteProfiles();
	}
	get AthletesReady(): boolean{
		return userStore.athleteProfilesInitialized && !userStore.loadAthleteProfilesLoading && !userStore.loading;
	}
	get MyAthleteProfile(): AthleteProfileModel | undefined{
		if(this.AthletesReady){
			return userStore.athlete;
		}
		return undefined;
	}
	get MyAthleteFullName(): string {
		if( !userStore ) return '-';
		return userStore.FullName;
	}
	get MyAthleteShareUrl(): string {
		return  'https://' + this.PublicAppUrl + this.MyAthleteProfile.PublicSharingUrl;
	}

	updatingAthlete: boolean = false;
	updatingTeams: boolean = false;
	get UpdatingAthlete(): boolean {
		return this.updatingAthlete || this.updatingTeams;
	}
}
