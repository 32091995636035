// TeamDashboardMixin.ts

import { PlayerOnTeam, TeamModel } from '@/models/team';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { teamDashboardStore } from '@/store';
import { AthleteApiMixin, QRCodeMixin, RoutingMixin, StringsMixin } from "@/mixins";
import { athleteApi } from "@/api/AthleteApi";
import { AthleteProfileModel } from '@/models';

/**
 * Provides computed props to display information on a team
 */
@Component
export class TeamDashboardMixin extends Mixins(AthleteApiMixin, QRCodeMixin, StringsMixin, RoutingMixin) {
    @Prop({type: String, default: undefined}) teamId;
    
	team: TeamModel = undefined;
	teamInitialized: boolean = false;
	defaultPictureUrl: string = 'https://cdn.bestathletes.co/static-assets/bestie-square-100x100.png';

	get IsTeamLoading(): boolean {
		return !this.TeamInitialized || !this.IsAthleteApiReady;
	}

	get TeamInitialized(): boolean {
		return this.teamInitialized;
	}
	get TeamPublicSharingUrl(): string {
		return `https://${this.PublicAppUrl}/team/${this.teamId}/${this.team.sharingUrlId}`
	}
    get TeamPlayers(): PlayerOnTeam[] {
        if( !this.team ) return [];
        if( this.IsEmptyArray(this.team.players) ) return [];
        return this.team.players.sort((a, b) => {
            const A = Number(a.number);
            const B = Number(b.number);
            if( A <= 0 ) return 999;
            if( B <= 0 ) return -999;
            return(A - B)
        });
    }
    get TeamPlayersCount(): number {
        return this.TeamPlayers.length;
    }
    athleteProfiles: AthleteProfileModel[] = [];
    HasTeamAthleteProfile(athleteId: string): boolean {
        return this.athleteProfiles.some(p => p.id === athleteId);
    }
    TeamPlayerAthleteProfile(athleteId: string): AthleteProfileModel {
        return this.athleteProfiles.find(p => p.id === athleteId);
    }
	AthletePublicSharingUrl(athleteProfile: Partial<AthleteProfileModel>): string {
        if( this.IsEmpty(athleteProfile) ) return '';
        if( this.IsEmpty(athleteProfile.sharingUrlId) ) return `https://${this.PublicAppUrl}/athlete/${athleteProfile.id}`;
		return `https://${this.PublicAppUrl}/athlete/${athleteProfile.id}/${athleteProfile.sharingUrlId}`
	}
    gotoAthleteProfile(athleteProfile: Partial<AthleteProfileModel>) {
        if( this.IsEmpty(athleteProfile) ) return;
        this.gotoUrl(this.AthletePublicSharingUrl(athleteProfile))
    }
    async gotoTeamSharingUrl() {
        this.gotoUrl(this.TeamPublicSharingUrl);
   }

	mounted() {
		this.loadTeam();
	}

	async loadTeam() {
        if( this.teamInitialized ) return;
        
		this.teamInitialized = false;
		if( !this.teamId ) return;

		await teamDashboardStore.loadTeam(this.teamId);
		this.team = teamDashboardStore.team;
        
        this.teamQR = await this.generateQRCode(this.TeamPublicSharingUrl);
        if( !!this.team ) {
            for( const player of this.TeamPlayers ) {
                try{
                    const athleteProfile = player.athleteId? await athleteApi.getPublicProfile(player.athleteId) : undefined;
                    if( this.IsNotEmpty(athleteProfile) ) {
                        this.athleteProfiles.push(athleteProfile);
                        player.qrCode = await this.generateQRCode(this.AthletePublicSharingUrl(athleteProfile));
                    } else {
                        player.qrCode = this.TeamQRCode;
                    }
                } catch(e) {
                    console.log(`Athlete not found (${player.athleteId}): ${player.lastName}`)
                }
            }
        }
		
        this.teamInitialized = true;
	}

    teamQR: string = undefined;
    get TeamQRCode(): string {
        if( !this.teamQR ) return this.defaultTeamQR;
        return this.teamQR;
    }

	GetFullName( player: PlayerOnTeam ): string {
		if( !player ) return '';

		return player.firstName + ' ' + player.lastName;
	}
    GetAthletePictureUrl( athlete: Partial<AthleteProfileModel> ) {
        if( !this.HasProfile(athlete) || this.IsEmpty(athlete.pictureUrl) ) return this.GetTeamPictureUrl;
        return athlete.pictureUrl;
    }
    get GetTeamPictureUrl() {
        if( this.IsEmpty(this.team) ) return this.defaultPictureUrl;
        if( this.IsNotEmpty(this.team.logoUrl) ) return this.team.logoUrl;
        return this.defaultPictureUrl;
    }

	HasProfile( athlete: Partial<AthleteProfileModel> ) {
		return !!athlete;
	}
	HasWeight( athlete: Partial<AthleteProfileModel> ) {
		if( !this.HasProfile(athlete) ) return false;
		return athlete.hasData('weight');
	}
	HasHeight( athlete: Partial<AthleteProfileModel> ) {
		if( !this.HasProfile(athlete) ) return false;
		return athlete.hasData('height');
	}
    HasBirthDate(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): boolean {
        return this.IsNotEmpty(this.AthleteBirthDate(athlete, athleteProfile));
    }
    AthleteBirthDate(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): string {
        let birthDate: Date = undefined;
        if( this.IsNotEmpty(athleteProfile) && this.IsNotEmpty(athleteProfile.BirthDate) ) {
            // return athleteProfile.BirthDate;
            birthDate = new Date(athleteProfile.birthDate);
        } else if( this.IsNotEmpty(athlete) && this.IsNotEmpty(athlete.dateOfBirth) ) {
            // return athlete.dateOfBirth;
            birthDate = new Date(athlete.dateOfBirth);
        }
        if( !birthDate ) return '';
        return this.formatDateSlashesYYYYMMDD(birthDate, true);
    }
    HasGradYear(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): boolean {
        return this.IsNotEmpty(this.AthleteGradYear(athlete, athleteProfile));
    }
    AthleteGradYear(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): string {
        if( !!athleteProfile && this.IsNotEmpty(athleteProfile.GradYear)) return athleteProfile.GradYear.toFixed(0);
        else if( this.IsNotEmpty(athlete.gradYear) ) return athlete.gradYear.toFixed(0);
        return '';
    }
    AthleteEmail(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): string {
        if( !!athleteProfile ) return athleteProfile.Email;
        else if( this.IsNotEmpty(athlete.email) ) return athlete.email;
        return '';
    }
    AthletePhone(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): string {
        if( !!athleteProfile ) return athleteProfile.PhoneNumber;
        return '';
    }
    AthleteHasPhone(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): boolean {
        if( !!athleteProfile && this.IsNotEmpty(athleteProfile.PhoneNumber) ) return true;
        return false;
    }
    AthleteSchoolCommitment(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): string {
        if( !!athleteProfile && this.IsNotEmpty( athleteProfile.schoolCommitment) ) return athleteProfile.schoolCommitment;
        else if( this.IsNotEmpty(athlete.schoolCommitment) ) return athlete.schoolCommitment;
        return '';
    }
    AthleteHasSchoolCommitment(athlete: PlayerOnTeam, athleteProfile: AthleteProfileModel): boolean {
        return this.IsNotEmpty(this.AthleteSchoolCommitment(athlete, athleteProfile));
    }

}
