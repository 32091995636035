/* PhysicalAssessmentMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { SportModel } from '@/models/sport';
import { StringsMixin } from './StringsMixin';
import { DataTableHeader } from 'vuetify'

interface PhysicalAssessmentInformation {
	name: string,
	abbr: string,
	description: string,
	measurement: string
}

@Component
export class PhysicalAssessmentMixin extends Mixins(StringsMixin) {
	PhysicalTestsInfoHeaders: Array<DataTableHeader> = [
		{ text: 'Test', value: 'name', sortable: false },
		{ text: 'Description', value: 'description', sortable: false }
	]

	PhysicalTestsInfo(sport: SportModel = undefined): Array<PhysicalAssessmentInformation> {
		if( this.IsEmpty(sport) ) return this.PhysicalTestsInfoItems;

		var physicalTestsInfo: Array<PhysicalAssessmentInformation> = [];
		for( const label of sport.physicalLabels ) {
			const assessment = this.PhysicalTestsInfoItems.find(info => info.name.replace(/\s/g, '').toLowerCase() === label.replace(/\s/g, '').toLowerCase() );
			if( this.IsNotEmpty(assessment) ) {
				physicalTestsInfo.push(assessment);
			}
		}
		return physicalTestsInfo;
	}

	PhysicalTestsInfoItems: Array<PhysicalAssessmentInformation> = [
		{
			name: "Speed",
			abbr: "Spd",
			description: "Maximal running speed during a sprint",
			measurement: "Running time from 20m and 35m"
		},
		{
			name: "Acceleration",
			abbr: "Accl",
			description: "Sprint time over the first 10m",
			measurement: "Running time from 0m to 10m"
		},
		{
			name: "Agility",
			abbr: "Agl",
			description: "Reactive strength index using a Drop Jump",
			measurement: "Drop Jump Test. Jump height and ground contact time"
		},
		{
			name: "Power",
			abbr: "Pow",
			description: "Leg muscle power using Counter Movement Jump",
			measurement: "Counter Movement Jump. Jump height"
		},
		{
			name: "Recovery",
			abbr: "Rec",
			description: "Distance covered during a YoYo Test",
			measurement: "YoYo Test"
		},
		{
			name: "Long Jump",
			abbr: "Jmp",
			description: "Jump distance",
			measurement: "Jump"
		},
		{
			name: "Bronco",
			abbr: "Bco",
			description: "Time for five 60m, 40m and 20m shuttle runs",
			measurement: "Running time for 5 shuttles"
		}
	]

}
