
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { BAIconsMixin, VuetifyMixin, SportsSelectorMixin } from "@/mixins";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import HintText from "@/components/ui/HintText.vue";
import LinkText from "@/components/ui/LinkText.vue";
import { SocialMediaField } from '@/../types/interfaces';
import { Mass, Length, BaseSoccerPosition, DominantSide, SocialMedia, SocialMediaKeys } from "@/../types/enums";
import ProfilePictureUpload from "@/components/forms/ProfilePictureUpload.vue";
import SectionHeading from "@/components/ui/SectionHeading.vue";
import convert from "convert-units";

type Item<T> = {
	text: string;
	value: T;
};

@Component({
	components: {
		HintText,
		LinkText,
		ProfilePictureUpload,
		SectionHeading
	}
})
export default class EditRecruitingProfileBasic extends Mixins(BAIconsMixin, VuetifyMixin, SportsSelectorMixin) {
	@Prop() clicked: DominantSide;
	@Prop() value: AthleteProfileModel;
	
	requiredRule = [(v: any): boolean | string => !!v || "Required"];

	async created() {
		this.eagerSocialMediaProfiles = this.value.socialMedia;
	}

	get secondaryPositionItems(): Item<BaseSoccerPosition>[] {
		/**
		 * Returns an array of enum keys as text and enum values as values for vuetify items
		 */
		let items: Item<BaseSoccerPosition>[] = [];

		Object.keys(BaseSoccerPosition)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(position => {
				items.push({
					text: position as keyof typeof BaseSoccerPosition,
					value: BaseSoccerPosition[position] 
				});
			});
		return items;
	}
	get primaryPositionItems(): Item<BaseSoccerPosition>[] {
		// Primary options cannot be None
		return this.secondaryPositionItems
	}

	toggleWeight() {
		const newUnit = this.value.weightUnit == Mass.KILOGRAM? Mass.POUND : Mass.KILOGRAM;
		if( this.value.weight ) {
			this.value.weight = Math.round(convert(this.value.weight).from(this.value.weightUnit).to(newUnit));
		}
		this.value.weightUnit = newUnit;
		this.setFocus(this.$refs.height);
		this.setFocus(this.$refs.weight);
	}

	toggleHeight() {
		const newUnit = this.value.heightUnit == Length.CENTIMETER? Length.INCH : Length.CENTIMETER;
		if( this.value.height ) {
			this.value.height = Math.round(convert(this.value.height).from(this.value.heightUnit).to(newUnit));
		}
		this.value.heightUnit = newUnit;
		this.setFocus(this.$refs.weight);
		this.setFocus(this.$refs.height);
	}

	// Social Media Platforms
	eagerSocialMediaProfiles: SocialMediaField[] = [];
	get platformItems(): Item<SocialMedia>[] {
		let items: Item<SocialMedia>[] = [];

		Object.keys(SocialMedia)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(media_key => {
				items.push({
					text: media_key as SocialMediaKeys,
					value: SocialMedia[media_key]
				});
			});
		return items;
	}
	get isPlatformsFull(): boolean {
		let platformsSelected: SocialMedia[] = [];
		let platformLinks: string[] = [];

		this.value.socialMedia.forEach(athletePlatform => {
			platformsSelected.push(athletePlatform.platform);
			platformLinks.push(athletePlatform.link);
		});

		return (
			this.isArrayValuesFull(platformLinks) &&
			platformsSelected.length ==
				platformsSelected.filter(value => value).length
		);
	}

}
