
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, MyCoachMixin, StringsMixin, RoutingMixin, TeamDashboardMixin, BAIconsMixin } from "@/mixins";
import { StaffRole } from '@/../types/enums';
import { StaffMember } from "@/models/team/StaffMember";
import { PlayerOnTeam } from '@/models/team';
import TeamHeadingCard from '@/components/teams/TeamHeadingCard.vue';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

@Component({
	components: {
		TeamHeadingCard,
	}
})
export default class TeamProfileCard extends Mixins(VuetifyMixin, AppHostnameMixin, MyCoachMixin, StringsMixin, RoutingMixin, TeamDashboardMixin, BAIconsMixin) {
	@Prop({ default: '' }) owner: string;
	@Prop({ default: '' }) sharingUrlId: string;
	@Prop({ type: Boolean }) printPreview;
    @Prop({ type: Boolean }) hideNoProfile;
    @Prop({ type: Boolean }) hideNoNumber;
    @Prop({ type: Boolean }) compact;

    ShowStaffMember(staff: StaffMember, headCoach: StaffMember): boolean {
        if( this.team.staffMembers.length < 2 ) return true;
        if( staff === headCoach ) return false;
        if( staff.role === StaffRole.Admin ) return false;
        return true;
    }
    ShowAthlete(athlete: PlayerOnTeam): boolean {
        if( this.hideNoProfile && !this.HasTeamAthleteProfile(athlete.athleteId)) return false;
        if( this.hideNoNumber && !this.IsNotEmpty(athlete.number)) return false;
        return true;
    }
    NumberStyle(number: string): string {
        let style: string = '';
        if( this.IsNotEmpty(number) ) {
            style = style + 'font-weight-medium text-h5'
            if( number.length < 2 ) style += ' px-5'
        } else {
            style = style + 'px-6';
        }
        return style;
    }

	get IsOwner(): boolean {
		return this.sharingUrlId.startsWith('share');
	}
	get IsValidShare(): boolean {
		if( this.IsOwner ) return true;
		return ( this.sharingUrlId === this.team.sharingUrlId );
	}

    async onToggleHideNoProfile() {
        this.hideNoProfile = !this.hideNoProfile;
    }
    async onToggleHideNoNumber() {
        this.hideNoNumber = !this.hideNoNumber;
    }
	async onPrintPreview() {
        this.compact = true;
        this.printPreview = true;
	}
    async onClosePrintPreview() {
        this.compact = false;
        this.printPreview = false;
    }
	async onSaveToPDF() {
        this.printStatus = "Preview Document"
		await this.onPrintPreview();
        await new Promise(resolve => setTimeout(resolve, 200));
        await this.generatePDF();
        await this.onClosePrintPreview();
	}


    printProgress: number = 0;
    printTotal: number = 100;
    printing: boolean = false;
    printStatus: string = '';
    async generatePDF() {
        this.printProgress = 0;
        this.printing = true;
        this.printStatus = "Preparing document";
        await new Promise(resolve => setTimeout(resolve, 500));

        const pdf = new jsPDF('p', 'in', [8.5, 11])
        let imgHeight = 0
        let numPages = 0;
        this.printTotal = 0;

        const options = {
            scale: 2,
            useCORS: true,
        };

        // prepare image elements
        let imgElements = document.getElementsByTagName('img');
        for (let i = 0; i < imgElements.length; i++) {
            imgElements[i].crossOrigin = 'anonymous';
        }
        const images = this.$refs.profileImages as any;
        for( let i = 0; i < images.length as any; i++) {
            this.$refs.profileImages[i].crossOrigin = 'anonymous'
        }
        // determine the size of the document
        const cards = (this.$refs.cards as any);
        for( let i = 0; i < cards.length; i++) {
            const canvas = await html2canvas(this.$refs.cards[i], options)
            const imgWidth = 7.5 // image width in inches
            const imgAspectRatio = canvas.width / canvas.height
            const newImgHeight = imgWidth / imgAspectRatio // calculate image height maintaining aspect ratio

            this.printTotal = this.printTotal + newImgHeight;
        }

        this.printStatus = "Generating document";
        for( let i = 0; i < cards.length; i++) {
            const canvas = await html2canvas(this.$refs.cards[i], options)
            const imgData = canvas.toDataURL('image/png')
            const imgWidth = 7.5 // image width in inches
            const imgAspectRatio = canvas.width / canvas.height
            const newImgHeight = imgWidth / imgAspectRatio // calculate image height maintaining aspect ratio
            if (imgHeight + newImgHeight > 10) { // if adding image exceeds page height, add a new page
                pdf.addPage()
                imgHeight = 0
                numPages = numPages + 1;
            }
            pdf.addImage(imgData, 'PNG', 0.5, 0.5 + imgHeight, imgWidth, newImgHeight)
            imgHeight += newImgHeight
            this.printProgress = imgHeight + 8.5 * numPages;
        }

        // save the PLF as team.pdf
        this.printStatus = "Saving document";
        pdf.save(`${this.team.name}.pdf`);

        this.printPreview = false;
        this.printing = false;
        this.printStatus = "";
    }
    get PrintProgress(): number {
        return (100 * this.printProgress / this.printTotal) //.toFixed(0);
    }
}
