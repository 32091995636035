import { getEnumValues } from "@/../types/enums/get-enum-values";

export enum CalendarMode{
	Agenda='agenda',
	Month='month',
	Week='week',
	Day='day',
	FourDay='4day',
}

export const CalendarModeValues = getEnumValues<CalendarMode>(CalendarMode);