/* MindsetMixin.ts */

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { MindsetUserModel, MindsetReportModel } from '@/models';
import { MindsetUserStatus } from '@/../types/interfaces/MindsetUser';
import { RyzerMixin, AthleteRoutingMixin } from "@/mixins";

import { mindsetUserApi } from '@/api/MindsetUserApi'
import { mindsetReportApi } from '@/api/MindsetReportApi'

import { notificationStore } from '@/store/index';
import { formatDatePretty } from '../helpers/date';
import { isEmpty, isNotEmpty } from '@/pipes';

import { RyzerDataModel, MindsetError } from "@/../types/constants/ryzer"
import * as Routes from '@/../types/constants/web_client_user.routes';

/**
 * Provides access to Ryzer icons and data types
 */
@Component
export class MindsetMixin extends Mixins(RyzerMixin, AthleteRoutingMixin) {
	@Prop({ default: undefined }) userId;
	@Prop({ default: undefined }) mindsetReport: MindsetReportModel;
	mindsetUser: MindsetUserModel;
	loadedMindsetReport: boolean = false;
	loadedMindsetUser: boolean = false;
	mindsetHTML: string = '';

	mounted(): void {
		this.loadMindsetReport();
	}

	async loadMindsetUser(): Promise<MindsetUserModel> {
		this.loadedMindsetUser = false;
		if( !this.mindsetUser ) {
			try {
				if( this.userId ) {
					this.mindsetUser = await mindsetUserApi.findById(this.userId);
				}
			} catch(e) {
				this.mindsetUser = null;
				notificationStore.pushSnackbarError({message: e})
			}
		}
		this.loadedMindsetUser = true;
		return this.mindsetUser;
	}
	async reloadMindsetUser(): Promise<MindsetUserModel> {
		this.mindsetUser = null;
		return await this.loadMindsetUser();
	}

	async loadMindsetReport(): Promise<MindsetReportModel> {
		await this.reloadMindsetUser();

		this.loadedMindsetReport = false;
		if( !this.mindsetReport) {
			try {
				if( this.userId ) {
					this.mindsetReport = await mindsetReportApi.findById(this.userId);
				}
			} catch(e) {
				notificationStore.pushSnackbarError({message: e})
			}
		}

		if( this.mindsetReport ) this.SetMindsetArchetype(this.mindsetReport.archetype);

		if( !this.HasMindsetReport ) {
			// this athlete has no mindset report
			if( this.NoRyzerUser ) {
				// this athlete is not a mindset user
			} else if( this.MindsetUserUnknown ) {
				this.mindsetUser = await mindsetUserApi.findById(this.userId);
			}
		}

		this.loadedMindsetReport = true;
		return this.mindsetReport;
	}
	async reloadMindsetReport(): Promise<MindsetReportModel> {
		this.mindsetReport = null;
		return await this.loadMindsetReport();
	}

	get LoadingMindset(): boolean {
		return !this.loadedMindsetReport || !this.loadedMindsetUser;
	}
	get HasMindsetReport(): boolean {
		if( !this.mindsetReport ) return false;
		return !this.HasMindsetError;
	}
	get MindsetReport(): MindsetReportModel | null {
		return this.mindsetReport;
	}
	get MindsetAssessmentDate(): string {
		return formatDatePretty(this.mindsetReport.date);
	}

	get HasMindsetError(): boolean {
		if( !this.mindsetReport ) return true;
		return this.mindsetReport.HasError;
	}
	get MindsetError(): string {
		if( !this.mindsetReport ) return MindsetError.MindsetNotAvailable;
		if( isEmpty(this.mindsetReport.error) ) return MindsetError.NoErrorReported;

		if( this.MindsetReport.error.indexOf(MindsetError.RyzerUserDoesNotExist) >= 0 ) return MindsetError.RyzerUserDoesNotExist;
		if( this.MindsetReport.error.indexOf(MindsetError.RyzerUserNoTAP) >= 0 ) return MindsetError.RyzerUserNoTAP;
		if( this.MindsetReport.error.indexOf(MindsetError.RyzerIdMismatch) >= 0 ) return MindsetError.RyzerIdMismatch;

		return this.MindsetReport.error;
	}
	get HasMindsetCriticalError(): boolean {
		if( !this.HasMindsetReport ) return false;
		if( !this.HasMindsetError ) return false;

		if( this.MindsetReport.error.indexOf(MindsetError.RyzerUserDoesNotExist) >= 0 ) return false;
		if( this.MindsetReport.error.indexOf(MindsetError.RyzerUserNoTAP) >= 0 ) return false;
	}

	get HasMindsetUser(): boolean {
		if( !this.mindsetUser ) return false;
		return this.mindsetUser.status != MindsetUserStatus.Unknown;
	}
	get MindsetUserEmail(): string {
		if( !this.HasMindsetUser ) return '';
		return this.mindsetUser.email;
	}
	get MindsetUserUnknown(): boolean {
		if( !this.HasMindsetUser ) return true;
		return  this.mindsetUser.status == MindsetUserStatus.NoRyzerAccount;
	}
	get NoRyzerUser(): boolean {
		return this.MindsetError === MindsetError.RyzerUserDoesNotExist;
	}
	get NoRyzerTest(): boolean {
		return this.MindsetError === MindsetError.RyzerUserNoTAP;
	}
	get IsRyzerIdMismatch(): boolean {
		return this.MindsetError === MindsetError.RyzerIdMismatch;
	}
	get RyzerLink(): string {
		if( !this.HasMindsetUser ) return 'https://mindset.ryzer.com/';
		return this.mindsetUser.link;
	}
	get RyzerLinkReady(): boolean {
		if( !this.HasMindsetUser ) return false;
		return isNotEmpty(this.mindsetUser.link);
	}
	get RyzerTAPInfoLink(): string {
		return 'https://ryzer.com/mindset/TAP/';
	}

	get HasMindsetHTML() {
		return isNotEmpty(this.mindsetHTML);
	}
	get MindsetHTML() {
		if( !this.HasMindsetHTML ) return '';
		return this.mindsetHTML;
	}

	async handleResult( result: RyzerDataModel ) {
		this.mindsetHTML = result.html;
		if( result.Error && result.Error.length > 0 ) {
			notificationStore.pushSnackbarError({message: result.Error})
		}

		await this.reloadMindsetReport();
	}

	async CreateMindsetAccount() {
		this.loadedMindsetReport = false;

		const result: RyzerDataModel = await mindsetReportApi.createMindsetUser(this.userId);
		await this.handleResult(result);
	}

	async LinkMindsetAccount() {
		this.loadedMindsetReport = false;

		const result = await mindsetReportApi.prepareMindsetTest(this.userId);
		await this.handleResult(result);
		if( this.CoachAppActive) this.gotoCoachDashboard();
		this.gotoAthleteDashboard();
	}

	gotoCoachDashboard(): void{
		this.$router.push({ name: Routes.CoachDashboard });
	}

	async StartMindsetTest() {
		this.gotoUrl(this.RyzerLink);
	}

	async SendMindsetInvitation(coachId: string) {
		this.loadedMindsetReport = false;

		const result = await mindsetReportApi.sendMindsetInvitation(this.userId, coachId);
		await this.handleResult(result);
	}
}
