
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAPartnerProduct, BAPurchaseModel } from '@/models';
import { BAIconsMixin, MyAthleteMixin, StringsMixin, StylesMixin } from '@/mixins';
import { baPurchaseApi } from '@/api/BAPurchaseApi';

@Component({
    components: { 
    },
})
export default class PartnerProductPurchasedCard extends Mixins(BAIconsMixin, StringsMixin, StylesMixin, MyAthleteMixin) {
    @Prop({required: true}) product: BAPartnerProduct;

    mounted() {
        this.loadProductPurchase();
    }

    productPurchase: BAPurchaseModel;
    loadedProductPurchase: boolean = false;
    async loadProductPurchase() {
        this.loadedProductPurchase = false;
        this.productPurchase = await baPurchaseApi.findPurchase(this.AthleteId, this.product.id);
        this.loadedProductPurchase = true;
    }

    get IsReady() {
        return this.AthletesReady && this.loadedProductPurchase;
    }

    get AthletePurchase(): BAPurchaseModel {
        if( !this.IsReady ) return undefined;
        return this.productPurchase;
    }
    get PurchaseDate(): string {
        if( !this.IsReady ) return '';
        if( this.IsEmpty(this.productPurchase) ) return '';
        return this.formatDatePrettyShort(new Date(this.productPurchase.date));
    }
}
