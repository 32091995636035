import store from "./store";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { PaginatedResponse, QueryOptions } from "@/../types/interfaces";
import { BAPartnerModel } from "@/models";
import { baPartnerApi } from "@/api/BAPartnerApi";

const Mutations = {
	QUERY_PARTNERS: 'QUERY_PARTNERS',
	QUERY_PARTNERS_SUCCESS: 'QUERY_PARTNERS_SUCCESS',
	QUERY_PARTNERS_FAILURE: 'QUERY_PARTNERS_FAILURE',
	FINDBYID_PARTNERS: 'FINDBYID_PARTNERS',
	FINDBYID_PARTNERS_SUCCESS: 'FINDBYID_PARTNERS_SUCCESS',
	FINDBYID_PARTNERS_FAILURE: 'FINDBYID_PARTNERS_FAILURE',
	FINDBYNAME_PARTNERS: 'FINDBYNAME_PARTNERS',
	FINDBYNAME_PARTNERS_SUCCESS: 'FINDBYNAME_PARTNERS_SUCCESS',
	FINDBYNAME_PARTNERS_FAILURE: 'FINDBYNAME_PARTNERS_FAILURE',
	SAVE_PARTNER: 'SAVE_PARTNER',
	SAVE_PARTNER_SUCCESS: 'SAVE_PARTNER_SUCCESS',
	SAVE_PARTNER_FAILURE: 'SAVE_PARTNER_FAILURE',
	DELETE_PARTNER: 'DELETE_PARTNER',
	DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
	DELETE_PARTNER_FAILURE: 'DELETE_PARTNER_FAILURE',
}

const name = "BAPartnerStore";

if (store.state[name]) {
	store.unregisterModule(name);
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store,
})
export default class BAPartnerModule extends VuexModule {
	numPartners: number = 0;
	partners: BAPartnerModel[] = [];
	currentPartner: BAPartnerModel = undefined;

	loadedPartners: boolean = false;
	loadingPartners: boolean = false;
	findingPartner: boolean = false;
	creatingPartner: boolean = false;
	deletingPartner: boolean = false;
	@Action async loadPartners(): Promise<BAPartnerModel[]> {
		this.context.commit(Mutations.QUERY_PARTNERS);
		try {
			const options: QueryOptions = {page: 1, limitPerPage: -1}
			const data = await baPartnerApi.queryAll( { fields: [] }, options );
			this.context.commit(Mutations.QUERY_PARTNERS_SUCCESS, data);
			return data.docs;
		} catch (e) {
			this.context.commit(Mutations.QUERY_PARTNERS_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_PARTNERS](): void {
		this.loadingPartners = true;
	}
	@Mutation [Mutations.QUERY_PARTNERS_SUCCESS](data: PaginatedResponse<BAPartnerModel>): void {
		this.loadingPartners = false;
		this.numPartners = data.total;
		this.partners = data.docs.map(d => new BAPartnerModel().load(d));
		this.loadedPartners = true;
	}
	@Mutation [Mutations.QUERY_PARTNERS_FAILURE](error: any): void {
		this.loadingPartners = false;
	}

	@Action async findPartnerByName(partnerName: string): Promise<BAPartnerModel> {
		this.context.commit(Mutations.FINDBYNAME_PARTNERS);
		try {
			const data = await baPartnerApi.findByName(partnerName);
			this.context.commit(Mutations.FINDBYNAME_PARTNERS_SUCCESS, data);
			return data;
		} catch(e) {
			this.context.commit(Mutations.FINDBYNAME_PARTNERS_FAILURE, e);
		}
	}

	@Action async findPartner(partnerId: string): Promise<BAPartnerModel> {
		this.context.commit(Mutations.FINDBYID_PARTNERS);
		try {
			const data = await baPartnerApi.findById(partnerId);
			this.context.commit(Mutations.FINDBYID_PARTNERS_SUCCESS, data);
			return data;
		} catch (e) {
			this.context.commit(Mutations.FINDBYID_PARTNERS_FAILURE, e);
		}
	}
	@Mutation [Mutations.FINDBYID_PARTNERS](): void {
		this.findingPartner = true;
	}
	@Mutation [Mutations.FINDBYID_PARTNERS_SUCCESS](data: BAPartnerModel): void {
		this.currentPartner = data;
		this.findingPartner = false;
	}
	@Mutation [Mutations.FINDBYID_PARTNERS_FAILURE](error: any): void {
		this.findingPartner = false;
	}
    
	@Action async savePartner(partner: BAPartnerModel): Promise<BAPartnerModel> {
		this.context.commit(Mutations.SAVE_PARTNER)
		try{
			const newPartner = await baPartnerApi.save(partner);
			this.context.commit(Mutations.SAVE_PARTNER_SUCCESS);
			return newPartner;
		}catch(e){
			this.context.commit(Mutations.SAVE_PARTNER_FAILURE, e);
		}
	}
	@Mutation [Mutations.SAVE_PARTNER](){
		this.creatingPartner = true;
	}
	@Mutation [Mutations.SAVE_PARTNER_SUCCESS](){
		this.creatingPartner = false;
	}
	@Mutation [Mutations.SAVE_PARTNER_FAILURE](){
		this.creatingPartner = false;
	}

	@Action async deletePartner(partner: BAPartnerModel): Promise<BAPartnerModel> {
		this.context.commit(Mutations.DELETE_PARTNER)
		try{
			const delPartner = await baPartnerApi.delete(partner);
			this.context.commit(Mutations.DELETE_PARTNER_SUCCESS);
			return delPartner;
		}catch(e){
			this.context.commit(Mutations.DELETE_PARTNER_FAILURE, e);
		}
	}
	@Mutation [Mutations.DELETE_PARTNER](){
		this.deletingPartner = true;
	}
	@Mutation [Mutations.DELETE_PARTNER_SUCCESS](){
		this.deletingPartner = false;
	}
	@Mutation [Mutations.DELETE_PARTNER_FAILURE](){
		this.deletingPartner = false;
	}

}