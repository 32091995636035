import { SubscriptionModel } from "@/models/stripe/SubscriptionModel";
import { AvailableFeatures } from "@/../types/interfaces";
import { api } from "./api"

export class SubscriptionApi {
	static Api = api;

	async getSubscriptionAndFeatures(resource: string, resourceId: string): Promise<{subscription: SubscriptionModel, features: AvailableFeatures}> {
		const [ subscription, features ] = await Promise.all([
			this.findByParentId(resource, resourceId),
			this.getAvailableFeatures(resource,resourceId)
		])
		return {subscription, features}
	}

	async findByParentId(resource: string, resourceId: string): Promise<SubscriptionModel> {
		const url = `${resource}/${resourceId}/subscription/`;
		const subscriptionModel = new SubscriptionModel().load(await SubscriptionApi.Api((c) => c.get(url)));
		return subscriptionModel;
	}

	async getAvailableFeatures(resource: string, resourceId: string): Promise<AvailableFeatures> {
		const url = `${resource}/${resourceId}/subscription/available-resources`;
		const resources: AvailableFeatures = await SubscriptionApi.Api((c) => c.get(url));
		const resources1: AvailableFeatures = await SubscriptionApi.Api((c) => c.get(url));
		return resources;
	}

	async redeemVerifiedAssessment(resource: string, resourceId: string): Promise<{code: string}> {
		const url = `${resource}/${resourceId}/subscription/redeem/verified-assessment`;
		const resources: { code: string } = await SubscriptionApi.Api((c) => c.post(url));
		return resources;
	}

	async unsubscribe(resource: string, resourceId: string, planId: string): Promise<SubscriptionModel> {
		this.getAvailableFeatures(resource, resourceId);
		
		const url = `${resource}/${resourceId}/subscription/unsubscribe/${planId}`;
		const subscriptionModel: SubscriptionModel = await SubscriptionApi.Api((c) => c.get(url));
		return subscriptionModel;
	}
}
export const subscriptionApi = new SubscriptionApi();