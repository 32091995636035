
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import { BAIconsMixin,VuetifyMixin } from '@/mixins';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { AwardItem, ChampionshipItem, AthleteCoachedItem, CertificationItem } from '@/models/coach/CoachResumeModel';
import CoachAwardRow from "@/components/profile/settings/CoachAwardRow.vue";
import CoachChampionshipRow from "@/components/profile/settings/CoachChampionshipRow.vue";
import CoachPlayersCoachedRow from "@/components/profile/settings/CoachPlayersCoachedRow.vue";
import CoachPlayersCertificationRow from "@/components/profile/settings/CoachPlayersCertificationRow.vue";

@Component({
	components: {
		CoachAwardRow,
		CoachChampionshipRow,
		CoachPlayersCoachedRow,
		CoachPlayersCertificationRow
	}
})
export default class CoachSettingCoachingAccolades extends Mixins(BAIconsMixin, VuetifyMixin){
	@PropSync('coachProfile', { required: true, type: CoachProfileModel }) 
	CoachProfile: CoachProfileModel;

	eagerAwards: AwardItem[] = []
	eagerChampionships: ChampionshipItem[] = []
	eagerAthletesCoached: AthleteCoachedItem[] = []
	eagerCertifications: CertificationItem[] = []

	async created(): Promise<void> {
		this.eagerAwards = this.CoachProfile.resume.awards;
		this.eagerChampionships = this.CoachProfile.resume.championships;
		this.eagerAthletesCoached = this.CoachProfile.resume.athletesCoached;
		this.eagerCertifications = this.CoachProfile.resume.certifications;
	}

	get Awards(): AwardItem[] {
		return this.eagerAwards;
	}
	get Championships(): ChampionshipItem[] {
		return this.eagerChampionships;
	}
	get AthletesCoached(): AthleteCoachedItem[] {
		return this.eagerAthletesCoached;
	}
	get Certifications(): CertificationItem[] {
		return this.eagerCertifications;
	}
}
