import { StaffRole, CourseStaffRole } from '@/../types/enums';

export function role(role: StaffRole | CourseStaffRole) {
	switch(role){
	case StaffRole.Admin: return 'Admin';
	case StaffRole.HeadCoach: return 'Head Coach';
	case StaffRole.AssistantCoach: return 'Assistant';
	case CourseStaffRole.Owner: return 'Owner';
	case CourseStaffRole.Instructor: return 'Instructor';
	case CourseStaffRole.Mentor: return 'Mentor';
	default:
		return "Staff Member";
	}
}