import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Provides helpers to access Vuetify theme and color information.
 */
@Component
export class VuetifyMixin extends Vue {
	@Prop({ type: Boolean }) public;

	get theme(): 'light' | 'dark' {
		if (!this.$vuetify.theme) return 'light';
		if (this.$vuetify.theme.dark) return 'dark';
		return 'light';
	}

    // refresh the window
    reloadWindow() {
        window.location.reload();
    }

	getColor(color: string, dark = false): string {
		if (!this.$vuetify.theme) return color;
		return this.$vuetify.theme.themes[dark ? 'dark' : this.theme][color] as string || color;
	}
	
    setFocus(field: any) {
        field.focus();
    }

	isArrayValuesFull(array: Array<any>): boolean {
		return array.length == array.filter(value => value).length;
	}

	get IsHandheld(): boolean {
		return this.$vuetify.breakpoint.xsOnly;
	}
	get IsMobile(): boolean {
		return this.$vuetify.breakpoint.mobile;
	}
	get IsSmallScreen(): boolean {
		return this.$vuetify.breakpoint.smAndDown;
	}
	get IsLargeScreen(): boolean {
		return this.$vuetify.breakpoint.lgAndUp;
	}
	get IsDark(): boolean {
		if (!this.$vuetify.theme) return false;
		return this.$vuetify.theme.dark;
	}
}
