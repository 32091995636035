import { getDaysBetween } from '@/helpers';
import { AthleteMetric, AthleteMetricValues } from '@/../types/enums';
import { AccessControlledModel } from '../AccessControlledModel';
import { SelfAssessmentTestModel } from './SelfAssessmentTestModel';

export class SelfAssessmentModel extends AccessControlledModel {
	readonly __type = 'SelfAssessmentModel';
	athleteId?: string;
	startDate: Date
	standingHeightCm: number | null = null;
	trueSittingHeightCm: number | null = null;
	sittingHeightWithBoxCm: number | null = null;
	bodyMassKg: number | null = null;
	boxHeightCm: number | null = null;
	
	accelerationResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Acceleration);
	speedResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Speed);
	powerResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Power);
	agilityResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Agility);
	recoveryResults: SelfAssessmentTestModel = new SelfAssessmentTestModel(AthleteMetric.Recovery);

	resultsKey(metric: AthleteMetric): string{
		return `${metric}Results`;
	}
	results(metric: AthleteMetric): SelfAssessmentTestModel{
		return this[this.resultsKey(metric)];
	}

	/** This assessment was started within the last 5 days */
	get IsExpired(): boolean{
		const days = getDaysBetween(this.startDate, new Date()).days;
		return days >= 5;
	}
	/** This assessment has results for all Metrics */
	get IsComplete(): boolean{
		const isComplete = AthleteMetricValues.map(metric => this.results(metric).finalResult).reduce((a, b) => {
			return a && b !== null
		}, true);
		return isComplete;
	}
	/** This assessment has at least one test completed */
	get IsStarted(): boolean{
		const isStarted = AthleteMetricValues.map(metric => this.results(metric).finalResult !== null).reduce((a, b) => {
			return a || b
		});
		return isStarted;
	}

	// Compatibility fields with AssessmentDataModel
	get assessmentDate(): Date{
		return this.startDate;
	}
	set assessmentDate(date: Date){
		this.startDate = date;
	}

	get tenMeterSprint(): string{
		if(this.accelerationResults.finalResult === null) return "";
		return `${this.accelerationResults.finalResult}`;
	}
	set tenMeterSprint(_: string){ return }
	get acceleration(): number {
		if( !this.tenMeterSprint ) return undefined;
		if( parseInt(this.tenMeterSprint) == 0 ) return undefined;
		return( parseInt(this.tenMeterSprint) / 10 * 3.6 ) / 3.6;
	}

	get twentyToThirtyFiveMeterSplit(): string{
		if(this.speedResults.finalResult === null) return "";
		return `${this.speedResults.finalResult}`;
	}
	set twentyToThirtyFiveMeterSplit(_: string){ return }
	get speed(): number {
		if( !this.twentyToThirtyFiveMeterSplit ) return undefined;
		if( parseInt(this.twentyToThirtyFiveMeterSplit) == 0 ) return undefined;
		return( 15 / parseInt(this.twentyToThirtyFiveMeterSplit)*3.6 ) / 3.6;
	}

	get counterMovementJumpHeight(): string{
		if(this.powerResults.finalResult === null) return "";
		return `${this.powerResults.finalResult}`;
	}
	set counterMovementJumpHeight(_: string){ return }
	get power(): number {
		if( !this.counterMovementJumpHeight) return undefined;
		if( parseInt(this.counterMovementJumpHeight) == 0 ) return undefined;
		return( 60.7*this.bodyMassKg + 45.3*parseInt(this.counterMovementJumpHeight) - 2055 );
	}

	get reactiveStrengthIndex(): string{
		if(this.agilityResults.finalResult === null) return "";
		return `${this.agilityResults.finalResult}`;
	}
	set reactiveStrengthIndex(_: string){ return }
	
	get yoyoIntermittentRecoveryTestStage(): string{
		if(this.recoveryResults.finalResult === null) return "";
		return `${this.recoveryResults.finalResult}`;
	}
	set yoyoIntermittentRecoveryTestStage(_: string){ return }
	get maximalAerobicVelocity(): number {
		if( !this.yoyoIntermittentRecoveryTestStage ) return undefined;
		const yoyo = parseInt(this.yoyoIntermittentRecoveryTestStage);
		if( yoyo < 9 ) return 10;
		else if( yoyo <= 11 ) return 11.5;
		else if( yoyo <= 12 ) return 13;
		else if( yoyo <= 13 ) return 13.5;
		else if( yoyo <= 14 ) return 14;
		else if( yoyo <= 15 ) return 14.5;
		else if( yoyo <= 16 ) return 15;
		else if( yoyo <= 17 ) return 15.5;
		else if( yoyo <= 18 ) return 16;
		else if( yoyo <= 19 ) return 16.5;
		else if( yoyo <= 20 ) return 17;
		else if( yoyo <= 21 ) return 17.5;
		else if( yoyo <= 22 ) return 18;
		else if( yoyo <= 23 ) return 18.5;
		else if( yoyo <= 24 ) return 19;
		else if( yoyo <= 25 ) return 19.5;
		else if( yoyo <= 26 ) return 20;
		else if( yoyo <= 27 ) return 20.5;
		else if( yoyo <= 28 ) return 21;
		else if( yoyo <= 29 ) return 21.5;
		return 22;
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['startDate']) this.startDate = new Date(obj['startDate']);
		if (obj['accelerationResults']) this.accelerationResults = new SelfAssessmentTestModel().load(obj['accelerationResults']);
		if (obj['speedResults']) this.speedResults = new SelfAssessmentTestModel().load(obj['speedResults']);
		if (obj['powerResults']) this.powerResults = new SelfAssessmentTestModel().load(obj['powerResults']);
		if (obj['agilityResults']) this.agilityResults = new SelfAssessmentTestModel().load(obj['agilityResults']);
		if (obj['recoveryResults']) this.recoveryResults = new SelfAssessmentTestModel().load(obj['recoveryResults']);
		return this;
	}
}