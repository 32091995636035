
import { Component, Prop, Mixins, PropSync, Watch } from 'vue-property-decorator';
import CircledAvatar from '../ui/CircledAvatar.vue';
import ContextMenu from '../ui/ContextMenu.vue';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import { LineupModel } from '@/models/lineup';
import { DepthChartPosition } from '@/models/depthChart';
import { PlayerOnTeam, TeamModel } from '@/models/team';

@Component({
	components: { CircledAvatar, ContextMenu },
})
export default class CreateLineupForm extends Mixins(VuetifyMixin, BAIconsMixin) {
	@Prop({ default: 70 }) headerHeight: number;
	@Prop({ default: 205 }) subHeight: number;

	value: boolean = false;
	input(): void{
		this.$emit('input', this.value);
	}
	get SubInnerHeight(): number{
		return this.subHeight - 16;
	}
	get BodyHeight(): number{
		return this.windowHeight - this.headerHeight;
	}
	get BodyStyle(): Record<string,any>{
		return {
			height: `${this.BodyHeight}px`,
		}
	}
	@Prop({ required: true }) team: TeamModel | null;
	@PropSync('lineup',{ default: null }) Lineup: LineupModel | null;
	@Prop({ default: '' }) lineupName: string;
	updateLineup(lineup: LineupModel): void{
		this.$emit('update:lineup', lineup);
	}

	isValid: boolean = false;
	created():void{
		if (this.Lineup){
			this.validateForm()
		}
	}
	@Watch('lineup') async lineupChanged(): Promise<void>{
		this.validateForm()
	}
	async validateForm(): Promise<void>{
		if(this.Lineup.positions.filter(x => x.playerIds[0] !== null).length < 11){
			this.isValid = false
		}
		else this.isValid = true
	}

	windowHeight: number = 900;
	listView: boolean = true;
	onResize(): void{
		this.windowHeight = ~~(window.innerHeight * 0.9);
	}
	get LineupSubstitutes(): DepthChartPosition[] |  {text: string, value: PlayerOnTeam}[]{	
		if (this.Lineup){
			return this.Lineup.substitutes
		}
		return [];
	}
	get SubLength(): number{
		return this.LineupSubstitutes.length;
	}
	get LineupPositions(): DepthChartPosition[]{
		if(this.Lineup === null) return [];
		return this.Lineup.positions.sort((a,b) => +b.number - +a.number);
	}
	get AvailablePlayers(): {text: string, value: PlayerOnTeam}[]{
		return this.team.players.map(p => ({
			text: `${p.firstName} ${p.lastName}`,
			value: p,
		}))
	}

	createLineup():void{
		this.Lineup.name = this.lineupName;
		this.$emit('submit:lineup',this.Lineup);
	}

	playerFromTeam(position: string): PlayerOnTeam | null{
		const player = this.team.players.find(p => p.id === position);
		if(player === undefined) return null;
		return player;
	}
	setPlayerInPosition(position: DepthChartPosition, player: PlayerOnTeam): void{
		if (player){
			position.playerIds[0] = player['value'].id;
		} else {
			position.playerIds[0] = null;
		}
		this.validateForm();
		this.$emit('select:player')
	}
	setSubstitutePlayerInPosition(position: DepthChartPosition, player: PlayerOnTeam): void{
		position.playerIds[0] = player.id;
	}

	removeSub(index: number): void{
		this.Lineup.substitutes.splice(index, 1);
		this.updateLineup(this.Lineup);
	}
	addSub(): void{
		this.Lineup.substitutes.push(this.Lineup.getEmptyPosition());
	}

}
