
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { StaffMember } from '@/models/team/StaffMember';
import { TeamModel } from '@/models';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component
export default class TeamContactCard extends Mixins(StringsMixin, BAIconsMixin, VuetifyMixin) {
    @Prop() team: TeamModel;

    get HasTeam() {
        return this.IsNotEmpty(this.team);
    }
    onContactStaff(staff: StaffMember) {
        window.open(`mailto:${staff.email}`, '_blank');
    }
}

