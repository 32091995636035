import { EnvironmentName } from "@/../types/constants/environments";

export type BaFrontendConfiguration = {
	BA_ENV: EnvironmentName;
	/** Current build version in package.json */
	version: string;
	Auth0Config: {
		domain: string;
		clientId: string;
		audience: string;
	},
	StripePublicKey: string;
	VUE_APP_API_ROOT_URL: string;
	VUE_APP_LOG_ROCKET_APP_ID: string;
	VUE_APP_ENVIRONMENT_NAME: string;
}
const baseAuth0ConfigPath = './auth0Configs';
const StripeLivePubKey = "pk_live_51H5XzXLYt9Dn1tGPjAMwqHMBHS325VQaVFCvvTAKZJQFo7lP9So5UfRJgD9kIYaC8pNLdnT2D7w36283rBUfPsAC00QUK67XbH"
const StripeTestPubKey = "pk_test_51H5XzXLYt9Dn1tGPfJoEp5Huy6nadguVHZ7W6sw04ZqsYcVFIBn0kZU7vNybfb6KDDosl3Bn40Oml5PBwDulTFIz00Pa4nfCRD"
/**
 * This environment variable is set in `index.html` and can be replaced in the dist output of the app to select and environment at runtime.
 */
const WINDOW_ENVIRONMENT: EnvironmentName = window['baConfigEnv'];
const WINDOW_VERSION: string = window['baAppVersion'] && window['baAppVersion'] !== "<<BA_VERSION_PLACEHOLDER>>" ? window['baAppVersion'] : 'dev-build';

function getConfigs(env): BaFrontendConfiguration{
	if (env === "production" || env === "prod") {
		return {
			BA_ENV: WINDOW_ENVIRONMENT,
			version: WINDOW_VERSION,
			Auth0Config: require(`${baseAuth0ConfigPath}/auth_config_production.json`),
			StripePublicKey: StripeLivePubKey,
			VUE_APP_API_ROOT_URL: 'https://api.app.bestathletes.co',
			VUE_APP_LOG_ROCKET_APP_ID: 'yuebri/best-athletes-prod',
			VUE_APP_ENVIRONMENT_NAME: 'production',
		};
	} else if (env === "staging" || env === "stage") {
		return {
			BA_ENV: WINDOW_ENVIRONMENT,
			version: WINDOW_VERSION,
			Auth0Config: require(`${baseAuth0ConfigPath}/auth_config_staging.json`),
			StripePublicKey: StripeTestPubKey,
			VUE_APP_API_ROOT_URL: 'https://api.stage.bestathletes.co',
			VUE_APP_LOG_ROCKET_APP_ID: 'yuebri/best-athletes-stage',
			VUE_APP_ENVIRONMENT_NAME: 'staging',
		};
	} else if (env === "testing" || env === "test") {
		return {
			BA_ENV: WINDOW_ENVIRONMENT,
			version: WINDOW_VERSION,
			Auth0Config: require(`${baseAuth0ConfigPath}/auth_config_test.json`),
			StripePublicKey: StripeTestPubKey,
			VUE_APP_API_ROOT_URL: 'https://api.test.bestathletes.co',
			VUE_APP_LOG_ROCKET_APP_ID: 'yuebri/best-athletes-test',
			VUE_APP_ENVIRONMENT_NAME: 'testing',
		};
	} else if (env === "dev" || env === "development") {
		return {
			BA_ENV: WINDOW_ENVIRONMENT,
			version: WINDOW_VERSION,
			Auth0Config: require(`${baseAuth0ConfigPath}/auth_config_dev.json`),
			StripePublicKey: StripeTestPubKey,
			VUE_APP_API_ROOT_URL: 'https://api.dev.bestathletes.co',
			VUE_APP_LOG_ROCKET_APP_ID: 'yuebri/best-athletes-dev',
			VUE_APP_ENVIRONMENT_NAME: 'dev',
		};
	} else {
		const environment: BaFrontendConfiguration = {
			BA_ENV: 'local',
			version: WINDOW_VERSION,
			Auth0Config: require(`${baseAuth0ConfigPath}/auth_config_production.json`),
			StripePublicKey: StripeLivePubKey,
			VUE_APP_API_ROOT_URL: process.env.VUE_APP_API_ROOT_URL,
			VUE_APP_LOG_ROCKET_APP_ID: process.env.VUE_APP_LOG_ROCKET_APP_ID,
			VUE_APP_ENVIRONMENT_NAME: process.env.VUE_APP_ENVIRONMENT_NAME
		};

		// To override these in local development,
		// create a .env.local file in the root of web_client_user
		//
		// DEBUG PROD DB (search DEBUG_PROD)
		// to debug prod db, comment out the lines for DEV DB and STAGING DB below
		environment.Auth0Config = require(`${baseAuth0ConfigPath}/auth_config_production.json`);
		environment.StripePublicKey = StripeLivePubKey;

		// DEBUG STAGING DB (search DEBUG_STAGE)
		// environment.Auth0Config = require(`${baseAuth0ConfigPath}/auth_config_staging.json`);
		// environment.StripePublicKey = StripeTestPubKey;

		// DEV DB
		// the two lines below overwrite the PROD DB and STATING DB debugging. Leave uncommented to use local DEV DB config
		environment.Auth0Config = require(`${baseAuth0ConfigPath}/auth_config_dev.json`);
		environment.StripePublicKey = StripeTestPubKey;

		return environment;
	}
}

/**
 * Runtime configs allow us to build the app once and select the environment at runtime.
 * This saves alot of build minutes since a successful build can be passed up to the next environment.
 */
export const config = getConfigs(WINDOW_ENVIRONMENT);
if(!WINDOW_ENVIRONMENT.includes("prod") && !WINDOW_ENVIRONMENT.includes("stage")){
	console.log("Development Mode. Using Environment Configuration", config);
}