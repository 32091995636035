import { AccessControlledModel } from '../AccessControlledModel';
import { LineupOnTeam } from '../team/LineupOnTeam';
import { DepthChartModel, DepthChartPosition } from '../depthChart';
import { LineupType } from '@/../types/enums';
import { TeamModel } from '../team';

export class LineupModel extends AccessControlledModel implements LineupOnTeam {
	name: string = "";
	type: string = "";
	formation: string = "";
	positions: DepthChartPosition[] = [];
	substitutes: DepthChartPosition[] = [];
	associatedDepthChartId?: string = "";
	team: TeamModel | null = null;

	setName(name: string){
		this.name = name;
		return this;
	}
	setType(){
		this.type = LineupType.Gameday;
		return this;
	}
	setFormation(formation){
		this.formation = formation;
		return this;
	}
	setStarters(positions: DepthChartPosition[]){
		this.positions = positions.map(pos => new DepthChartPosition().load(pos));
		return this;
	}
	getEmptyPosition(): DepthChartPosition{
		const emptyPosition = new DepthChartPosition();
		emptyPosition.number = "";
		emptyPosition.playerIds = [];
		emptyPosition.position = "";
		return emptyPosition;
	}
	setSubstitutes(){
		for (let index = 0; index < 3; index++) {
			const emptyPosition = this.getEmptyPosition();
			this.substitutes.push(emptyPosition);
		}
		return this
	}
	setAssociatedDepthChart(depthChartId: string) {
		this.associatedDepthChartId = depthChartId;
		return this;
	}
	setLineup(depthChart: DepthChartModel){
		const lineup = depthChart.copy();
		Object.assign(this, lineup);
		return this;
	}

	static GenerateLineup(name: string, depthChart: DepthChartModel){
		return new LineupModel()
			.setName(name)
			.setType()
			.setFormation(depthChart.formation)
			.setStarters(depthChart.positions)
			.setSubstitutes()
			.setAssociatedDepthChart(depthChart.id);
	}
}