import {AccessControlledModel} from '../AccessControlledModel';
import {MindsetUser, MindsetUserStatus} from '@/../types/interfaces/MindsetUser';

export class MindsetUserModel extends AccessControlledModel implements MindsetUser {
	// @inherited		id: string;

    ryzerId: string = '';            // Ryzer UserID
    email: string = '';              // Email common in Best Athletes and Ryzer database to identify user
    link: string = '';               // TAP link for this user
    status: MindsetUserStatus = MindsetUserStatus.Unknown;
}