import { CrudApi } from './CrudApi';
import { GameReportModel } from "@/models";
import { QueryOptions, RepositoryQuery } from '@/../types/interfaces';

class GameReportApi extends CrudApi<GameReportModel> {
	constructor() {
		super('game-report', (obj) => new GameReportModel(obj));
	}

	async findByObservation(observationId: string): Promise<GameReportModel> {
		const query: RepositoryQuery<GameReportModel> = {};
		query.fields = ['observations'];
		query.search = observationId;
		const options: QueryOptions = {};
		const response = await this.queryAll(query, options);
		if( response.count > 0 ) return response.docs[0];
		return undefined;
	}
}

export const gameReportApi = new GameReportApi();
