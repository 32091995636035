import mixpanel from 'mixpanel-browser';
import { BAUser, UserTypeMap } from "@/../types/interfaces";

export function mixpanelTrack(event: string, data: any){
	mixpanel.track(event, data);
}

export function useMixpanelTracking(user: BAUser, map: Partial<UserTypeMap> = {}, debug: boolean = false): void{
	mixpanel.init('46ae2bbe5e9a3dbdad084c868af1d197', { debug });
	mixpanel.identify(user.id);
	mixpanel.register({
		email: user.email,
		email_verified: user.email_verified,
		pictureUrl: user.pictureUrl,
		firstName: user.firstName,
		lastName: user.lastName,
		...map,
	});
}
