
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BAIconsMixin, VuetifyMixin } from '@/mixins';
import { TeamJersey } from '@/models/team';
import { Compact } from 'vue-color';

interface VueColor {
  hex: string;
  rgba: { r: number; g: number; b: number; a: number };
  hsl: { h: number; s: number; l: number; a: number };
  [key: string]: any; // To account for other possible color representations
}

@Component({
    components: {
        'compact-picker': Compact
    }
})
export default class TeamCreateTeamInfo extends Mixins(VuetifyMixin, BAIconsMixin) {
    @Prop() value: TeamJersey;

    editingColors: boolean = false;
    editingJerseyColor: boolean = false;
    editingJerseyAccent: boolean = false;
    editingJerseyNumber: boolean = false;
	vueColorJersey: VueColor = {
		hex: '#194d33',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};
	vueColorAccent: VueColor = {
		hex: '#ffffff',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};
	vueColorNumber: VueColor = {
		hex: '#ffffff',
	    rgba: { r: 25, g: 77, b: 51, a: 1 },
    	hsl: { h: 146, s: 49, l: 20, a: 1 }
	};

    onEditJersey() {
        this.vueColorJersey.hex = this.value.color;
        this.vueColorAccent.hex = this.value.accent;
        this.vueColorNumber.hex = this.value.number;
        this.editingColors = true;
    }
    onEditJerseyComplete() {
        this.$emit('input', this.value);
        this.editingColors = false;
    }
	onChangeJerseyColor(newColor: VueColor) {
		this.value.color = newColor.hex;
        this.editingJerseyColor = false;
	}
	onChangeAccentColor(newColor: VueColor) {
		this.value.accent = newColor.hex;
        this.editingJerseyAccent = false;
	}
	onChangeNumberColor(newColor: VueColor) {
		this.value.number = newColor.hex;
        this.editingJerseyNumber = false;
	}
    onEditJerseyColor() {
        this.editingJerseyColor = true;
    }
    onEditJerseyAccent() {
        this.editingJerseyAccent = true;
    }
    onEditJerseyNumber() {
        this.editingJerseyNumber = true;
    }
}
