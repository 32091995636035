// HelpInfoMixin.ts
/*
<v-card-text v-if="ShowHelp">
    <HelpInfo
        v-model="value"
        preText="this text will appear before the link"
        linkText="link text"
        postText="this text will appear after the link"
        @click="gotoLinkDestination"
        @toggle="onToggleHelp"
    />
 </v-card-text>
 */

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AppHostnameMixin } from './AppHostnameMixin';

@Component
export class HelpInfoMixin extends Mixins(AppHostnameMixin) {
    @Prop({type: Boolean}) value;

    onToggleHelp() {
        this.$emit('input', this.value);
        this.$emit('toggle');
    }
    get ShowHelp() {
        if( !this.AthleteAppActive ) return false;
        return this.value;
    }
}