
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
@Component
export default class JerseySvg extends Mixins(VuetifyMixin){
	@Prop({ default: 19 }) size: number;
	@Prop({ default: '#005AA8' }) private color: string;
	@Prop({ default: '#FDB62F' }) private collarColor: string;

	get Color(){
		return this.getColor(this.color);
	}
	get CollarColor(){
		return this.getColor(this.collarColor);
	}
}
