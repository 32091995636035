import { Component, Vue } from 'vue-property-decorator';
import { coachProfile as coachProfileStore } from '../store';
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { CoachResumeModel } from '@/models/coach/CoachResumeModel';
import { Auth0Plugin } from '@/auth/Auth0';

/**
 * Provides computed properties: 
 * - MyCoachId
 * - MyCoachProfile
 * - MyCoachResume
 */
@Component
export class MyCoachMixin extends Vue {
	reloadMyCoach: boolean = true;
	$auth: Auth0Plugin;
	get MyCoachIsReady(): boolean{
		return !coachProfileStore.loading && coachProfileStore.coachProfileInitialized;
	}

	get MyCoachId(): string {
		if (this.$auth.user && this.$auth.user.coachId){
			return this.$auth.user.coachId;
		}
		return undefined;
	}
	/** Alias for MyCoachId */
	get MyCoachProfileId(): string {
		if (this.$auth.user && this.$auth.user.coachId){
			return this.$auth.user.coachId;
		}
		return undefined;
	}

	get MyCoachProfile(): CoachProfileModel {
		if(this.MyCoachIsReady){
			return coachProfileStore.coachProfile;
		}
		return undefined;
	}

	get MyCoachResume(): CoachResumeModel {
		if(this.MyCoachIsReady){
			return coachProfileStore.coachProfile.resume;
		}
		return undefined;
	}

	created(){
		if( !this.MyCoachId ) return;
		
		if( this.reloadMyCoach || !coachProfileStore.coachProfileInitialized ){
			coachProfileStore.getCoachProfile(this.MyCoachId);
		}
	}

}
