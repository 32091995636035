import { ActionableModel } from "@/models";
import { ActionType } from "@/../types/enums";
import { Action } from '@/../types/interfaces';

export function navigateAction(to: string, routeParams: Action<any>['payload']): ActionableModel {
	/**
	 * Creates a navigate notification action; Takes route and route params as parameters
	 */
	const navigateAction = new ActionableModel().load({
		type: ActionType.VueRouter,
		method: undefined,
		action: to,
		payload: routeParams,
		used: false,
		onetime: true,
	})
	return navigateAction;
}