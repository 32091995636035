// AthleteProfileModel.ts

import { AthleteProfileRole, AthleteRelationship, Position, AthleteUserRelationship, SocialMediaField, ProfileVisibilitySetting, HostedVideoDetail, ScheduleDetails } from '@/../types/interfaces';
import { AclPermissionValues } from '@/../types/permissions';
import { Roles, Relationships } from '@/../types/constants/athlete';
import { AthleteProfileRelationshipName, CountryCode, BaseSoccerPosition, SoccerPositionToBasePositionMapping, LanguageCode, Length, Mass, DominantSide } from '@/../types/enums';
import { AgeGroup, Gender, Under13AgeGroup } from '@best-athletes/ba-types';
import { ageToAgeGroup } from "@/../types";
import { AthleteProfileStatus } from '@/../types/enums/athlete-profile-status.ts';
import { getDifference, getTodayUTC } from '@/helpers/date';

import { UserProfileModel } from '../UserProfileModel';
import { grantUserAcls, revokeUserAcls } from '@/services/AccessControlledService';
import { RecruitingProfileModel } from './RecruitingProfileModel';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '../video/VideoClipModel';
import { formatDate } from "@/helpers/date";
import { formatPositionShort } from "@/helpers/strings"
import { Country, Language, SportGender, SocialMedia } from "@/../types/enums";
import { capitalize, isEmpty, isEmptyArray, isNotEmpty, isNotEmptyArray } from '@/pipes';
import { BAEventModel, EventTicketModel } from '../baEvent';
import { BAPurchaseModel } from '../baPurchase';
import { SportModel } from '../sport';
import { videoUrlParser, youtubeThumbnailUrl, youtubeUrl } from '@/helpers/youtube';
import { PlayerOnTeam, TeamAthleteProgress, TeamModel } from '../team';
import { teamApi } from '@/api/TeamApi';

export class AthleteProfileModel extends UserProfileModel {
	organizationId?: string | null = null;
	profileStatus?: AthleteProfileStatus | null = null;
	birthDate: Date | null = null;
	city: string = "";
	country: string = "";
	phone: number = null;
	demo: boolean = false;						// indicates if this is a demo athlete profile
	altEmail?: Array<string> = [];
	gender: Gender | null = null;
	gradYear: string = "";
	height: number = 0;
	heightUnit: Length = Length.CENTIMETER;
	weight: number = 0;
	weightUnit: Mass = Mass.KILOGRAM;
	sports: Position[] = [];					// legacy
	athleteSports: string[] = [];				// ids of sports this athlete participates in
	activeSport: string = undefined;			// id of active SportModel
	currentTeam: string = undefined;			// id of current team
	featuredScoutingReport: string = undefined;	// id of preferred scouting report
	participantSports: SportModel[] = [];
	tosAgreed: boolean = false;
	dominantSide: DominantSide; 

	// Recruiting Profile Settings
	recruitingProfileComplete: boolean = false;
	primaryPosition: BaseSoccerPosition | null = null;
	secondaryPosition: BaseSoccerPosition | null = null;
	shortBio: string = "";
	accolades: string = "";
	academicAwards: string = "";
	extraCurriculars: string = "";
	membershipID: string = "";
	highlightVideo: string= "";
	highlightVideos: string[] = [];
	fullmatchVideos: string[] = [];
	fullmatchDetails: HostedVideoDetail[] = [];
	highlightDetails: HostedVideoDetail[] = [];
	schedule: ScheduleDetails[] = [];
	highestLevelPlayed: string = "";
	currentLevel: string = "";
	previousClubs: string = "";
	otherActivities: string = "";
	languagesSpoken: LanguageCode[] = [];
	citizenships: CountryCode[] = [];
	socialMedia: SocialMediaField[] = [];
	highschoolGPA: number = null;
	highschoolName: string = "";
	highschoolGradYear: number =  null;
	schoolCommitment: string = "";
	ncaaID: string = "";
	SATScore: number = null;
	ACTScore: number = null;
	anticipatedStudy: string = "";

	videos: Array<VideoModel> = [];
	clips: Array<VideoClipModel> = [];

	baPurchases: Array<string> = [];			// event and product purchase ids

	// the following are purchases. Items that are loaded from related database collections and are not stored in athlete database
	purchases: Array<BAPurchaseModel> = [];
	events: Array<BAEventModel> = [];

	sharingUrlId: string;

	// used by front end only
	primary: boolean = false;
	useInMerge: boolean = false;
	progress: TeamAthleteProgress = undefined;

	teams: Array<TeamModel> = [];
	team: TeamModel;

	get Sports(): SportModel[] {
		if( !this.participantSports ) return [new SportModel];

		return this.participantSports;
	}
	get Sport(): SportModel {
		const sport = this.Sports.find(s => s.id === this.activeSport );
		if( sport ) return sport;

		return new SportModel;
	}

	get SportGender(): SportGender {
		if( isEmpty(this.gender) ) return SportGender.MEN;
		if( this.gender.toLowerCase() === Gender.Male ) return SportGender.MEN;
		if( this.gender.toLowerCase() === Gender.Female ) return SportGender.WOMEN;
		return SportGender.MEN;
	}
	get Events(): BAEventModel[] {
		return this.events.filter(ev => isNotEmpty(ev));
	}

	get ProfileHasVisibility(): boolean{
		if( !this.profileVisibility ) return false;
		const atLeastOneVisible = [
			this.profileVisibility['recruiter'].gradYear,
			this.profileVisibility['recruiter'].email,
			this.profileVisibility['recruiter'].pictureUrl,
			this.profileVisibility['recruiter'].birthDate,
			this.profileVisibility['recruiter'].city,
			this.profileVisibility['recruiter'].country,
			this.profileVisibility['recruiter'].phone,
			this.profileVisibility['recruiter'].gender,
			this.profileVisibility['recruiter'].height,
			this.profileVisibility['recruiter'].dominantSide,
			this.profileVisibility['recruiter'].weight,
			this.profileVisibility['recruiter'].sports,
		].reduce((a,b) => a || b);
		return atLeastOneVisible;
	}
	profileVisibility: ProfileVisibilitySetting<RecruitingProfileModel> = null;

	// take a pice of information and check if the data is valid
	// return `undefined` if data is not valid
	SanitizeInfo(info: any): any {
		if( info == null || info == undefined ) return undefined;

		if( typeof(info) === 'string' && info === '' ) return undefined;
		if( typeof(info) === 'number' && info === 0 ) return undefined;

		return info;
	}

	// getters
	get Age(): number | undefined {
		if( !this.birthDate ) return undefined;
		const age = getDifference(this.birthDate, getTodayUTC(), "years");
		return Math.floor(age.years);
	}
	get AgeGroup(): AgeGroup | Under13AgeGroup {
		return ageToAgeGroup(this.Age, { allowUnder13: true });
	}
	get Bio(): string | undefined {
		return this.SanitizeInfo(this.shortBio);
	}
	get Location(): string {
		if( this.city ) {
			if( this.country ) return `${this.City}, ${this.Country}`;
			return this.City;
		}
		return this.Country;
	}
	get City(): string {
		return this.SanitizeInfo(capitalize(this.city));
	}
	get Country(): string {
		const country: Country = Country[this.country];
		if( isNotEmpty(country) ) return country;
		return this.SanitizeInfo(capitalize(this.country));
	}
	get Accolades(): string | undefined {
		return this.SanitizeInfo(this.accolades);
	}
	get AcademicAwards(): string | undefined {
		return this.SanitizeInfo(this.academicAwards);
	}
	get ExtraCurriculars(): string | undefined {
		return this.SanitizeInfo(this.extraCurriculars);
	}
	get CurrentPlayingLevel(): string | undefined {
		return this.SanitizeInfo(this.currentLevel);
	}
	get OtherActivities(): string | undefined {
		return this.SanitizeInfo(this.otherActivities);
	}
	get Email(): string {
		return this.SanitizeInfo(this.email);
	}
	get BirthDate(): string | undefined {
		if(!this.birthDate) return undefined;
		return formatDate(this.birthDate, true, true);
	}
	get Citizenship(): string | undefined {
		if(!this.citizenships) return undefined;
		return this.citizenships.map(country => Country[country]).join(", ");
	}
	get Languages(): string | undefined {
		if( isEmptyArray(this.languagesSpoken)) return undefined;
		return this.languagesSpoken.map(lang => Language[lang]).join(", ");
	}
	get GPA(): number | undefined {
		return this.SanitizeInfo(this.highschoolGPA);
	}
	get GradYear(): number | undefined {
		return this.SanitizeInfo(this.highschoolGradYear)?? this.SanitizeInfo(this.gradYear);
	}
	get HighSchool(): string | undefined {
		return this.SanitizeInfo(this.highschoolName);
	}
	get Commitment(): string | undefined {
		return this.SanitizeInfo(this.schoolCommitment);
	}
	get HighestLevelPlayed(): string | undefined {
		return this.SanitizeInfo(this.highestLevelPlayed);
	}
	get PhoneNumber(): string {
		return this.SanitizeInfo(this.phoneNumber)?? this.SanitizeInfo(this.phone);
	}
	get Position(): string | undefined {
		if( this.primaryPosition ) {
			var pos = this.primaryPosition.toString();
			if( this.secondaryPosition && this.primaryPosition != this.secondaryPosition ) {
				pos += ", " + this.secondaryPosition.toString();
			}
			return pos;
		}
	}
	get PositionShort(): string {
		if( this.primaryPosition ) {
			var pos = formatPositionShort(this.primaryPosition);
			if( this.secondaryPosition && this.primaryPosition != this.secondaryPosition ) {
				pos += ", " + formatPositionShort(this.secondaryPosition);
			}
			return pos;
		}
	}
	get PreviousClubs(): string | undefined {
		return this.SanitizeInfo(this.previousClubs);
	}
	get ProgramOfStudy(): string | undefined {
		return this.SanitizeInfo(this.anticipatedStudy);
	}
	get NCAA(): string | undefined {
		return this.SanitizeInfo(this.ncaaID);
	}
	get SAT(): number | undefined {
		return this.SanitizeInfo(this.SATScore);
	}
	get ACT(): number | undefined {
		return this.SanitizeInfo(this.ACTScore);
	}
	get CurrentTeam(): TeamModel {
		return this.team;
	}

	// visibility of data
	isVisible(key: string): boolean {
		return this.profileVisibility.recruiter[key];
	}
	isAnyVisible(keys: string[]): boolean {
		for( const k of keys ) {
			if( this.isVisible(k) ) return true;
		}
		return false;
	}
	isAllVisible(keys: string[]): boolean {
		for( const k of keys ) {
			if( !this.isVisible(k) ) return false;
		}
		return true;
	}
	// ownership of data
	hasData(key: string): boolean {
		const value = this.SanitizeInfo(this[key]);
		return isNotEmpty(value);
	}
	hasAnyData(keys: string[]): boolean {
		for( const k of keys ) {
			if( this.hasData(k) ) return true;
		}
		return false;
	}
	hasAllData(keys: string[]): boolean {
		for( const k of keys ) {
			if( !this.hasData(k) ) return false;
		}
		return true;
	}
	// coach sharing
	shareWithCoaches(key: string): boolean {
		return this.hasData(key) && this.isVisible(key);
	}
	shareAny(keys: string[]): boolean {
		for( const k of keys ) {
			if( this.shareWithCoaches(k) ) return true;
		}
		return false;
	}
	shareAll(keys: string[]): boolean {
		for( const k of keys ) {
			if( !this.shareWithCoaches(k) ) return false;
		}
		return true;
	}

	// data accessors
	aboutFields = ['shortBio','accolades','extraCurriculars'];
	get shareAbout(): boolean {
		return this.shareAny(this.aboutFields);
	}

	exAboutFields = ['previousClubs','highestLevelPlayed','currentLevel'];
	get shareExtendedAbout(): boolean {
		return this.shareAny(this.exAboutFields);
	}

	contactFields = ['email','phoneNumber','phone'];
	get isVisibleContact(): boolean {
		return this.isAnyVisible(this.contactFields);
	}
	get shareContact(): boolean {
		return this.shareAny(this.contactFields);
	}

	baseBioFields = ['gender','birthDate','height','weight','dominantSide','city','country','citizenships','languagesSpoken'];
	get shareBaseInfo(): boolean {
		return this.shareAny(this.baseBioFields);
	}
	educationFields = ['highschoolName','highschoolGradYear','highschoolGPA','schoolCommitment','ncaaID','SATScore','ACTScore','anticipatedStudy'];
	get isVisibleEducation(): boolean {
		return this.isAnyVisible(this.educationFields);
	}
	get hasEducation(): boolean {
		return this.hasAnyData(this.educationFields);
	}

	get hasBio(): boolean {
		if( !!this.gender ) return true;
		if( !!this.birthDate ) return true;
		if( !!this.height && this.height > 0 ) return true;
		if( !!this.weight && this.weight > 0 ) return true;
		if( !!this.city ) return true;
		if( !!this.country ) return true;
		if( this.hasLanguages ) return true;
		if( !!this.membershipID ) return true;
		if( this.hasCitizenship ) return true;

		return false;
	}
	get hasCitizenship(): boolean {
		return isNotEmptyArray(this.citizenships);
	}
	get hasLanguages(): boolean {
		return isNotEmptyArray(this.languagesSpoken);
	}

	get hasVideos(): boolean {
		return( isNotEmptyArray(this.highlightDetails) || isNotEmptyArray(this.fullmatchDetails) );
	}

	get hasSchedule(): boolean {
		return isNotEmptyArray(this.schedule);
	}
	get hasArchivedSchedule(): boolean {
		return isNotEmptyArray( this.ArchivedSchedule );
	}
	hasFutureSchedule(includeArchived: boolean = false): boolean {
		return isNotEmptyArray( this.FutureSchedule(includeArchived) );
	}
	hasPastSchedule(includeArchived: boolean = false): boolean {
		return isNotEmptyArray( this.PastSchedule(includeArchived) );
	}
	get ArchivedSchedule(): ScheduleDetails[] {
		if( !this.hasSchedule ) return [];
		return this.schedule.filter(s => s.archive );
	}
	FutureSchedule(includeArchived: boolean = false): ScheduleDetails[] {
		if( !this.hasSchedule ) return [];
		const today = new Date();
		const future = this.schedule.filter(s => s.date >= today );
		if( includeArchived ) return future;
		return future.filter(s => !s.archive );
	}
	PastSchedule(includeArchived: boolean = false): ScheduleDetails[] {
		if( !this.hasSchedule ) return [];
		const today = new Date();
		const past = this.schedule.filter(s => s.date < today );
		if( includeArchived ) return past;
		return past.filter(s => !s.archive );
	}
	get PastEvents(): BAEventModel[] {
		if( isEmptyArray(this.Events) ) return [];
		const today = new Date();
		return this.Events.filter(e => e.date < today );
	}
	get hasPastEvents(): boolean {
		return isNotEmptyArray(this.PastEvents);
	}
	get FutureEvents(): BAEventModel[] {
		if( isEmptyArray(this.Events) ) return [];
		const today = new Date();
		const fiveDaysAgo = new Date;
		fiveDaysAgo.setDate(today.getDate() - 5);
		return this.Events.filter(e => e.date >= fiveDaysAgo );
	}
	get hasFutureEvents(): boolean {
		return isNotEmptyArray(this.FutureEvents);
	}
	get hasEvents(): boolean {
		return isNotEmptyArray(this.Events);
	}
	hasEvent(eventId: string): boolean {
		if( !this.hasEvents ) return false;
		const event = this.Events.find(e => e.id === eventId);
		return isNotEmpty(event);
	}
	addEvent(event: BAEventModel): boolean {
		// if event already exists for this athlete, do nothing
		const athleteEvent = this.events.find(e => e.id === event.id);
		if( athleteEvent ) return false;

		const data: Record<string, any> = event.dataLabels.map(label => {label: this[label]});
		const purchase = new BAPurchaseModel().load({
			parentId: this.id,
			productId: event.id,
			priceId: undefined,
			date: new Date(),
			requested: false,
			completed: true,
			data
		})

		this.purchases.push(purchase);
		return true;
	}
	removeEvent(eventId: string): boolean {
		if( !this.hasEvents ) return false;

		// remove from events array
		const index = this.events.findIndex( e => e.id === eventId);
		if( index < 0 ) return false;		
		this.events.splice(index, 1);

		// remove from purchases array
		const purchaseIdx = this.purchases.findIndex( p => p.productId === eventId );
		if( index < 0 ) return false;
		this.purchases.splice(purchaseIdx, 1);

		return true;
	}
	MyTicket(baEvent: BAEventModel): EventTicketModel {
		return baEvent.AthleteTicket(this.athleteId);
	}

	public readonly userRelationships: AthleteUserRelationship[] = [];

	isAthlete(userId: string): boolean{
		const rel = this.userRelationships.find(u => u.userId === userId);
		if(rel === undefined) return false;
		if(rel.relationship.name === AthleteProfileRelationshipName.Athlete) return true;
		return false;
	}
	isParent(userId: string): boolean{
		const rel = this.userRelationships.find(u => u.userId === userId);
		if(rel === undefined) return false;
		if(rel.relationship.name === AthleteProfileRelationshipName.Parent) return true;
		return false;
	}
	getUser(userId: string): AthleteUserRelationship{
		return this.userRelationships.find(u => u.userId === userId);
	}
	/**
	 * Sets a user's relationship to athlete and applies the correct UserACLs from the role.
	 */
	setUser(userId: string, userRelation: AthleteUserRelationship): void{
		if (userRelation.relationship && userRelation.role === undefined) {
			userRelation.role = AthleteProfileModel.Roles.find(role => role.name === userRelation.relationship.defaultRole);
		}
		if (userRelation.role !== undefined) {
			revokeUserAcls(this, AclPermissionValues, { userId: userRelation.userId });
			grantUserAcls(this, userRelation.role.permissions, { userId: userRelation.userId });
		}

		// Update the existing relationship if there is one between the users
		const userIndex = this.userRelationships.findIndex(u => u.userId === userId);
		if(userIndex > -1){
			this.userRelationships.splice(userIndex, 1, userRelation);
		}
		// Otherwise insert a new relationship
		else{
			this.userRelationships.push(userRelation);
		}
	}
	removeUser(userId: string): void{
		const userAclIndex = this.users.findIndex(u => u.id === userId);
		if (userAclIndex > -1){
			this.users.splice(userAclIndex, 1);
		}
		const userIndex = this.userRelationships.findIndex(u => u.userId === userId);
		if(userIndex > -1){
			this.userRelationships.splice(userIndex, 1);
		}
	}

	isTeamLoaded: boolean = false;
	get HasTeam(): boolean {
		if( !this.isTeamLoaded ) return false;
		return isNotEmpty(this.team);
	}
	get NumTeams(): number {
		return this.teams.length;
	}
	async loadTeams(force: boolean = false) {
		if( isEmpty(this.id) ) return;
		if( this.HasTeam && !force ) return;
		
		this.isTeamLoaded = false;
		if( isEmptyArray(this.teams) ) {
			const teams = await teamApi.findByAthleteId(this.id);
			this.teams = teams.map(t => new TeamModel().load(t));
		}
		if( isNotEmptyArray(this.teams) ) {
			this.team = this.teams.find(t => t.id === this.currentTeam);
			if( isEmpty(this.team) ) this.team = this.teams[this.teams.length - 1];
		}
		this.isTeamLoaded = true;
	}
	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if (obj['birthDate'] !== undefined && obj['birthDate'] !== null) 
			this.birthDate = new Date(obj['birthDate']);
		if (obj['videos']) {
			this.videos = obj['videos'].map((video: any) =>  new VideoModel().load(video));
		}
		if (obj['clips']) {
			this.clips = obj['clips'].map((clip: any) =>  new VideoClipModel().load(clip));
		}

		// populate schedule with the proper date format
		if( isNotEmptyArray(this.schedule) ) {
			this.schedule = this.schedule.map(s => {
				const event: ScheduleDetails = s;
				event.date = new Date(s.date);
				return event;
			})
		}
		// populate fullmatchDetails from deprecated items
		if( isEmptyArray(this.fullmatchDetails) ) {
			this.fullmatchDetails = this.fullmatchVideos.map(v => {
				const videoId = videoUrlParser(v);
				return {
					videoId,
					label: videoId,
					videoUrl: youtubeUrl(videoId),
					thumbnailUrl: youtubeThumbnailUrl(videoId)
				}
			})
		}
		// pupulate highlightDetails from deprecated items
		if( isEmptyArray(this.highlightDetails) ) {
			if( isEmptyArray(this.highlightVideos) || isEmpty(this.highlightVideos[0]) ) {
				if( isNotEmpty(this.highlightVideo) ) {
					const videoId = videoUrlParser(this.highlightVideo);
					this.highlightDetails = [{
						videoId,
						label: videoId,
						videoUrl: youtubeUrl(videoId),
						thumbnailUrl: youtubeThumbnailUrl(videoId)
					}];
				}
			} else {
				this.highlightDetails = this.highlightVideos.map(v => {
					const videoId = videoUrlParser(v);
					return {
						videoId,
						label: videoId,
						videoUrl: youtubeUrl(videoId),
						thumbnailUrl: youtubeThumbnailUrl(videoId)
					}
				})
			}
		}
		if( this.profileVisibility && this.profileVisibility.recruiter ) {
			this.profileVisibility.recruiter = {
				...DefaultVisibilitySetting.recruiter,
				...this.profileVisibility.recruiter,
				...{ highlightDetails: true }
			}
		} 
		
		return this;
	}

	HasProductPurchase(productId: string): boolean {
		const purchase = this.purchases.find(p => p.productId === productId);
		return isNotEmpty(purchase);
	}
	get PublicSharingUrl(): string {
		return `/athlete/${this.id}/${this.sharingUrlId}`
	}

	get PlayerNumber(): string {
		return undefined;
	}
	get AsPlayerOnTeam(): PlayerOnTeam {
		const playerOnTeam = {
			userId: isEmptyArray(this.users)? null: this.users[0].id,
			athleteId: this.id,
			email: this.email,
			firstName: this.firstName,
			lastName: this.lastName,
			gender: this.gender,
			number: this.PlayerNumber,
			position: SoccerPositionToBasePositionMapping[this.primaryPosition],
			secondaryPosition: SoccerPositionToBasePositionMapping[this.secondaryPosition],
			preferredPositionNumbers: null,
			gradYear: this.GradYear,
			dateOfBirth: this.BirthDate,
			schoolCommitment: this.Commitment,
			pictureUrl: this.pictureUrl,
		}
		return playerOnTeam;
	}
	InitProgress(recruitingProfile: RecruitingProfileModel = undefined): TeamAthleteProgress {
		const playerOnTeam = this.AsPlayerOnTeam;
		this.progress = {...playerOnTeam,
			shortId: this.shortId,
			hasPhoto: isNotEmpty(this.pictureUrl),
			hasEmail: isNotEmpty(this.Email),
			hasPhone: isNotEmpty(this.PhoneNumber),
			hasInsta: isNotEmpty(this.socialMedia.find(sm => sm.platform === SocialMedia.Instagram)?.link),
			hasTwitter: isNotEmpty(this.socialMedia.find(sm => sm.platform === SocialMedia.Twitter)?.link),
			hasBio: isNotEmpty(this.Bio),
			hasDOB: isNotEmpty(this.BirthDate),
			hasHeight: isNotEmpty(this.height),
			hasWeight: isNotEmpty(this.weight),
			hasDominant: isNotEmpty(this.dominantSide),
			hasLocation: isNotEmpty(this.Location),
			hasHighlights: isNotEmpty(this.hasVideos),
			hasGradYear: isNotEmpty(this.GradYear),
			hasGPA: isNotEmpty(this.GPA),
			hasSAT: isNotEmpty(this.SAT),
			commitment: this.Commitment,
			hasVerifiedAssessment: isNotEmpty(recruitingProfile?.physicalPerformance),
			mindsetArchetype: recruitingProfile?.mindsetReport?.archetype,
			hasScoutingReport: isNotEmpty(recruitingProfile?.scoutingReport),
		};

		return this.progress;
	}

	get ProgressValue(): number {
		let pVal: number = 0;
		if( isEmpty(this.progress) ) this.InitProgress();

		if( this.progress.hasPhoto ) pVal += 10;
		if( this.progress.hasEmail ) pVal += 1;
		if( this.progress.hasPhone ) pVal += 5;
		if( this.progress.hasInsta ) pVal += 10;
		if( this.progress.hasTwitter ) pVal += 10;
		if( this.progress.hasBio ) pVal += 25;
		if( this.progress.hasDOB ) pVal += 1; 
		if( this.progress.hasHeight ) pVal += 10;
		if( this.progress.hasWeight ) pVal += 10;
		if( this.progress.hasDominant ) pVal += 1;
		if( this.progress.hasLocation ) pVal += 1;
		if( this.progress.hasHighlights ) pVal += 25;
		if( this.progress.hasGradYear ) pVal += 5;
		if( this.progress.hasGPA ) pVal += 10;
		if( this.progress.hasSAT ) pVal += 10;
		if( this.progress.hasVerifiedAssessment ) pVal += 100;
		if( this.progress.mindsetArchetype ) pVal += 100;
		if( this.progress.hasScoutingReport ) pVal += 50;
		if( this.progress.commitment ) pVal += 25;
	
		return pVal;
	}

	public static Roles: AthleteProfileRole[] = Roles;
	public static Relationships: AthleteRelationship[] = Relationships;

}

export const DefaultVisibilitySetting = {
	recruiter: {
		firstName: true,
		lastName: true,
		email: true,
		phone: true,
		pictureUrl: true,
		gender: true,
		birthDate: true,
		primaryPosition: true,
		secondaryPosition: true,
		dominantSide: true,
		shortBio: true,
		accolades: true,
		academicAwards: true,
		extraCurriculars: true,
		previousClubs: true,
		otherActivities: true,
		membershipID: true,
		highlightVideo: true,
		highlightVideos: true,
		fullmatchVideos: true,
		fullmatchDetails: true,
		highlightDetails: true,
		highestLevelPlayed: true,
		currentLevel: true,
		height: true,
		weight: true,
		city: true,
		country: true,
		citizenships: true,
		schedule: true,
		languagesSpoken: true,
		highschoolName: true,
		highschoolGradYear: true,
		highschoolGPA: true,
		schoolCommitment: true,
		ncaaID: true,
		SATScore: true,
		ACTScore: true,
		anticipatedStudy: true,
		gradYear: true,

		socialMedia: true,
		completedProfile: true,
		sports: true,
		recruitingProfileComplete: true,
		videos: true,
		profileVisibility: true,
		physicalPerformance: true,
		selfAssessment: true,
		scoutingAverage: true,
		scoutingReport: true,
		mindsetReport: true,
		teams: true,
		sharingUrlId: true,
	}
};
