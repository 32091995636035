import { CrudApi } from './CrudApi';
import { CoachProfileModel } from '../models/coach/CoachProfileModel';
import { PublicProfileInfo, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { ContactInfo } from '@/../types/interfaces/ContactInfo';

class CoachApi extends CrudApi<CoachProfileModel>{
	constructor(){
		super('coach', (obj) => new CoachProfileModel().load(obj));
	}

	async getBasicProfile(id: string): Promise<PublicProfileInfo<CoachProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-basic`));
	}

	async getProfilePicture(id: string): Promise<PublicProfileInfo<CoachProfileModel>> {
		return await CrudApi.Api(c => c.get(`/${this.resource}/public/${id}/profile-picture`));
	}

	async findByContact(contact: ContactInfo): Promise<CoachProfileModel> {
		var coach = await this.findByEmail(contact.email);
		if( !coach ) { 
			coach = await this.findByName(contact.firstName, contact.lastName);
		}
		return coach;
	}

	async findByEmail(email: string): Promise<CoachProfileModel> {
		// check for valid email address
		if( !email.toLowerCase().match(/^\S+@\S+\.\S+$/) ) return null;

		const query: RepositoryQuery<CoachProfileModel> = { 
			search: email.trim().replace(/[\+]/g, '\\+'),
			fields: ['email']
		};
		const options: QueryOptions = {};
		const athleteFound = await this.queryAll(query, options);
		if( athleteFound.count === 0 ) return null;
		return athleteFound.docs[0];
	}

	async findByName(firstName: string, lastName: string): Promise<CoachProfileModel | null> {
		const query: RepositoryQuery<CoachProfileModel> = { 
			search: lastName.trim(),
			fields: ['lastName']
		};
		const options: QueryOptions = {};
		const athletesFound = await this.queryAll(query, options);
		if( athletesFound.count == 0 ) return null;
		for( const athlete of athletesFound.docs ) {
			if( athlete.firstName === firstName ) return athlete;
		}
	}

}
export const coachApi = new CoachApi();