// PurchaseMixin.ts

import { Component, Mixins } from 'vue-property-decorator';
import { StringsMixin } from './StringsMixin';
import { AthleteProfileModel, BAPurchaseModel } from '@/models'
import { baPurchaseApi } from '@/api/BAPurchaseApi';
import { baEventApi } from '@/api/BAEventsApi';
import { EventParticipantModel } from "@/models/baEvent";

@Component
export class PurchaseMixin extends Mixins(StringsMixin) {
    showPurchaseDialog: boolean = false;
    waitForStripe: boolean = false;
    waitForPurchase: boolean = false;
    purchaseRequest: BAPurchaseModel;

    AthleteLabel(label: string, athleteKeys: Array<string>): string {
        const athleteLabel = label.replace(/\s/g, '').toLowerCase();
        let athleteKey = athleteKeys.find(k => k.toLowerCase() === athleteLabel);
        if( this.IsEmpty(athleteKey) ) {
            // some of the AthleteProfileModel keys do not map directly to expected labels
            if( athleteLabel === "dob" ) athleteKey = "birthDate"; 
            else if( athleteLabel === "birthday" ) athleteKey = "birthDate";
            else if( athleteLabel === "gpa" ) athleteKey = "highschoolGPA";
            else if( athleteLabel === "sat" ) athleteKey = "SATScore";
            else if( athleteLabel === "act" ) athleteKey = "ACTScore";
            else if( athleteLabel === "gradyear" ) athleteKey = "highschoolGradYear";
        } else {
            // some of the AthleteProfileModel keys map to the wrong labels
            if( athleteLabel === "gradyear" ) athleteKey = "highschoolGradYear";
        }
        if( this.IsNotEmpty(athleteKey) ) return athleteKey;
        return label;
    }
    PurchaseAthleteLabels(labels: Array<string>, athleteProfile: AthleteProfileModel): Record<string,any> {
        let athleteLabels: Record<string, any> = {};

        const athleteKeys = Object.keys(athleteProfile);
        for( const label of labels ) {
            const athleteLabel = this.AthleteLabel(label, athleteKeys);
            if( athleteLabel === "birthDate" ) {
                athleteLabels[label] = athleteKeys.includes(athleteLabel)? this.formatDateSlashesYYYYMMDD(athleteProfile[athleteLabel]) : "";
            } else {
                athleteLabels[label] = athleteKeys.includes(athleteLabel)? athleteProfile[athleteLabel] : "";
            }
        }
        return athleteLabels;
    }

    async completePurchaseRequest(purchase: BAPurchaseModel): Promise<BAPurchaseModel> {
        this.waitForPurchase = true;
        const completedPurchase: BAPurchaseModel = await baEventApi.addParticipant(purchase.productId, purchase.priceId, purchase.parentId);
        this.waitForPurchase = false;
        return completedPurchase;
    }
    async savePurchaseRequest(): Promise<BAPurchaseModel> {
        this.waitForStripe = true;
        return await baPurchaseApi.save(this.purchaseRequest);
    }

    async deletePurchase(parentId, eventId, ticketId) {
        const purchase: BAPurchaseModel = await baPurchaseApi.findPurchase(parentId, eventId);
        await baPurchaseApi.delete(purchase);
    }

    onShowPurchaseDialog() {
        this.showPurchaseDialog = true;
    }
    onHidePurchaseDialog() {
        this.showPurchaseDialog = false;
    }
}