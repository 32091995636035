import { EventbriteEventStatus } from '@/../types/enums';
export function eventStatusColor(status: EventbriteEventStatus): string{
	if (status === EventbriteEventStatus.Live) {
		return this.getColor('baColorEventSale');
	} else if (status === EventbriteEventStatus.Completed) {
		return this.getColor('baColorEventCompleted');
	} else if (status === EventbriteEventStatus.Deleted) {
		return this.getColor('baColorAlertRed');
	}
	return this.getColor('baColorEventDraft');
}
