
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import TeamDashboardInfoProvider from '@/components/hoc/TeamDashboardInfoProvider.vue';
// @ts-ignore
import WinnerIndicator from "../../assets/img/winner-indicator.png";
import { GameResultModel } from '@/models/calendar/GameResultModel';

@Component({
	components: {
		TeamDashboardInfoProvider,
	}
})
export default class FinalScoreBox extends Mixins(VuetifyMixin) {
	/** Compact mode for inline view on mobile */
	@Prop({ default: false, type: Boolean }) compact: boolean;
	@Prop({ default: false, type: Boolean }) inline: boolean;
	@Prop({ required: true }) gameResult: GameResultModel | null;
	@Prop({ required: true }) teamId: string;
	WinnerIndicator = WinnerIndicator;

	get GameResultBackgroundColor(): string{
		if(this.GameResultGameOutcome === "W"){
			return this.getColor('baColorTeamWin');
		}else if(this.GameResultGameOutcome === "L"){
			return this.getColor('baColorTeamLoss');
		}else if(this.GameResultGameOutcome === "D" || this.GameResultGameOutcome === "T"){
			return this.getColor('baColorTeamTie');
		}
		return this.getColor('baColorGray3');
	}
	get GameResultGameOutcome(): string | null{
		if(this.gameResult === null) return null;
		return this.gameResult.Outcome;
	}
	get GameResultHomeScore(): number{
		if(this.gameResult === null) return 0;
		return this.gameResult.homeScore;
	}
	get GameResultGameTiming(): string{
		if(this.gameResult === null) return "--";
		return this.gameResult.gameTiming;
	}
	get OpponentScore(): number{
		if(this.gameResult === null) return 0;
		return this.gameResult.opponentScore;
	}
	get Opponent(): string{
		if(this.gameResult === null) return "--";
		return this.gameResult.opponent;
	}

	get GameTime(): string{
		if(this.GameResultGameTiming === 'Full Time') return "FT";
		if(this.GameResultGameTiming === 'Overtime') return "OT";
		if(this.GameResultGameTiming === 'In Progress') return "in progress";
		return "upcoming";
	}

	get ArrowDirection() {
		if (this.gameResult.homeScore > this.gameResult.opponentScore) {
			return {
				weWin: true,
				arrowStyle: {
					"align-self": "center",
				},
				marginRight:'mr-3'
				
			};
		} else if (this.gameResult.opponentScore == this.gameResult.homeScore) {
			return {
				weWin: false,
				weLose: false,
				marginRight: 'mr-16',
			};
		} else {
			return {
				weLose: true,
				arrowStyle: {
					"align-self": "center",
					transform: "scaleX(-1)"
				},
				marginLeft:'ml-3'
			};
		}
	}
}
