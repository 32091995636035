import { getEnumValues } from './get-enum-values';

export enum BodyPart{
    Hand = 'Hand',
    Foot = 'Foot',
    Swing = 'Swing',
    Arm = 'Arm',
    Leg = 'Leg',
    Head = 'Head',
    Stick = 'Stick',
    Unknown = '',
}
export const BodyPartValues = getEnumValues<BodyPart>(BodyPart);