import store from '../store';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';

import { VideoJsPlayer } from 'video.js';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { LoopMode, VideoSource } from '@/../types/enums';
import { MuxVideoDetail, YoutubeVideoDetail } from '@best-athletes/ba-types';
import { videoApi } from '@/api/VideoApi';
import { videoClipApi } from '@/api/VideoClipApi';
import { videoClipStore } from '..';
import { youtubeUrl } from '@/helpers/youtube'

const VideoEditorMutationTypes = {
	SETUP_VID_STORE: 'SETUP_VID_STORE',
	RESET_STORE_STATE: 'RESET_STORE_STATE',

	UPDATE_CURRENT_TRACKED_TIME: 'UPDATE_CURRENT_TRACKED_TIME',

	UPDATE_CURRENT_TITLE: 'UPDATE_CURRENT_TITLE',
	UPDATE_TOTAL_TIME: 'UPDATE_TOTAL_TIME',
	UPDATE_PLAYER_WIDTH: 'UPDATE_PLAYER_WIDTH',
	UPDATE_CALCULATED_WIDTH: 'UPDATE_CALCULATED_WIDTH',
	UPDATE_CALCULATED_TOTAL_WIDTH: 'UPDATE_CALCULATED_TOTAL_WIDTH',
	UPDATE_CALCULATED_DURATION: 'UPDATE_CALCULATED_DURATION',
	UPDATE_PLAYER_LEFT_OFFSET: 'UPDATE_PLAYER_LEFT_OFFSET',

	UPDATE_IS_MUTED: 'UPDATE_IS_MUTED',
	UPDATE_IS_PLAYING: 'UPDATE_IS_PLAYING',
	UPDATE_IS_AT_START_OR_END: 'UPDATE_IS_AT_START_OR_END',
	DRAG_START: 'DRAG_START',
	DRAG_MOVE: 'DRAG_MOVE',
	DRAG_STOP_ON_UP: 'DRAG_STOP_ON_UP',
	DRAG_STOP_ON_LEAVE: 'DRAG_STOP_ON_LEAVE',
	DRAG_STOP: 'DRAG_STOP',

	RESET_SCRUB_STATE: 'RESET_SCRUB_STATE',
	RESET_LEFT_HANDLE: 'RESET_LEFT_HANDLE',
	RESET_RIGHT_HANDLE: 'RESET_RIGHT_HANDLE',
	RESET_TIMELINE_CALCS: 'RESET_TIMELINE_CALCS',
	MANUALLY_SET_SCRUB_SPLIT: 'MANUALLY_SET_SCRUB_SPLIT',
	SET_SCRUBBER_FOR_VIEW_ONLY_CLIP: 'SET_SCRUBBER_FOR_VIEW_ONLY_CLIP',

	UPDATE_VOLUME: 'UPDATE_VOLUME',
	UPDATE_PLAYBACK_SPEED: 'UPDATE_PLAYBACK_SPEED',

	LOAD_SOURCE_VIDEO: 'LOAD_SOURCE_VIDEO',
	LOAD_SOURCE_VIDEO_FAILED: 'LOAD_SOURCE_VIDEO_FAILED',

	CHECK_FOR_SOURCE_VIDEO: 'CHECK_FOR_SOURCE_VIDEO',

	SET_SOURCE_VIDEO: 'SET_SOURCE_VIDEO',
	SET_SOURCE_VIDEO_COMPLETED: 'SET_SOURCE_VIDEO_COMPLETED',
	REMOVE_SOURCE_VIDEO: 'REMOVE_SOURCE_VIDEO',
	SET_VIDEO_JS_PLAYER: 'SET_VIDEO_JS_PLAYER',
	REMOVE_VIDEO_JS_PLAYER: 'REMOVE_VIDEO_JS_PLAYER',
	SET_DIV_PLAYER_CONTAINER: 'SET_DIV_PLAYER_CONTAINER',
	SET_DIV_PROGRESS_METER_FULL: 'SET_DIV_PROGRESS_METER_FULL',

	SET_PLAYER_START: '',
	SET_PLAYER_STOP: '',

	MTC_CREATE_NEW_MTC: 'MTC_CREATE_NEW_MTC',
	MTC_CREATE_SUCCESS: 'MTC_CREATE_SUCCESS',
	MTC_CREATE_FAILURE: 'MTC_CREATE_FAILURE',

	UPDATE_PLAYER_CAN_PLAY: 'UPDATE_PLAYER_CAN_PLAY',
	JUMP_CURRENT_TIME: 'JUMP_CURRENT_TIME',
	UPDATE_CURRENT_PLAYER_TIME: 'UPDATE_CURRENT_PLAYER_TIME',
	UPDATE_CURRENT_PLAYER_TIME_SELF: 'UPDATE_CURRENT_PLAYER_TIME_SELF',
	SEEK_TO_POSITION_IN_PIXELS: 'SEEK_TO_POSITION_IN_PIXELS',
	CURRENT_PLAYER_PLAY: 'CURRENT_PLAYER_PLAY',
	CURRENT_PLAYER_PAUSE: 'CURRENT_PLAYER_PAUSE',
	CURRENT_PLAYER_STOP: 'CURRENT_PLAYER_STOP',
	CURRENT_PLAYER_ENDED: 'CURRENT_PLAYER_ENDED',

	UPDATE_CLIP_SELECTED: 'UPDATE_CLIP_SELECTED',
	UPDATE_CLIP_VIEW_ONLY_STATUS: 'UPDATE_CLIP_VIEW_ONLY_STATUS',

	RESET_CLIPS_FOR_SV: 'RESET_CLIPS_FOR_SV',
	UPDATE_CLIPS_FOR_SV: 'UPDATE_CLIPS_FOR_SV',
	// CLIPS_FOR_SV_SETUP_COMPLETED: 'CLIPS_FOR_SV_SETUP_COMPLETED',
	CLIPS_FOR_SV_SETUP_SUCCESS: 'CLIPS_FOR_SV_SETUP_SUCCESS',
	CLIPS_FOR_SV_SETUP_FAILURE: 'CLIPS_FOR_SV_SETUP_FAILURE',

	DELETE_CLIP: 'DELETE_CLIP',

	// Loop Mode
	SET_LOOP_MODE: 'SET_LOOP_MODE',
}

type storeType = 1 | 2;

@Module({
	namespaced: true,
	dynamic: true,
	name: 'VideoStore',
	store: store
})
export default class VideoModule extends VuexModule {

	public thePlayer: VideoJsPlayer;


	playerCurrentTime = 0;
	totalTime = 0;
	scrubState: 'whole' | 'split' | 'splitLeft' | 'splitRight' = 'whole';
	// The split scrubber works like this:
	// 1) When in 'whole' mode the whole scrubber moves as one and continues
	//    that way as long as all the dragging is done with the main, middle scrubber icon
	// 2) Click and drag on one of the outside drag handles and the scrubber transitions
	//    to "splitLeft" or 'splitRight' mode where the left of right outside drag handle
	//    and position are still attached to the middle scrubber icon. Further clicks and drags
	//    on the middle icon will carry the attached outside handle with with it.
	// 3) Click and drag on the attached outside drag handle in "splitLeft" or 'splitRight' mode 
	//    will transition the scubber to 'split' mode where all three drag elements operate
	//    independantly of one another.  
	//     - the left drag handle (green) sets the starting point for a clip
	//     - the right drag handle (red) sets the ending poing for a clip
	//     - the middle scrubber set the time / location in the source video
	// 4)  Double-clicking the main, middle scrubber will transition back to 'whole" mode and remove
	//     the interval that was being shown.


	playerLeftOffset = 10;
	playerWidth = 320;
	calculatedWidth = 0; // is current position of video in px
	// calculatedDuration = 10; // is redundant with total time
	startMarkerPosition = 0; // is current position of duration marker start
	stopMarkerPosition = 0; // is current position of duration marker end
	rlMarkersOffset = 12;
	scrubberHalfSize = 10;  // Half the scrubber width to make sure the drags are in the middle.

	startMarkerTimeInSeconds = 0.0;
	stopMarkerTimeInSeconds = 0.0;

	isMuted = false;
	isPlaying = false;
	isAtStartOrEnd = false;
	isDragging = false;
	hasDragged = false;
	isDraggingLeftMarker = false;
	isDraggingRightMarker = false;

	volume = 0.1;
	playbackSpeed = 1.0;

	public sourceVideoURL = 'https://stream.mux.com/KvtwXKpfHFtNqW8FgLco8abB4GuYuGAp.m3u8';

	public loadedSourceVideo:  VideoModel | null = null;

	selectedClipFromSourceVideoId: string = '';
	clipViewOnlyMode: boolean = false;

	savingMTCToBackend: boolean = false;

	loadingAllMTCs: boolean = false;
	markupTagClipsInitialized: boolean = false;
	markupTagClipsForSV:  VideoClipModel[] = []

	creatingNewMTC: boolean = false;

	defaultClipDuration: number = 4.0;
	defualtClipBackupAmount: number = 1.0;

	// Timeline division marker settings.
	smallSplitSizeMin = 10;
	textSplitSizeMin = 79;
	textTickSkipFactor = 5;
	pixelsPerSecond = 0.5;
	secondsPerPixel = 0.5;
	smallToLargeDivisions = 4;
	scaleDensityArray: number[] = [0, 1, 2, 4, 10, 20, 30, 60, 120, 240, 600, 1200, 1800, 3600, 7200, 14400, 28800, 43200, 86400];
	scaleDensityCurrent = 2;
	numMainSegments = 10;
	mainSegmentsWidthInPixels = 250;
	mainSegmentsWidthAsPercentage = 0.89;
	leftoverSeconds = 0.5;
	leftoverSecondsWidthInPixels = 42;
	leftoverSecondsWidthAsPercentage = 0.05;

	// Current video & clip being played
	currentVideo: VideoModel = null;
	currentClip: VideoClipModel = null;


	// Loop Mode
	loopMode: LoopMode = LoopMode.LOOP_PLAYLIST;
	@Action({ rawError: true }) async setLoopMode(newLoopMode: LoopMode) {
		this.context.commit(VideoEditorMutationTypes.SET_LOOP_MODE, newLoopMode);
	}
	@Mutation [VideoEditorMutationTypes.SET_LOOP_MODE](newLoopMode: LoopMode) {
		this.loopMode = newLoopMode
	}

	clipDetailFormVisible = false;

	@MutationAction
	async updateClipDetailFormVisible(visibility: boolean) {
		return { clipDetailFormVisible: visibility };
	}

	/** 
	 * Getters                      
	 */
	get veSourceVideoURL(): string {
		return this.sourceVideoURL
	}

	get createVideoURL(){
		return (video: VideoModel) => {
			if (video.source == VideoSource.Mux) {
				// There will be only be 1 playback ID
				// TODO: Signed URLs
				const playbackID = (video.detail as MuxVideoDetail).playback_ids[0].id;
				return`https://stream.mux.com/${playbackID}.m3u8`
			}
			else if (video.source == VideoSource.Youtube) {
				return youtubeUrl((video.detail as YoutubeVideoDetail).embedded_id);
			}
		}
	}



	/** 
	 * Actions & Mutation for setting up the Video Editor                   
	 */
	@Action({ rawError: true }) async setupStore() {
		// console.log('In the vid store call to set up the store ')
		this.context.commit(VideoEditorMutationTypes.SETUP_VID_STORE)
		videoClipStore.updateClipCategories();
	}

	@Mutation [VideoEditorMutationTypes.SETUP_VID_STORE]() {
		// console.log("!!!! SETUP VIDEO EDIT STORE IS BEING CALLED");
		this.sourceVideoURL = ''
		this.calculatedWidth = 0
		this.startMarkerPosition = 0;
		this.startMarkerTimeInSeconds = 0.0;
		this.stopMarkerPosition = 0;
		this.stopMarkerTimeInSeconds = 0.0;
		this.clipViewOnlyMode = false;
	}

	@Action({ rawError: true }) async teardownVidStore() {
		this.context.commit(VideoEditorMutationTypes.RESET_STORE_STATE);
	}

	@Mutation [VideoEditorMutationTypes.RESET_STORE_STATE]() {
		this.sourceVideoURL = ''
		this.calculatedWidth = 0
		this.startMarkerPosition = 0;
		this.stopMarkerPosition = 0;
		// this.loadedSourceVideo = new SourceVideoMuxModel();
		this.loadedSourceVideo = null;
		this.clipViewOnlyMode = false;

		this.markupTagClipsForSV = []
	}

	/** 
	 * Actions & Mutation for setting player from videojs                   
	 */
	@Action({ rawError: true }) async setVideoJSPlayer(player: VideoJsPlayer) {
		// console.log('In the vid store call to set player and the player is: ', thePlayer)
		this.context.commit(VideoEditorMutationTypes.SET_VIDEO_JS_PLAYER, player)

		if (this.loadedSourceVideo) {
			this.context.commit(VideoEditorMutationTypes.SET_SOURCE_VIDEO, this.loadedSourceVideo)
		}
	}

	@Mutation [VideoEditorMutationTypes.SET_VIDEO_JS_PLAYER](player: VideoJsPlayer) {
		this.thePlayer = player;
		this.totalTime = this.thePlayer.duration()
	}

	// Remove thePlayer when the video js player element is removed from the DOM.
	@Action({ rawError: true }) async videoJSPlayerRemove() {
		// console.log('Remove the VidedJS player')
		this.context.commit(VideoEditorMutationTypes.REMOVE_VIDEO_JS_PLAYER)
	}

	@Mutation [VideoEditorMutationTypes.REMOVE_VIDEO_JS_PLAYER]() {
		this.thePlayer = null;
	}


	

	/** 
	 * Load all the Clips for this SV from DB                                
	 */
	@Action({
		rawError: true,
		commit: VideoEditorMutationTypes.CLIPS_FOR_SV_SETUP_SUCCESS
	}) async loadAllClipsForSourceVideo(theSourceVideoId: string) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIPS_FOR_SV);
		try {
			// return await markupTagClipMuxModelApi.getAll();
			// return await videoApi.getAllClipsForSVWithId(theSourceVideoId);

		} catch (e) {
			console.error("Failed to Load The Clips for the Source Videos", e);
			this.context.commit(VideoEditorMutationTypes.CLIPS_FOR_SV_SETUP_FAILURE, e);
		}
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_CLIPS_FOR_SV]() {
		this.loadingAllMTCs = true;
	}
	@Mutation [VideoEditorMutationTypes.CLIPS_FOR_SV_SETUP_SUCCESS](mtcsArray: VideoClipModel[]) {
		// console.log('In the vid editor getting all Clips and the returned array is: ', mtcsArray);
		this.loadingAllMTCs = false;
		this.markupTagClipsInitialized = true;
		this.markupTagClipsForSV = mtcsArray; // probably want to push these on one by one.
	}
	@Mutation [VideoEditorMutationTypes.CLIPS_FOR_SV_SETUP_FAILURE](error: any) {
		this.loadingAllMTCs = false;
	}
	@Mutation [VideoEditorMutationTypes.RESET_CLIPS_FOR_SV]() {
		while (this.markupTagClipsForSV.length > 0) {
			// console.log('Popping a value from the markupTagClipsForSV array')
			this.markupTagClipsForSV.pop()
		}  
	}


	/**
		* Add new clips for this SV from DB                                
		*/
	@Action({
		rawError: true,
		commit: VideoEditorMutationTypes.MTC_CREATE_SUCCESS
	}) async addMarkupTagClipforCurrentSV(theMarkupTagClip: VideoClipModel) {
		
			this.context.commit(VideoEditorMutationTypes.MTC_CREATE_NEW_MTC);

			theMarkupTagClip.video = this.loadedSourceVideo.id;

			try {
				return await videoClipApi.insertWithOwnership(theMarkupTagClip);
				
			} catch (e) {
				console.error("Failed to insert The Clip", e);
				this.context.commit(VideoEditorMutationTypes.MTC_CREATE_FAILURE, e);
			}
		
	}

	@Action({
		rawError: true,
		commit: VideoEditorMutationTypes.MTC_CREATE_SUCCESS
	}) async addMarkupTagClip(theMarkupTagClip: VideoClipModel) {
		this.context.commit(VideoEditorMutationTypes.MTC_CREATE_NEW_MTC);

		try {
			return await videoClipApi.insertWithOwnership(theMarkupTagClip);

		} catch (e) {
			console.error("Failed to insert The Clip", e);
			this.context.commit(VideoEditorMutationTypes.MTC_CREATE_FAILURE, e);
		}
	}

	@Mutation [VideoEditorMutationTypes.MTC_CREATE_NEW_MTC]() {
		this.creatingNewMTC = true;
	}
	@Mutation [VideoEditorMutationTypes.MTC_CREATE_SUCCESS](addedMTC: VideoClipModel) {
		this.creatingNewMTC = false;
		this.markupTagClipsForSV.push(addedMTC); // probably want to push these on one by one.
	}
	@Mutation [VideoEditorMutationTypes.MTC_CREATE_FAILURE](error: any) {
		this.creatingNewMTC = false;
	}



	/** 
	 * Delete a clip this SV from DB                                
	 */
	@Action({
		rawError: true,
	}) async deleteAClip(clip: VideoClipModel) {
		await videoClipApi.delete(clip);
		this.context.commit(VideoEditorMutationTypes.DELETE_CLIP, clip);
		
	}

	@Mutation [VideoEditorMutationTypes.DELETE_CLIP](theMTC: VideoClipModel) {
		for (let index = 0; index < this.markupTagClipsForSV.length; index++) {
			if (this.markupTagClipsForSV[index].id === theMTC.id) {
				this.markupTagClipsForSV.splice(index, 1); // probably want to push these on one by one.
			}
		}
	 
	}




	/** 
	 * Actions & Mutation for selecting current Source Video                 
	 */
	@Action({ 
		rawError: true, 
	}) async setSourceVideo(theNewSV: VideoModel) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_VIEW_ONLY_STATUS, false);
		this.context.commit(VideoEditorMutationTypes.RESET_SCRUB_STATE);
		this.context.commit(VideoEditorMutationTypes.SET_SOURCE_VIDEO, theNewSV)

		// this.context.commit(VideoEditorMutationTypes.RESET_CLIPS_FOR_SV);

		// await this.loadAllClipsForSourceVideo(theNewSV.id)
		// Reset the MarkupTagClips array for this SV.
		return {"message": "Source Video set - Clips should have loaded"}
	}

	@Mutation [VideoEditorMutationTypes.SET_SOURCE_VIDEO](video: VideoModel) {
		// console.log("STARTING the SET_SOURCE_VIDEO mutation and the passed in value is: ", theSV);
		this.loadedSourceVideo = video;

		if (video.source == VideoSource.Mux) {
			// There will be only be 1 playback ID
			// TODO: Signed URLs
			const playbackID = (video.detail as MuxVideoDetail).playback_ids[0].id;
			this.sourceVideoURL =`https://stream.mux.com/${playbackID}.m3u8`
		}
		else if (video.source == VideoSource.Youtube) {
			this.sourceVideoURL = youtubeUrl((video.detail as YoutubeVideoDetail).embedded_id);
		}
		
		this.calculatedWidth = 0;
		this.startMarkerPosition = 0;
		this.startMarkerTimeInSeconds = 0.0;
		this.stopMarkerPosition = 0;
		this.stopMarkerTimeInSeconds = 0.0;
		this.isPlaying = false;
		this.playerCurrentTime = 0;

		try {
			this.thePlayer.reset();

			// Set the source based on the source type
			if (video.source == VideoSource.Mux) {	
				this.thePlayer.src({src: this.sourceVideoURL});
			}
			else if (video.source == VideoSource.Youtube) {
				this.thePlayer.src({src: this.sourceVideoURL, type: 'video/youtube'});
			}
			this.volume = this.thePlayer.volume();
		} catch (error) {
			this.volume = 0.1;
		}

		// console.log("Finishing up the SET_SOURCE_VIDEO ... what is the current one: ", this.loadedSourceVideo);
	}

	@Mutation [VideoEditorMutationTypes.CHECK_FOR_SOURCE_VIDEO]() {
		// console.log("STARTING the CHECK_FO_SOURCE_VIDEO mutation and the passed in value is: ", theSV);

		// Set the player to the current source video.
		if (this.loadedSourceVideo) {
			this.thePlayer.reset();
			this.thePlayer.src(this.sourceVideoURL);
		}
	}

	@Action({
		rawError: true,
	}) async emptyTheEditor() {
		this.context.commit(VideoEditorMutationTypes.REMOVE_SOURCE_VIDEO)

		this.context.commit(VideoEditorMutationTypes.RESET_CLIPS_FOR_SV);
	}

	@Mutation [VideoEditorMutationTypes.REMOVE_SOURCE_VIDEO]() {
		// console.log("STARTING the REMOVE_SOURCE_VIDEO mutation");
		this.loadedSourceVideo = null;

		// Player is watching this and should respond.;
		this.sourceVideoURL = ''
		this.calculatedWidth = 0;
		this.startMarkerPosition = 0;
		this.startMarkerTimeInSeconds = 0.0;
		this.stopMarkerPosition = 0;
		this.stopMarkerTimeInSeconds = 0.0;
		this.isPlaying = false;
		this.playerCurrentTime = 0;
		if (this.thePlayer) {
			try {
				this.volume = this.thePlayer.volume();
				this.thePlayer.reset();
			} catch (error) {
				this.volume = 0.1;
			}
		}
	}


	/** 
	 * Actions & Mutation for selecting current Source Video                 
	 */
	@Action({
		rawError: true,
	}) async loadSourceVideoById(sourceVideoId: string) {

		try {
			const loadedSourceVideo = await videoApi.findById(sourceVideoId);
			this.context.commit(VideoEditorMutationTypes.SET_SOURCE_VIDEO, loadedSourceVideo)
			this.context.commit(VideoEditorMutationTypes.RESET_CLIPS_FOR_SV);
			await this.loadAllClipsForSourceVideo(sourceVideoId)
			// Reset the MarkupTagClips array for this SV.
			return { 
				"message": "Source Video set - Clips should have loaded",
				"sourceVideoId": sourceVideoId
			}
		} catch (e) {
			console.error("Failed to Load The Source Videos", e);
			this.context.commit(VideoEditorMutationTypes.RESET_STORE_STATE);
		}
	}

	@Mutation [VideoEditorMutationTypes.LOAD_SOURCE_VIDEO_FAILED](video: VideoModel) {
		// console.log("STARTING the SET_SOURCE_VIDEO mutation and the passed in value is: ", theSV);
		this.loadedSourceVideo = video;

		// Player is watching this and should respond.
		if (video.source == VideoSource.Mux) {
			// There will be only be 1 playback ID
			// TODO: Signed URLs
			const playbackID = (video.detail as MuxVideoDetail).playback_ids[0].id;
			this.sourceVideoURL =`https://stream.mux.com/${playbackID}.m3u8`
		}
		else if (video.source == VideoSource.Youtube) {
			this.sourceVideoURL = youtubeUrl((video.detail as YoutubeVideoDetail).embedded_id);
		}
		this.calculatedWidth = 0;
		this.startMarkerPosition = 0;
		this.startMarkerTimeInSeconds = 0.0;
		this.stopMarkerPosition = 0;
		this.stopMarkerTimeInSeconds = 0.0;
		this.isPlaying = false;
		this.playerCurrentTime = 0;

		this.thePlayer.reset();
		this.thePlayer.src(this.sourceVideoURL);

		try {
			this.volume = this.thePlayer.volume();
		} catch (error) {
			this.volume = 0.1;
		}

		// console.log("Finishing up the SET_SOURCE_VIDEO ... what is the current one: ", this.loadedSourceVideo);
	}




	/** 
	 * Actions & Mutation Clip Tag Image Clicked                               
	 */
	@Action({ rawError: true }) async gotClickOnATagClipImage(clip: VideoClipModel) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_SELECTED, clip.id);
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_VIEW_ONLY_STATUS, false);
		this.context.commit(VideoEditorMutationTypes.RESET_SCRUB_STATE);

		this.seekToTimeInSeconds(clip.start);
	}

	@Action({ rawError: true }) async setClipForViewOnlyMode(clip: VideoClipModel, play: boolean = false) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_SELECTED, clip.id)
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_VIEW_ONLY_STATUS, true);
		this.context.commit(VideoEditorMutationTypes.SET_SCRUBBER_FOR_VIEW_ONLY_CLIP, clip);
		
		if (play) 
			this.seekToTimeInSeconds(clip.start);
	}

	@Action({ rawError: true }) async updateClipSelectedToClipId(tagClipId: string) {
		const clipIndex = this.markupTagClipsForSV.findIndex(mtc => mtc.id === tagClipId);
		if ( clipIndex >= 0) {
			this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_SELECTED, tagClipId)
			this.seekToTimeInSeconds(this.markupTagClipsForSV[clipIndex].start)
		} else {
			console.log("The clip selected is not in the current list of clips for the loaded source video");
		}
	}

	@Action({ rawError: true }) async unselectAnyMarkupTagClip() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CLIP_SELECTED, '')
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_CLIP_SELECTED](theClipId: string) {
		this.selectedClipFromSourceVideoId = theClipId
	}
	
	@Mutation [VideoEditorMutationTypes.UPDATE_CLIP_VIEW_ONLY_STATUS](setToViewOnly: boolean) {
		if (setToViewOnly) {
			this.clipViewOnlyMode = true;
		} else {
			this.clipViewOnlyMode = false;
		}
	}

	@Mutation [VideoEditorMutationTypes.SET_SCRUBBER_FOR_VIEW_ONLY_CLIP](clip: VideoClipModel) {
		this.scrubState = "split"
		this.playerCurrentTime = clip.start
		this.thePlayer.currentTime(clip.start)
		this.calculatedWidth = (clip.start / this.totalTime) * this.playerWidth  
		this.startMarkerPosition = this.calculatedWidth;
		this.startMarkerTimeInSeconds = clip.start;
		this.stopMarkerPosition = ((clip.start + clip.duration) / this.totalTime) * this.playerWidth;
		this.stopMarkerTimeInSeconds = (clip.start + clip.duration);
	}

	




	/** 
	 * Actions & Mutation for Player Setup / Settings                       
	 */
	@Action({ rawError: true }) async canPlayHandler() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_PLAYER_CAN_PLAY);
		// this.context.commit(VideoEditorMutationTypes.RESET_TIMELINE_CALCS);

	}

	@Mutation [VideoEditorMutationTypes.UPDATE_PLAYER_CAN_PLAY]() {
		// console.log('In the vid store update player mutation mutation and the duration is: ', this.thePlayer.duration())
		this.thePlayer.volume(this.volume);
		this.totalTime = this.thePlayer.duration();
	}

	@Mutation [VideoEditorMutationTypes.RESET_TIMELINE_CALCS]() {
		// console.log('In the vid store update player mutation mutation and the duration is: ', this.thePlayer.duration())

		// console.log('---------------------------- CALCULATING TIMELINE DIVISIONS ------------------------');
		// Calcualte the timeline layout (this is repeated when the size changes so should be a subroutine)
		this.pixelsPerSecond = this.playerWidth / this.totalTime;
		this.secondsPerPixel = this.totalTime / this.playerWidth;

		const secsPerLargeSegment = this.secondsPerPixel * this.smallSplitSizeMin * this.smallToLargeDivisions;

		// console.log('The player width = ', this.playerWidth);
		// console.log('The total time = ', this.totalTime);
		// console.log('The pixels per second is = ', this.pixelsPerSecond);
		// console.log('The seconds per pixel is = ', this.secondsPerPixel);
		// console.log('The seconds per large segment is = ', secsPerLargeSegment);


		const tempDensity = 0;
		let notFound = true;
		let aIdx = 0;
		while (notFound && aIdx < this.scaleDensityArray.length) {
			if (this.scaleDensityArray[aIdx] < secsPerLargeSegment && this.scaleDensityArray[aIdx + 1] >= secsPerLargeSegment) {
				this.scaleDensityCurrent = this.scaleDensityArray[aIdx + 1];
				notFound = false;
			}
			aIdx++;
		}
		// console.log('The scale density is = ' + this.scaleDensityCurrent + 'seconds per large tick mark');

		this.numMainSegments = Math.floor(this.totalTime / this.scaleDensityCurrent);
		this.mainSegmentsWidthInPixels = Math.floor((this.scaleDensityCurrent * this.numMainSegments) * this.pixelsPerSecond);
		this.mainSegmentsWidthAsPercentage = this.mainSegmentsWidthInPixels / this.playerWidth;
		this.leftoverSeconds = this.totalTime - (this.scaleDensityCurrent * this.numMainSegments);
		this.leftoverSecondsWidthInPixels = Math.floor(this.leftoverSeconds * this.pixelsPerSecond);
		this.leftoverSecondsWidthAsPercentage = this.leftoverSecondsWidthInPixels / this.playerWidth;
		this.textTickSkipFactor = Math.ceil(this.textSplitSizeMin / (this.scaleDensityCurrent * this.pixelsPerSecond));

		// console.log('The number of Main segments is = ' + this.numMainSegments);
		// console.log('The width of the Main segments in pixels is = ' + this.mainSegmentsWidthInPixels);
		// console.log('The width of the Main segments as percentage is = ' + this.mainSegmentsWidthAsPercentage);
		// console.log('The leftover number of seconds = ' + this.leftoverSeconds);
		// console.log('The width of the Leftover Seconds in pixels is = ' + this.leftoverSecondsWidthInPixels);
		// console.log('The width of the Leftover Seconds as percentage is = ' + this.leftoverSecondsWidthAsPercentage);

		// console.log('------------------------------------------------------------------------------------');

	}

	

	
	@Action({ rawError: true }) async closingPlayerWindow() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, false);
		this.context.commit(VideoEditorMutationTypes.CURRENT_PLAYER_PAUSE);
		this.context.commit(VideoEditorMutationTypes.RESET_SCRUB_STATE);
	}




	/** 
		* Actions & Mutation when new source loads                      
		*/
	@Action({ rawError: true }) async loadedMetaDataHandler() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_PLAYER_CAN_PLAY);

		this.context.commit(VideoEditorMutationTypes.RESET_TIMELINE_CALCS);

		// Set clip info if Clip is loaded.
		if (this.selectedClipFromSourceVideoId !== '') {
			if (this.clipViewOnlyMode) {
				const clipIndex = this.markupTagClipsForSV.findIndex(mtc => mtc.id === this.selectedClipFromSourceVideoId);
				this.context.commit(VideoEditorMutationTypes.SET_SCRUBBER_FOR_VIEW_ONLY_CLIP, this.markupTagClipsForSV[clipIndex]);
			}
		}

	}







	/** 
	 * Actions & Mutation time moving events                                
	 */
	@Action({ rawError: true }) async seekToTimeInSeconds(theTime: number) {
		// console.log('In the vid store call to seekToTime')
		this.context.commit(VideoEditorMutationTypes.UPDATE_CURRENT_TRACKED_TIME, theTime)
		this.context.commit(VideoEditorMutationTypes.UPDATE_CURRENT_PLAYER_TIME, theTime)
	}

	@Action({ rawError: true }) async seekToPixelWidthInProgressBar(theXOffset: number) {
		// console.log('In the vid store call to seekToPixelWidthInProgressBar')
		this.context.commit(VideoEditorMutationTypes.SEEK_TO_POSITION_IN_PIXELS, theXOffset)
	}

	@Action({ rawError: true }) async jumpTimeline(jumpAmount: number) {
		this.context.commit(VideoEditorMutationTypes.JUMP_CURRENT_TIME, jumpAmount)
		this.context.commit(VideoEditorMutationTypes.UPDATE_CURRENT_PLAYER_TIME, this.playerCurrentTime)
	}

	@Action({ rawError: true }) async updateTrackedTime(theTime: number) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CURRENT_TRACKED_TIME, theTime)
	}

	@Action({ rawError: true }) async updateTimeSelf() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_CURRENT_PLAYER_TIME_SELF)
	}

	@Mutation [VideoEditorMutationTypes.JUMP_CURRENT_TIME](theAmount: number) {
		this.playerCurrentTime = this.playerCurrentTime + theAmount
		if (this.playerCurrentTime < 0) {
			this.playerCurrentTime = 0
		}
		if (this.playerCurrentTime >= this.totalTime) {
			this.playerCurrentTime = this.totalTime - 0.2
		}
		// this.thePlayer.currentTime(this.playerCurrentTime)
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_CURRENT_TRACKED_TIME](theTimeParam: number) {
		this.playerCurrentTime = theTimeParam
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_CURRENT_PLAYER_TIME](theTimeParam: number) {
		this.thePlayer.currentTime(theTimeParam)
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_CURRENT_PLAYER_TIME_SELF]() {
		this.playerCurrentTime = this.thePlayer.currentTime()
		// calculate the width.
		let temptime: number
		if (this.playerCurrentTime > this.totalTime) {
			temptime = this.totalTime
		} else {
			temptime = this.playerCurrentTime
		}
		if (!this.isDragging && !this.isDraggingLeftMarker && !this.isDraggingRightMarker) {
			this.calculatedWidth = (temptime / this.totalTime) * this.playerWidth // same as player width
			// check drag handles
			if (this.scrubState === 'whole' || this.scrubState === 'splitLeft') {
				this.startMarkerPosition = this.calculatedWidth;
				this.startMarkerTimeInSeconds = this.playerCurrentTime;
			}
			if (this.scrubState === 'whole' || this.scrubState === 'splitRight') {
				this.stopMarkerPosition = this.calculatedWidth;
				this.startMarkerTimeInSeconds = this.playerCurrentTime;

			}

			// Check for hitting a seclected tag or clip.
			let tempIndex = 0
			let notFound = true
			while (tempIndex < this.markupTagClipsForSV.length && notFound) {
				const tempClip = this.markupTagClipsForSV[tempIndex]
				if (tempClip.start - 0.2 <= temptime && (tempClip.start + tempClip.duration) > temptime) {
					this.selectedClipFromSourceVideoId = tempClip.id
					notFound = false
				}
				tempIndex++
			}
			if (notFound) {
				this.selectedClipFromSourceVideoId = ''
			}
		}
	}

	@Mutation [VideoEditorMutationTypes.SEEK_TO_POSITION_IN_PIXELS](xOffsetParam: number) {
		if (xOffsetParam < 0) {
			xOffsetParam = 0;
		} else if (xOffsetParam > this.playerWidth) {
			xOffsetParam = this.playerWidth;
		}
		const widthAsPct = xOffsetParam / this.playerWidth;
		this.playerCurrentTime = this.totalTime * widthAsPct;
		this.thePlayer.currentTime(this.playerCurrentTime);
		this.calculatedWidth = xOffsetParam;
		if (this.scrubState === 'whole' || this.scrubState === 'splitLeft') {
			this.startMarkerPosition = this.calculatedWidth;
			this.startMarkerTimeInSeconds = this.playerCurrentTime;
		}
		if (this.scrubState === 'whole' || this.scrubState === 'splitRight') {
			this.stopMarkerPosition = this.calculatedWidth;
			this.startMarkerTimeInSeconds = this.playerCurrentTime;
		}
	}











	/** 
	 * Actions & Mutation for Playing / Pausing / Stopping                  
	 */
	@Action({ rawError: true }) async togglePlaying() {
		if (this.isPlaying) {
			this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, false)
		} else {
			this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, true)
		}
	}

	@Action({ rawError: true }) async startPlaying() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, true)
		this.context.commit(VideoEditorMutationTypes.CURRENT_PLAYER_PLAY)
	}

	@Action({ rawError: true }) async stopPlaying() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, false)
		this.context.commit(VideoEditorMutationTypes.CURRENT_PLAYER_STOP)
	}

	@Action({ rawError: true }) async pausePlaying() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, false)
		this.context.commit(VideoEditorMutationTypes.CURRENT_PLAYER_PAUSE)
	}

	@Action({ rawError: true }) async playerEndedEvent() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_PLAYING, false)
		this.context.commit(VideoEditorMutationTypes.CURRENT_PLAYER_ENDED)
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_IS_PLAYING](theValue: boolean) {
		this.isPlaying = theValue
	}

	@Mutation [VideoEditorMutationTypes.CURRENT_PLAYER_PLAY]() {
		this.thePlayer.play()
	}

	@Mutation [VideoEditorMutationTypes.CURRENT_PLAYER_PAUSE]() {
		// console.log('In the vid store pause mutation and the player is: ', this.thePlayer)
		this.thePlayer.pause()
	}

	@Mutation [VideoEditorMutationTypes.CURRENT_PLAYER_STOP]() {
		this.thePlayer.pause()
		this.thePlayer.currentTime(0)
		this.isAtStartOrEnd = true
	}

	@Mutation [VideoEditorMutationTypes.CURRENT_PLAYER_ENDED]() {
		this.playerCurrentTime = this.thePlayer.currentTime()
		this.isAtStartOrEnd = true
		// What about looping ?
	}









	/** 
	 * Actions & Mutation for isMuted and voluem                             
	 */
	@Action({ rawError: true }) async toggleMute() {
		if (this.isMuted) {
			// console.log('Unmuting ... but the volume is: ', this.volume)
			if (this.volume < 0.1) {
				this.context.commit(VideoEditorMutationTypes.UPDATE_VOLUME, 0.1)
			}
			this.context.commit(VideoEditorMutationTypes.UPDATE_IS_MUTED, false)
		} else {
			this.context.commit(VideoEditorMutationTypes.UPDATE_IS_MUTED, true)
		}
	}

	@Action({ rawError: true }) async mute() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_MUTED, true)
	}

	@Action({ rawError: true }) async unmute() {
		this.context.commit(VideoEditorMutationTypes.UPDATE_IS_MUTED, false)
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_IS_MUTED](theValue: boolean) {
		this.isMuted = theValue
		this.thePlayer.muted(this.isMuted)
	}

	@Action({ rawError: true }) async setTheVolume(theVolume: number) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_VOLUME, theVolume)
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_VOLUME](theValue: number) {
		this.volume = theValue
		this.thePlayer.volume(this.volume)
	}









	/** 
	 * Actions & Mutations for Player Width and offset                       
	 */
	@Action({ rawError: true }) async setPlayerWidth(theWidth: number) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_PLAYER_WIDTH, theWidth)
		// when this changes it will affect the calcualted width and the calculated total width.
		this.context.commit(VideoEditorMutationTypes.RESET_TIMELINE_CALCS);
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_PLAYER_WIDTH](theNewWidth: number) {
		// console.log('Update Player width has been called !!! NewWidth is: ', theNewWidth);
		// console.log("update plater width and the TotalTime is: ", this.totalTime);
		this.playerWidth = theNewWidth
		if (this.totalTime && this.totalTime > 0) {
			this.calculatedWidth = (this.playerCurrentTime / this.totalTime) * this.playerWidth;
			this.startMarkerPosition = (this.startMarkerTimeInSeconds / this.totalTime) * this.playerWidth;
			this.stopMarkerPosition = (this.stopMarkerTimeInSeconds / this.totalTime) * this.playerWidth;
		}
	}

	@Action({ rawError: true }) async setPlayerLeftOffset(theOffset: number) {
		this.context.commit(VideoEditorMutationTypes.UPDATE_PLAYER_LEFT_OFFSET, theOffset)
		// need to call this when the larger container window is resized.
	}

	@Mutation [VideoEditorMutationTypes.UPDATE_PLAYER_LEFT_OFFSET](theNewOffset: number) {
		// console.log('Update Player left offset has been called !!! New Offset is: ', theNewOffset)
		this.playerLeftOffset = theNewOffset
	}

	







	/** 
	 * Actions & Mutation for dragging scrubber                             
	 */
	@Action({ rawError: true }) async dragStart(theEvent: MouseEvent) {
		this.context.commit(VideoEditorMutationTypes.DRAG_START, theEvent);
	}

	@Mutation [VideoEditorMutationTypes.DRAG_START](theEventParam: MouseEvent) {

		// console.log('---------------------------- DRAG START MUTATION ------------------------');
		// console.log(theEventParam);
		// who is being dragged 
		const eventPath = theEventParam.composedPath();
		let dragTargetId = '';
		const noSvgFound = true;
		let countIndex = 0;
		while (dragTargetId === '' && countIndex < 100) {
			const evtTarget = eventPath[countIndex] as HTMLElement;
			if (evtTarget.nodeName === 'svg') {
				dragTargetId = evtTarget.id;
			}
			countIndex++;
		}
		if (noSvgFound) 
		// console.log("the drag target is: ", dragTargetId);
		
			switch (dragTargetId) {
			case 'thumbScrubber': {
				this.calculatedWidth = theEventParam.clientX - this.playerLeftOffset;

				if (this.scrubState === 'whole') {
					this.startMarkerPosition = this.calculatedWidth;
					this.startMarkerTimeInSeconds = (this.startMarkerPosition / this.playerWidth) * this.totalTime;
					this.stopMarkerPosition = this.calculatedWidth;
					this.stopMarkerTimeInSeconds = (this.stopMarkerPosition / this.playerWidth) * this.totalTime;
				}

				this.isDragging = true;
				this.isDraggingLeftMarker = false;
				this.isDraggingRightMarker = false;
			}
				break;
		
			case 'leftWing': {
				// this.calculatedWidth = theEventParam.clientX - this.playerLeftOffset;
				this.isDraggingLeftMarker = true;
				this.isDraggingRightMarker = false;
				this.isDragging = false;
			}
				break;

			case 'rightWing': {
				// this.calculatedWidth = theEventParam.clientX - this.playerLeftOffset;
				this.isDraggingRightMarker = true;
				this.isDraggingLeftMarker = false;
				this.isDragging = false;
			}
				break;

			default: {
				console.log("!! BAD DRAG TARGET - for Drag start !!");
				// maybe throw an exception here.
			}
				break;
			}
		
		this.hasDragged = false;

		// console.log('isDragging is: ', this.isDragging);
		// console.log('isDraggingLeftMarker is: ', this.isDraggingLeftMarker);
		// console.log('isDraggingRightMarker is: ', this.isDraggingRightMarker);
		// console.log('-------------------------------------------------------------------------');

	}

	@Action({ rawError: true }) async dragMove(theEvent: MouseEvent) {
		this.context.commit(VideoEditorMutationTypes.DRAG_MOVE, theEvent);
	}

	@Mutation [VideoEditorMutationTypes.DRAG_MOVE](theEventParam: MouseEvent) {

		if (this.isDragging || this.isDraggingLeftMarker || this.isDraggingRightMarker) {
			// console.log('---------------------------- DRAG MOVE MUTATION ------------------------');

			// Is Dragging the Main Scrubber
			if (this.isDragging) {
				this.calculatedWidth = theEventParam.clientX - this.playerLeftOffset;

				switch (this.scrubState) {
				case 'whole': {
					this.startMarkerPosition = this.calculatedWidth;
					this.startMarkerTimeInSeconds = (this.startMarkerPosition / this.playerWidth) * this.totalTime;
					this.stopMarkerPosition = this.calculatedWidth;
					this.stopMarkerTimeInSeconds = (this.stopMarkerPosition / this.playerWidth) * this.totalTime;
				}
					break;
				case 'splitLeft': {
					if (this.calculatedWidth > this.stopMarkerPosition) {
						this.scrubState = 'split';
						this.startMarkerPosition = this.stopMarkerPosition;
						this.startMarkerTimeInSeconds = (this.startMarkerPosition / this.playerWidth) * this.totalTime;
					} else {
						this.startMarkerPosition = this.calculatedWidth;
						this.stopMarkerTimeInSeconds = (this.stopMarkerPosition / this.playerWidth) * this.totalTime;
					}
				}
					break;
				case 'splitRight': {
					if (this.calculatedWidth < this.startMarkerPosition) {
						this.scrubState = 'split';
						this.stopMarkerPosition = this.startMarkerPosition;
						this.stopMarkerTimeInSeconds = this.startMarkerTimeInSeconds;
					} else {
						this.stopMarkerPosition = this.calculatedWidth;
						this.stopMarkerTimeInSeconds = (this.stopMarkerPosition / this.playerWidth) * this.totalTime;
					}
				}
					break;
				default: // if its 'split' do nothing to the others
					break;
				}

				// Is Dragging the Left Marker  
			} else if (this.isDraggingLeftMarker) {
				// console.log('dragging left Marker');
				this.startMarkerPosition = theEventParam.clientX - this.playerLeftOffset + this.rlMarkersOffset;
				this.startMarkerTimeInSeconds = (this.startMarkerPosition / this.playerWidth) * this.totalTime;
				switch (this.scrubState) {
				case 'whole': { // thi will only happen once per drag session
					this.scrubState = 'splitLeft'
					this.calculatedWidth = this.startMarkerPosition;
				}
					break;
				case 'splitLeft': {
					if (this.hasDragged) {  // keep splitLeft if this was split from whole 
						this.calculatedWidth = this.startMarkerPosition;
					} else { // first time from splitLeft makes it full split
						this.scrubState = "split"
					} 
				}
					break;
				case 'split':
				case 'splitRight': {
					if (this.startMarkerPosition > this.stopMarkerPosition) { 
						this.startMarkerPosition = this.stopMarkerPosition;
						this.startMarkerTimeInSeconds = this.stopMarkerTimeInSeconds;
					} 
				}
					break;
				default:
					break;
				}

				// Is Dragging the Right Marker  
			} else if (this.isDraggingRightMarker) {
				// console.log('dragging right marker');
				this.stopMarkerPosition = theEventParam.clientX - this.playerLeftOffset - this.rlMarkersOffset;
				this.stopMarkerTimeInSeconds = (this.stopMarkerPosition / this.playerWidth) * this.totalTime;
				switch (this.scrubState) {
				case 'whole': { // thi will only happen once per drag session
					this.scrubState = 'splitRight'
					this.calculatedWidth = this.stopMarkerPosition;
				}
					break;
				case 'splitRight': {
					if (this.hasDragged) {  // keep splitLeft if this was split from whole 
						this.calculatedWidth = this.stopMarkerPosition;
					} else { // first time from splitLeft makes it full split
						this.scrubState = "split"
					}
				}
					break;
				case 'split':
				case 'splitLeft': {
					if (this.stopMarkerPosition < this.startMarkerPosition) {
						this.stopMarkerPosition = this.startMarkerPosition;
						this.stopMarkerTimeInSeconds = this.startMarkerTimeInSeconds;
					}
				}
					break;
				default:
					break;
				}
			}

			this.hasDragged = true;

			// console.log('-------------------------------------------------------------------------');
		}
	}

	@Action({ rawError: true }) async dragStopOnMouseUp(theEvent: MouseEvent) {
		// console.log('Inside the drag stop mutation .. can I see hasDragged ? - it is: ', this.hasDragged)

		if (this.isDragging || this.isDraggingLeftMarker || this.isDraggingRightMarker) {
			if (this.hasDragged) {

				this.context.commit(VideoEditorMutationTypes.DRAG_MOVE, theEvent);
				if (this.scrubState !== 'split' || this.isDragging)  {
					console.log('Updating position')
					this.context.commit(VideoEditorMutationTypes.SEEK_TO_POSITION_IN_PIXELS, (this.calculatedWidth));
				}
			}
			this.context.commit(VideoEditorMutationTypes.DRAG_STOP);
		}
	}

	@Mutation [VideoEditorMutationTypes.DRAG_STOP]() {

		this.isDragging = false;
		this.isDraggingLeftMarker = false;
		this.isDraggingRightMarker = false;
	}

	@Action({ rawError: true }) async dragStopOnMouseLeave(theEvent: MouseEvent) {
		if (this.scrubState !== 'split' || this.isDragging) {
			this.context.commit(VideoEditorMutationTypes.SEEK_TO_POSITION_IN_PIXELS, (theEvent.clientX - this.playerLeftOffset));
		}
		this.context.commit(VideoEditorMutationTypes.DRAG_STOP);
	}




	@Action({ rawError: true }) async resetScrubberToWhole() {
		if (!this.clipViewOnlyMode) {
			this.context.commit(VideoEditorMutationTypes.RESET_SCRUB_STATE);
		}
	}

	@Mutation [VideoEditorMutationTypes.RESET_SCRUB_STATE]() {

		// console.log("In the VideoEditorStore - RESET SCRUB STATE Mutation !!");
		this.scrubState = 'whole';
		// reset the drag handles
		this.startMarkerPosition = this.calculatedWidth;
		this.startMarkerTimeInSeconds = this.playerCurrentTime;
		this.stopMarkerPosition = this.calculatedWidth;
		this.stopMarkerTimeInSeconds = this.playerCurrentTime;

		this.isDragging = false;
		this.isDraggingLeftMarker = false;
		this.isDraggingRightMarker = false;
	}

	@Action({ rawError: true }) async resetRightDragHandle() {
		this.context.commit(VideoEditorMutationTypes.RESET_RIGHT_HANDLE);
	}

	@Mutation [VideoEditorMutationTypes.RESET_RIGHT_HANDLE]() {

		// console.log("In the VideoEditorStore - RESET Right Handle Mutation !!");
		if (this.scrubState === 'splitLeft') {
			this.scrubState = 'whole';
			this.stopMarkerPosition = this.calculatedWidth;
			this.stopMarkerTimeInSeconds = this.playerCurrentTime;
		}
		if (this.scrubState === 'split') {
			if (this.calculatedWidth < this.startMarkerPosition) {
				this.stopMarkerPosition = this.startMarkerPosition;
				this.stopMarkerTimeInSeconds = this.startMarkerTimeInSeconds;
			} else {
				this.scrubState = 'splitRight';
				this.stopMarkerPosition = this.calculatedWidth;
				this.stopMarkerTimeInSeconds = this.playerCurrentTime;
			}
		}

		this.isDragging = false;
		this.isDraggingLeftMarker = false;
		this.isDraggingRightMarker = false;
	}


	@Action({ rawError: true }) async resetLeftDragHandle() {
		this.context.commit(VideoEditorMutationTypes.RESET_LEFT_HANDLE);
	}

	@Mutation [VideoEditorMutationTypes.RESET_LEFT_HANDLE]() {

		// console.log("In the VideoEditorStore - RESET Left Handle Mutation !!");
		if (this.scrubState === 'splitRight') {
			this.scrubState = 'whole';
			this.startMarkerPosition = this.calculatedWidth;
			this.startMarkerTimeInSeconds = this.playerCurrentTime;
		}
		if (this.scrubState === 'split') {
			if (this.calculatedWidth > this.stopMarkerPosition) {
				this.startMarkerPosition = this.stopMarkerPosition;
				this.startMarkerTimeInSeconds = this.stopMarkerTimeInSeconds;
			} else {
				this.scrubState = 'splitLeft';
				this.startMarkerPosition = this.calculatedWidth;
				this.startMarkerTimeInSeconds = this.playerCurrentTime;

			}
		}

		this.isDragging = false;
		this.isDraggingLeftMarker = false;
		this.isDraggingRightMarker = false;
	}

	@Action({ rawError: true }) async manuallySplitCursorBasedOnDefaultValues() {
		this.context.commit(VideoEditorMutationTypes.MANUALLY_SET_SCRUB_SPLIT);
	}
	
	@Mutation [VideoEditorMutationTypes.MANUALLY_SET_SCRUB_SPLIT]() {

		// console.log("In the VideoEditorStore - manually setting the scrub split on create clip. !!");
		if (this.scrubState === 'whole') {
			if (this.defualtClipBackupAmount > 0.0) {
				this.scrubState = 'split';
				this.startMarkerTimeInSeconds = (this.playerCurrentTime - this.defualtClipBackupAmount);
				this.startMarkerPosition = (this.startMarkerTimeInSeconds / this.totalTime) * this.playerWidth;
				if (this.startMarkerPosition < 0.0) {
					this.startMarkerPosition = 0.0;
					this.startMarkerTimeInSeconds = 0.0;
				}
			} else {
				this.scrubState = 'splitLeft';
				this.startMarkerPosition = this.calculatedWidth;
				this.startMarkerTimeInSeconds = this.playerCurrentTime;
			}
			this.stopMarkerTimeInSeconds = (this.playerCurrentTime - this.defualtClipBackupAmount + this.defaultClipDuration);
			this.stopMarkerPosition = (this.stopMarkerTimeInSeconds / this.totalTime) * this.playerWidth;
			if (this.stopMarkerPosition > this.playerWidth) {
				this.stopMarkerPosition = this.playerWidth;
				this.stopMarkerTimeInSeconds = this.totalTime;
			}
		}

		this.isDragging = false;
		this.isDraggingLeftMarker = false;
		this.isDraggingRightMarker = false;
	}

}
