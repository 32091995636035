import { render, staticRenderFns } from "./HelpInfo.vue?vue&type=template&id=76846a66&scoped=true&"
import script from "./HelpInfo.vue?vue&type=script&lang=ts&"
export * from "./HelpInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76846a66",
  null
  
)

export default component.exports