/* ScoutingReportMixin.ts */

import { SportModel } from '@/models/sport';
import { Component, Mixins } from 'vue-property-decorator';
import { StringsMixin } from './StringsMixin';
import { DataTableHeader } from 'vuetify';

interface ScoutingReportsInformation {
	name: string,
	abbr: string,
	description: string,
}

@Component
export class ScoutingReportMixin extends Mixins(StringsMixin) {
	ScoutingReportsInfoHeaders: Array<DataTableHeader> = [
		{ text: 'Observation', value: 'name', sortable: false },
		{ text: 'Description', value: 'description', sortable: false }
	]

	ScoutingReportsInfo(sport: SportModel = undefined): Array<ScoutingReportsInformation> {
		if( this.IsEmpty(sport) ) return this.ScoutingReportsInfoItems;

		var scoutingReportsInfo: Array<ScoutingReportsInformation> = [];
		for( const label of sport.scoutingLabels.concat(sport.scoutingMoments).concat(sport.scoutingEvaluations) ) {
			const assessment = this.ScoutingReportsInfoItems.find(info => info.name.replace(/\s/g, '').toLowerCase() === label.replace(/\s/g, '').toLowerCase() );
			if( this.IsNotEmpty(assessment) ) {
				scoutingReportsInfo.push(assessment);
			}
		}
		return scoutingReportsInfo;
	}

	ScoutingReportsInfoItems: Array<ScoutingReportsInformation> = [
		{
			name: "Technical",
			abbr: "Tech",
			description: "Ball control, dribbling, passing, shooting"
		},
		{
			name: "Tactical",
			abbr: "Tact",
			description: "Strategy, problem solving, teamwork",
		},
		{
			name: "Physical",
			abbr: "Phys",
			description: "Speed, acceleration, agility, power, recovery",
		},
		{
			name: "Mental",
			abbr: "Ment",
			description: "Focus, resilience, emotion, energy management",
		},
		{
			name: "Attacking Organization",
			abbr: "A.Or",
			description: "Moments when team is in posession",
		},
		{
			name: "Attacking Transition",
			abbr: "A.Tr",
			description: "Moments when team gains posession",
		},
		{
			name: "Attacking Set Plays",
			abbr: "A.SP",
			description: "Moments when team wins a set piece",
		},
		{
			name: "Defending Organization",
			abbr: "D.Or",
			description: "Moments when team is out of posession",
		},
		{
			name: "Defending Transition",
			abbr: "D.Tr",
			description: "Moments when team loses posession",
		},
		{
			name: "Defending Set Plays",
			abbr: "D.SP",
			description: "Moments when team defends a set piece",
		},
		{
			name: "Competitive",
			abbr: "Cmp",
			description: "Desire, drive, ambition, combative, determination",
		},
		{
			name: "Resilience",
			abbr: "Res",
			description: "Toughness, recovery, strength, fortitude, endurance",
		},
		{
			name: "Intelligence",
			abbr: "Int",
			description: "Decison making, awareness, understanding, creativity",
		},
		{
			name: "Speed",
			abbr: "Spd",
			description: "Physical pace, mental quickness, velocity of play",
		},
		{
			name: "Presence",
			abbr: "Prs",
			description: "Influence, leadership, impact, importance",
		},
	]
}
