import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { BaseSoccerPosition } from '@/../types/enums/sports';
import { notificationStore } from '@/store/index';

@Component
export class CSVUploadMixin extends Vue {
	@Prop({ default: "/download/SampleTemplate.csv" }) fileTemplate: string;
	@Prop({ default: [{
			number: 6,
			firstName: "Abigail",
			lastName: "Armstrong",
			email: "abby101@bestathletes.co",
			position: BaseSoccerPosition.Forward,
			dateOfBirth: "16 April 2006",
			gradYear: "2024",
			schoolCommitment: ""
		},
	]}) sampleCSVData: Array<Record<string, any>>;

	get CSVSampleItems(): Array<Record<string, any>> {
		return this.sampleCSVData;
	}

	get CSVSampleItem(): Record<string, any> {
		return this.CSVSampleItems[0];
	}

	get CSVColumns(): string[] {
		return Object.keys(this.CSVSampleItem);
	}

	get CSVDataTableHeaders(): DataTableHeader[] {
		if( !this.keys || this.keys.length == 0 ) {
			this.keys = this.CSVColumns;
		}
		return [
			{ text: "", sortable: false, value: "row", align: "end", width: "60px" },
			...this.keys.map(col => ({
				text: col,
				sortable: false,
				value: col,
			})),
		]
	}

	keys: string[] = [];
	csvColumns(csv: string): string[] {
		const lines = csv.split('\n');
		if( lines.length == 0 ) throw new Error(`CSV file is empty`);
		return lines[0].trim().split(',').map(h => h.trim());
	}
	csv2rows(csv: string): string[][] {
		const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
		return csv.split('\n').map(line => line.replaceAll('\r', '')).filter(line => line.length > 0).map(line => line.split(regex)).slice(1);
	}
	csv2json(csv: string): Record<string, string>[] {
		this.keys = this.csvColumns(csv);
		const rows = this.csv2rows(csv);
		const rowsJson = rows.map( row => {
			return this.keys.reduce((json, value, index) => { 
				if(row[index] === undefined || row[index] === '#DIV/0!'){
					// Missing or invalid value in cell
					json[value] = undefined
				}else if(["email","dateOfBirth","assessmentDate","Phone"].includes(value)){
					// For emails, only trim whitespace
					json[value] = row[index].trim();
				} else if(["logoUrl", "website"].includes(value) || value.includes("links")) {
					// For URLs, only trim whitespace
					json[value] = row[index].trim();
				}
				else{
					// For all other values, remove any non-alphanumeric or punctuation chars '" "10"' => 10
					json[value] = row[index].replace(/[^A-Za-z0-9-.,: \r]/g, '').trim();
				}
				return json;
			}, {})			
		})		
		return rowsJson;
	}

	/** TEMPLATE File */
	get TemplateFileTitle(): string {
		const fileNameStart = this.fileTemplate.indexOf("/",2) + 1;
		return this.fileTemplate.substring(fileNameStart);
	}

	downloadTemplate(): void {
		window.open(this.fileTemplate, '_self');
		notificationStore.pushSnackbarSuccess({message:`Downloaded ${this.TemplateFileTitle}`});
	}
}