
import { Component, Prop, Mixins } from 'vue-property-decorator';

import FormAddArea from './FormAddArea.vue';
import {fileApi} from '../../api/FileApi';
import { UploadResponse } from '../utilities/upload/UploadDialog.vue';
import { BAIconsMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component({
	components: { FormAddArea },
})
export default class FileUploadArea extends Mixins(VuetifyMixin, BAIconsMixin, StringsMixin){

	@Prop({ required: true }) prefix: string;
	@Prop({ default: 140 }) height: number;
	@Prop({ default: '100%' }) width: string;
	@Prop() value: string;
	@Prop({ default: "" }) buttonText: string;
	@Prop({ default: "" }) buttonIcon: string;
	@Prop({ default: () => ['image'] }) allowedMimeTypes: string[];

	input(value: File){
		this.$emit('input', value);
	}
	inputFileUrl(fileUrl: string){
		this.$emit('input', fileUrl);
	}

	get HasIcon() {
		return this.IsNotEmpty(this.buttonIcon);
	}
	get HasText() {
		return this.IsNotEmpty(this.buttonText);
	}
	get HasIconNoText() {
		return this.HasIcon && !this.HasText;
	}
	isHovered = false;
	file: File | null = null;
	imagePreview: string | null = null;
	fileReader = new FileReader();
	uploading: boolean = false;
	progress: number = 0;

	dragover(): void { this.isHovered = true; }
	dragleave(): void { this.isHovered = false; }

	isAllowedMimetype(allowedMIMETypes: string[], givenMIMEType: string): boolean{
		return allowedMIMETypes.findIndex(allowed => givenMIMEType.includes(allowed)) !== -1;
	}
	previewImage(file): void{
		this.fileReader.onload = ($event: any) => {
			this.imagePreview = $event.target.result;
		}
		this.fileReader.readAsDataURL(file);
	}

	handleFileChange($event: any): void{
		this.isHovered = false;
		this.imagePreview = null;
		const files: FileList = $event.target.files;
		this.file = files.item(0);
		if (this.isAllowedMimetype(['image'], this.file.type)) {
			this.previewImage(this.file);
		}
		if(this.isAllowedMimetype(this.allowedMimeTypes, this.file.type)){
			this.uploadFile(this.file);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if( this.formType === 'image' ){
			this.input(this.file);
		} else if( this.formType === 'text' ) {
			this.inputFileUrl(this.file.name);
		} else if( this.formType === 'pdf' ) {
			this.inputFileUrl(this.file.name);
		}
		// Reset the file upload
		$event.target.value = ''
	}

	get formType(): string {
		if( this.isAllowedMimetype(this.allowedMimeTypes, 'image') ) return 'image';
		if( this.isAllowedMimetype(this.allowedMimeTypes, 'text') ) return 'text';
		if( this.isAllowedMimetype(this.allowedMimeTypes, 'pdf') ) return 'pdf';
		return 'unknown';
	}
	async uploadFile(file: File): Promise<void> {
		try {
			const result = await fileApi.upload(file, this.prefix, event => {
				this.progress = Math.round((100 * event.loaded) / event.total);
			});

			this.imagePreview = result.url;
			this.$emit('uploaded', {file, key: result.key, url: result.url} as UploadResponse);
			this.inputFileUrl(result.url);

		} catch (error) {
			console.error('File Upload Error', error);
		} finally {
			this.uploading = false;
		}
	}
}
